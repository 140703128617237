import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, TimeoutError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReactionApiService {
  constructor(private http: HttpClient) {}

  reactToArticle(articleId: number, reactionId: string): Observable<void> {
    return this.http.put<void>(
      environment.apiBaseUrl + `/api/article/${articleId}/reaction?reactionId=${reactionId}`,
      null
    );
    // return new Observable<void>((subscriber) => {
    //   subscriber.next();
    //   subscriber.complete();
    //   //subscriber.error({ status: 400, error: { ErrorCode: 2000 } });
    // }).pipe(delay(2000));
  }

  reactToAnnouncement(
    schoolId: number,
    announcementId: number,
    studentId: number,
    reactionId: string
  ): Observable<void | TimeoutError> {
    const data = {
      announcementId: announcementId,
      studentId: studentId,
      reactionId: reactionId
    };
    return this.http.put<void | TimeoutError>(
      environment.apiBaseUrl + `/api/school/${schoolId}/announcements/reaction`,
      data
    );
    // return new Observable<void>((subscriber) => {
    //   subscriber.next({
    //   });
    //   subscriber.complete();
    // }).pipe(delay(2000));
  }
}
