import { Capacitor } from '@capacitor/core';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import * as _ from 'lodash';
import * as moment from 'moment';
import { normalize, schema } from 'normalizr';
import { environment } from 'src/environments/environment';
import { Channel } from '../../models/channel.model';
import { Attachment, Item } from '../../models/item.model';
import { Publisher, PublisherAvatar } from '../../models/publisher.model';
import { ReadReceipt } from '../../models/read-receipt.model';
import { School, SchoolAsset } from '../../models/school.model';
import { Student } from '../../models/student.model';
import * as fromCore from '../actions/core.action';

const schoolInfoEntity = new schema.Entity('schoolInfo');
const publisherEntity = new schema.Entity('publishers');
const channelEntity = new schema.Entity('channels', {
  publishers: [publisherEntity],
  schoolInfo: schoolInfoEntity
});
const channelsEntity = [channelEntity];
const attachmentEntity = new schema.Entity('attachments', {}, { idAttribute: 'attachmentId' });
const itemEntity = new schema.Entity(
  'items',
  {
    createdBy: publisherEntity,
    attachments: [attachmentEntity]
  },
  { idAttribute: 'sequenceNumber' }
);
const itemsEntity = [itemEntity];

const studentEntity = new schema.Entity('students');
const schoolEntity = new schema.Entity('schools', {
  students: [studentEntity]
});
const schoolsEntity = [schoolEntity];

export interface ItemLoadingState {
  queryTimestamp?: string;
  inProgress: boolean;
  hasMore: boolean;
}

export interface ItemPageLoadingInfo {
  id: string;
  loadingNew: ItemLoadingState;
  loadingMore: ItemLoadingState;
}

export type ItemPageLoadingInfoState = EntityState<ItemPageLoadingInfo>;

export const itemPageLoadingInfoAdapter: EntityAdapter<ItemPageLoadingInfo> =
  createEntityAdapter<ItemPageLoadingInfo>();

export interface ItemState extends EntityState<Item> {
  currentPageId: string;
  pageState: ItemPageLoadingInfoState;
}

export const itemAdapter: EntityAdapter<Item> = createEntityAdapter<Item>({
  selectId: (item: Item) => item.sequenceNumber,
  sortComparer: (a, b) => {
    return b.sequenceNumber - a.sequenceNumber;
  }
});

export type AttachmentState = EntityState<Attachment>;

export const attachmentAdapter: EntityAdapter<Attachment> = createEntityAdapter<Attachment>({
  selectId: (attachment: Attachment) => attachment.attachmentId
});

export type ChannelState = EntityState<Channel>;

export const channelAdapter: EntityAdapter<Channel> = createEntityAdapter<Channel>();

export type PublisherState = EntityState<Publisher>;

export const publisherAdapter: EntityAdapter<Publisher> = createEntityAdapter<Publisher>();

export type PublisherAvatarState = EntityState<PublisherAvatar>;

export const publisherAvatarAdapter: EntityAdapter<PublisherAvatar> = createEntityAdapter<PublisherAvatar>();

export type SchoolState = EntityState<School>;

export const schoolAdapter: EntityAdapter<School> = createEntityAdapter<School>();

export type SchoolAssetState = EntityState<SchoolAsset>;

export const schoolAssetAdapter: EntityAdapter<SchoolAsset> = createEntityAdapter<SchoolAsset>();

export type StudentState = EntityState<Student>;

export const studentAdapter: EntityAdapter<Student> = createEntityAdapter<Student>();

export type ItemReadReceiptState = EntityState<ReadReceipt>;

export const itemReadReceiptAdapter: EntityAdapter<ReadReceipt> = createEntityAdapter<ReadReceipt>();

export interface UnreadItemsState {
  remoteUnreadItems: number;
  lastTotalUnreadFetch: string;
}

export interface PaymentsState {
  paymentMethodId: string;
}

export interface UnreadItemsState {
  remoteUnreadItems: number;
  lastTotalUnreadFetch: string;
}

export interface CoreState {
  firstTimeOpening: boolean;
  loading: boolean;
  loadingFailed: boolean;
  refreshCoreUnderway: boolean;
  schools: SchoolState;
  schoolAssets: SchoolAssetState;
  students: StudentState;
  channels: ChannelState;
  publishers: PublisherState;
  publisherAvatars: PublisherAvatarState;
  items: ItemState;
  attachments: AttachmentState;
  itemReadReceipts: ItemReadReceiptState;
  profileExists: boolean;
  channelsSchoolsLoaded: boolean;
  initialLoadingActive: boolean;
  showDownloadingNewItemsSpinner: boolean;
  showFirstContentLoad: string;
  lastItemPurge: Date;
  unreadItemsState: UnreadItemsState;
  paymentsState: PaymentsState;
  coldStartTab: 'Hub' | 'Chats';
}

export const initialState: CoreState = {
  firstTimeOpening: true,
  loading: false,
  loadingFailed: false,
  refreshCoreUnderway: false,
  schools: schoolAdapter.getInitialState(),
  schoolAssets: schoolAssetAdapter.getInitialState(),
  students: studentAdapter.getInitialState(),
  channels: channelAdapter.getInitialState(),
  publishers: publisherAdapter.getInitialState(),
  publisherAvatars: publisherAvatarAdapter.getInitialState(),
  items: {
    pageState: itemPageLoadingInfoAdapter.getInitialState(),
    currentPageId: null,
    ...itemAdapter.getInitialState()
  },
  attachments: attachmentAdapter.getInitialState(),
  itemReadReceipts: itemReadReceiptAdapter.getInitialState(),
  profileExists: null,
  channelsSchoolsLoaded: false,
  initialLoadingActive: undefined,
  showDownloadingNewItemsSpinner: false,
  showFirstContentLoad: null,
  lastItemPurge: moment().utc().toDate(),
  unreadItemsState: {
    remoteUnreadItems: 0,
    lastTotalUnreadFetch: null
  },
  paymentsState: {
    paymentMethodId: null
  },
  coldStartTab: 'Hub'
};

export function reducer(state = initialState, action: fromCore.CoreAction): CoreState {
  switch (action.type) {
    case fromCore.INITIALISE_CORE: {
      return {
        ...state,
        refreshCoreUnderway: false,
        showDownloadingNewItemsSpinner: false,
        showFirstContentLoad: null,
        unreadItemsState: state.unreadItemsState
          ? state.unreadItemsState
          : {
              remoteUnreadItems: 0,
              lastTotalUnreadFetch: null
            },
        paymentsState: state.paymentsState
          ? state.paymentsState
          : {
              paymentMethodId: null
            },
        coldStartTab: 'Hub'
      };
    }
    case fromCore.REFRESH_CORE: {
      return {
        ...state,
        loading: true,
        loadingFailed: false,
        refreshCoreUnderway: true
      };
    }

    case fromCore.REFRESH_CORE_SUCCESS:
    case fromCore.REFRESH_CORE_SUBSCRIPTION_CHANGED: {
      const normalizedChannelData = normalize(action.payload.channels, channelsEntity);
      const normalizedSchoolData = normalize(action.payload.schools, schoolsEntity);
      return {
        ...state,
        loading: false,
        loadingFailed: false,
        refreshCoreUnderway: false,
        schools: schoolAdapter.setAll(_.values(normalizedSchoolData.entities.schools), state.schools),
        students: studentAdapter.setAll(_.values(normalizedSchoolData.entities.students), state.students),
        channels: channelAdapter.setAll(_.values(normalizedChannelData.entities.channels), state.channels),
        publishers: publisherAdapter.upsertMany(_.values(normalizedChannelData.entities.publishers), state.publishers),
        items:
          action.type === fromCore.REFRESH_CORE_SUBSCRIPTION_CHANGED
            ? {
                pageState: itemPageLoadingInfoAdapter.getInitialState(),
                currentPageId: null,
                ...itemAdapter.getInitialState()
              }
            : state.items,
        channelsSchoolsLoaded: true
      };
    }

    case fromCore.REFRESH_CORE_FAIL: {
      return {
        ...state,
        loading: false,
        loadingFailed: true,
        refreshCoreUnderway: false
      };
    }

    case fromCore.SET_SUBSCRIBED_CHANNELS: {
      const normalizedChannelData = normalize(action.payload.channels, channelsEntity);
      return {
        ...state,
        channels: channelAdapter.setAll(_.values(normalizedChannelData.entities.channels), state.channels),
        publishers: publisherAdapter.upsertMany(_.values(normalizedChannelData.entities.publishers), state.publishers)
      };
    }

    case fromCore.LOAD_PUBLISHER_AVATARS_SUCCESS: {
      return {
        ...state,
        publisherAvatars: publisherAvatarAdapter.addMany(action.payload.avatars, state.publisherAvatars)
      };
    }

    case fromCore.LOAD_SCHOOL_ASSETS_SUCCESS: {
      return {
        ...state,
        schoolAssets: schoolAssetAdapter.addMany(action.payload.assets, state.schoolAssets)
      };
    }
    case fromCore.LOAD_NEW_ITEMS: {
      const pageState = {
        ...state.items.pageState.entities[action.payload.pageId],
        id: action.payload.pageId
      };
      pageState.loadingNew = {
        ...pageState.loadingNew,

        inProgress: true,
        hasMore: true
      };

      return {
        ...state,
        items: {
          ...state.items,
          currentPageId: action.payload.pageId,
          pageState: {
            ...itemPageLoadingInfoAdapter.upsertOne(pageState, state.items.pageState)
          }
        }
      };
    }
    case fromCore.LOAD_MORE_ITEMS: {
      const pageState = {
        ...state.items.pageState.entities[action.payload.pageId],
        id: action.payload.pageId
      };
      pageState.loadingMore = {
        ...pageState.loadingMore,
        inProgress: true,
        hasMore: true
      };
      return {
        ...state,
        items: {
          ...state.items,
          currentPageId: action.payload.pageId,
          pageState: {
            ...itemPageLoadingInfoAdapter.upsertOne(pageState, state.items.pageState)
          }
        }
      };
    }
    case fromCore.LOAD_ITEMS_SUCCESS: {
      const op = action.payload.type === fromCore.LOAD_NEW_ITEMS ? 'loadingNew' : 'loadingMore';
      let normalizedItemData;

      if (action.payload.pageId === 'hub-todo') {
        const todoItemIds = Object.keys(state.items.entities)
          .filter((key) => state.items.entities[key].pageSource === 'hub-todo')
          .map((key) => state.items.entities[key].id);
        const lowestSequenceNumber = todoItemIds.length === 0 ? 0 : Math.min.apply(null, todoItemIds);

        const filteredResults = action.payload.itemSearchResults.results.filter(
          (searchItem) => searchItem.sequenceNumber >= lowestSequenceNumber || op === 'loadingMore'
        );

        normalizedItemData = normalize(filteredResults, itemsEntity);
      } else {
        normalizedItemData = normalize(action.payload.itemSearchResults.results, itemsEntity);
      }

      _.forEach(normalizedItemData.entities.attachments, (value, key) => {
        const foundItem: Item = _.find(normalizedItemData.entities.items, (item) => item.attachments.indexOf(key) >= 0);
        if (foundItem.itemType === 0) {
          value.url = environment.apiBaseUrl + '/api/attachments/' + key;
        } else {
          value.url =
            environment.apiBaseUrl +
            `/api/school/${foundItem.schoolId}/announcements/${foundItem.id}/attachment/${key}`;
        }
      });
      // Tag items with page they were loaded through
      _.forEach(normalizedItemData.entities.items, (value, key) => {
        value.pageSource = action.payload.pageId;
      });
      const pageState = {
        ...state.items.pageState.entities[action.payload.pageId]
      };
      pageState[op] = {
        inProgress: false,
        hasMore: action.payload.itemSearchResults.hasMoreItems,
        queryTimestamp: action.payload.itemSearchResults.queryTimestamp
      };

      const itemUpdates = _.values<Item>(normalizedItemData.entities.items).reduce<number[]>((updateAccum, item) => {
        const currentItem = state.items.entities[item.sequenceNumber];
        if (currentItem) {
          // Updates loaded via the 'hub' pages trump the page source
          if (op === 'loadingNew' && currentItem.pageSource !== 'unread-items') {
            item.pageSource = currentItem.pageSource;
          } else {
            item.pageSource = item.pageSource.startsWith('hub-') ? item.pageSource : currentItem.pageSource;
          }

          (updateAccum || []).push(item.sequenceNumber);
          item.showInFeed = currentItem.showInFeed || action.payload.showInFeed;

          // If an item gets loaded after we've marked it read locally but before we've
          // sent the read receipt, keep the local state
          item.localReadOn = currentItem.localReadOn;
          item.readOn = item.readOn || currentItem.readOn;
        } else {
          item.showInFeed = action.payload.showInFeed;
        }
        return updateAccum;
      }, []);

      return {
        ...state,
        loading: false,
        loadingFailed: false,
        items: {
          // ngrx entities bug https://github.com/ngrx/platform/issues/993
          ...itemAdapter.addMany(
            _.values(normalizedItemData.entities.items),
            itemAdapter.removeMany(itemUpdates || [], state.items)
          ),
          pageState: {
            ...itemPageLoadingInfoAdapter.upsertOne(pageState, state.items.pageState)
          }
        },
        publishers: publisherAdapter.upsertMany(_.values(normalizedItemData.entities.publishers), state.publishers),
        attachments: attachmentAdapter.addMany(_.values(normalizedItemData.entities.attachments), state.attachments)
      };
    }
    case fromCore.LOAD_ITEMS_FAIL: {
      const op = action.payload.type === fromCore.LOAD_NEW_ITEMS ? 'loadingNew' : 'loadingMore';
      const pageState = {
        ...state.items.pageState.entities[action.payload.pageId]
      };
      pageState[op] = {
        ...pageState[op],
        inProgress: false,
        hasMore: true
      };

      // const operationType =
      //   action.payload.type === fromCore.LOAD_NEW_ITEMS
      //     ? 'loadingNew'
      //     : 'loadingMore';
      // const changes = {
      //   [operationType]: {
      //     queryTimestamp: null,
      //     inProgress: false,
      //     hasMore: true // We don't know so allow another go
      //   }
      // };
      // mixin current query timestamp if defined
      // const currentPageState =
      //   state.items.pageState.entities[action.payload.pageId];
      // if (currentPageState) {
      //   const currentPageLoadingInfo: ItemLoadingState =
      //     currentPageState[operationType];
      //   if (currentPageLoadingInfo) {
      //     changes[operationType].queryTimestamp =
      //       currentPageLoadingInfo.queryTimestamp;
      //   }
      // }
      return {
        ...state,
        items: {
          ...state.items,
          pageState: {
            ...itemPageLoadingInfoAdapter.upsertOne(pageState, state.items.pageState)
          }
        }
      };
    }
    case fromCore.DELETE_ITEMS: {
      return {
        ...state,
        items: itemAdapter.removeMany(action.payload.ids, state.items)
      };
    }
    case fromCore.UPDATE_ITEM: {
      return {
        ...state,
        items: itemAdapter.updateOne({ id: action.payload.id, changes: action.payload.changes }, state.items)
      };
    }
    case fromCore.OPEN_ATTACHMENT_FAIL: {
      return {
        ...state,
        attachments: attachmentAdapter.updateOne(
          {
            id: action.payload.id,
            changes: { downloaded: false, downloading: false }
          },
          state.attachments
        )
      };
    }
    case fromCore.DOWNLOAD_ATTACHMENT: {
      return {
        ...state,
        attachments: attachmentAdapter.updateOne(
          {
            id: action.payload.id,
            changes: { downloaded: false, downloading: true }
          },
          state.attachments
        )
      };
    }
    case fromCore.DOWNLOAD_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        attachments: attachmentAdapter.updateOne(
          {
            id: action.payload.id,
            changes: {
              // non hybrid attachments can't be downloaded
              downloaded: !!action.payload.url,
              downloading: false,
              fullUrl: action.payload.url
            }
          },
          state.attachments
        )
      };
    }
    case fromCore.DOWNLOAD_ATTACHMENT_IMAGE_SUCCESS: {
      const changes = {
        downloaded: true,
        downloading: false,
        [action.payload.thumb ? 'thumbUrl' : 'fullUrl']: action.payload.url
      };
      return {
        ...state,
        attachments: attachmentAdapter.updateOne(
          {
            id: action.payload.id,
            changes
          },
          state.attachments
        )
      };
    }
    case fromCore.CLEAR_ATTACHMENT_DOWNLOADS: {
      const updates = _.values<Attachment>(state.attachments.entities).reduce<Update<Attachment>[]>(
        (updateAccum, attachment) => {
          if (attachment.downloaded) {
            (updateAccum || []).push({
              id: attachment.attachmentId,
              changes: { fullUrl: null, downloaded: false }
            });
          }
          return updateAccum;
        },
        []
      );
      return {
        ...state,
        attachments: attachmentAdapter.updateMany(updates, state.attachments)
      };
    }
    case fromCore.DELETE_ATTACHMENTS: {
      return {
        ...state,
        attachments: attachmentAdapter.removeMany(action.payload.ids, state.attachments)
      };
    }
    case fromCore.TOGGLE_ITEM_DONE_SUCCESS: {
      return {
        ...state,
        items: itemAdapter.updateOne(
          {
            id: action.payload.id,
            changes: { done: action.payload.done }
          },
          state.items
        )
      };
    }
    case fromCore.QUEUE_ITEM_READ_RECEIPT: {
      return {
        ...state,
        itemReadReceipts: itemReadReceiptAdapter.upsertOne(
          {
            id: action.payload.id,
            item: state.items.entities[action.payload.id],
            failureCode: null,
            retryNext: null
          },
          state.itemReadReceipts
        )
      };
    }
    case fromCore.MARK_ITEM_READ: {
      // If we read an item that was loaded through the search page it will be marked as read on the server
      // however we purge search items on exiting search consequently our local count will be incorrect so
      // we need to reduce the remoteUnreadItems count
      const remoteUnreadItems = state.unreadItemsState.remoteUnreadItems;
      const item = state.items.entities[action.payload.id];

      return {
        ...state,
        items: itemAdapter.updateOne(
          {
            id: action.payload.id,
            changes: { readOn: item?.readOn || 'now', localReadOn: new Date().toISOString() }
          },
          state.items
        ),
        unreadItemsState: {
          ...state.unreadItemsState,
          remoteUnreadItems: remoteUnreadItems > 0 ? remoteUnreadItems : 0
        }
      };
    }
    case fromCore.PROCESS_ITEM_READ_RECEIPT_SUCCESS: {
      if (action.payload.readOn) {
        return {
          ...state,
          items: itemAdapter.updateOne(
            {
              id: action.payload.id,
              changes: { readOn: action.payload.readOn, localReadOn: new Date().toISOString() }
            },
            state.items
          ),
          itemReadReceipts: itemReadReceiptAdapter.removeOne(action.payload.id, state.itemReadReceipts)
        };
      } else {
        return {
          ...state,
          itemReadReceipts: itemReadReceiptAdapter.removeOne(action.payload.id, state.itemReadReceipts)
        };
      }
    }
    case fromCore.PROCESS_ITEM_READ_RECEIPT_FAIL: {
      return {
        ...state,
        itemReadReceipts: itemReadReceiptAdapter.updateOne(
          {
            id: action.payload.id,
            changes: {
              failureCode: action.payload.reason,
              retryNext: new Date(new Date().getTime() + 1000 * 60)
            }
          },
          state.itemReadReceipts
        )
      };
    }
    case fromCore.PROFILE_CHECK: {
      return {
        ...state,
        loading: true
      };
    }

    case fromCore.PROFILE_CHECK_SUCCESS: {
      return {
        ...state,
        loading: false,
        profileExists: true
      };
    }

    case fromCore.PROFILE_CHECK_FAIL: {
      return {
        ...state,
        loading: false,
        profileExists: false
      };
    }

    case fromCore.ONBOARDING_CHECK: {
      return {
        ...state,
        loading: false
      };
    }

    case fromCore.ONBOARDING_COMPLETE: {
      return {
        ...state,
        loading: false,
        profileExists: true
      };
    }

    case fromCore.ONBOARDING_CHECK_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromCore.ADD_CHANNEL_TO_STATE_SUCCESS: {
      const normalizedChannelData = normalize([action.payload], channelsEntity);
      const pageId = 'channel-' + action.payload.id;
      const pageState = {
        ...state.items.pageState.entities[pageId],
        loadingMore: null,
        loadingNew: null
      };
      return {
        ...state,
        channels: channelAdapter.addMany(_.values(normalizedChannelData.entities.channels), state.channels),
        publishers: publisherAdapter.addMany(_.values(normalizedChannelData.entities.publishers), state.publishers),
        // We need to reset the page state if we have unsubscribed then resubscribed
        items: {
          ...state.items,
          pageState: {
            ...itemPageLoadingInfoAdapter.upsertOne(pageState, state.items.pageState)
          }
        }
      };
    }

    case fromCore.ADD_CHANNELS_TO_STATE_SUCCESS: {
      const normalizedChannelData = normalize(action.payload, channelsEntity);

      const pageLoadUpdates = action.payload
        .map((channel) => {
          return {
            ...state.items.pageState.entities['channel-' + channel.id],
            loadingMore: null,
            loadingNew: null
          };
        })
        .filter((i) => i.id);

      return {
        ...state,
        channels: channelAdapter.addMany(_.values(normalizedChannelData.entities.channels), state.channels),
        publishers: publisherAdapter.addMany(_.values(normalizedChannelData.entities.publishers), state.publishers),
        // We need to reset the page state if we have unsubscribed then resubscribed
        items: {
          ...state.items,
          pageState: {
            ...itemPageLoadingInfoAdapter.upsertMany(pageLoadUpdates, state.items.pageState)
          }
        }
      };
    }

    case fromCore.DELETE_CHANNEL: {
      return {
        ...state,
        channels: channelAdapter.removeOne(action.payload.id, state.channels)
      };
    }

    case fromCore.ADD_SCHOOL_TO_STATE_SUCCESS: {
      return {
        ...state,
        schools: schoolAdapter.addOne(action.payload, state.schools)
      };
    }

    case fromCore.REMOVE_SCHOOL_FROM_STATE_SUCCESS: {
      const normalizedSchoolData = normalize(action.payload, schoolsEntity);
      return {
        ...state,
        schools: schoolAdapter.setAll(_.values(normalizedSchoolData.entities.schools), state.schools)
      };
    }

    case fromCore.ADD_SCHOOL_STUDENTS: {
      const studentIds = action.payload.students.map((student) => student.id);
      return {
        ...state,
        schools: schoolAdapter.updateOne(
          {
            id: action.payload.schoolId,
            changes: {
              students: studentIds,
              subscriptionState: 3
            }
          },
          state.schools
        ),
        students: studentAdapter.addMany(action.payload.students, state.students)
      };
    }

    case fromCore.TURN_OFF_WELCOME: {
      return {
        ...state,
        firstTimeOpening: false
      };
    }

    case fromCore.INITIAL_LOADING_START: {
      return {
        ...state,
        initialLoadingActive: true
      };
    }

    case fromCore.INITIAL_LOADING_COMPLETE: {
      return {
        ...state,
        initialLoadingActive: false
      };
    }

    case fromCore.UPDATE_FILE_PATHS: {
      const updates = _.values<Attachment>(state.attachments.entities).reduce<Update<Attachment>[]>(
        (updateAccum, attachment) => {
          if (attachment.thumbUrl || attachment.fullUrl) {
            const changes: any = {};
            if (attachment.thumbUrl) {
              changes.thumbUrl = Capacitor.convertFileSrc(action.payload.path + '/thumbs/' + attachment.attachmentId);
            }
            if (attachment.fullUrl) {
              changes.fullUrl = Capacitor.convertFileSrc(
                action.payload.path + '/attachments/' + attachment.attachmentId
              );
            }
            (updateAccum || []).push({
              id: attachment.attachmentId,
              changes
            });
          }

          return updateAccum;
        },
        []
      );
      return {
        ...state,
        attachments: attachmentAdapter.updateMany(updates, state.attachments)
      };
    }

    case fromCore.SHOW_CHECK_NEW_ITEMS: {
      return {
        ...state,
        showDownloadingNewItemsSpinner: true
      };
    }

    case fromCore.HIDE_CHECK_NEW_ITEMS: {
      return {
        ...state,
        showDownloadingNewItemsSpinner: false
      };
    }
    case fromCore.SHOW_FIRST_CONTENT_LOAD: {
      return {
        ...state,
        showFirstContentLoad: action.payload.page
      };
    }

    case fromCore.HIDE_FIRST_CONTENT_LOAD: {
      return {
        ...state,
        showFirstContentLoad: null
      };
    }

    case fromCore.RESET_ITEM_LOADING_STATE: {
      const pageState = state.items.pageState;
      const updates: Update<ItemPageLoadingInfo>[] = [];

      for (const key in action.payload.changes) {
        if (Object.prototype.hasOwnProperty.call(action.payload.changes, key)) {
          if (pageState.entities[key] && pageState.entities[key].loadingNew && action.payload.changes[key] === true) {
            updates.push({
              id: key,
              changes: { loadingNew: { ...pageState.entities[key].loadingNew, queryTimestamp: null } }
            });
          }
          if (pageState.entities[key] && pageState.entities[key].loadingMore) {
            updates.push({ id: key, changes: { loadingMore: undefined } });
          }
        }
      }
      return {
        ...state,
        items: {
          ...state.items,
          pageState: itemPageLoadingInfoAdapter.updateMany(updates, state.items.pageState)
        }
      };
    }
    case fromCore.PURGE_ITEMS: {
      return {
        ...state,
        lastItemPurge: action.payload.date
      };
    }

    case fromCore.FETCH_UNREAD_ITEM_COUNT_SUCCESS: {
      return {
        ...state,
        unreadItemsState: {
          remoteUnreadItems: action.payload.remoteUnreadItemCount,
          lastTotalUnreadFetch: action.payload.unreadItemFetchDate
        }
      };
    }

    case fromCore.UPDATE_PAYMENT_METHOD_ID: {
      return {
        ...state,
        paymentsState: {
          paymentMethodId: action.payload.paymentMethodId
        }
      };
    }

    case fromCore.CLEAR_PAYMENT_METHOD_ID: {
      return {
        ...state,
        paymentsState: {
          paymentMethodId: null
        }
      };
    }

    case fromCore.ADD_PUBLISHERS: {
      return {
        ...state,
        publishers: publisherAdapter.upsertMany(action.payload.publishers, state.publishers)
      };
    }

    case fromCore.SET_COLD_START_TAB: {
      return {
        ...state,
        coldStartTab: action.payload.tab
      };
    }

    default: {
      return state;
    }
  }
}
