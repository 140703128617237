/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, selectAll, selectEntities } from '../reducers/chat-message-receipts.reducer';

export const getChatMessageReceiptsState = createFeatureSelector<State>('chatMessageReceiptsState');
export const getChatMessageReceiptEntities = createSelector(getChatMessageReceiptsState, (state) =>
  selectEntities(state)
);
export const getAllChatMessageReceipts = createSelector(getChatMessageReceiptsState, (state) => selectAll(state));

export const getPendingReadReceipts = createSelector(getAllChatMessageReceipts, (items) =>
  items.filter((item) => !item.sent && item.failureCode !== 404 && item.failureCode !== 403)
);
export const getSentReadReceipts = createSelector(getAllChatMessageReceipts, (items) =>
  items.filter((item) => item.sent)
);
export const getNextReceipt = (props: { refDate: Date }) =>
  createSelector(getPendingReadReceipts, (items) => {
    return items.find((r) => r.retryNext == null || r.retryNext < props.refDate);
  });
export const getById = (props: { id: number }) =>
  createSelector(getChatMessageReceiptEntities, (entities) => {
    return entities[props.id];
  });
