// Modules
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AttendanceSessions, AttendanceSummary } from '../models/attendance.model';
import { ContactDetails } from '../models/contact-details.model';
// Models
import { School } from '../models/school.model';
import { Student } from '../models/student.model';
import { StudentTimetable } from '../models/timetable.model';

export interface ItemDto {
  itemType: number;
  sequenceNumber: number;
  id: number;
  title: string;
  message: string;
  createdOn: Date;
  readOn: string;
  localReadOn?: string;
  createdBy: PublisherDto;
  attachments: [
    {
      attachmentMimeType: string;
      attachmentId: string;
      name: string;
      size: number;
      order: number;
    }
  ];
  categoryInfo: any;
  reaction: {
    id: string;
    name: string;
    reactionOn: string;
  };
  allowedReactions: {
    id: string;
    name: string;
    reactionOn: string;
  }[];
}
export interface SearchParams {
  maxId?: number;
  sinceDate?: string;
  searchTerm?: string;
  filter?: SearchFilter;
}
export interface SearchFilter {
  itemType?: number;
  done?: boolean;
  channelId?: number;
  schoolId?: number;
  studentId?: number;
  presub?: boolean;
  unread?: boolean;
  attendance?: boolean;
}
export interface ItemSearchResults {
  hasMoreItems: boolean;
  totalItems: number;
  queryTimestamp: string;
  results: ItemDto[];
}
export class PublisherDto {
  id: string;
  title: string;
  forename: string;
  surname: string;
  displayNameFormat: number;
  avatarUrl: string;
}
export class ChannelDto {
  id: number;
  name: string;
  descriptions: string;
  code: string;
  subscribedOn: Date;
  publishers: PublisherDto[];
}
export class ProfileDto {
  forename: string;
  surname: string;
}

export class MarkItemReadResponse {
  readOn: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getSchools(): Observable<School[]> {
    return this.http.get<School[]>(environment.apiBaseUrl + '/api/school');
  }
  getSchoolSudents(schoolId: number): Observable<Student[]> {
    return this.http.get<Student[]>(environment.apiBaseUrl + `/api/school/${schoolId}/students`);
  }
  getChannels(): Observable<ChannelDto[]> {
    return this.http.get<ChannelDto[]>(environment.apiBaseUrl + '/api/channel/subscribed');
  }

  public loadItems(searchParams: SearchParams): Observable<ItemSearchResults> {
    let url = environment.apiBaseUrl + '/api/item/find?maxResults=20&useCategories=true';

    if (searchParams.maxId) {
      url += `&maxId=${searchParams.maxId}`;
    }

    if (searchParams.sinceDate) {
      url += `&sinceDate=${searchParams.sinceDate}`;
    }

    if (searchParams.searchTerm) {
      url += `&searchTerm=${searchParams.searchTerm}`;
    }

    url += this.getFilter(searchParams.filter);

    return this.http.get<ItemSearchResults>(url);
  }

  private getFilter(searchFilter?: SearchFilter): string {
    let filter = '';
    if (searchFilter) {
      filter += '&filterexpression=';
      if (searchFilter.itemType) {
        filter += `itemtype:${searchFilter.itemType},`;
      }
      if (searchFilter.done !== undefined) {
        filter += `done:${searchFilter.done},`;
      }
      if (searchFilter.channelId) {
        filter += `channelid:${searchFilter.channelId},`;
      }
      if (searchFilter.schoolId) {
        filter += `schoolId:${searchFilter.schoolId},`;
      }
      if (searchFilter.studentId) {
        filter += `studentId:${searchFilter.studentId},`;
      }
      if (searchFilter.presub) {
        filter += `presub:${searchFilter.presub},`;
      }
      if (searchFilter.unread) {
        filter += `unread:${searchFilter.unread},`;
      }
    }

    return filter;
  }

  public toBase64(blob: Blob): Observable<string> {
    const isSvg = blob.type === 'image/svg';
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.onloadend = (ev): void => {
        if (reader.result === null) {
          observer.error(ev);
        } else {
          observer.next(
            isSvg
              ? 'data:image/svg+xml; charset=utf8, ' + (reader.result as string).replace('#', '%23')
              : (reader.result as string)
          );
        }

        observer.complete();
      };
      reader.onerror = (error) => console.log(error);
      if (isSvg) {
        reader.readAsText(blob);
      } else {
        reader.readAsDataURL(blob);
      }
    });
  }

  public getImageAsBase64(url: string): Observable<string> {
    // following line is bit of a hack for #10585 for broken school image while onboarding
    // could be removed once getSchoolLogoAndNavigate is refactored to better cope with
    // null shool logo
    if (!url) return throwError(() => new Error('empty url'));

    const strippedUrl = url.substring(url.indexOf('/api/'));
    return this.http
      .get(environment.apiBaseUrl + strippedUrl, {
        responseType: 'blob'
      })
      .pipe(
        switchMap((blob) => {
          return this.toBase64(blob);
        })
      );
  }

  public getImage(url: string): Observable<Blob> {
    const strippedUrl = url.substring(url.indexOf('/api/'));
    return this.http.get(environment.apiBaseUrl + strippedUrl, {
      responseType: 'blob'
    });
  }
  downloadAttachment(path: string): Observable<Blob> {
    const headers = new HttpHeaders({ timeout: '0' });

    const url = path.startsWith('http') ? path : environment.apiBaseUrl + path;
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  markArticleDone(articleId: number, done: boolean): Observable<void> {
    return this.http.put<any>(environment.apiBaseUrl + `/api/article/${articleId}/done?done=${done}`, null);
  }
  markAnnouncementDone(schoolId: number, announcementId: number, studentId: number, done: boolean): Observable<void> {
    const data = {
      announcementId,
      studentId,
      done
    };
    return this.http.put<any>(environment.apiBaseUrl + `/api/school/${schoolId}/announcements/done`, data);
  }
  markArticleRead(articleId: number): Observable<MarkItemReadResponse> {
    return this.http.put<MarkItemReadResponse>(environment.apiBaseUrl + `/api/article/${articleId}/read`, null);
  }
  markAnnouncementRead(schoolId: number, announcementId: number, studentId: number): Observable<MarkItemReadResponse> {
    const data = {
      announcementId,
      studentId
    };
    return this.http.put<MarkItemReadResponse>(
      environment.apiBaseUrl + `/api/school/${schoolId}/announcements/read`,
      data
    );
  }

  getProfile(): Observable<ProfileDto> {
    return this.http.get<ProfileDto>(environment.apiBaseUrl + '/api/profile');
  }

  updateProfile(firstname: string, lastname: string): Observable<string> {
    const profile = {
      forename: firstname,
      surname: lastname
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put(environment.apiBaseUrl + '/api/profile', JSON.stringify(profile), {
      headers,
      responseType: 'text'
    });
  }

  deleteAccount(): Observable<void> {
    return this.http.post<void>(environment.apiBaseUrl + '/api/account/deletion', null);
  }

  getContactDetails(schoolId: number): Observable<ContactDetails> {
    return this.http.get<ContactDetails>(environment.apiBaseUrl + `/api/school/${schoolId}/contactdetails`);
    // return new Observable<ContactDetails>(subscriber => {
    //   subscriber.next({
    //     title: 'Mr',
    //     forename: 'Joe',
    //     surname: 'Bloggs',
    //     email: 'joe@yopmail.com',
    //     mobile: '447777111111'
    //   });
    //   subscriber.complete();
    // }).delay(2000);
  }

  getAttendanceSummary(schoolId: number, studentId: number): Observable<AttendanceSummary> {
    return this.http.get<AttendanceSummary>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/attendancesummary`
    );
    // return new Observable<AttendanceSummary>(subscriber => {
    //   subscriber.next({
    //     studentId: studentId,
    //     totalSessions: 256,
    //     attendanceSessions: 238,
    //     absenceSessions: 18,
    //     authAbsenceSessions: 18,
    //     unAuthAbsenceSessions: 0,
    //     lateSessions: 0,
    //     percentageAttendance: 92.97,
    //     percentageAbsence: 7.03,
    //     percentageAuthAbsences: 7.03,
    //     percentageUnAuthAbsences: 0,
    //     percentageLates: 0
    //   });
    //   subscriber.complete();
    // }).delay(2000);
  }

  getSessionAttendance(
    schoolId: number,
    studentId: number,
    fromDate: string,
    toDate: string,
    filter: string
  ): Observable<AttendanceSessions> {
    return this.http.get<AttendanceSessions>(
      environment.apiBaseUrl +
        `/api/school/${schoolId}/student/${studentId}/attendanceSessions?fromDate=${fromDate}&toDate=${toDate}` +
        (filter ? `&filter=${filter}` : '')
    );
    // return new Observable<AttendanceSessions>(subscriber => {
    //   subscriber.next({
    //     studentId: studentId,
    //     sessions: [
    //       {
    //         date: new Date(2018, 6, 21),
    //         session: 'PM',
    //         code: '/'
    //       },
    //       {
    //         date: new Date(2018, 6, 21),
    //         session: 'AM',
    //         code: '/'
    //       },
    //       {
    //         date: new Date(2018, 6, 20),
    //         session: 'PM',
    //         code: '/'
    //       },
    //       {
    //         date: new Date(2018, 6, 20),
    //         session: 'AM',
    //         code: '/'
    //       },
    //       {
    //         date: new Date(2018, 6, 19),
    //         session: 'PM',
    //         code: '/'
    //       },
    //       {
    //         date: new Date(2018, 6, 19),
    //         session: 'AM',
    //         code: '/'
    //       },
    //       {
    //         date: new Date(2018, 6, 19),
    //         session: 'PM',
    //         code: '/'
    //       },
    //       {
    //         date: new Date(2018, 6, 19),
    //         session: 'AM',
    //         code: '/'
    //       }
    //     ]
    //   });
    //   subscriber.complete();
    // }).delay(3000);
  }
  private getMonday(d): Date {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getStudentTimetable(schoolId: number, studentId: number): Observable<StudentTimetable> {
    const monday = this.getMonday(new Date());
    const toDayOffset = (hours, minutes): string =>
      hours > 12 ? hours - 12 + ':' + ('0' + minutes).slice(-2) + 'pm' : hours + ':' + ('0' + minutes).slice(-2) + 'am';
    return new Observable<StudentTimetable>((subscriber) => {
      subscriber.next({
        studentId,
        generatedOn: new Date(),
        days: [
          {
            day: monday,
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 1),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 2),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 3),
            periods: []
          },
          {
            day: this.addDays(monday, 4),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 7),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 8),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 9),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 10),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          },
          {
            day: this.addDays(monday, 11),
            periods: [
              {
                start: toDayOffset(9, 0),
                end: toDayOffset(10, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P1'
              },
              {
                start: toDayOffset(10, 0),
                end: toDayOffset(11, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P2'
              },
              {
                start: toDayOffset(11, 0),
                end: toDayOffset(12, 0),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P3'
              },
              {
                start: toDayOffset(13, 30),
                end: toDayOffset(14, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P4'
              },
              {
                start: toDayOffset(14, 30),
                end: toDayOffset(15, 30),
                className: 'class 1',
                roomNumber: 'Rm1',
                subject: 'subject 1',
                teacherName: 'Teacher 1',
                periodNumber: 'P5'
              }
            ]
          }
        ]
      });
      subscriber.complete();
      // subscriber.error('test');
    }).pipe(delay(3000));
  }

  submitAbsenceReport(
    schoolId: number,
    studentId: number,
    date: string,
    session: string,
    reason: string
  ): Observable<void> {
    const data = {
      date,
      session,
      reason
    };
    return this.http.put<any>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/absencereport`,
      data
    );

    // return new Observable<void>(subscriber => {
    //   subscriber.next();
    //   //subscriber.complete();
    //   subscriber.error({ status: 400, error: { ErrorCode: 2000 } });
    // }).delay(2000);
  }

  getUnreadItemCount(): Observable<ItemSearchResults> {
    return this.http.get<ItemSearchResults>(
      environment.apiBaseUrl + '/api/item/find?maxResults=0&useCategories=true&filterexpression=unread:true'
    );
  }
}
