import { createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../store';
import * as fromPush from './push.reducer';
export interface PushModuleState {
  pushState: fromPush.PushState;
}

export interface AppState extends fromRoot.AppState {
  push: PushModuleState;
}

export const reducers = {
  pushState: fromPush.reducer
};

export const getPushModuleState = createFeatureSelector<PushModuleState>('push');
