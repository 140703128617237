import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { PublisherViewModel } from 'src/app/models/publisher.model';
import { ChannelComplete, ChannelData, ChannelPublisherAvatars, Publisher } from '../../models/channel.model';
import { ApiService } from '../../services/api.service';
import { ChannelService } from '../../services/channel.service';
import { NavigateTo } from '../actions';
import * as channelActions from '../actions/channel.action';

@Injectable()
export class ChannelEffects {
  constructor(
    private actions$: Actions,
    private channelService: ChannelService,
    private apiService: ApiService,
    private store: Store<any>
  ) {}

  findChannelByCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.FindChannelByCode>(channelActions.FIND_CHANNEL_BY_CODE),
      map((action: channelActions.FindChannelByCode) => action),
      switchMap((action) =>
        this.channelService.findChannelByCode(action.payload).pipe(
          map((response: ChannelComplete) => {
            this.store.dispatch(new channelActions.FindChannelByCodeSuccess());

            return new channelActions.GetPublishersAvatars(response);
          }),
          catchError((error) => {
            let message = null;
            let header = 'Oops!';

            if (error.status === 404) {
              header = 'Incorrect channel code';
              message = `We couldn't find a channel that uses this code. Please try again, or contact the channel admin.`;
            }

            return of(new channelActions.FindChannelByCodeFail({ header, message }));
          })
        )
      )
    )
  );

  getAvatarsAndNavigate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.GetPublishersAvatars>(channelActions.GET_PUBLISHERS_AVATARS),
      map((action: channelActions.GetPublishersAvatars) => action),
      switchMap((action) => {
        const requests: Observable<string>[] = [];

        action.payload.publishers.forEach((publisher: Publisher) => {
          if (publisher.avatarUrl.trim().length !== 0) {
            requests.push(
              this.apiService.getImage(publisher.avatarUrl).pipe(
                switchMap((result) => {
                  return this.apiService.toBase64(result);
                }),
                catchError((error) => of(null))
              )
            );
          }
        });

        return forkJoin<any>(of(action.payload), ...requests);
      }),
      switchMap((data) => {
        const [channel] = data;
        const publishers: Publisher[] = (data[0] as ChannelComplete).publishers;
        const channelWithAvatars = new ChannelPublisherAvatars(
          channel.id,
          channel.name,
          channel.description,
          channel.code,
          channel.publishers.map(
            (pub) => new PublisherViewModel(pub.id, null, pub.forename, pub.surname, null, pub.avatarUrl)
          ),
          channel.schoolInfo
        );
        let index = 1;
        let avatarIndex = 1;

        channelWithAvatars.publishers.forEach((publisher) => {
          switch (publishers[index - 1].displayNameFormat) {
            case 0:
              publisher.displayName = `${publisher.forename}`;
              break;
            case 1:
              publisher.displayName = `${publisher.forename} ${publisher.surname}`;
              break;
            case 2:
              publisher.displayName = `${publisher.surname[0]}`;
              break;
            case 3:
              publisher.displayName = `${publishers[index - 1].title} ${publisher.forename} ${publisher.surname}`;
              break;
            case 4:
              publisher.displayName = `${publishers[index - 1].title} ${publisher.surname[0]}`;
              break;
            case 5:
              publisher.displayName = `${publishers[index - 1].title} ${publisher.surname}`;
              break;
          }

          if (publisher.avatarUrl.trim().length !== 0) {
            publisher.avatarBase64 = data[avatarIndex] || undefined;
            avatarIndex++;
          }

          index++;
        });

        return of(new channelActions.GetPublishersAvatarsSuccess(channelWithAvatars));
      })
    )
  );

  subscribeChannel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.SubscribeChannel>(channelActions.SUBSCRIBE_CHANNEL),
      map((action: channelActions.SubscribeChannel) => action),
      mergeMap((action) =>
        this.channelService.subscribeChannel(action.payload.id).pipe(
          map(() => {
            return new channelActions.SubscribeChannelSuccess();
          }),
          catchError((error) => {
            const errorMsg = 'Something went wrong';

            return of(new channelActions.SubscribeChannelFail(errorMsg));
          })
        )
      )
    )
  );

  leaveChannel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.LeaveChannel>(channelActions.LEAVE_CHANNEL),
      map((action: channelActions.LeaveChannel) => action),
      mergeMap((action) =>
        this.channelService.leaveChannel(action.payload).pipe(
          map((response) => {
            return new channelActions.LeaveChannelSuccess(action.payload);
          }),
          catchError((error) => {
            return of(new channelActions.LeaveChannelFail());
          })
        )
      )
    )
  );

  getSubscribedChannelsAndNavigate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.GetSubscribedChannelsAndNavigate>(channelActions.GET_SUBSCRIBED_CHANNELS_AND_NAVIGATE),
      map((action: channelActions.GetSubscribedChannelsAndNavigate) => action),
      switchMap((action) =>
        this.channelService.getSubscribedChannels().pipe(
          map((response) => {
            this.store.dispatch(
              new NavigateTo({
                page: action.payload.page,
                payload: {
                  subscribedChannels: response,
                  actionPayload: action.payload.payload
                }
              })
            );

            return new channelActions.GetSubscribedChannelsAndNavigateSuccess();
          }),
          catchError(() => {
            return of(new channelActions.GetSubscribedChannelsAndNavigateFail());
          })
        )
      )
    )
  );

  subscribeChannels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.SubscribeChannels>(channelActions.SUBSCRIBE_CHANNELS),
      map((action: channelActions.SubscribeChannels) => action),
      mergeMap((action) => {
        if (action.payload.length === 0) {
          return of(new channelActions.SubscribeChannelsSuccess());
        } else {
          const requests: Observable<string>[] = [];
          action.payload.forEach((element: ChannelData) => {
            requests.push(
              this.channelService.subscribeChannel(element.channelId).pipe(
                map((result) => result),
                catchError((error) => {
                  return of(null);
                })
              )
            );
          });
          return forkJoin<any>(...requests);
        }
      }),
      mergeMap((data) => {
        return of(new channelActions.SubscribeChannelsSuccess());
      }),
      catchError((err) => {
        return of(new channelActions.SubscribeChannelsFail());
      })
    )
  );

  leaveChannels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.LeaveChannels>(channelActions.LEAVE_CHANNELS),
      map((action: channelActions.LeaveChannels) => action),
      mergeMap((action) => {
        action.payload.forEach((channel) => {
          this.store.dispatch(new channelActions.LeaveChannel(channel.id));
        });

        return of(new channelActions.LeaveChannelsSuccess());
      }),
      catchError((err) => {
        return of(new channelActions.LeaveChannelsFail());
      })
    )
  );
}
