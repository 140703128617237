<ion-header mode="ios">
  <ion-toolbar mode="ios" *ngIf="headerModel$ | async as headerModel">
    <ion-buttons slot="start">
      <app-modal-back-button [priority]="9998" (activated)="onDismiss()"></app-modal-back-button>
      <app-timed-spinner [loading]="headerModel.threadsLoading"></app-timed-spinner>
    </ion-buttons>
    <ion-title>{{ headerModel.title }}</ion-title>
    <ion-buttons *ngIf="headerModel.title && !headerModel.disableInfo" slot="end">
      <ion-icon id="open-chat-info" name="information-circle-outline"></ion-icon>
      <ion-modal
        #chatInfoModal
        class="chat-info"
        trigger="open-chat-info"
        mode="ios"
        [initialBreakpoint]="1"
        [breakpoints]="[0, 1]">
        <ng-template>
          <div *ngIf="infoSheetModel$ | async as infoSheetModel" class="container">
            <div class="modal-title">Chat details</div>
            <div class="chat-title">{{ infoSheetModel.title }}</div>
            <div class="publisher-status-container">
              <div class="publisher-container">
                <app-user-badge [userId]="infoSheetModel.creatorUserId"></app-user-badge>
                <ion-label>{{ infoSheetModel.creator }}</ion-label>
              </div>
              <div class="created-container">
                <ion-img src="/assets/icon/date-icon.svg"></ion-img>
                <ion-label>{{ infoSheetModel.createdOn | date: 'dd MMM YYYY' }}</ion-label>
              </div>
              <div class="open-container">
                <div class="status-circle" [ngClass]="infoSheetModel.closed ? 'closed' : ''"></div>
                <ion-label>{{ infoSheetModel.closed ? 'Closed' : 'Open' }}</ion-label>
              </div>
            </div>
            <hr />
            <div class="participants">
              <div class="heading">Chat participants: {{ infoSheetModel.participants.length }}</div>
              <div *ngFor="let participant of infoSheetModel.participants">{{ participant }}</div>
            </div>
          </div>
        </ng-template>
      </ion-modal>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-toast
  *ngIf="loadFailureToastState$ | async as state"
  [isOpen]="state.open"
  [message]="state.message"
  [cssClass]="state.class ?? ''"
  position="top"
  [buttons]="toastButtons"
  (willDismiss)="onToastWillDismiss()"
  (didDismiss)="onToastDidDismiss($event)"></ion-toast>
<ion-content class="ion-padding" scrollEvents="true" (appResizeObserver)="onContentResize($event)">
  <app-chat-modal-overlay
    *ngIf="contentModel$ | async as contentModel"
    slot="fixed"
    vertical="top"
    horizontal="start"
    [showSpinner]="contentModel.useInlineSpinner"
    [loading]="contentModel.loading"
    [loadFailReason]="contentModel.loadFailReason"
    [initialScrollComplete]="contentModel.initialScrollComplete"
    (retry)="onRetryLoad()"></app-chat-modal-overlay>
  <div *ngIf="contentModel$ | async as contentModel">
    <div *ngIf="contentModel.info" class="content-container">
      <ng-container *ngIf="contentModel.info.timeline; else requiresUpgrade">
        <div class="day" *ngFor="let day of contentModel.info.timeline">
          <div class="date">
            <span>{{ day.date }}</span>
          </div>
          <div *ngFor="let entry of day.entries">
            <app-chat-timeline-entry
              [entry]="entry"
              (markAsRead)="onMarkAsRead($event)"
              (saveCalendar)="onSaveCalendar($event)"
              (retrySendMessage)="onRetrySendMessage($event)"
              (participantMessageAdded)="onParticipantMessageAdded($event)"
              (pendingMessageAdded)="onPendingMessageAdded($event)"
              (upgradeClicked)="onUpdate()"></app-chat-timeline-entry>
          </div>
        </div>
        <div *ngIf="contentModel.info.closed" class="closed-container">
          <div class="info-container">
            <div class="chatbubble-container">
              <ion-icon name="chatbubble-outline"></ion-icon>
              <ion-icon class="closed" name="close-outline"></ion-icon>
            </div>
            <div>This chat is currently closed</div>
          </div>
          <a (click)="chatClosedModal.present()">What does this mean?</a>
        </div>
        <div
          *ngIf="contentModel.info.pendingClosure"
          class="pending-closure-container"
          (appResizeObserver)="onPendingClosureResize()">
          <p>We were unable to send your message as this chat is now closed.</p>
          <p>Please tap the button below to refresh.</p>
          <ion-button type="button" class="ph-btn" color="white" (click)="onRefreshChat(contentModel.info.threadId)"
            >Refresh Chat</ion-button
          >
        </div>
      </ng-container>
      <ng-template #requiresUpgrade>
        <div class="requires-update">
          <div>This version of the app can't display the contents of this chat.</div>
          <div>Please tap below to upgrade.</div>
          <ion-button class="ph-btn-white" (click)="onUpdate()">UPGRADE</ion-button>
        </div>
      </ng-template>
    </div>
  </div>
  <ion-fab *ngIf="newMessageModel$ | async as newMessageModel" slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button color="tertiary" (click)="onNewMessagesClick(newMessageModel.firstNewMessage)">
      <ion-label>{{ newMessageModel.count }} NEW</ion-label>
      <ion-icon name="arrow-down-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer *ngIf="footerModel$ | async as footerModel" mode="ios" #footer topBorder>
  <ion-toolbar>
    <div class="container">
      <ion-textarea #dummyText rows="1" type="text"></ion-textarea>
      <ion-textarea
        #text
        fill="outline"
        shape="round"
        rows="1"
        type="text"
        autocapitalize="on"
        class="clamped-autogrow"
        [ngClass]="{ invalid: text.value?.length > replyContentMaxLength }"
        [autoGrow]="true"
        [ngModel]="replyContent"
        (ngModelChange)="onReplyContentChanged($event, footerModel.threadId)"></ion-textarea>
      <ion-buttons>
        <ion-button
          [disabled]="!text.value?.length || text.value.length > replyContentMaxLength || footerModel.disableSend"
          (click)="onReply(text, footerModel.threadId); text.value = ''">
          <div class="circle"></div>
          <ion-icon class="icon" name="send"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-footer>
<ion-modal
  #chatInfoModal
  class="chat-info"
  trigger="open-chat-info"
  mode="ios"
  [initialBreakpoint]="1"
  [breakpoints]="[0, 1]">
  <ng-template>
    <div *ngIf="infoSheetModel$ | async as infoSheetModel" class="container">
      <div class="modal-title">Chat details</div>
      <div class="chat-title">{{ infoSheetModel.title }}</div>
      <div class="publisher-status-container">
        <div class="publisher-container">
          <app-user-badge [userId]="infoSheetModel.creatorUserId"></app-user-badge>
          <ion-label>{{ infoSheetModel.creator }}</ion-label>
        </div>
        <div class="created-container">
          <ion-img src="/assets/icon/date-icon.svg"></ion-img>
          <ion-label>{{ infoSheetModel.createdOn | date: 'dd MMM YYYY' }}</ion-label>
        </div>
        <div class="open-container">
          <div class="status-circle" [ngClass]="infoSheetModel.closed ? 'closed' : ''"></div>
          <ion-label>{{ infoSheetModel.closed ? 'Closed' : 'Open' }}</ion-label>
        </div>
      </div>
      <hr />
      <div class="participants">
        <div class="heading">Chat participants: {{ infoSheetModel.participants.length }}</div>
        <div *ngFor="let participant of infoSheetModel.participants">{{ participant }}</div>
      </div>
    </div>
  </ng-template>
</ion-modal>

<ion-modal #chatClosedModal class="chat-closed" mode="ios" [initialBreakpoint]="1" [breakpoints]="[0, 1]">
  <ng-template>
    <div class="container">
      <div class="modal-title">Chat closed</div>
      <div class="chatbubble-container">
        <ion-icon name="chatbubble-outline"></ion-icon>
        <ion-icon class="closed" name="close-outline"></ion-icon>
      </div>
      <p>This chat is currently closed.</p>
      <p>When a chat is closed, <b>new messages can't be added to the conversation.</b></p>
      <p>The school may still send follow-up messages, or re-open the chat at any time.</p>
    </div>
  </ng-template>
</ion-modal>
