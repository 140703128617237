// No longer maintained at https://github.com/natural-apptitude/ngrx-store-ionic-storage
// so this is a modified snapshot
// Now changed to work with @capacitor-community/sqlite rather than through @ionic/storage-angular
import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionReducer } from '@ngrx/store';
import { Observable, from, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { LocalForageService } from 'src/app/services/localForage.service';
const STORAGE_KEY = 'NSIS_APP_STATE';

@Injectable({
  providedIn: 'root'
})
export class StateStoreService {
  constructor(
    private databaseService: DatabaseService,
    private localForageService: LocalForageService,
    private platform: Platform
  ) {}

  private async set(key: string, value: any): Promise<void> {
    if (this.platform.is('hybrid')) {
      return await this.databaseService.executeQuery(async (db: SQLiteDBConnection) => {
        let sqlcmd: string = 'INSERT OR REPLACE INTO _ionickv (key,value) VALUES (?,?)';
        let values: Array<any> = [key, JSON.stringify(value)];
        let ret = await db.run(sqlcmd, values, true);

        if (ret.changes.changes !== 1) {
          throw Error('set failed');
        }
      });
    } else {
      return this.localForageService.set(key, value);
    }
  }

  private async get(key: string): Promise<any> {
    if (this.platform.is('hybrid')) {
      return this.databaseService.executeQuery(async (db: SQLiteDBConnection) => {
        let sqlcmd: string = 'SELECT value FROM _ionickv WHERE key = ? LIMIT 1';
        let values: Array<any> = [key];
        let ret = await db.query(sqlcmd, values);
        if (ret.values.length !== 1) {
          throw Error('db get failed');
        }
        return JSON.parse(ret.values[0].value);
      });
    } else {
      return this.localForageService.get(key);
    }
  }

  // get/setNested inspired by
  // https://github.com/mickhansen/dottie.js
  private getNested(obj: any, path: string): any {
    if (obj !== null && path) {
      // Recurse into the object.
      const parts = path.split('.').reverse();
      while (obj != null && parts.length) {
        obj = obj[parts.pop()];
      }
    }
    return obj;
  }

  private setNested(obj: any, path: string, value: any): any {
    if (obj != null && path) {
      let pieces = path.split('.'),
        current = obj,
        piece,
        i,
        length = pieces.length;

      for (i = 0; i < length; i++) {
        piece = pieces[i];
        if (i === length - 1) {
          current[piece] = value;
        } else if (!current[piece]) {
          current[piece] = {};
        }
        current = current[piece];
      }
    }

    return obj;
  }

  async fetchState(): Promise<{}> {
    return this.get(STORAGE_KEY)
      .then((s) => {
        return s || {};
      })
      .catch((err) => {});
  }

  saveState(state: any, keys: string[]): Promise<void> {
    // Pull out the portion of the state to save.
    if (keys) {
      state = keys.reduce((acc, k) => {
        const val = this.getNested(state, k);
        if (val) {
          this.setNested(acc, k, val);
        }
        return acc;
      }, {});
    }
    return this.set(STORAGE_KEY, state);
  }

  clear(): Promise<void> {
    return this.set(STORAGE_KEY, '{}');
  }
}

export const StorageSyncActions = {
  HYDRATE: 'NSIS_APP_HYDRATE',
  HYDRATED: 'NSIS_APP_HYDRATED'
};

@Injectable()
export class StorageSyncEffects {
  constructor(private actions$: Actions, private store: StateStoreService) {}
  hydrate$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSyncActions.HYDRATE),
      switchMap((_) =>
        from(this.store.fetchState()).pipe(
          map((state) => ({
            type: StorageSyncActions.HYDRATED,
            payload: state
          })),
          catchError((e) => {
            console.warn(`error fetching data from store for hydration: ${e}`);

            return of({
              type: StorageSyncActions.HYDRATED,
              payload: {}
            });
          })
        )
      )
    )
  );
}

export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
}

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  onSyncError: (err) => {}
};

export function storageSync(storageService: StateStoreService, options?: StorageSyncOptions): any {
  const { keys, ignoreActions, hydratedStateKey, onSyncError } = Object.assign({}, defaultOptions, options || {});

  ignoreActions.push(StorageSyncActions.HYDRATE);
  ignoreActions.push(StorageSyncActions.HYDRATED);
  ignoreActions.push('@ngrx/store/init');
  ignoreActions.push('@ngrx/effects/init');
  ignoreActions.push('@ngrx/store/update-reducers');
  ignoreActions.push('@ngrx/store-devtools/recompute');

  const hydratedState: any = {};

  return function storageSyncReducer(reducer: ActionReducer<any>) {
    return (state: any, action: any) => {
      const { type, payload } = action;

      if (type === StorageSyncActions.HYDRATED) {
        state = Object.assign({}, state, payload);
        if (hydratedStateKey) {
          hydratedState[hydratedStateKey] = true;
        }
      }

      const nextState = Object.assign({}, reducer(state, action), hydratedState);

      if (ignoreActions.indexOf(type) === -1) {
        storageService.saveState(nextState, keys).catch((err) => onSyncError(err));
      }

      return nextState;
    };
  };
}
