import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { SchoolAttendanceViewModel } from '../models/school.model';
import { getSchoolAttendanceViewModel } from '../store/selectors/core.selector';

@Injectable({
  providedIn: 'root'
})
export class SchoolResolverService {
  constructor(
    private store: Store<any>,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SchoolAttendanceViewModel> | Observable<never> {
    const id = +route.paramMap.get('schoolId');

    return this.store.select(getSchoolAttendanceViewModel(id)).pipe(
      take(1),
      mergeMap((school) => {
        if (school) {
          return of(school);
        } else {
          // id not found
          this.router.navigate(['/app/tabs/hub']);
          return EMPTY;
        }
      })
    );
  }
}
