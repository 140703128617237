import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
export class AuthResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  userName: string;
  issued: string;
  expires: string;
  localExpiry: number;
  betaUser: boolean;
  sid: string;
  emailPending: string;
}

@Injectable()
export class AuthService {
  refreshing = false;

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<AuthResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body =
      'grant_type=password&username=' +
      username +
      '&password=' +
      encodeURIComponent(password) +
      '&scope=openid+api1+email+offline_access&client_id=api.client';

    return this.http.post<AuthResponse>(environment.accountBaseUrl + '/connect/token', body, {
      headers: headers
    });
  }

  refreshToken(refreshToken: string): Observable<AuthResponse> {
    this.refreshing = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body =
      'grant_type=refresh_token&scope=openid+api1+email+offline_access&client_id=api.client&refresh_token=' +
      refreshToken;

    return this.http.post<AuthResponse>(environment.accountBaseUrl + '/connect/token', body, {
      headers: headers
    });
  }
}
