// State
import { Channel } from '../../models/channel.model';
import { School } from '../../models/school.model';
import * as fromSchool from '../actions/school.action';

export interface SchoolState {
  loading: boolean;
  error: string;
  school: School;
  logoData: string;
  subscriptionState: number;
  schoolChannels: Channel[];
  subscribedChannels: Channel[];
  currentStep: number;
  priorStep: number;
  callScreenErrorCode: string;
  callScreenError1: string;
  callScreenError2: string;
}

export const initialState: SchoolState = {
  loading: false,
  error: null,
  school: null,
  logoData: null,
  subscriptionState: null,
  schoolChannels: null,
  subscribedChannels: null,
  currentStep: null,
  priorStep: null,
  callScreenErrorCode: null,
  callScreenError1: null,
  callScreenError2: null
};

export function reducer(state = initialState, action: fromSchool.SchoolAction): SchoolState {
  switch (action.type) {
    case fromSchool.FIND_SCHOOL_BY_HANDLE: {
      return {
        ...state,
        loading: true,
        error: null,
        school: null,
        subscriptionState: null,
        schoolChannels: null,
        currentStep: null,
        priorStep: null,
        callScreenErrorCode: null,
        callScreenError1: null,
        callScreenError2: null
      };
    }

    case fromSchool.FIND_SCHOOL_BY_HANDLE_SUCCESS: {
      return {
        ...state,
        school: action.payload.school,
        loading: false,
        error: null
      };
    }

    case fromSchool.FIND_SCHOOL_BY_HANDLE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    }

    case fromSchool.GET_SCHOOL_LOGO: {
      return {
        ...state,
        loading: true
      };
    }

    case fromSchool.GET_SCHOOL_LOGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        logoData: action.payload.logoData
      };
    }

    case fromSchool.GET_SCHOOL_LOGO_FAIL: {
      return {
        ...state,
        logoData: null,
        loading: false
      };
    }

    case fromSchool.VERIFY_SCHOOL: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case fromSchool.VERIFY_SCHOOL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }

    case fromSchool.VERIFY_SCHOOL_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromSchool.CONFIRM_VERIFICATION_SCHOOL: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case fromSchool.CONFIRM_VERIFICATION_SCHOOL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }

    case fromSchool.CONFIRM_VERIFICATION_SCHOOL_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage
      };
    }

    case fromSchool.VERIFY_REMINDER_SCHOOL: {
      return {
        ...state,
        loading: true
      };
    }

    case fromSchool.VERIFY_REMINDER_SCHOOL_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case fromSchool.VERIFY_REMINDER_SCHOOL_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromSchool.CONFIRM_DOB_SCHOOL: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case fromSchool.CONFIRM_DOB_SCHOOL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }

    case fromSchool.CONFIRM_DOB_SCHOOL_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage
      };
    }

    case fromSchool.CHECK_ADD_SCHOOL_PROGRESS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromSchool.CHECK_ADD_SCHOOL_PROGRESS_FAIL: {
      return {
        ...state,
        loading: false,
        subscriptionState: null
      };
    }

    case fromSchool.CHECK_ADD_SCHOOL_PROGRESS_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case fromSchool.GET_SCHOOL_CHANNELS_AND_CHANGE_STEP_SUCCESS: {
      return {
        ...state,
        schoolChannels: action.payload
      };
    }

    case fromSchool.SCHOOL_CHANGE_STEP: {
      return {
        ...state,
        priorStep: state.currentStep,
        currentStep: action.payload.step
      };
    }

    case fromSchool.UPDATE_STATE_SCHOOL: {
      return {
        ...state,
        school: action.payload.school
      };
    }

    case fromSchool.GET_SCHOOL_CHANNELS: {
      return {
        ...state
      };
    }

    case fromSchool.GET_SCHOOL_CHANNELS_SUCCESS: {
      return {
        ...state,
        schoolChannels: action.payload
      };
    }

    case fromSchool.GET_SCHOOL_CHANNELS_AND_NAVIGATE_SUCCESS: {
      return {
        ...state,
        schoolChannels: action.payload
      };
    }

    case fromSchool.GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL_SUCCESS: {
      return {
        ...state,
        subscribedChannels: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getSchoolLoading = (state: SchoolState): boolean => state.loading;
export const getSchoolError = (state: SchoolState): string => state.error;
export const getSchoolSchool = (state: SchoolState): School => state.school;
export const getSchoolSubscriptionState = (state: SchoolState): number => state.subscriptionState;
export const getSchoolChannels = (state: SchoolState): Channel[] => state.schoolChannels;
export const getSchoolChannelsNum = (state: SchoolState): number => state.schoolChannels.length;
export const getSchoolCurrentStep = (state: SchoolState): number => state.currentStep;
export const getSchoolPriortStep = (state: SchoolState): number => state.priorStep;
