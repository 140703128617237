import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromFeature from './store';
import { AppState } from './store';
import { Token } from './store/token.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private accessToken: string;
  constructor(private store: Store<AppState>) {
    this.store.select('hydrated').subscribe((state: any) => {
      if (state === true) {
        this.store.select(fromFeature.getToken).subscribe((token: Token) => {
          if (token) {
            this.accessToken = token.access_token;
          } else {
            this.accessToken = null;
          }
        });
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.accessToken) {
      // Get the auth header from the service.
      const authHeader: string = 'Bearer ' + this.accessToken;
      // Clone the request to add the new header.
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authHeader)
      });
      // Pass on the cloned request instead of the original request.
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
