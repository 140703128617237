// Models
import { PublisherViewModel } from './publisher.model';
import { School } from './school.model';

export class Channel {
  id: number;
  name: string;
  description: string;
  code: string;
  subscribedOn: Date;
  publishers: string[];
  schoolInfo: number;
  subscriptionSource: number;
}

// Complete channel model
export class SubscriberDetails {
  subscriptionState: number;
  optedOutOn: string;
  confirmedOn: string;
}

export class SchoolInfo {
  id: number;
  isSchoolFeed: boolean;
  logoUrl: string;
  features: string[];
  subscriberDetails: SubscriberDetails;
}

export class Publisher {
  id: string;
  title: string;
  forename: string;
  surname: string;
  displayNameFormat: number;
  displayName: string;
  avatarUrl: string;
}

export class ChannelComplete {
  code: string;
  description: string;
  id: number;
  name: string;
  publishers: Publisher[];
  schoolInfo?: SchoolInfo;
  subscribedOn: Date;
  subscriptionSource: number;
}

// Complete channel info + publishers with base64 avatars
export class ChannelPublisherAvatars {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public code: string,
    public publishers: PublisherViewModel[],
    public schoolInfo?: SchoolInfo
  ) {}
}

// Models for the my channels view
export class MyChannelsViewModel {
  id: number;
  name: string;
  school: boolean;
  channels: Channel[];
}

export class ChannelViewModel {
  id: number;
  name: string;
  description: string;
  code: string;
  publishers: string[];
  schoolInfo?: School;
  subscribedOn: Date;
  isSchoolFeed: boolean;
}

export class ChannelCheckboxViewModel {
  id: number;
  name: string;
  description: string;
  code: string;
  publishers: string[];
  schoolInfo?: number;
  subscribedOn: Date;
  checked: boolean;
  subscriptionSource: number;
  subscribed: boolean;
  isFeed: boolean;
}

export class ChannelData {
  channelId: number;
  subscribe: boolean;
}
