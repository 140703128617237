// State
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchoolViewModel } from '../../models/school.model';
import { SchoolState } from '../reducers/school.reducer';

export const getSchoolState = createFeatureSelector<SchoolState>('schoolState');

export const getSchoolLoading = createSelector(getSchoolState, (state) => state.loading);

export const getSchoolError = createSelector(getSchoolState, (state) => state.error);

export const getSchoolModel = createSelector(getSchoolState, (state) => {
  return state.school
    ? new SchoolViewModel(
        state.school.id,
        state.school.name,
        state.school.address,
        state.school.headteacher,
        state.school.telephone,
        state.school.email,
        state.school.feedChannelId,
        state.logoData,
        state.school.websiteUrl,
        state.school.features,
        null, // don't need students
        state.school.subscriptionState,
        null // don't need weblinks
      )
    : null;
});

export const getSchoolSubscriptionState = createSelector(getSchoolState, (state) => state.subscriptionState);

export const getSchoolChannels = createSelector(getSchoolState, (state) => state.schoolChannels);

export const getSubscribedChannelsForSchool = createSelector(getSchoolState, (state) => state.subscribedChannels);

export const getSchoolCurrentStep = createSelector(getSchoolState, (state) => state.currentStep);

export const getSchoolCallScreenErrorCode = createSelector(getSchoolState, (state) => state.callScreenErrorCode);

export const getSchoolCallScreenError1 = createSelector(getSchoolState, (state) => state.callScreenError1);

export const getSchoolCallScreenError2 = createSelector(getSchoolState, (state) => state.callScreenError2);

export const getSchoolPriorStep = createSelector(getSchoolState, (state) => state.priorStep);
