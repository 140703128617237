import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class AppReleaseDto {
  id: number;
  platform: string;
  appVersion: string;
  releasedOn: string;
  minOsVersion: string;
  minAppVersion: string;
  notes: string;
}
@Injectable({
  providedIn: 'root'
})
export class AppVersionApiService {
  constructor(private http: HttpClient) {}

  getAppVersion(platform: string): Observable<AppReleaseDto> {
    return this.http.get<AppReleaseDto>(environment.apiBaseUrl + `/api/release?platform=${platform}`);
  }
}
