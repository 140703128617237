import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { forkJoin, interval, Observable, of, TimeoutError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { Payment, PaymentService, PaymentsResponse } from 'src/app/services/payment.service';
import { AddPublishers } from 'src/app/store/actions/core.action';
import { AppState } from 'src/app/store/reducers';
import { getAllPublisherViewModels } from 'src/app/store/selectors/core.selector';
import { PublisherViewModel } from '../../models/publisher.model';
import { PaymentIntentTracking } from '../../store/reducers/payment-intent.reducer';
import { selectAllPaymentIntents } from '../../store/selectors/payment-intent.selector';
import {
  PaymentStatusFilter,
  RequestStatusFilter
} from './student-payment-list-filter-modal/student-payment-list-filter-modal.component';

export interface StudentPaymentsState {
  payments: Payment[];
  paymentIntents: PaymentIntentTracking[];
  totalPayments: number;
  loadingMore: boolean;
  emptyTemplate: string;
  firstLoadErrorType: string;
  requestStatusFilter: RequestStatusFilter;
  paymentStatusFilter: PaymentStatusFilter;
  publisherViewModels: PublisherViewModel[];
}

@Injectable({
  providedIn: 'root'
})
export class StudentPaymentsStore extends ComponentStore<StudentPaymentsState> {
  constructor(
    private paymentService: PaymentService,
    private store: Store<AppState>,
    private toastController: ToastController
  ) {
    super({
      payments: [],
      paymentIntents: [],
      totalPayments: 0,
      loadingMore: false,
      emptyTemplate: '',
      firstLoadErrorType: '',
      requestStatusFilter: null,
      paymentStatusFilter: null,
      publisherViewModels: []
    });

    this.store.select(selectAllPaymentIntents).subscribe((paymentIntents: PaymentIntentTracking[]) => {
      this.setState((state) => {
        return {
          ...state,
          paymentIntents
        };
      });
    });

    this.store.select(getAllPublisherViewModels).subscribe((publisherViewModels: PublisherViewModel[]) => {
      this.setState((state) => {
        return {
          ...state,
          publisherViewModels
        };
      });
    });
  }

  readonly vm$ = this.select(this.state$, (state) => state);
  readonly loadingMore$ = this.select(this.state$, (state) => state.loadingMore);

  readonly setFirstLoadErrorType = this.updater((state, value: string) => ({ ...state, firstLoadErrorType: value }));

  readonly initializeState = this.updater((state) => {
    return {
      ...state,
      payments: [],
      totalPayments: 0,
      loadingMore: false,
      emptyTemplate: '',
      requestStatusFilter: null,
      paymentStatusFilter: null
    };
  });

  readonly addPayments = this.updater((state, value: PaymentsResponse) => {
    const publishers = value.payments.map(({ contact }) => contact);
    this.store.dispatch(new AddPublishers({ publishers }));

    return {
      ...state,
      payments: state.payments.concat(value.payments),
      totalPayments: value.total
    };
  });

  readonly updateFilter = this.updater(
    (state, value: { requestStatus: RequestStatusFilter; paymentStatus: PaymentStatusFilter }) => {
      return { ...state, requestStatusFilter: value.requestStatus, paymentStatusFilter: value.paymentStatus };
    }
  );

  readonly setPayments = this.updater((state, value: PaymentsResponse) => {
    const publishers = value.payments.map(({ contact }) => contact);
    this.store.dispatch(new AddPublishers({ publishers }));

    return {
      ...state,
      payments: value.payments,
      totalPayments: value.total
    };
  });

  readonly setPaymentsArray = this.updater((state, value: Payment[]) => {
    const publishers = value.map(({ contact }) => contact);
    this.store.dispatch(new AddPublishers({ publishers }));

    return {
      ...state,
      payments: value
    };
  });

  readonly setEmptyTemplate = this.updater((state, value: string) => ({ ...state, emptyTemplate: value }));
  readonly setLoadingMore = this.updater((state, value: boolean) => ({ ...state, loadingMore: value }));

  readonly getPaymentViewModels = this.select(({ payments, paymentIntents, publisherViewModels }) => {
    return payments.map((payment) => ({
      ...payment,
      contact: publisherViewModels.find((publisherViewModel) => publisherViewModel.id === payment.contact.id),
      paymentBeingConfirmed: paymentIntents.findIndex((paymentIntent) => paymentIntent.paymentId === payment.id) !== -1
    }));
  });

  readonly updatePayment = this.effect((params$: Observable<{ payment: Payment; studentId: number }>) => {
    return params$.pipe(
      tap((params: { payment: Payment; studentId: number }) => {
        const { payment, studentId } = params;
        const index = this.get().payments.findIndex(
          (statePayment) => statePayment.id === payment.id && statePayment.studentId === studentId
        );
        if (index === -1) return;
        this.store.dispatch(new AddPublishers({ publishers: [payment.contact] }));
        const newPaymentsArray = [
          ...this.get().payments.slice(0, index),
          payment,
          ...this.get().payments.slice(index + 1)
        ];

        this.setPaymentsArray(newPaymentsArray);
      })
    );
  });

  readonly loadPayments = this.effect((params$: Observable<{ schoolId: number; studentId: number }>) => {
    return params$.pipe(
      tap((params: { schoolId: number; studentId: number }) => {
        this.setEmptyTemplate('firstLoad');
        this.setLoadingMore(false);

        const timer = interval(1000).pipe(take(1));
        const filters = [this.get().requestStatusFilter, this.get().paymentStatusFilter];
        const request = this.paymentService
          .loadPayments(params.schoolId, params.studentId, filters)
          .pipe(catchError((error) => of(error)));

        forkJoin({
          timer,
          request
        }).subscribe((result) => {
          if (result.request instanceof HttpErrorResponse || result.request instanceof TimeoutError) {
            console.log('[HTTP REQUEST] Load Payments Error', result.request);

            if (result.request instanceof HttpErrorResponse) {
              switch (result.request.status) {
                case 0:
                  this.setFirstLoadErrorType('noInternet');
                  this.showToast('Please check your internet connection and try again later', 'noInternetToast');
                  break;

                case 404:
                  this.setFirstLoadErrorType('notFound');
                  break;

                default:
                  this.setFirstLoadErrorType('somethingWrong');
                  break;
              }
            } else {
              this.setFirstLoadErrorType('somethingWrong');
            }

            this.setEmptyTemplate('firstLoadError');
          } else {
            console.log('[HTTP REQUEST] Load Payments Success', result.request);

            this.setPayments(result.request);

            this.setEmptyTemplate(null);
          }
        });
      })
    );
  });

  readonly loadMorePayments = this.effect((params$: Observable<{ schoolId: number; studentId: number }>) => {
    return params$.pipe(
      switchMap((params: { schoolId: number; studentId: number }) => {
        const filters = [this.get().requestStatusFilter, this.get().paymentStatusFilter];
        const skip = this.get().payments.length;

        this.setLoadingMore(true);

        return this.paymentService.loadMorePayments(params.schoolId, params.studentId, filters, skip, 10).pipe(
          catchError((err) => of(err)),
          tap((result: PaymentsResponse) => {
            if (result instanceof HttpErrorResponse) {
              console.log('[HTTP REQUEST] Load Payments Error');

              this.setLoadingMore(false);
            } else {
              console.log('[HTTP REQUEST] Load Payments Success', result);

              this.addPayments(result);

              this.setLoadingMore(false);
            }
          })
        );
      })
    );
  });

  showToast(toastMessage: string, toastCssClass: string): void {
    const toast = this.toastController.create({
      message: toastMessage,
      duration: 2000,
      position: 'top',
      cssClass: toastCssClass
    });

    toast.then((t) => t.present());
  }
}
