/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/chat-thread-reply-cache.reducer';
import { getChatThreadId } from './chat-thread.selector';

export const getChatThreadReplyCacheState = createFeatureSelector<State>('chatThreadReplyCacheState');

export const getCachedChatThreadReply = createSelector(
  getChatThreadReplyCacheState,
  getChatThreadId,
  (state, threadId) => {
    return state.entities[threadId];
  }
);
