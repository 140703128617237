import { createActionGroup, props } from '@ngrx/store';
import { AboutOption } from '../reducers/chat-threads-filter-settings.reducer';

export const ChatThreadsFilterSettingsActions = createActionGroup({
  source: 'Chat Threads Filter Settings',
  events: {
    updateFilters: props<{
      aboutFilter: AboutOption[];
    }>(),
    aboutOptionsChanged: props<{
      aboutFilter: AboutOption[];
      options: AboutOption[];
    }>()
  }
});
