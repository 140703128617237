import { createAction, props } from '@ngrx/store';
import { SupportedLanguage } from '../reducers/languages.reducer';

export const load = createAction('[Languages] Load');
export const loadSuccess = createAction(
  '[Languages] Load Success',
  props<{
    result: SupportedLanguage[];
  }>()
);
export const loadFail = createAction(
  '[Languages] Load Fail',
  props<{
    error: string;
  }>()
);
