// State
import { ActionPerformed, PushNotificationSchema } from '@capacitor/push-notifications';
import { Action } from '@ngrx/store';
import { LifecycleActions } from 'src/app/store/actions/lifecycle.action';
import * as fromPush from '../actions';
import { Registration } from '../registration.model';

export interface PushState {
  installationId: string;
  version: string;
  registration: Registration;
  notification: PushNotificationSchema;
  action: ActionPerformed;
  initialising: boolean;
  error: string;
  notificationsAtResume: PushNotificationSchema[];
}

export const initialState: PushState = {
  installationId: null,
  version: null,
  registration: null,
  notification: null,
  action: null,
  initialising: false,
  error: null,
  notificationsAtResume: null
};

export function theReducer(state = initialState, action: fromPush.PushAction): PushState {
  switch (action.type) {
    case fromPush.PUSH_INIT: {
      return {
        ...state,
        initialising: true,
        error: null
      };
    }

    case fromPush.PUSH_RECEIVED: {
      return {
        ...state,
        notification: action.payload.notification
      };
    }

    case fromPush.RESUMED_WITH_NOTIFICATIONS: {
      return {
        ...state,
        notificationsAtResume: state.notificationsAtResume
          ? [...state.notificationsAtResume, ...action.payload.notifications]
          : action.payload.notifications
      };
    }
    case fromPush.RESUMED_WITH_NOTIFICATIONS_CLEAR: {
      return {
        ...state,
        notificationsAtResume: null
      };
    }
    case fromPush.PUSH_CLEAR: {
      return {
        ...state,
        notification: null
      };
    }

    case fromPush.PUSH_ACTION_PERFORMED: {
      return {
        ...state,
        action: action.payload.action,
        notificationsAtResume: state.notificationsAtResume
          ? [...state.notificationsAtResume, action.payload.action.notification]
          : [action.payload.action.notification]
      };
    }

    case fromPush.PUSH_ACTION_CLEAR: {
      return {
        ...state,
        action: null
      };
    }

    case fromPush.REGISTER_INSTALLATION_SUCCESS: {
      return {
        ...state,
        initialising: false,
        installationId: action.payload.installationId,
        version: action.payload.version,
        registration: action.payload.registration
      };
    }

    default: {
      return state;
    }
  }
}
export function reducer(state: PushState | undefined, action: Action): any {
  switch (action.type) {
    case LifecycleActions.paused.type: {
      return {
        ...state,
        notification: null
      };
    }
    default:
      return theReducer(state, action as fromPush.PushAction);
  }
}
