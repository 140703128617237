// State
import * as fromOnboarding from '../actions/onboarding.action';

export interface OnboardingState {
  loading: boolean;
  email: string;
  profileCreated: boolean;
}

export const initialState: OnboardingState = {
  loading: false,
  email: null,
  profileCreated: false
};

export function reducer(state = initialState, action: fromOnboarding.OnboardingAction): OnboardingState {
  switch (action.type) {
    case fromOnboarding.CREATE_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOnboarding.CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        email: null
      };
    }

    case fromOnboarding.CREATE_ACCOUNT_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOnboarding.CREATE_PROFILE: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOnboarding.CREATE_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        profileCreated: true
      };
    }

    case fromOnboarding.CREATE_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
        profileCreated: false
      };
    }

    case fromOnboarding.NEW_ACCOUNT_LOGIN: {
      return {
        ...state
      };
    }

    case fromOnboarding.CHECK_EMAIL: {
      return {
        ...state,
        loading: true,
        email: action.payload
      };
    }

    case fromOnboarding.CHECK_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOnboarding.CHECK_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
        email: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getOnboardingLoading = (state: OnboardingState): boolean => state.loading;
export const getOnboardingProfileCreated = (state: OnboardingState): boolean => state.profileCreated;
