import { createActionGroup, props } from '@ngrx/store';
import { ChatThreadMessageDto } from 'src/app/services/chat.service';

export const ChatThreadPendingReplyActions = createActionGroup({
  source: 'Chat Thread Pending Reply',
  events: {
    reply: props<{ id: number; schoolId: number; threadId: number; content: string }>(),
    replySuccess: props<{ id: number; threadId: number; dto: ChatThreadMessageDto }>(),
    replyFail: props<{ id: number; error: string }>(),
    retryReply: props<{ id: number }>(),
    clearFailed: props<{ threadId: number }>()
  }
});
