import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicContentComponent {
  @Input() content: string;
}
