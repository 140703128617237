import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChatInfo, TimeLineEntry } from '../../chat-modal.store';

@Component({
  selector: 'app-chat-timeline-info-entry',
  templateUrl: './chat-timeline-info-entry.component.html',
  styleUrls: ['./chat-timeline-info-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTimelineInfoEntryComponent {
  @Input() entry: TimeLineEntry;

  get chatInfo(): ChatInfo {
    return this.entry as ChatInfo;
  }
}
