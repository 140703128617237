// State
import { Action } from '@ngrx/store';
// Models
import {
  ChannelCheckboxViewModel,
  ChannelComplete,
  ChannelData,
  ChannelPublisherAvatars
} from '../../models/channel.model';

export const FIND_CHANNEL_BY_CODE = '[Channel] Find channel by code';
export const FIND_CHANNEL_BY_CODE_SUCCESS = '[Channel] Find channel by code Success';
export const FIND_CHANNEL_BY_CODE_FAIL = '[Channel] Find channel by code Fail';
export const GET_PUBLISHERS_AVATARS = '[Channel] Get publisher avatars';
export const GET_PUBLISHERS_AVATARS_SUCCESS = '[Channel] Get publisher avatars Success';
export const GET_PUBLISHERS_AVATARS_FAIL = '[Channel] Get publisher avatars Fail';
export const SUBSCRIBE_CHANNEL = '[Channel] Subscribe channel';
export const SUBSCRIBE_CHANNEL_SUCCESS = '[Channel] Subscribe channel Success';
export const SUBSCRIBE_CHANNEL_FAIL = '[Channel] Subscribe channel Fail';
export const LEAVE_CHANNEL = '[Channel] Leave Channel';
export const LEAVE_CHANNEL_SUCCESS = '[Channel] Leave Channel Success';
export const LEAVE_CHANNEL_FAIL = '[Channel] Leave Channel Fail';
export const GET_SUBSCRIBED_CHANNELS_AND_NAVIGATE = '[Channel] Get subscribed channels and navigate';
export const GET_SUBSCRIBED_CHANNELS_AND_NAVIGATE_SUCCESS = '[Channel] Get subscribed channels and navigate Success';
export const GET_SUBSCRIBED_CHANNELS_AND_NAVIGATE_FAIL = '[Channel] Get subscribed channels and navigate Fail';
export const CHANNEL_CHANGE_STEP = '[Channel] Change Step';
export const SUBSCRIBE_CHANNELS = '[Channel] Subscribe channels';
export const SUBSCRIBE_CHANNELS_SUCCESS = '[Channel] Subscribe channels Success';
export const SUBSCRIBE_CHANNELS_FAIL = '[Channel] Subscribe channels Fail';
export const LEAVE_CHANNELS = '[Channel] Leave channels';
export const LEAVE_CHANNELS_SUCCESS = '[Channel] Leave channels Success';
export const LEAVE_CHANNELS_FAIL = '[Channel] Leave channels Fail';

export class FindChannelByCode implements Action {
  readonly type = FIND_CHANNEL_BY_CODE;

  constructor(public payload: string) {}
}

export class FindChannelByCodeSuccess implements Action {
  readonly type = FIND_CHANNEL_BY_CODE_SUCCESS;
}

export class FindChannelByCodeFail implements Action {
  readonly type = FIND_CHANNEL_BY_CODE_FAIL;

  constructor(public payload: { header: string; message: string }) {}
}

export class GetPublishersAvatars implements Action {
  readonly type = GET_PUBLISHERS_AVATARS;

  constructor(public payload: ChannelComplete) {}
}

export class GetPublishersAvatarsSuccess implements Action {
  readonly type = GET_PUBLISHERS_AVATARS_SUCCESS;

  constructor(public payload: ChannelPublisherAvatars) {}
}

export class GetPublishersAvatarsFail implements Action {
  readonly type = GET_PUBLISHERS_AVATARS_FAIL;
}

export class SubscribeChannel implements Action {
  readonly type = SUBSCRIBE_CHANNEL;

  constructor(public payload: { id: number; code: string }) {}
}

export class SubscribeChannelSuccess implements Action {
  readonly type = SUBSCRIBE_CHANNEL_SUCCESS;
}

export class SubscribeChannelFail implements Action {
  readonly type = SUBSCRIBE_CHANNEL_FAIL;

  constructor(public payload: string) {}
}

export class LeaveChannel implements Action {
  readonly type = LEAVE_CHANNEL;

  constructor(public payload: number) {}
}

export class LeaveChannelSuccess implements Action {
  readonly type = LEAVE_CHANNEL_SUCCESS;

  constructor(public payload: number) {}
}

export class LeaveChannelFail implements Action {
  readonly type = LEAVE_CHANNEL_FAIL;
}

export class GetSubscribedChannelsAndNavigate implements Action {
  readonly type = GET_SUBSCRIBED_CHANNELS_AND_NAVIGATE;

  constructor(public payload: { page: string; payload: any }) {}
}

export class GetSubscribedChannelsAndNavigateSuccess implements Action {
  readonly type = GET_SUBSCRIBED_CHANNELS_AND_NAVIGATE_SUCCESS;
}

export class GetSubscribedChannelsAndNavigateFail implements Action {
  readonly type = GET_SUBSCRIBED_CHANNELS_AND_NAVIGATE_FAIL;
}

export class ChannelChangeStep implements Action {
  readonly type = CHANNEL_CHANGE_STEP;

  constructor(public payload: { step: number; redirectTo?: string }) {}
}

export class SubscribeChannels implements Action {
  readonly type = SUBSCRIBE_CHANNELS;

  constructor(public payload: ChannelData[]) {}
}

export class SubscribeChannelsSuccess implements Action {
  readonly type = SUBSCRIBE_CHANNELS_SUCCESS;
}

export class SubscribeChannelsFail implements Action {
  readonly type = SUBSCRIBE_CHANNELS_FAIL;
}

export class LeaveChannels implements Action {
  readonly type = LEAVE_CHANNELS;

  constructor(public payload: ChannelCheckboxViewModel[]) {}
}

export class LeaveChannelsSuccess implements Action {
  readonly type = LEAVE_CHANNELS_SUCCESS;
}

export class LeaveChannelsFail implements Action {
  readonly type = LEAVE_CHANNELS_FAIL;
}

export type ChannelAction =
  | FindChannelByCode
  | FindChannelByCodeSuccess
  | FindChannelByCodeFail
  | GetPublishersAvatars
  | GetPublishersAvatarsSuccess
  | GetPublishersAvatarsFail
  | SubscribeChannel
  | SubscribeChannelSuccess
  | SubscribeChannelFail
  | LeaveChannel
  | LeaveChannelSuccess
  | LeaveChannelFail
  | GetSubscribedChannelsAndNavigate
  | GetSubscribedChannelsAndNavigateSuccess
  | GetSubscribedChannelsAndNavigateFail
  | ChannelChangeStep
  | SubscribeChannels
  | SubscribeChannelsSuccess
  | SubscribeChannelsFail
  | LeaveChannels
  | LeaveChannelsSuccess
  | LeaveChannelsFail;
