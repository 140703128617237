import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppVersionState } from '../reducers/app-version.reducer';

export const getAppVersionState = createFeatureSelector<AppVersionState>('appVersionState');

export const getLatestRelease = createSelector(getAppVersionState, (state) => state.latestRelease);
export const getAvailableUpdateVersionShown = createSelector(
  getAppVersionState,
  (state) => state.availableUpdateVersionShown
);
export const getLastCheck = createSelector(getAppVersionState, (state) => state.lastCheck);
