<ion-content class="ion-padding">
  <ion-grid fixed>
    <ion-col class="ion-justify-content-center">
      <ion-row class="spinnerCont ion-justify-content-center ion-align-items-end">
        <ion-spinner name="crescent" appSafariAnimate></ion-spinner>
      </ion-row>

      <ion-row class="textCont ion-justify-content-center">
        <p class="loadingText ion-text-center ion-text-wrap">Contacting Parent Hub</p>
      </ion-row>
    </ion-col>
  </ion-grid>
</ion-content>
