import { Update } from '@ngrx/entity';
import { createActionGroup, props } from '@ngrx/store';
import { ChatPushLoadProgress } from '../reducers/chat-push-load-progress.reducer';

export const ChatPushLoadProgressActions = createActionGroup({
  source: 'Chat Push Load Progress',
  events: {
    setAmbientSessionId: props<{ id: number }>(),
    track: props<{ threadId: number }>(),
    updateProgress: props<{ update: Update<ChatPushLoadProgress> }>()
  }
});
