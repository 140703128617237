// Modules
import { Injectable } from '@angular/core';
// import { SchoolAllChannelsPage } from '../pages/schools/school-all-channels/school-all-channels';
// import { WelcomePage } from '../pages/hub/welcome/welcome';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  // public showModal(pageName: string, data?: any, options?: object) {
  // let page = this.getPage(pageName);
  // let modal = this.modalCtrl.create(page, data, options);
  // modal.present();
  // }
  // private getPage(pageName) {
  // switch (pageName) {
  //   case 'All School Channels':
  //     return SchoolAllChannelsPage;
  //   case 'Welcome':
  //     return WelcomePage;
  // }
  // }
}
