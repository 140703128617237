import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as authSelectors from 'src/app/auth/store/selectors/auth.selectors';
import { TranslationApiService } from 'src/app/services/translation.api.service';
import { environment } from 'src/environments/environment';
import { NullAction } from '..';
import * as appVersionActions from '../actions/app-version.action';
import * as actions from '../actions/languages.action';
import * as coreSelectors from '../selectors/core.selector';
import * as languagesSelectors from '../selectors/languages.selector';
@Injectable()
export class LanguageEffects {
  constructor(
    private store: Store,
    private translationService: TranslationApiService,
    private actions$: Actions
  ) {}
  resumeCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appVersionActions.resumeCheck, appVersionActions.coldStartCheck),
      withLatestFrom(this.store.select(languagesSelectors.getLastLoaded)),
      withLatestFrom(this.store.select(authSelectors.getToken)),
      withLatestFrom(this.store.select(coreSelectors.getSchools)),
      debounceTime(2000),
      mergeMap(([[[action, lastCheck], token], schools]) => {
        const needLanguages = !!schools.find((s) => s.features.includes('messagetranslation'));
        const checkPeriodMinutes = environment.testMode ? 2 : 10080;
        const checkDue = Date.now() >= lastCheck + checkPeriodMinutes * 60000;
        if (checkDue && token && needLanguages) {
          return of(actions.load());
        } else {
          return of(new NullAction());
        }
      })
    )
  );

  translateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.load),
      mergeMap((action) => {
        const api = this.translationService.getLanguages();
        return api.pipe(
          map((result) => {
            return actions.loadSuccess({ result: result });
          }),
          catchError((err) => {
            const error = err.status === 0 ? 'offline' : 'unknown';
            return of(actions.loadFail({ error: error }));
          })
        );
      })
    )
  );
}
