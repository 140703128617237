import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InitialLoadingPage } from './pages/initial-loading/initial-loading.page';
import { InitialPage } from './pages/initial/initial.page';
import { SchoolResolverService } from './services/school-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: InitialPage
  },
  {
    path: 'loading',
    component: InitialLoadingPage
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule)
  },
  {
    path: 'create-account-email',
    loadChildren: () =>
      import('./pages/create-account-email/create-account-email.module').then((m) => m.CreateAccountEmailPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'create-account-password',
    loadChildren: () =>
      import('./pages/create-account-password/create-account-password.module').then(
        (m) => m.CreateAccountPasswordPageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'create-account-name',
    loadChildren: () =>
      import('./pages/create-account-name/create-account-name.module').then((m) => m.CreateAccountNamePageModule),
    data: { disableMenu: true }
  },
  {
    path: 'create-account-complete',
    loadChildren: () =>
      import('./pages/create-account-complete/create-account-complete.module').then(
        (m) => m.CreateAccountCompletePageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then((m) => m.SignInPageModule)
  },
  {
    path: 'forgotten-password',
    loadChildren: () =>
      import('./pages/forgotten-password/forgotten-password.module').then((m) => m.ForgottenPasswordPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'first-onboarding-choice',
    loadChildren: () =>
      import('./pages/first-onboarding-choice/first-onboarding-choice.module').then(
        (m) => m.FirstOnboardingChoicePageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'join-channel-handle',
    loadChildren: () =>
      import('./pages/join-channel-handle/join-channel-handle.module').then((m) => m.JoinChannelHandlePageModule),
    data: { disableMenu: true }
  },
  {
    path: 'join-channel-confirmation',
    loadChildren: () =>
      import('./pages/join-channel-confirmation/join-channel-confirmation.module').then(
        (m) => m.JoinChannelConfirmationPageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'join-school-handle',
    loadChildren: () =>
      import('./pages/join-school-handle/join-school-handle.module').then((m) => m.JoinSchoolHandlePageModule),
    data: { disableMenu: true }
  },
  {
    path: 'join-school-confirmation',
    loadChildren: () =>
      import('./pages/join-school-confirmation/join-school-confirmation.module').then(
        (m) => m.JoinSchoolConfirmationPageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'join-school-verification',
    loadChildren: () =>
      import('./pages/join-school-verification/join-school-verification.module').then(
        (m) => m.JoinSchoolVerificationPageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'join-school-verification-confirmation',
    loadChildren: () =>
      import('./pages/join-school-verification-confirmation/join-school-verification-confirmation.module').then(
        (m) => m.JoinSchoolVerificationConfirmationPageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'join-school-dob-confirmation',
    loadChildren: () =>
      import('./pages/join-school-dob-confirmation/join-school-dob-confirmation.module').then(
        (m) => m.JoinSchoolDobConfirmationPageModule
      ),
    data: { disableMenu: true }
  },
  {
    path: 'join-school-call/:errorCode',
    loadChildren: () =>
      import('./pages/join-school-call/join-school-call.module').then((m) => m.JoinSchoolCallPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'join-school-channel-picker',
    children: [
      {
        path: ':schoolId',
        resolve: {
          school: SchoolResolverService
        },
        loadChildren: () =>
          import('./pages/join-school-channel-picker/join-school-channel-picker.module').then(
            (m) => m.JoinSchoolChannelPickerPageModule
          ),
        data: { disableMenu: true }
      },
      {
        path: ':schoolId/:hideSkip',
        resolve: {
          school: SchoolResolverService
        },
        loadChildren: () =>
          import('./pages/join-school-channel-picker/join-school-channel-picker.module').then(
            (m) => m.JoinSchoolChannelPickerPageModule
          ),
        data: { disableMenu: true }
      }
    ]
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule)
  },
  {
    path: 'app/tabs/hub/my-channels',
    loadChildren: () => import('./pages/my-channels/my-channels.module').then((m) => m.MyChannelsPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'app/tabs/schools/my-channels',
    loadChildren: () => import('./pages/my-channels/my-channels.module').then((m) => m.MyChannelsPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'app/tabs/chats/my-channels',
    loadChildren: () => import('./pages/my-channels/my-channels.module').then((m) => m.MyChannelsPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then((m) => m.EditProfilePageModule),
    data: { disableMenu: true }
  },
  {
    path: 'close-account',
    loadChildren: () => import('./pages/close-account/close-account.module').then((m) => m.CloseAccountPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'change-email',
    loadChildren: () => import('./pages/change-email/change-email.module').then((m) => m.ChangeEmailPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then((m) => m.ChangePasswordPageModule),
    data: { disableMenu: true }
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
    data: { disableMenu: true }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
