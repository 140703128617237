<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button ionBackButton (click)="onDismiss()">
        <ion-icon src="/assets/icon/back-icon-dark.svg"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>Filter Requests for {{ studentName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <!-- ngIf required after ionic 5 migration otherwise items appear unselected after closing then reopening modal -->
    <!-- {{ }} required after ionic 5 migration otherwise binding doesn't work -->
    <ion-radio-group
      *ngIf="preselectedRequestStatus >= 0"
      value="{{ preselectedRequestStatus }}"
      name="requestStatusGroup"
      (ionChange)="requestStatusGroupChange($event)"
      #requestStatusGroup>
      <ion-list-header>
        <ion-label>Request Status</ion-label>
      </ion-list-header>

      <ion-item lines="none">
        <ion-radio mode="md" value="{{ RequestStatus.All }}"></ion-radio>
        <ion-label>All Requests</ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-radio mode="md" value="{{ RequestStatus.Active }}"></ion-radio>
        <ion-label>Active Requests</ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-radio mode="md" value="{{ RequestStatus.Closed }}"></ion-radio>
        <ion-label>Closed Requests</ion-label>
      </ion-item>
    </ion-radio-group>

    <ion-radio-group
      *ngIf="preselectedPaymentStatus >= 0"
      value="{{ preselectedPaymentStatus }}"
      name="paymentStatusGroup"
      (ionChange)="paymentStatusGroupChange($event)"
      #paymentStatusGroup>
      <ion-list-header>
        <ion-label> Payment Status</ion-label>
      </ion-list-header>

      <ion-item lines="none">
        <ion-radio mode="md" value="{{ PaymentStatus.All }}"></ion-radio>
        <ion-label>All Requests</ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-radio mode="md" value="{{ PaymentStatus.Paid }}"></ion-radio>
        <ion-label>Fully Paid</ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-radio mode="md" value="{{ PaymentStatus.Owing }}"></ion-radio>
        <ion-label>Still Owing</ion-label>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer mode="ios">
  <ion-button mode="md" class="submitButton" color="secondary" (click)="onApplyFilters()"> Apply Filters </ion-button>
</ion-footer>
