/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, selectEntities, SlipQuestionResponse, State } from '../reducers/slip-question-response.reducer';

export const getSlipQuestionResponseState = createFeatureSelector<State>('slipQuestionResponseState');

export const selectSlipQuestionResponseEntities = createSelector(getSlipQuestionResponseState, (state) =>
  selectEntities(state)
);

export const selectAllSlipQuestionResponse = createSelector(getSlipQuestionResponseState, (state) => selectAll(state));

export const selectSlipQuestionResponse = (id: string) =>
  createSelector(selectSlipQuestionResponseEntities, (state) => {
    return state[id] as SlipQuestionResponse;
  });
