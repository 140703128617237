import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ExtendedChatThreadDto } from 'src/app/services/chat.service';
import { ChatThreadsFilterSettingsActions } from '../actions/chat-threads-filter-settings.action';
import { ChatThreadLoadReason, ChatThreadsActions } from '../actions/chat-threads.action';
import * as coreActions from '../actions/core.action';
import * as schoolActions from '../actions/school.action';

export interface State extends EntityState<ExtendedChatThreadDto> {
  // We show an in line spinner and progress when loading chats initially
  // and after changing filter settings
  primed: boolean;
  maxChangeSequenceNumber: number;
  loading: boolean;
  loadingReason: ChatThreadLoadReason;
  loadingError: string;
  loadingMore: boolean;
  canLoadMore: boolean;
  searchTerm: string;
  canSearchMore: boolean;
}

export const chatThreadsAdapter: EntityAdapter<ExtendedChatThreadDto> = createEntityAdapter<ExtendedChatThreadDto>({
  selectId: (thread: ExtendedChatThreadDto) => thread.threadId,
  sortComparer: (a, b) => {
    return (b.localSequenceNumber ?? 0) - (a.localSequenceNumber ?? 0) || b.orderSequenceNumber - a.orderSequenceNumber;
  }
});

export const initialState: State = chatThreadsAdapter.getInitialState({
  primed: false,
  maxChangeSequenceNumber: null,
  loading: false,
  loadingReason: null,
  loadingError: null,
  loadingMore: false,
  canLoadMore: true,
  searchTerm: null,
  canSearchMore: true
});

export const theReducer = createReducer(
  initialState,
  on(ChatThreadsActions.load, (state, action) => {
    return { ...state, loading: true, loadingReason: action.reason, loadingError: null };
  }),
  on(ChatThreadsActions.loadSuccess, (state, action) =>
    chatThreadsAdapter.upsertMany(action.threads, {
      ...state,
      primed: true,
      maxChangeSequenceNumber: action.maxChangeSequenceNumber,
      loading: false,
      loadingReason: null
    })
  ),
  on(ChatThreadsActions.loadFailure, (state, action) => {
    return { ...state, loading: false, loadingError: action.errorMsg };
  }),
  on(ChatThreadsActions.loadMore, (state) => {
    return { ...state, loadingMore: true };
  }),
  on(ChatThreadsActions.loadMoreSuccess, (state, action) =>
    chatThreadsAdapter.upsertMany(action.threads, {
      ...state,
      loadingMore: false,
      canLoadMore: state.searchTerm?.length > 0 ? state.canLoadMore : action.hasMore,
      canSearchMore: state.searchTerm?.length > 0 ? action.hasMore : state.canSearchMore
    })
  ),
  on(ChatThreadsActions.loadMoreFailure, (state) => {
    return { ...state, loadingMore: false };
  }),
  on(ChatThreadsActions.updateThread, (state, action) => chatThreadsAdapter.updateOne(action.update, state)),
  on(ChatThreadsActions.updateThreads, (state, action) => chatThreadsAdapter.updateMany(action.updates, state)),
  on(ChatThreadsActions.applySentMessageReceiptChanges, (state, action) =>
    chatThreadsAdapter.updateMany(action.updates, state)
  ),
  // Occurs after search so need to reset canLoadMore
  on(ChatThreadsActions.removeThreads, (state, action) =>
    chatThreadsAdapter.removeMany(action.ids, {
      ...state,
      canLoadMore: action.ids.length > 0 ? true : state.canLoadMore
    })
  ),
  on(ChatThreadsActions.setSearchTerm, (state, action) => {
    return chatThreadsAdapter.removeMany(
      Object.values(state.entities)
        .filter((e) => e.isSearchResult)
        .map((e) => e.threadId),
      { ...state, searchTerm: action.term, canSearchMore: true }
    );
  }),
  // On filter change clear out and reload via effect
  on(ChatThreadsFilterSettingsActions.updateFilters, () => {
    return initialState;
  })
);

export function reducer(state: State | undefined, action: Action): any {
  switch (action.type) {
    case coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED:
    case schoolActions.CONFIRM_DOB_SCHOOL_SUCCESS:
      return initialState;
    default:
      return theReducer(state, action);
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = chatThreadsAdapter.getSelectors();
