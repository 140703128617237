import { Action, createReducer, on } from '@ngrx/store';
import { ChatThreadDto } from 'src/app/services/chat.service';
import { ChatThreadActions } from '../actions/chat-thread.action';

export interface State {
  id: number;
  schoolId: number;
  thread: ChatThreadDto;
  loading: boolean;
  loadReason: 'chats' | 'notification-tap' | 'notification-toast';
  loadFailReason: string;
  loadMethod: 'thread' | 'threads';
}

export const initialState: State = {
  id: null,
  schoolId: null,
  thread: null,
  loading: false,
  loadReason: null,
  loadFailReason: null,
  loadMethod: null
};

export const theReducer = createReducer(
  initialState,
  on(ChatThreadActions.open, (state, action) => {
    // Don't null the thread here since the modal may be showing while
    // the open is fired and it can mess with the selector
    return {
      ...state,
      id: action.threadId,
      schoolId: action.schoolId,
      loading: true,
      loadReason: action.reason,
      loadFailReason: null
    };
  }),
  on(ChatThreadActions.closed, (state) => {
    // Don't null the thread here since the modal may be showing while
    // the open is fired and it can mess with the selector
    return initialState;
  }),
  on(ChatThreadActions.primeChat, (state, action) => {
    return {
      ...state,
      thread: action.thread,
      loading: action.reason !== 'chats' ? true : false,
      loadReason: action.reason
      //loadMethod: 'threads'
    };
  }),
  on(ChatThreadActions.loadedChatUpdate, (state, action) => {
    return {
      ...state,
      thread: action.thread,
      loading: false,
      loadReason: action.reason,
      loadMethod: 'threads' as const
    };
  }),
  on(ChatThreadActions.updateChat, (state, action) => {
    return { ...state, thread: action.thread };
  }),
  on(ChatThreadActions.loadThread, (state, action) => {
    return { ...state, loading: true, loadFailReason: null, loadMethod: 'thread' as const };
  }),
  on(ChatThreadActions.loadThreadSuccess, (state, action) => {
    return { ...state, thread: action.thread, loading: false, loadReason: action.reason };
  }),
  on(ChatThreadActions.loadThreadFail, (state, action) => {
    return { ...state, loading: false, loadFailReason: action.reason };
  }),
  on(ChatThreadActions.monitorUpdate, (state, action) => {
    return { ...state, loading: action.loading, loadFailReason: action.loadingError };
  }),
  on(ChatThreadActions.retryLoad, (state, action) => {
    return { ...state, loading: true, loadFailReason: null };
  })
);
export function reducer(state: State | undefined, action: Action): any {
  return theReducer(state, action);
}
