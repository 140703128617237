import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { PluginListenerHandle, Plugins } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { PushNotifications } from '@capacitor/push-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, filter, first, take, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as fromAuth from './auth/store';
import * as fromPush from './push/store';
import { ResumedWithNotifications } from './push/store';
import { DeviceService } from './services/device.service';
import { LoggingService } from './services/logging.service';
import { MonitoringService } from './services/monitoring.service';
import * as fromStore from './store';
import { LoadNewItems, LogOut } from './store';
import { BadgeActions } from './store/actions/badge.action';
import { ChatPushLoadProgressActions } from './store/actions/chat-push-load-progress.action';
import { ChatThreadsActions } from './store/actions/chat-threads.action';
import { LifecycleActions } from './store/actions/lifecycle.action';
import * as slipPaymentIntentActions from './store/actions/slip-payment-intent.action';
const { ProcessLifecycle } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  network = new Subject<ConnectionStatus>();
  networkListener: PluginListenerHandle;

  constructor(
    private platform: Platform,
    private deviceService: DeviceService,
    private store: Store<fromStore.AppState>,
    private actions$: Actions,
    private monitoringService: MonitoringService,
    private loggingService: LoggingService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private router: Router
  ) {
    this.loadStripeJs();
    // Stop capacitor debug being shown in browser window
    (window as any).Capacitor.DEBUG = !!(environment as any).debug;

    // Set status bar colour
    StatusBar.setStyle({
      style: Style.Light
    });

    if (platform.is('android')) {
      StatusBar.setBackgroundColor({ color: '#FFFFFF' });
    }
    // #7454 Surprise logout (iOS)
    // Seems that the AppComponent can be started again without a full cold start and seems to remember
    // the last route so needs resetting
    this.router.navigate(['']);

    this.interceptConsole();

    console.log('App component starting');

    // Okay, so the platform is ready and our plugins are available.
    // Here you can do any higher level native things you might need.
    // console.log('Platform ready');

    this.platform.backButton.subscribeWithPriority(-1, () => {
      const rootPages = ['/welcome', '/app/tabs/hub/todo', '/app/tabs/schools'];
      rootPages.forEach((page) => {
        if (this.router.isActive(page, true) && this.router.url === page) {
          App.minimizeApp();
        }
      });
    });

    this.store
      .select(fromStore.getHydratedState)
      .pipe(
        filter((state) => !!state),
        take(1),
        tap(() => {
          this.store.dispatch(new fromStore.InitialiseSettings());
          this.store.dispatch(new fromStore.InitialiseCore());
          this.actions$
            .pipe(ofType<fromStore.FilePathsUpdated>(fromStore.FILE_PATHS_UPDATED), take(1))
            .subscribe(() => {
              this.doStoreReadyActions();
            });
          this.store.dispatch(new fromStore.PrepareFilePathUpdate());
        })
      )
      .subscribe();
  }
  loadStripeJs(): void {
    // Need to load stripejs for non native platforms
    if (!this.platform.is('hybrid')) {
      const node = document.createElement('script');
      node.src = 'https://js.stripe.com/v3';
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  private doStoreReadyActions(): void {
    // Note: Need to do this here rather than constructor to wait until device service
    // has initialised
    if (environment.aiConnectionString) {
      this.monitoringService.init(environment.aiConnectionString, (envelope) => {
        if (envelope.baseType === 'RemoteDependencyData' && !envelope.baseData?.target.includes('/api/')) {
          return false;
        }
        envelope.tags['ai.device.type'] = this.deviceService.devicePlatform;
        envelope.tags['ai.device.os'] = this.deviceService.devicePlatform;
        envelope.tags['ai.device.osVersion'] = this.deviceService.deviceOsVersion;
      });
      if (this.monitoringService.appInsights.context) {
        this.monitoringService.appInsights.context.application.ver = this.deviceService.appVersion;
        this.monitoringService.appInsights.context.device.model = this.deviceService.deviceModel;
      }
      this.monitoringService.logEvent('startup');
    } else {
      console.warn('Application insights unconfigured');
    }

    // Make sure our rehydrated state is in good condition
    this.store.dispatch(new fromAuth.Initialise());

    this.store
      .select(fromAuth.getToken)
      .pipe(take(1))
      .subscribe((token) => {
        Network.getStatus().then((status) => {
          console.log('Network on initial token check', status);
          if (status.connectionType !== 'none' && token) {
            // this.rootPage = InitialLoadingPage;
            this.store.dispatch(new fromStore.NavigateTo({ page: 'Loading', setRoot: true }));
            this.store.dispatch(new fromStore.InitialLoadingStart());
          } else {
            this.store.dispatch(new fromStore.InitialLoadingComplete());
          }
        });
      });

    // Platform pause/resume in android relates to the current activity so can get fired when
    // other system modals or our payment process are activated
    if (this.platform.is('hybrid')) {
      if (this.platform.is('android')) {
        ProcessLifecycle.addListener('paymentMethodChanged', (result: any) => {
          if (result.event === 'ON_PAUSE') {
            this.store.dispatch(LifecycleActions.paused());
          } else if (result.event === 'ON_RESUME') {
            this.store.dispatch(LifecycleActions.resumed());
          }
        });
      } else if (this.platform.is('ios')) {
        this.platform.resume.subscribe(() => this.store.dispatch(LifecycleActions.resumed()));
        this.platform.pause.subscribe(() => this.store.dispatch(LifecycleActions.paused()));
      }
    }

    this.actions$
      .pipe(
        ofType(LifecycleActions.resumed),
        tap(() => {
          // Note: If resumed through a notification tap ios removes the tapped notification such that
          // it doesn't appear in this list so we add it also via the push action performed action
          PushNotifications.getDeliveredNotifications().then((deliveredNotifications) => {
            this.store.dispatch(new ResumedWithNotifications({ notifications: deliveredNotifications.notifications }));
          });
          console.log('Got Resumed Event');
        }),
        debounceTime(2000)
      )
      .subscribe((event) => {
        console.log('Processing Resumed Event');
        this.store.dispatch(fromStore.resumeCheck());

        this.networkListener = Network.addListener('networkStatusChange', (status) => {
          this.network.next(status);
        });

        PushNotifications.removeAllDeliveredNotifications().then(() => {
          this.store.dispatch(BadgeActions.recalculate());
        });

        this.store
          .select(fromAuth.getToken)
          .pipe(take(1))
          .subscribe((token) => {
            // #5492 Cordova-plugin-network-information bug on certain Android versions
            // if (this.network.type !== 'none' && token) {
            if (token) {
              this.store
                .select(fromPush.getNotification)
                .pipe(first())
                .subscribe((notification) => {
                  if (notification != null) {
                    this.store.dispatch(new fromStore.ShowCheckNewItems());
                  }
                  this.store.dispatch(new fromAuth.ValidateToken({ reason: 'resume' }));
                });
            }
          });
      });
    this.actions$
      .pipe(
        ofType(LifecycleActions.paused),
        tap(() => console.log('Got Paused Event'))
      )
      .subscribe((event) => {
        console.log('Processing Paused Event');

        // Android fires the network event on every resume so remove it on pause and reattach on resume
        this.networkListener.remove();

        this.store.dispatch(new fromAuth.CancelTokenRefresh());
        this.store.dispatch(new fromPush.ResumedWithNotificationsClear());
      });

    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.network.next(status);
    });

    this.network
      .pipe(
        tap((status) => console.log(`Network status changed ${status.connected} ${status.connectionType}`)),
        filter((status) => status.connected),
        debounceTime(2000)
      )
      .subscribe((status) => {
        console.log(`Processing network connected ${status.connected} ${status.connectionType}`);
        this.store
          .select(fromAuth.getToken)
          .pipe(take(1))
          .subscribe((token) => {
            if (status.connectionType !== 'none' && token) {
              this.store.dispatch(new fromAuth.ValidateToken({ reason: 'network' }));
            }
          });
        this.store.dispatch(new fromPush.FlushRegisterInstallation());
      });

    this.actions$.pipe(ofType<fromStore.ProfileCheckFail>(fromStore.PROFILE_CHECK_FAIL)).subscribe((action) => {
      if (action.payload != null) {
        this.showProfileCheckError(action.payload);
      }
    });

    // Need to be careful here since we could load new 'already read' items
    // meaning that the selector result would be unchanged and we wouldn't
    // update the badge, so also set badge on resume
    // this.store
    //   .select(fromStore.getItemBadgeCount)
    //   // Skip setting the badge on intial start so we don't trample on it
    //   // it started in the background due to notification and prior to fetching
    //   // new items
    //   //.pipe(debounceTime(2000))
    //   .subscribe(result => {
    //     console.log('badge count: ' + result.value);
    //     this.pushService.setBadgeCount(result.value === 0 ? 0 : 1);
    //   });

    this.actions$
      .pipe(ofType(fromAuth.VALIDATE_TOKEN_FAILED, fromAuth.REFRESH_TOKEN_FAILED))
      .subscribe((action: any) => {
        if (action.payload.reason === 'startup') {
          this.navigatePage('Welcome');
        } else {
          this.logout();
        }
      });
    this.actions$
      .pipe(ofType<fromAuth.RefreshTokenUnavailable>(fromAuth.REFRESH_TOKEN_UNAVAILABLE))
      .subscribe((action) => {
        if (action.payload.reason === 'startup') {
          this.store.dispatch(new fromStore.OnboardingCheck({ reason: action.payload.reason }));
        }
      });
    this.actions$
      .pipe(
        ofType<Action>(fromAuth.VALIDATE_TOKEN_SUCCESS, fromAuth.REFRESH_TOKEN_SUCCESS),
        withLatestFrom(this.store.select(fromStore.getCoreRefreshCoreUnderway))
      )
      .subscribe(([action, getCoreRefreshCoreUnderway]) => {
        let payload;

        if (action.type === fromAuth.VALIDATE_TOKEN_SUCCESS) {
          payload = (action as fromAuth.ValidateTokenSuccess).payload;
        } else if (action.type === fromAuth.REFRESH_TOKEN_SUCCESS) {
          payload = (action as fromAuth.RefreshTokenSuccess).payload;
        }

        if (payload.reason === 'startup') {
          // this.store.dispatch(new fromStore.InitialLoadingComplete());

          this.store.dispatch(new fromStore.OnboardingCheck({ reason: payload.reason }));
        } else if (payload.reason === 'resume') {
          if (getCoreRefreshCoreUnderway) {
            return;
          }

          this.store.dispatch(new fromStore.RefreshCore({ reason: payload.reason }));
        }
      });
    this.actions$.pipe(ofType<fromPush.PushReceived>(fromPush.PUSH_RECEIVED)).subscribe((push) => {
      console.log('Push received');

      // Android and ios data presented slightly differently
      const pushData = push.payload.notification.data.aps ?? push.payload.notification.data;

      // If foreground load item updates
      if (push.payload.isForeground) {
        if (pushData.type === '0' || pushData.type === '1') {
          this.store.dispatch(new fromStore.ShowCheckNewItems());
          this.store.dispatch(new LoadNewItems({ pageId: 'hub-todo' }));
        } else if (pushData.type === '2') {
          const refId = Date.now();
          this.store.dispatch(ChatPushLoadProgressActions.setAmbientSessionId({ id: refId }));
          this.store.dispatch(ChatPushLoadProgressActions.track({ threadId: +pushData.thread }));
          this.store.dispatch(
            ChatThreadsActions.foregroundNotificationReceived({
              schoolId: +pushData.school,
              threadId: +pushData.thread,
              // iOS carries alert and Android has message
              message: pushData.alert ?? pushData.message
            })
          );
          // This will use the ambient session id when reporting progress
          this.store.dispatch(ChatThreadsActions.load({ reason: 'foreground-push' }));
        }
      }
    });
    this.actions$.pipe(ofType<fromPush.PushActionPerformed>(fromPush.PUSH_ACTION_PERFORMED)).subscribe((action) => {
      console.log('Push action performed');

      // Android and ios data presented slightly differently
      const pushData = action.payload.action.notification.data.aps ?? action.payload.action.notification.data;

      if (pushData.type === '2' && action.payload.action.actionId === 'tap') {
        const processChatNotificationTap = (): void => {
          const refId = Date.now();
          // On cold start we want to start on the Chats tab
          this.store.dispatch(new fromStore.SetColdStartTab({ tab: 'Chats' }));
          // The push action performed should always happen before resume so set our session id
          // so that the chat threads load will use after core refresh on resume
          this.store.dispatch(ChatPushLoadProgressActions.setAmbientSessionId({ id: refId }));
          this.store.dispatch(ChatPushLoadProgressActions.track({ threadId: +pushData.thread }));
          this.store.dispatch(
            ChatThreadsActions.showTappedNotification({ threadId: +pushData.thread, schoolId: +pushData.school })
          );
        };
        processChatNotificationTap();
      }
      PushNotifications.getDeliveredNotifications().then((deliveredNotifications) => {
        this.store.dispatch(new ResumedWithNotifications({ notifications: deliveredNotifications.notifications }));
        this.store.dispatch(new fromPush.PushActionClear());
      });
    });

    this.actions$.pipe(ofType<fromStore.RefreshCoreFail>(fromStore.REFRESH_CORE_FAIL)).subscribe((action) => {
      if (!action.payload.noInternet) {
        this.presentLoadingFailed(action.payload.reason);
      }
    });

    // Refresh items if resuming and hub todo page is showing
    this.actions$
      .pipe(
        ofType<fromStore.RefreshCoreSuccess>(
          fromStore.REFRESH_CORE_SUCCESS || fromStore.REFRESH_CORE_SUBSCRIPTION_CHANGED
        )
      )
      .subscribe((action) => {
        if (action.payload.reason === 'resume') {
          console.log('loading new from resume');
          this.store.dispatch(new fromStore.ShowCheckNewItems());
          this.store.dispatch(new LoadNewItems({ pageId: 'hub-todo' }));
        }
      });
    // Check our auth token
    this.store.dispatch(new fromAuth.ValidateToken({ reason: 'startup' }));

    this.store.dispatch(new fromStore.StartItemReadReceiptProcessing());
    this.store.dispatch(fromStore.startIntentProcessing());
    this.store.dispatch(slipPaymentIntentActions.startIntentProcessing());
    this.store.dispatch(fromStore.coldStartCheck());
  }

  /**
   * Helper function to navigate to a page according to the page parameter
   *
   * @param page name of the page to navigate to
   */

  navigatePage(page: string): void {
    switch (page) {
      case 'Welcome':
        this.store.dispatch(
          new fromStore.NavigateTo({
            page: 'Welcome',
            setRoot: true
          })
        );

        break;
    }
  }

  logout(): void {
    this.store.dispatch(new LogOut());
  }

  private presentLoadingFailed(reason: string): void {
    const alert = this.alertCtrl.create({
      header: 'Oops!',
      message: 'Something went wrong. Please try again or come back later',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Retry',
          handler: () => {
            this.store.dispatch(new fromStore.RefreshCore({ reason }));
            return true;
          }
        }
      ]
    });
    alert.then((a) => a.present());
  }

  showProfileCheckError(error: string): void {
    // loading control from login may be up so remove it it is
    this.loadingCtrl.getTop().then((overlay) => {
      if (overlay) {
        overlay.dismiss();
      }
    });
    const alert = this.alertCtrl.create({
      header: 'Oops!',
      message: error,
      buttons: [
        {
          text: 'Logout',
          role: 'cancel',
          handler: () => {
            this.logout();
          }
        },
        {
          text: 'Retry',
          handler: () => {
            this.store.dispatch(new fromStore.ProfileCheck());
          }
        }
      ]
    });
    alert.then((a) => a.present());
  }

  private interceptConsole(): void {
    const interceptFn = (method: string): void => {
      // eslint-disable-next-line no-console
      const original = console[method];
      const loggingProvider = this.loggingService;
      // eslint-disable-next-line no-console
      console[method] = function () {
        // eslint-disable-next-line prefer-spread, prefer-rest-params
        loggingProvider.log.apply(loggingProvider, arguments);
        if (environment.testMode) {
          if (original.apply) {
            // eslint-disable-next-line prefer-rest-params
            original.apply(console, arguments);
          } else {
            // eslint-disable-next-line prefer-rest-params
            const message = Array.prototype.slice.apply(arguments).join(' ');
            original(message);
          }
        }
      };
    };
    ['log', 'warn', 'error'].forEach((method) => {
      interceptFn(method);
    });
  }
}
