import { Student, StudentItemViewModel } from './student.model';

export class SchoolWebLink {
  name: string;
  linkUrl: string;
  iconUrl: string;
  displayOrder: number;
}

export class School {
  id: number;
  subscriptionState: number;
  name: string;
  address: string;
  headteacher: string;
  telephone: string;
  email: string;
  feedChannelId: number;
  logoUrl: string;
  websiteUrl: string;
  features: string[];
  students: number[];
  confirmedOn: Date;
  webLinks: SchoolWebLink[];
  paymentKey: string;
}
export class SchoolAsset {
  id: string;
  dataUrl: string;
}

export class SchoolWebLinkViewModel {
  name: string;
  linkUrl: string;
  iconUrl: string;
  iconDataUrl: string;
  displayOrder: number;
}
export class SchoolViewModel {
  constructor(
    public id: number,
    public name: string,
    public address: string,
    public headteacher: string,
    public telephone: string,
    public email: string,
    public feedChannelId: number,
    public logoBase64: string,
    public websiteUrl: string,
    public features: string[],
    public students: Student[],
    public subscriptionState: number,
    public webLinks: SchoolWebLinkViewModel[]
  ) {}

  public get hasAttendance(): boolean {
    return !!this.features.find(
      (feature) => feature === 'studentattendancerecentv2' || feature === 'studentattendancestatisticsv2'
    );
  }
  public get hasTimetable(): boolean {
    return !!this.features.find((feature) => feature === 'studenttimetable');
  }

  public get isVerified(): boolean {
    return this.subscriptionState === 3;
  }
  public get hasAbsenceReporting(): boolean {
    return !!this.features.find((feature) => feature === 'studentabsencereporting');
  }
}

export class SchoolAttendanceViewModel {
  constructor(
    public id: number,
    public name: string,
    public address: string,
    public headteacher: string,
    public telephone: string,
    public email: string,
    public feedChannelId: number,
    public logoBase64: string,
    public websiteUrl: string,
    public features: string[],
    public students: StudentItemViewModel[],
    public subscriptionState: number,
    public webLinks: SchoolWebLinkViewModel[],
    public paymentKey: string
  ) {}

  public get hasAttendance(): boolean {
    return !!this.features.find(
      (feature) => feature === 'studentattendancerecentv2' || feature === 'studentattendancestatisticsv2'
    );
  }
  public get hasTimetable(): boolean {
    return !!this.features.find((feature) => feature === 'studenttimetable');
  }

  public get hasAppDevelopment(): boolean {
    return !!this.features.find((feature) => feature === 'appdevelopment');
  }

  public get isVerified(): boolean {
    return this.subscriptionState === 3;
  }
  public get hasAbsenceReporting(): boolean {
    return !!this.features.find((feature) => feature === 'studentabsencereporting');
  }

  public get hasAttendanceAlerts(): boolean {
    return !!this.features.find((feature) => feature === 'attendancealerting');
  }

  public get hasAdhocPayments(): boolean {
    return !!this.features.find((feature) => feature === 'adhocpayments');
  }

  public get hasResponseSlips(): boolean {
    return !!this.features.find((feature) => feature === 'responseslips');
  }
}
