import { Injectable } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { Observable, Observer } from 'rxjs';
import { ParentHubCalendar } from '../../plugins/parent-hub-calendar';
import { AttachmentService } from './attachment.service';

export class CalendarEvent {
  constructor(
    public title: string,
    public start: string,
    public end: string
  ) {}
}
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  constructor(
    private platform: Platform,
    private attachmentService: AttachmentService,
    private actionSheetCtrl: ActionSheetController
  ) {}

  public SaveEvent(event: CalendarEvent): Observable<boolean> {
    if (!this.platform.is('hybrid')) {
      const blob = this.createBlob(event);
      const filename = Date.now() + '.ics';
      return this.attachmentService.openBlob(filename, 'text/calendar', blob);
    } else {
      return Observable.create((observer) => {
        const buttons = [];

        buttons.push({
          text: 'Add a new calendar event',
          handler: () => {
            const start = moment(event.start, 'YYYYMMDDTHHmm00').toDate();
            const end = moment(event.end, 'YYYYMMDDTHHmm00').toDate();
            this.saveToCalendar(observer, event.title, start, end);
          }
        });

        buttons.push({
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            observer.next(false);
          }
        });

        const actionSheet = this.actionSheetCtrl.create({
          buttons
        });

        actionSheet.then((as) => as.present());
      });
    }
  }

  private saveToCalendar(observer: Observer<boolean>, title: string, start: Date, end: Date): void {
    ParentHubCalendar.createEventInteractively({
      title: title,
      startDate: start.getTime(),
      endDate: end.getTime()
    }).then(
      () => observer.next(true),
      () => observer.next(false)
    );
  }

  private createBlob(event: CalendarEvent): Blob {
    // https://raw.githubusercontent.com/nwcell/ics.js/master/ics.js

    const uidDomain = 'default';

    const prodId = 'Calendar';

    const SEPARATOR = navigator.appVersion.indexOf('Win') !== -1 ? '\r\n' : '\n';
    const calendarEvents = [];
    const calendarStart = ['BEGIN:VCALENDAR', 'PRODID:' + prodId, 'VERSION:2.0'].join(SEPARATOR);
    const calendarEnd = SEPARATOR + 'END:VCALENDAR';

    const now = moment().format('YYYYMMDDTHHmm00');
    const startEnd = ['DTSTART:' + event.start];
    if (event.end) {
      startEnd.push('DTEND:' + event.end);
    }
    const calendarEvent = [
      'BEGIN:VEVENT',
      'UID:' + calendarEvents.length + '@' + uidDomain,
      'CLASS:PUBLIC',
      'DTSTAMP:' + now,
      ...startEnd,
      'SUMMARY;LANGUAGE=en-us:' + event.title,
      'TRANSP:TRANSPARENT',
      'END:VEVENT'
    ];

    const calendar = calendarStart + SEPARATOR + calendarEvent.join(SEPARATOR) + calendarEnd;
    const mimeString = 'text/calendar';
    return new Blob([calendar], { type: mimeString });
  }
}
