import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root'
})
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerValue = req.headers.get('timeout');
    const timeoutValue = headerValue ? Number(headerValue) : this.defaultTimeout;

    return timeoutValue === 0 ? next.handle(req) : next.handle(req).pipe(timeout(timeoutValue));
  }
}

// The following is useful in simulating timeouts on http request
// Activate it by including the simulatetimeout http header with a
// value greater than the timeout used in TimeoutInterceptor
@Injectable({
  providedIn: 'root'
})
export class SimulateTimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerValue = req.headers.get('simulatetimeout');
    const timeoutValue = headerValue ? Number(headerValue) : 0;

    return timeoutValue === 0 ? next.handle(req) : next.handle(req).pipe(delay(timeoutValue));
  }
}
