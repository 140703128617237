// https://github.com/jepiqueau/angular-sqlite-app-starter/blob/master/src/app/services/database.service.ts

import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Platform } from '@ionic/angular';
import { SQLiteService } from './sqlite.service';

interface SQLiteDBConnectionCallback<T> {
  (myArguments: SQLiteDBConnection): T;
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private db: SQLiteDBConnection;
  constructor(
    private sqlite: SQLiteService,
    private platform: Platform
  ) {}

  async renameLegacyDb(): Promise<void> {
    // Move away from original @ionic/storage convention
    try {
      if (this.platform.is('ios')) {
        await this.sqlite.moveDatabasesAndAddSuffix('Library/LocalDatabase', ['_ionicstorage']);
      } else if (this.platform.is('android')) {
        await this.sqlite.moveDatabasesAndAddSuffix('default', ['_ionicstorage']);
      }
    } catch (error) {
      // ignore
    }
  }

  /**
   * this function will handle the sqlite isopen and isclosed automatically for you.
   * @param callback: The callback function that will execute multiple SQLiteDBConnection commands or other stuff.
   * @param databaseName optional another database name
   * @returns any type you want to receive from the callback function.
   */
  async executeQuery<T>(callback: SQLiteDBConnectionCallback<T>, databaseName: string = '_ionicstorage'): Promise<T> {
    try {
      await this.prepareConnection(databaseName);
      const cb = await callback(this.db);
      return cb;
    } catch (error) {
      console.log('error');
      throw Error(`DatabaseServiceError: ${error}`);
    }
  }

  private async prepareConnection(databaseName: string): Promise<void> {
    if (!this.db) {
      const ret = await this.sqlite.checkConnectionsConsistency();
      const isConnection = await this.sqlite.isConnection(databaseName);
      if (isConnection.result || ret.result) {
        this.db = await this.sqlite.retrieveConnection(databaseName);
      } else {
        this.db = await this.sqlite.createConnection(databaseName, false, 'no-encryption', 1);
        await this.db.open();
      }
      if (!(await this.db.isDBOpen())) {
        await this.db.open();
      }
    }
  }
}
