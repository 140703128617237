// Modules
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { AccountApiService } from '../../services/account.api.service';
// Services
import { ApiService } from '../../services/api.service';
// State
import * as accountActions from '../actions/account.action';

@Injectable()
export class AccountEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private accountApiService: AccountApiService
  ) {}

  getProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.GetProfile>(accountActions.GET_PROFILE),
      switchMap((action) =>
        this.apiService.getProfile().pipe(
          delay(1000),
          map((profile) => {
            return new accountActions.GetProfileSuccess({
              forename: profile.forename,
              surname: profile.surname
            });
          }),
          catchError((error) => {
            return of(new accountActions.GetProfileFail());
          })
        )
      )
    )
  );

  updateProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.UpdateProfile>(accountActions.UPDATE_PROFILE),
      switchMap((action) =>
        this.apiService.updateProfile(action.payload.forename, action.payload.surname).pipe(
          map(() => {
            return new accountActions.UpdateProfileSuccess({
              forename: action.payload.forename,
              surname: action.payload.surname
            });
          }),
          catchError((error) => {
            return of(
              new accountActions.UpdateProfileFail({
                forename: action.payload.forename,
                surname: action.payload.surname
              })
            );
          })
        )
      )
    )
  );

  changePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.ChangePassword>(accountActions.CHANGE_PASSWORD),
      switchMap((action) =>
        this.accountApiService.changePassword(action.payload.password, action.payload.newPassword).pipe(
          map(() => {
            return new accountActions.ChangePasswordSuccess({
              password: action.payload.password,
              newPassword: action.payload.newPassword
            });
          }),
          catchError((result) => {
            return of(
              new accountActions.ChangePasswordFail({
                password: action.payload.password,
                newPassword: action.payload.newPassword,
                reason:
                  result.status === 400
                    ? // TODO change result.error response to : The current password you've entered
                      // is incorrect. Please try again.
                      this.determineError(result.error)
                    : null
              })
            );
          })
        )
      )
    )
  );

  changeEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.ChangeEmail>(accountActions.CHANGE_EMAIL),
      switchMap((action) =>
        this.accountApiService.changeEmail(action.payload.password, action.payload.newEmail).pipe(
          map(() => {
            return new accountActions.ChangeEmailSuccess({
              password: action.payload.password,
              newEmail: action.payload.newEmail
            });
          }),
          catchError((result) => {
            return of(
              new accountActions.ChangeEmailFail({
                password: action.payload.password,
                newEmail: action.payload.newEmail,
                reason: result.status === 400 ? this.determineError(result.error) : null
              })
            );
          })
        )
      )
    )
  );

  getUserInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.GetUserInfo>(accountActions.GET_USER_INFO),
      switchMap((action) =>
        this.accountApiService.getUserInfo().pipe(
          delay(1000),
          map((userInfo) => {
            return new accountActions.GetUserInfoSuccess({
              email: userInfo.email,
              emailVerified: userInfo.email_verified
            });
          }),
          catchError((error) => {
            return of(new accountActions.GetUserInfoFail());
          })
        )
      )
    )
  );

  closeAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.CloseAccount>(accountActions.CLOSE_ACCOUNT),
      switchMap((action) =>
        this.accountApiService.prepareClose(action.payload.password).pipe(
          switchMap((token) => {
            return this.apiService.deleteAccount().pipe(
              switchMap(() => {
                return this.accountApiService.close(token).pipe(
                  map(() => {
                    return new accountActions.CloseAccountSuccess({
                      password: action.payload.password
                    });
                  })
                );
              })
            );
          }),
          catchError((result) => {
            const error = this.determineError2(result);
            return of(
              new accountActions.CloseAccountFail({
                password: action.payload.password,
                errorCode: error.code,
                reason: error.message
              })
            );
          })
        )
      )
    )
  );

  determineError = (errorText: string): string => {
    try {
      const error = JSON.parse(errorText);
      const firstError = error[Object.keys(error)[0]][0];
      return firstError;
    } catch (error) {
      return 'Unknown error';
    }
  };

  parseError = (errorText: string): { code: string; message: string } => {
    try {
      const error = JSON.parse(errorText);
      const code = Object.keys(error)[0];
      const message = error[code][0];
      return { code, message };
    } catch (error) {
      return { code: 'Unknown', message: 'An unknown error occurred' };
    }
  };

  determineError2 = (result: any): { code: string; message: string } => {
    const error =
      result.status === 400 ? this.parseError(result.error) : { code: 'Unknown', message: 'An unknown error occurred' };
    return error;
  };
}
