import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { effects, reducers } from './store';

@NgModule({
  imports: [StoreModule.forFeature('push', reducers), EffectsModule.forFeature(effects), CommonModule],
  declarations: []
})
export class PushModule {}
