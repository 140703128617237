import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of, timer } from 'rxjs';

@Component({
  selector: 'app-timed-spinner',
  templateUrl: './timed-spinner.component.html',
  styleUrls: ['./timed-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimedSpinnerComponent implements OnInit, OnChanges {
  @Input() loading: boolean;
  spinnerTimer$: Observable<0> = of(0);
  ngOnInit(): void {
    if (this.loading) {
      this.spinnerTimer$ = timer(1000);
    }
  }
  // Need to reset the timer if we've switched to different thread id
  // while the modal is showing
  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.loading;
    if (change && !change.isFirstChange() && change.currentValue === true) {
      this.spinnerTimer$ = timer(1000);
    }
  }
}
