// State
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as moment from 'moment';
import { AttendanceSessions, AttendanceSummary } from '../../models/attendance.model';
import { StudentTimetable } from '../../models/timetable.model';
import * as fromStudents from '../actions/students.action';

export interface AttendanceSummaryState extends EntityState<AttendanceSummary> {
  loading: boolean;
  error: string;
}
export const attendanceSummaryAdapter: EntityAdapter<AttendanceSummary> = createEntityAdapter<AttendanceSummary>({
  selectId: (summary: AttendanceSummary) => summary.studentId
});

export interface AttendanceSessionsState extends EntityState<AttendanceSessions> {
  loading: boolean;
  error: string;
}
export const attendanceSessionsAdapter: EntityAdapter<AttendanceSessions> = createEntityAdapter<AttendanceSessions>({
  selectId: (sessions: AttendanceSessions) => sessions.studentId
});

export interface UnauthorizedAbsencesState extends EntityState<AttendanceSessions> {
  loading: boolean;
  error: string;
}
export const unauthorizedAbsencesAdapter: EntityAdapter<AttendanceSessions> = createEntityAdapter<AttendanceSessions>({
  selectId: (sessions: AttendanceSessions) => sessions.studentId
});

export interface TimetableState extends EntityState<StudentTimetable> {
  loading: boolean;
  error: string;
}
export const timetableAdapter: EntityAdapter<StudentTimetable> = createEntityAdapter<StudentTimetable>({
  selectId: (timetable: StudentTimetable) => timetable.studentId
});
export interface StudentsState {
  attendanceSummary: AttendanceSummaryState;
  attendanceSessions: AttendanceSessionsState;
  unauthorizedAbsences: UnauthorizedAbsencesState;
  timetable: TimetableState;
  attendanceSessionUpdateDate: string;
}

export const initialState: StudentsState = {
  attendanceSummary: {
    ...attendanceSummaryAdapter.getInitialState(),
    loading: false,
    error: null
  },
  attendanceSessions: {
    ...attendanceSessionsAdapter.getInitialState(),
    loading: false,
    error: null
  },
  unauthorizedAbsences: {
    ...unauthorizedAbsencesAdapter.getInitialState(),
    loading: false,
    error: null
  },
  timetable: {
    ...timetableAdapter.getInitialState(),
    loading: false,
    error: null
  },
  attendanceSessionUpdateDate: null
};

export function reducer(state = initialState, action: fromStudents.StudentsAction): StudentsState {
  switch (action.type) {
    case fromStudents.GET_ATTENDANCE_SUMMARY: {
      return {
        ...state,
        attendanceSummary: { ...state.attendanceSummary, loading: true }
      };
    }
    case fromStudents.GET_ATTENDANCE_SUMMARY_FAIL: {
      return {
        ...state,
        attendanceSummary: { ...state.attendanceSummary, loading: false }
      };
    }
    case fromStudents.GET_ATTENDANCE_SUMMARY_SUCCESS: {
      return {
        ...state,
        attendanceSummary: {
          // ngrx entities bug https://github.com/ngrx/platform/issues/993
          ...attendanceSummaryAdapter.addOne(
            action.payload.summary,
            attendanceSummaryAdapter.removeOne(action.payload.summary.studentId, state.attendanceSummary)
          ),
          loading: false
        }
      };
    }
    case fromStudents.GET_ATTENDANCE_SESSIONS: {
      return {
        ...state,
        attendanceSessions: { ...state.attendanceSessions, loading: true }
      };
    }
    case fromStudents.GET_ATTENDANCE_SESSIONS_FAIL: {
      return {
        ...state,
        attendanceSessions: { ...state.attendanceSessions, loading: false }
      };
    }
    case fromStudents.GET_ATTENDANCE_SESSIONS_SUCCESS: {
      return {
        ...state,
        attendanceSessions: {
          // ngrx entities bug https://github.com/ngrx/platform/issues/993
          ...attendanceSessionsAdapter.addOne(
            action.payload.sessions,
            attendanceSessionsAdapter.removeOne(action.payload.sessions.studentId, state.attendanceSessions)
          ),
          loading: false
        }
      };
    }
    case fromStudents.GET_UNAUTHORIZED_ABSENCES: {
      return {
        ...state,
        unauthorizedAbsences: { ...state.unauthorizedAbsences, loading: true }
      };
    }
    case fromStudents.GET_UNAUTHORIZED_ABSENCES_FAIL: {
      return {
        ...state,
        unauthorizedAbsences: { ...state.unauthorizedAbsences, loading: false }
      };
    }
    case fromStudents.GET_UNAUTHORIZED_ABSENCES_SUCCESS: {
      return {
        ...state,
        unauthorizedAbsences: {
          // ngrx entities bug https://github.com/ngrx/platform/issues/993
          ...unauthorizedAbsencesAdapter.addOne(
            action.payload.sessions,
            unauthorizedAbsencesAdapter.removeOne(action.payload.sessions.studentId, state.unauthorizedAbsences)
          ),
          loading: false
        }
      };
    }
    case fromStudents.GET_TIMETABLE: {
      return {
        ...state,
        timetable: { ...state.timetable, loading: true }
      };
    }
    case fromStudents.GET_TIMETABLE_FAIL: {
      return {
        ...state,
        timetable: { ...state.timetable, loading: false }
      };
    }
    case fromStudents.GET_TIMETABLE_SUCCESS: {
      return {
        ...state,
        timetable: {
          // ngrx entities bug https://github.com/ngrx/platform/issues/993
          ...timetableAdapter.addOne(
            action.payload.timetable,
            timetableAdapter.removeOne(action.payload.timetable.studentId, state.timetable)
          ),
          loading: false
        }
      };
    }

    case fromStudents.SET_ATTENDANCE_SESSION_UPDATE_DATE: {
      return {
        ...state,
        attendanceSessionUpdateDate: moment().format()
      };
    }

    default: {
      return state;
    }
  }
}
