import { SQLiteService } from './sqlite.service';

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { StorageSyncActions } from 'src/external/ngrx-store-ionic-storage';
import { LocalForageService } from './localForage.service';
import { MigrationService } from './migrations.service';

@Injectable({
  providedIn: 'root'
})
export class InitializeAppService {
  constructor(
    private store: Store,
    private sqliteService: SQLiteService,
    private migrationService: MigrationService,
    private localForageService: LocalForageService,
    private platform: Platform
  ) {}

  async initializeApp(): Promise<void> {
    if (this.platform.is('hybrid')) {
      await this.sqliteService.initializePlugin().then(async (ret) => {
        try {
          //execute startup queries
          await this.migrationService.migrate();
          this.store.dispatch({ type: StorageSyncActions.HYDRATE });
        } catch (error) {
          throw Error(`initializeAppError: ${error}`);
        }
      });
    } else {
      this.localForageService.init();
      this.store.dispatch({ type: StorageSyncActions.HYDRATE });
      return Promise.resolve();
    }
  }
}
