// State
import { Action } from '@ngrx/store';
import { AttendanceSessions, AttendanceSummary } from '../../models/attendance.model';
import { StudentTimetable } from '../../models/timetable.model';

export const GET_ATTENDANCE_SUMMARY = '[Students] Get Attendance Summary';
export const GET_ATTENDANCE_SUMMARY_FAIL = '[Students] Get Attendance Summary Fail';
export const GET_ATTENDANCE_SUMMARY_SUCCESS = '[Students] Get Attendance Summary Success';
export const GET_ATTENDANCE_SESSIONS = '[Students] Get Attendance Sessions';
export const GET_ATTENDANCE_SESSIONS_FAIL = '[Students] Get Attendance Sessions Fail';
export const GET_ATTENDANCE_SESSIONS_SUCCESS = '[Students] Get Attendance Sessions Success';
export const GET_UNAUTHORIZED_ABSENCES = '[Students] Get Unauthorized Absences';
export const GET_UNAUTHORIZED_ABSENCES_FAIL = '[Students] Get Unauthorized Absences Fail';
export const GET_UNAUTHORIZED_ABSENCES_SUCCESS = '[Students] Get Unauthorized Absences Success';
export const REFRESH_TIMETABLE = '[Students] Refresh Timetable';
export const GET_TIMETABLE = '[Students] Get Timetable';
export const GET_TIMETABLE_FAIL = '[Students] Get Timetable Fail';
export const GET_TIMETABLE_SUCCESS = '[Students] Get Timetable Success';
export const REPORT_ABSENCE = '[Students] Report Absence';
export const REPORT_ABSENCE_FAIL = '[Students] Report Absence Fail';
export const REPORT_ABSENCE_SUCCESS = '[Students] Report Absence Success';
export const SET_ATTENDANCE_SESSION_UPDATE_DATE = '[Students] Set Attendance Session Update Date';

export class GetAttendanceSummary implements Action {
  readonly type = GET_ATTENDANCE_SUMMARY;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetAttendanceSummaryFail implements Action {
  readonly type = GET_ATTENDANCE_SUMMARY_FAIL;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetAttendanceSummarySuccess implements Action {
  readonly type = GET_ATTENDANCE_SUMMARY_SUCCESS;
  constructor(
    public payload: {
      studentId: number;
      schoolId: number;
      summary: AttendanceSummary;
    }
  ) {}
}

export class GetAttendanceSessions implements Action {
  readonly type = GET_ATTENDANCE_SESSIONS;
  constructor(public payload: { studentId: number; schoolId: number; onResume?: boolean }) {}
}

export class GetAttendanceSessionsFail implements Action {
  readonly type = GET_ATTENDANCE_SESSIONS_FAIL;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetAttendanceSessionsSuccess implements Action {
  readonly type = GET_ATTENDANCE_SESSIONS_SUCCESS;
  constructor(
    public payload: {
      studentId: number;
      schoolId: number;
      sessions: AttendanceSessions;
      requestDateTime?: string;
    }
  ) {}
}

export class GetUnauthorizedAbsences implements Action {
  readonly type = GET_UNAUTHORIZED_ABSENCES;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetUnauthorizedAbsencesFail implements Action {
  readonly type = GET_UNAUTHORIZED_ABSENCES_FAIL;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetUnauthorizedAbsencesSuccess implements Action {
  readonly type = GET_UNAUTHORIZED_ABSENCES_SUCCESS;
  constructor(
    public payload: {
      studentId: number;
      schoolId: number;
      sessions: AttendanceSessions;
    }
  ) {}
}

export class RefreshTimetable implements Action {
  readonly type = REFRESH_TIMETABLE;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetTimetable implements Action {
  readonly type = GET_TIMETABLE;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetTimetableFail implements Action {
  readonly type = GET_TIMETABLE_FAIL;
  constructor(public payload: { studentId: number; schoolId: number }) {}
}

export class GetTimetableSuccess implements Action {
  readonly type = GET_TIMETABLE_SUCCESS;
  constructor(
    public payload: {
      studentId: number;
      schoolId: number;
      timetable: StudentTimetable;
    }
  ) {}
}

export class ReportAbsence implements Action {
  readonly type = REPORT_ABSENCE;
  constructor(
    public payload: {
      studentId: number;
      schoolId: number;
      date: string;
      session: string;
      reason: string;
    }
  ) {}
}

export class ReportAbsenceFail implements Action {
  readonly type = REPORT_ABSENCE_FAIL;
  constructor(public payload: { studentId: number; schoolId: number; reason: string }) {}
}

export class ReportAbsenceSuccess implements Action {
  readonly type = REPORT_ABSENCE_SUCCESS;
}

export class SetAttendanceSessionUpdateDate implements Action {
  readonly type = SET_ATTENDANCE_SESSION_UPDATE_DATE;
}

export type StudentsAction =
  | GetAttendanceSummary
  | GetAttendanceSummaryFail
  | GetAttendanceSummarySuccess
  | GetAttendanceSessions
  | GetAttendanceSessionsFail
  | GetAttendanceSessionsSuccess
  | GetUnauthorizedAbsences
  | GetUnauthorizedAbsencesFail
  | GetUnauthorizedAbsencesSuccess
  | RefreshTimetable
  | GetTimetable
  | GetTimetableFail
  | GetTimetableSuccess
  | ReportAbsence
  | ReportAbsenceFail
  | ReportAbsenceSuccess
  | SetAttendanceSessionUpdateDate;
