import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { SettingsState } from '../reducers/settings.reducer';

export const getSettingsState = createFeatureSelector<SettingsState>('settingsState');

export const getRetentionThresholdDate = createSelector(getSettingsState, (state) =>
  moment.utc().subtract(moment.duration(state.retentionPeriod1)).toDate()
);

export const getLanguageId = createSelector(getSettingsState, (state) => state.languageId);
