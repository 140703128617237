import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../actions/slip-settings.action';

export interface SlipSettingsState {
  slipTypeFilter: string[];
  statusFilter: string[];
  progressFilter: string[];
  sortOrder: string;
}

export const initialState: SlipSettingsState = {
  slipTypeFilter: ['item', 'trip', 'consent', 'simple-survey', 'other'],
  statusFilter: ['open'],
  progressFilter: ['pending', 'in-progress'],
  sortOrder: 'due:asc'
};
export const theReducer = createReducer(
  initialState,
  on(actions.updateFilters, (state, action) => ({
    ...state,
    statusFilter: action.statusFilter,
    slipTypeFilter: action.slipTypeFilter,
    progressFilter: action.progressFilter
  })),
  on(actions.updateSortOrder, (state, action) => ({
    ...state,
    sortOrder: action.sortOrder
  }))
);

export function reducer(state: SlipSettingsState | undefined, action: Action): any {
  return theReducer(state, action);
}
