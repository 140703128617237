// Modules
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class UserInfoDto {
  email: string;
  email_verified: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AccountApiService {
  constructor(private http: HttpClient) {}

  requestPasswordReset(email: string): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(
      environment.accountBaseUrl + '/api/account/forgotpassword',
      { email: email },
      { headers: headers, responseType: 'text' }
    );
  }

  getUserInfo(): Observable<UserInfoDto> {
    return this.http.get<UserInfoDto>(environment.accountBaseUrl + '/connect/userinfo');
  }

  changePassword(password: string, newPassword: string): Observable<string> {
    const data = {
      password: password,
      newPassword: newPassword
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.accountBaseUrl + '/api/account/changepassword', data, {
      headers: headers,
      responseType: 'text'
    });
  }

  changeEmail(password: string, newEmail: string): Observable<string> {
    const data = {
      password: password,
      currentEmailAddress: 'a@b.com',
      newEmailAddress: newEmail,
      newEmailAddressConfirm: newEmail
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.accountBaseUrl + '/api/account/changeemailaddress', data, {
      headers: headers,
      responseType: 'text'
    });
  }

  prepareClose(password: string): Observable<string> {
    const data = {
      password: password
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.accountBaseUrl + '/api/account/prepareclose', data, {
      headers: headers,
      responseType: 'text'
    });
  }
  close(token: string): Observable<string> {
    const data = {
      token: token
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.accountBaseUrl + '/api/account/close', data, {
      headers: headers,
      responseType: 'text'
    });
  }
}
