import { createAction, props } from '@ngrx/store';

export const updateFilters = createAction(
  '[Slip Settings] Update Filters',
  props<{
    slipTypeFilter: string[];
    statusFilter: string[];
    progressFilter: string[];
  }>()
);

export const updateSortOrder = createAction(
  '[Slip Settings] Update Sort Order',
  props<{
    sortOrder: string;
  }>()
);
