import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Profile } from '../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  constructor(private http: HttpClient) {}

  createAccount(username: string, password: string, allowContact: boolean, confirmTCs: boolean): Observable<void> {
    const account = {
      emailAddress: username,
      password: password,
      allowContactByEmail: allowContact,
      confirmTCs: confirmTCs
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<void>(environment.accountBaseUrl + '/api/account/register', JSON.stringify(account), {
      headers: headers,
      responseType: 'json'
    });
  }

  createProfile(firstname: string, lastname: string): Observable<void> {
    const profile = {
      forename: firstname,
      surname: lastname
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<void>(environment.apiBaseUrl + '/api/profile', JSON.stringify(profile), {
      headers: headers,
      responseType: 'json'
    });
  }

  checkEmailRegistered(email: string): Observable<void> {
    return this.http.get<void>(environment.accountBaseUrl + '/api/account/user?name=' + email, {
      responseType: 'json'
    });
  }

  profileCheck(): Observable<Profile> {
    return this.http.get<Profile>(environment.apiBaseUrl + '/api/profile');
  }
}
