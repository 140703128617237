import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
@Component({
  selector: 'app-initial',
  templateUrl: './initial.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitialPage implements OnDestroy {
  ngOnDestroy(): void {
    SplashScreen.hide();
  }
}
