import { createAction, props } from '@ngrx/store';
import { SlipQuestionResponse } from '../reducers/slip-question-response.reducer';

// Used for caching slip question responses

export const upsertQuestionResponse = createAction(
  '[Slip Question Response] Upsert Question Response',
  props<{
    data: SlipQuestionResponse;
  }>()
);
export const deleteQuestionResponse = createAction(
  '[Slip Question Response] Delete Question Response',
  props<{
    id: string;
  }>()
);
