import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonRadioGroup, ModalController } from '@ionic/angular';

export enum RequestStatusFilter {
  Active,
  Closed,
  All
}

export enum PaymentStatusFilter {
  Paid,
  Owing,
  All
}

export interface FilterSelection {
  requestStatusFilter: RequestStatusFilter;
  paymentStatusFilter: PaymentStatusFilter;
}

@Component({
  selector: 'app-student-payment-list-filter-modal',
  templateUrl: './student-payment-list-filter-modal.component.html',
  styleUrls: ['./student-payment-list-filter-modal.component.scss']
})
export class StudentPaymentListFilterModalComponent implements OnInit {
  @ViewChild('requestStatusGroup', { static: true }) requestStatusGroup: IonRadioGroup;
  @ViewChild('paymentStatusGroup', { static: true }) paymentStatusGroup: IonRadioGroup;
  @Input() studentName: string;
  @Input() preselectedRequestStatus: RequestStatusFilter;
  @Input() preselectedPaymentStatus: PaymentStatusFilter;
  RequestStatus = RequestStatusFilter;
  PaymentStatus = PaymentStatusFilter;
  selectedFilters: FilterSelection = {
    requestStatusFilter: RequestStatusFilter.Active,
    paymentStatusFilter: PaymentStatusFilter.Owing
  };

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.selectedFilters.requestStatusFilter = this.preselectedRequestStatus ?? RequestStatusFilter.Active;
    this.selectedFilters.paymentStatusFilter = this.preselectedPaymentStatus ?? PaymentStatusFilter.Owing;
  }

  onDismiss(): void {
    this.modalCtrl.dismiss();
  }

  requestStatusGroupChange(event: Event): void {
    this.selectedFilters.requestStatusFilter = +(event as CustomEvent).detail.value;
  }

  paymentStatusGroupChange(event: Event): void {
    this.selectedFilters.paymentStatusFilter = +(event as CustomEvent).detail.value;
  }

  onApplyFilters(): void {
    this.modalCtrl.dismiss({
      selectedFilters: this.selectedFilters
    });
  }
}
