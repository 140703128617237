/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll } from '../reducers/chat-message-receipts.reducer';
import { State } from '../reducers/chat-push-load-progress.reducer';

export const getChatPushLoadProgressState = createFeatureSelector<State>('chatPushLoadProgressState');

export const getChatPushLoadEntities = createSelector(getChatPushLoadProgressState, (state) => state.entities);

export const getChatPushLoadProgressById = (props: { id: number }) =>
  createSelector(getChatPushLoadProgressState, (state) => {
    return state.entities[props.id];
  });

export const getChatPushLoadProgressByThreadId = (props: { threadId: number }) =>
  createSelector(selectAll, (state) => {
    return state.find((e) => e.chatThreadId === props.threadId);
  });
export const getChatPushLoadProgressAmbientSessionId = createSelector(
  getChatPushLoadProgressState,
  (state) => state.ambientSessionId
);
