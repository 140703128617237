import { Injectable } from '@angular/core';
import * as localforage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class LocalForageService {
  init(): void {
    localforage.config({
      name: '_ionickv',
      storeName: '_ionicstorage'
    });
  }

  get<T>(key: string): Promise<T> {
    return localforage.getItem<T>(key);
  }

  set(key: string, value: any): Promise<void> {
    return localforage.setItem(key, value);
  }
}
