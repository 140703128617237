import { Injectable } from '@angular/core';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { ModalController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import linkifyHtml from 'linkify-html';
import moment from 'moment';
import { Observable, filter, takeUntil, tap, withLatestFrom } from 'rxjs';
import { formatDisplayName } from 'src/app/models/publisher.model';
import { getNotificationsAtResume } from 'src/app/push/store';
import {
  ChatThreadCompatibilityLevel,
  ChatThreadDto,
  ChatThreadMessageCompatibilityLevel,
  ChatThreadMessageDto,
  ChatThreadParticipantDto
} from 'src/app/services/chat.service';
import { DateFunctions } from 'src/app/services/dateFunctions';
import { ChatMessageReceiptsActions } from 'src/app/store/actions/chat-message-receipts.action';
import { ChatThreadPendingReplyActions } from 'src/app/store/actions/chat-thread-pending-reply.action';
import { ChatThreadActions } from 'src/app/store/actions/chat-thread.action';
import { ChatThreadsActions } from 'src/app/store/actions/chat-threads.action';
import { LifecycleActions } from 'src/app/store/actions/lifecycle.action';
import { ChatThreadPendingReply } from 'src/app/store/reducers/chat-thread-pending-reply.reducer';
import { getChatPushLoadProgressAmbientSessionId } from 'src/app/store/selectors/chat-push-load-progress.selector';
import { getChatThreadPendingReplies } from 'src/app/store/selectors/chat-thread-pending-reply.selector';
import { getChatThreadModel, getLoadMethod } from 'src/app/store/selectors/chat-thread.selector';
import { getChatThreadsLoading } from 'src/app/store/selectors/chat-threads.selector';

export interface TimeLineEntry {}
export class ChatInfo implements TimeLineEntry {
  constructor(
    public creatorName: string,
    public aboutName: string,
    public otherParticipantNames: string[],
    public title: string
  ) {}
}

export class ParticipantMessages implements TimeLineEntry {
  constructor(
    public name: string,
    public userId: string,
    public messages: {
      id: number;
      content: string;
      timestamp: string;
      unread: boolean;
      requiresUpgrade: boolean;
      removed: boolean;
    }[]
  ) {}
}

export class PendingMessages implements TimeLineEntry {
  constructor(
    public name: string,
    public messages: {
      id: number;
      content: string;
      timestamp: string;
      status: 'in-progress' | 'failed';
      canRetry: boolean;
    }[]
  ) {}
}

export class ParticipantChange implements TimeLineEntry {
  constructor(
    public newParticipant: string,
    public timestamp: string,
    public otherParticipantNames: string[]
  ) {}
}

export class UnreadTidemark implements TimeLineEntry {
  constructor() {}
}

export interface TimelineDay {
  date: string;
  entries: TimeLineEntry[];
}

export interface ContentModel {
  schoolId: number;
  chatId: number;
  closed: boolean;
  // We've received a closed response while trying to reply
  pendingClosure: boolean;
  timeline: TimelineDay[];
}

export const INITIAL_STATE: ChatModalState = {
  stage: null,
  notificationId: null,
  initialScrollComplete: false,
  chatModel: null,
  openReason: null,
  pendingReplies: [],
  threadsLoading: false,
  newMessageIdTidemark: 0,
  insideLiveZone: true,
  unreadTidemark: null,
  scrollAction: null,
  resumingToThreadId: null,
  loadFailureToastState: { open: false, message: null, class: null }
};

export interface InfoSheetModel {
  title: string;
  creator: string;
  creatorUserId: string;
  createdOn: string;
  closed: boolean;
  participants: string[];
}

export interface ChatModalState {
  stage: 'await-notification-load' | 'complete';
  notificationId: number;
  // Hide content until we've scrolled to bottom
  initialScrollComplete: boolean;
  chatModel: {
    thread: ChatThreadDto;
    loading: boolean;
    loadReason: string;
    loadFailReason: string;
  };
  openReason: string;
  pendingReplies: ChatThreadPendingReply[];
  threadsLoading: boolean;
  newMessageIdTidemark: number;

  // This controls behaviour when new messages arrive:
  // When false - show the FAB X New button
  // When true - autoscroll to first new message
  insideLiveZone: boolean;
  // Unread tidemark should only show if applicable on initial modal open
  // It should remain shown until pause/resume or the modal is reopened
  // If this modal is showing while responding to a Notification Tray tap
  // then the tidemark should show the count of new messages
  unreadTidemark: {
    show: boolean;
    hasShown: boolean;
    date: string;
    count: number;
  };
  scrollAction: {
    type: 'bottom' | 'tidemark' | 'message';
    message: {
      rendered: boolean;
      id: string;
    };
  };
  // Use this to monitor background load (indicated by header spinner) failure
  // when resuming to a chat and an applicable pending notification exists
  resumingToThreadId: number;
  loadFailureToastState: {
    open: boolean;
    message: string;
    class: string;
  };
}

@Injectable()
export class ChatModalStore extends ComponentStore<ChatModalState> {
  constructor(
    private store: Store,
    private actions: Actions,
    private modalCtrl: ModalController
  ) {
    super(INITIAL_STATE);
  }

  readonly vm$ = this.select((state) => state);

  readonly contentModel$ = this.select((state) => {
    {
      if (!state.chatModel) return null;
      return {
        useInlineSpinner: !!state.notificationId,
        initialScrollComplete: state.initialScrollComplete,
        loading: state.chatModel.loading && state.stage === 'await-notification-load',
        loadFailReason: state.stage === 'await-notification-load' ? state.chatModel.loadFailReason : null,
        info: state.chatModel.thread
          ? {
              threadId: state.chatModel.thread.threadId,
              closed: !!state.chatModel.thread.closedOn || !!state.chatModel.thread.chat.closedOn,
              pendingClosure: state.pendingReplies.find((pr) => pr.status === 'failed' && pr.error === 'closed'),
              schoolId: state.chatModel.thread.schoolId,
              timeline: this.constructTimeline(state.chatModel.thread, state.pendingReplies)
            }
          : null
      };
    }
  });

  readonly footerModel$ = this.select((state) => {
    {
      if (!state.chatModel || state.chatModel.thread?.compatibilityLevel > ChatThreadCompatibilityLevel) return null;
      return state.chatModel.thread && !state.chatModel.thread.closedOn && !state.chatModel.thread.chat.closedOn
        ? {
            threadId: state.chatModel.thread.threadId,
            disableSend: state.pendingReplies.find((pr) => pr.status === 'failed' && pr.error === 'closed')
          }
        : null;
    }
  });

  readonly newMessagesModel$ = this.select((state) => {
    {
      if (!state.chatModel?.thread) return null;
      const myParticipantId = state.chatModel.thread.participants.find((p) => p.isMe)?.participantId;
      const newMessages = state.chatModel.thread.messages.filter(
        (m) => m.createdByParticipantId !== myParticipantId && m.messageId > state.newMessageIdTidemark
      );
      if (newMessages.length === 0) return null;
      const firstNewMessage = Math.min(...newMessages.map((m) => m.messageId));
      return { count: newMessages.length, firstNewMessage: firstNewMessage };
    }
  });

  readonly headerModel$ = this.select((state) => {
    {
      return state.chatModel?.thread
        ? {
            title:
              state.chatModel.thread.compatibilityLevel > ChatThreadCompatibilityLevel
                ? 'App upgrade required'
                : 'Chat about ' + state.chatModel.thread.about[0].forename,
            threadsLoading: state.threadsLoading && state.stage !== 'await-notification-load',
            disableInfo: state.chatModel.thread.compatibilityLevel > ChatThreadCompatibilityLevel
          }
        : { title: null, threadsLoading: false, disableInfo: true };
    }
  });

  readonly infoSheetModel$ = this.select((state) => {
    {
      return this.constructInfoSheetModel(state.chatModel.thread);
    }
  });

  readonly scrollAction$ = this.select((state) => {
    {
      return state.scrollAction;
    }
  });

  readonly loadFailureToastState$ = this.select((state) => {
    {
      return state.loadFailureToastState;
    }
  });

  readonly setInitialScrollComplete = this.updater((state) => ({
    ...state,
    initialScrollComplete: true
  }));
  readonly clearScrollAction = this.updater((state) => ({
    ...state,
    scrollAction: null
  }));

  readonly setPendingReplies = this.updater((state, value: ChatThreadPendingReply[]) => ({
    ...state,
    pendingReplies: value
  }));

  readonly setThreadsLoading = this.updater((state, value: boolean) => ({
    ...state,
    threadsLoading: value
  }));

  readonly setInsideLiveZone = this.updater((state, value: boolean) => ({
    ...state,
    insideLiveZone: value,
    newMessageIdTidemark: value
      ? state.chatModel.thread
        ? Math.max(...state.chatModel.thread.messages.map((m) => m.messageId))
        : null
      : state.newMessageIdTidemark
  }));

  readonly resetNewMessageIdTidemark = this.updater((state) => ({
    ...state,
    newMessageIdTidemark: Math.max(...state.chatModel.thread.messages.map((m) => m.messageId))
  }));

  readonly init = this.effect((params$: Observable<{ reason: string }>) => {
    return params$.pipe(
      tap(async (params: { reason: string }) => {
        this.store
          .select(getChatThreadModel)
          .pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.store.select(getChatPushLoadProgressAmbientSessionId)),
            tap(([model, sessionId]) => {
              console.log('chat thread model', model);
              // Modal has just been opened
              if (!this.get().stage) {
                console.log('just opened');
                const stage = params.reason !== 'chats' ? 'await-notification-load' : 'complete';
                this.patchState({
                  ...INITIAL_STATE,
                  notificationId: stage == 'await-notification-load' ? sessionId : null,
                  stage: stage
                });
              }
              const patch: Partial<ChatModalState> = {};

              // Thread has just been loaded, default scroll action is to bottom
              if (model.thread && !this.get().chatModel) {
                patch.scrollAction = {
                  type: 'bottom',
                  message: null
                };
              }

              patch.chatModel = model;
              if (model.thread) {
                let skipTidemark = false;
                if (this.get().stage === 'await-notification-load') {
                  if (!model.loading && !model.loadFailReason) {
                    patch.stage = 'complete';
                    // Default if no unread changes (e.g. read on alternate device)
                    patch.scrollAction = {
                      type: 'bottom',
                      message: null
                    };
                    console.log('patch complete');
                  } else {
                    skipTidemark = true;
                    patch.scrollAction = null;
                  }
                }
                const myParticipantId = model.thread.participants.find((p) => p.isMe)?.participantId;
                const unreadMessages = model.thread.messages.filter(
                  (m) =>
                    !m.deletedOn &&
                    m.createdByParticipantId !== myParticipantId &&
                    !m.reactions.find((r) => r.participantId == myParticipantId)
                );

                if (this.get().insideLiveZone || !this.get().newMessageIdTidemark) {
                  patch.newMessageIdTidemark = Math.max(
                    ...model.thread.messages
                      .filter((m) => m.createdByParticipantId !== myParticipantId)
                      .map((m) => m.messageId)
                  );
                }
                if (!skipTidemark && this.get().insideLiveZone) {
                  const previousThread = this.get().chatModel?.thread;
                  const previousLatestMessage = previousThread ? previousThread.messages.slice(-1)[0] : null;
                  const newUnreadMessages = unreadMessages.filter(
                    (m) => m.messageId > previousLatestMessage?.messageId ?? 0
                  );
                  const earliestUnreadMessageId =
                    newUnreadMessages.length > 0 ? Math.min(...newUnreadMessages.map((m) => m.messageId)) : null;
                  if (earliestUnreadMessageId) {
                    patch.scrollAction = {
                      type: 'message',
                      message: {
                        rendered: false,
                        id: 'msg-' + earliestUnreadMessageId
                      }
                    };
                  }
                }
                if (!skipTidemark && !this.get().unreadTidemark?.show && !this.get().unreadTidemark?.hasShown) {
                  const earliestUnreadMessageDate =
                    unreadMessages.length > 0 ? Math.min(...unreadMessages.map((m) => Date.parse(m.createdOn))) : null;
                  if (unreadMessages.length > 0) {
                    patch.unreadTidemark = {
                      show: true,
                      hasShown: true,
                      count: unreadMessages.length,
                      date: new Date(earliestUnreadMessageDate).toISOString()
                    };
                    patch.scrollAction = {
                      type: 'tidemark',
                      message: null
                    };
                  } else {
                    patch.unreadTidemark = {
                      show: false,
                      hasShown: true,
                      count: 0,
                      date: null
                    };
                  }
                }
              }
              this.patchState(patch);
            })
          )
          .subscribe();
        this.store
          .select(getChatThreadPendingReplies)
          .pipe(
            takeUntil(this.destroy$),
            tap((pendingReplies) => {
              this.setPendingReplies(pendingReplies);
            })
          )
          .subscribe();
        this.store
          .select(getChatThreadsLoading)
          .pipe(
            takeUntil(this.destroy$),
            tap((threadsLoading) => {
              this.setThreadsLoading(threadsLoading);
            })
          )
          .subscribe();

        // Modal is open and we've been asked to open the same or different thread
        // (via notification)
        this.actions
          .pipe(
            ofType(ChatThreadActions.open),
            takeUntil(this.destroy$),
            withLatestFrom(this.store.select(getChatPushLoadProgressAmbientSessionId)),
            tap(([action, sessionId]) => {
              if (this.get().chatModel.thread.threadId !== action.threadId) {
                this.patchState({
                  ...INITIAL_STATE,
                  notificationId: sessionId,
                  stage: 'await-notification-load',
                  resumingToThreadId: null
                });
              } else {
                this.patchState({ resumingToThreadId: action.threadId });
              }
            })
          )
          .subscribe();

        this.actions.pipe(ofType(LifecycleActions.resumed), takeUntil(this.destroy$)).subscribe(() => {
          if (!this.get().resumingToThreadId && this.get().stage === 'complete') {
            this.patchState({ resumingToThreadId: this.get().chatModel.thread?.threadId });
          }
        });

        this.actions
          .pipe(
            ofType(ChatThreadsActions.loadFailure),
            takeUntil(this.destroy$),
            withLatestFrom(this.store.select(getNotificationsAtResume))
          )
          .subscribe(async ([action, notificationsAtResume]) => {
            const resumingToThreadId = this.get().resumingToThreadId;
            if (resumingToThreadId) {
              if (
                notificationsAtResume.find((n: PushNotificationSchema) => {
                  // Android and ios data presented slightly differently
                  const pushData = n.data.aps ?? n.data;
                  return +pushData.thread === resumingToThreadId;
                })
              ) {
                this.patchState({
                  loadFailureToastState: {
                    open: true,
                    message: 'Unable to load new messages this time.',
                    class: 'chat-load-fail-toast'
                  }
                });
              }
            }
          });

        // In the case where the currently open thread is not picked up due to current
        // thread filters we need to load changes specifically for this thread on
        // receiving a matching notification
        this.actions
          .pipe(
            ofType(ChatThreadsActions.foregroundNotificationReceived),
            takeUntil(this.destroy$),
            withLatestFrom(this.store.select(getLoadMethod)),
            filter(
              ([action, loadMethod]) =>
                this.get().chatModel.thread.threadId === action.threadId && loadMethod === 'thread'
            )
          )
          .subscribe(() => {
            this.store.dispatch(ChatThreadActions.loadThread());
          });
      })
    );
  });

  readonly dismissModal = this.effect((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.modalCtrl.dismiss();
      })
    );
  });

  readonly dismissToast = this.effect((params$: Observable<{ buttonRole: string }>) => {
    return params$.pipe(
      tap((params: { buttonRole: string }) => {
        if (params.buttonRole === 'cancel') {
          this.patchState({
            resumingToThreadId: null,
            loadFailureToastState: { open: false, message: null, class: null }
          });
        } else {
          this.patchState({ loadFailureToastState: { open: false, message: null, class: null } });
          this.store.dispatch(ChatThreadActions.retryLoad());
        }
      })
    );
  });

  readonly messageRendered = this.effect((params$: Observable<{ id: string }>) => {
    return params$.pipe(
      tap((params: { id: string }) => {
        const scrollAction = this.get().scrollAction;
        if (
          scrollAction &&
          scrollAction.type === 'message' &&
          !scrollAction.message.rendered &&
          scrollAction.message.id === params.id
        ) {
          this.patchState({
            scrollAction: {
              type: 'message',
              message: {
                rendered: true,
                id: params.id
              }
            }
          });
        }
      })
    );
  });

  readonly pendingMessageRendered = this.effect((params$: Observable<{ id: string }>) => {
    return params$.pipe(
      tap((params: { id: string }) => {
        const scrollAction = this.get().scrollAction;
        if (
          scrollAction &&
          scrollAction.type === 'message' &&
          !scrollAction.message.rendered &&
          scrollAction.message.id === params.id
        ) {
          this.patchState({
            scrollAction: {
              type: 'message',
              message: {
                rendered: true,
                id: params.id
              }
            }
          });
        }
      })
    );
  });

  readonly reply = this.effect((params$: Observable<{ content: string }>) => {
    return params$.pipe(
      tap((params: { content: string }) => {
        const id = Date.now();
        this.patchState({
          scrollAction: { type: 'message', message: { id: 'msg-' + id, rendered: false } }
        });
        this.store.dispatch(
          ChatThreadPendingReplyActions.reply({
            id: id,
            schoolId: this.get().chatModel.thread.schoolId,
            threadId: this.get().chatModel.thread.threadId,
            content: params.content
          })
        );
      })
    );
  });

  readonly retryReply = this.effect((params$: Observable<{ id: number }>) => {
    return params$.pipe(
      tap((params: { id: number }) => {
        this.store.dispatch(
          ChatThreadPendingReplyActions.retryReply({
            id: params.id
          })
        );
      })
    );
  });

  readonly markAsRead = this.effect((params$: Observable<{ messageId: number }>) => {
    return params$.pipe(
      tap((params: { messageId: number }) => {
        this.store.dispatch(
          ChatMessageReceiptsActions.setLatestReadMessage({
            threadId: this.get().chatModel.thread.threadId,
            schoolId: this.get().chatModel.thread.schoolId,
            messageId: params.messageId
          })
        );
      })
    );
  });

  private constructInfoSheetModel(thread: ChatThreadDto): InfoSheetModel {
    if (!thread) return null;
    const createdByParticipant = thread.participants.find(
      (p) => p.participantId === thread.messages[0].createdByParticipantId
    );
    const participantNames = thread.participants
      .filter((p) => !p.isMe)
      .map((p) => formatDisplayName(p.title ?? '', p.forename, p.surname, p.displayNameFormat));
    return {
      title: thread.chat.title,
      creator: formatDisplayName(
        createdByParticipant.title,
        createdByParticipant.forename,
        createdByParticipant.surname,
        createdByParticipant.displayNameFormat
      ),
      creatorUserId: createdByParticipant.userId,
      createdOn: thread.chat.createdOn,
      closed: !!thread.closedOn || !!thread.chat.closedOn,
      participants: ['You', ...participantNames]
    };
  }

  private constructTimeline(thread: ChatThreadDto, pendingReplies: ChatThreadPendingReply[]): TimelineDay[] {
    if (thread.compatibilityLevel > ChatThreadCompatibilityLevel) return null;

    const initialDate = new Date(thread.createdOn);

    const info = this.createChatInfo(thread);

    const working: {
      [date: string]: {
        entryOn: number;
        timelineEntry: TimeLineEntry;
      }[];
    } = {};

    let currentDay = moment(initialDate).format('YYYY-MM-DD');
    let lastTimelineEntryType = null;
    working[currentDay] = [
      {
        entryOn: initialDate.valueOf(),
        timelineEntry: info
      }
    ];

    let messageBatchParticipantId = -1;
    let currentWorkingTimelineEntry: { entryOn: number; timelineEntry: ParticipantMessages } = null;
    let pendingMessageTimelineEntry: { entryOn: number; timelineEntry: PendingMessages } = null;

    const myParticipantId = thread.participants.find((p) => p.isMe)?.participantId;
    const laterParticipants = thread.participants.filter((p) => Date.parse(p.joinedOn) > Date.parse(thread.createdOn));

    const timelineEvents: { eventOn: string; type: string; order: number; payload: any }[] = laterParticipants.map(
      (lp) => ({
        eventOn: lp.joinedOn,
        type: 'participant',
        order: 2,
        payload: lp
      })
    );
    const unreadTidemark = this.get().unreadTidemark;
    if (unreadTidemark?.show) {
      timelineEvents.push({
        eventOn: unreadTidemark.date,
        type: 'unread-tidemark',
        order: 1,
        payload: unreadTidemark.count
      });
    }
    timelineEvents.push(
      ...thread.messages.map((m) => ({ eventOn: m.createdOn, type: 'message', order: 3, payload: m }))
    );
    timelineEvents.push(
      ...pendingReplies.map((m) => ({ eventOn: m.date, type: 'pending-reply', order: 4, payload: m }))
    );
    const orderedEvents = [...timelineEvents].sort(
      (a, b) => Date.parse(a.eventOn) - Date.parse(b.eventOn) || a.order - b.order
    );

    orderedEvents.forEach((event) => {
      const eventDate = moment(event.eventOn);
      const eventDay = eventDate.format('YYYY-MM-DD');

      if (currentDay !== eventDay) {
        currentDay = eventDay;
        working[eventDay] = [];
        messageBatchParticipantId = null;
      }

      if (event.type !== lastTimelineEntryType) {
        messageBatchParticipantId = null;
        lastTimelineEntryType = event.type;
      }

      if (event.type === 'participant') {
        const participantPayload = event.payload as ChatThreadParticipantDto;
        const otherParticipantNames = this.getOtherParticipantNamesAtTimestamp(thread, participantPayload.joinedOn);
        const participantChangeEntry = new ParticipantChange(
          formatDisplayName(
            participantPayload.title,
            participantPayload.forename,
            participantPayload.surname,
            participantPayload.displayNameFormat
          ),
          DateFunctions.toTimeString(participantPayload.joinedOn),
          otherParticipantNames
        );
        working[eventDay].push({ entryOn: eventDate.valueOf(), timelineEntry: participantChangeEntry });
        return;
      }
      if (event.type === 'message') {
        const messagePayload = event.payload as ChatThreadMessageDto;

        if (messageBatchParticipantId !== messagePayload.createdByParticipantId || messagePayload.deletedOn) {
          currentWorkingTimelineEntry = {
            entryOn: eventDate.valueOf(),
            timelineEntry: this.createParticipantMessages(
              !!messagePayload.deletedOn,
              thread.participants,
              messagePayload.createdByParticipantId
            )
          };
          messageBatchParticipantId = messagePayload.deletedOn ? null : messagePayload.createdByParticipantId;
          working[eventDay].push(currentWorkingTimelineEntry);
        }
        const requiresUpgrade =
          !messagePayload.deletedOn && messagePayload.compatibilityLevel > ChatThreadMessageCompatibilityLevel;
        currentWorkingTimelineEntry.timelineEntry.messages.push({
          id: messagePayload.messageId,
          content: messagePayload.deletedOn
            ? 'Message deleted'
            : this.transformMessage(messagePayload.content, '', new Date(messagePayload.createdOn)),
          timestamp: DateFunctions.toTimeString(messagePayload.createdOn),
          unread: !messagePayload.reactions.find((r) => r.participantId == myParticipantId),
          removed: !!messagePayload.deletedOn,
          requiresUpgrade: requiresUpgrade
        });
        return;
      }

      if (event.type === 'unread-tidemark') {
        working[eventDay].push({ entryOn: eventDate.valueOf(), timelineEntry: new UnreadTidemark() });
        return;
      }

      if (event.type === 'pending-reply') {
        const payload = event.payload as ChatThreadPendingReply;
        if (!pendingMessageTimelineEntry) {
          pendingMessageTimelineEntry = {
            entryOn: eventDate.valueOf(),
            timelineEntry: new PendingMessages(null, [])
          };
          working[eventDay].push(pendingMessageTimelineEntry);
        }
        pendingMessageTimelineEntry.timelineEntry.messages.push({
          id: payload.id,
          status: payload.status,
          canRetry: payload.status === 'failed' && payload.error !== 'closed',
          content: this.transformMessage(payload.content, '', new Date(payload.date)),
          timestamp: DateFunctions.toTimeString(payload.date)
        });
      }
    });

    const timeline = Object.entries(working)
      .sort(([a], [b]) => Date.parse(a) - Date.parse(b))
      .map(
        ([key, value]) =>
          ({
            date: DateFunctions.toRelativeDateString(key),
            entries: value.map((v) => v.timelineEntry)
          }) as TimelineDay
      );
    return timeline;
  }
  private createChatInfo(thread: ChatThreadDto): ChatInfo {
    const createdByParticipant = thread.participants.find(
      (p) => p.participantId === thread.messages[0].createdByParticipantId
    );

    const participantNames = this.getOtherParticipantNamesAtTimestamp(thread, thread.createdOn);

    const info = new ChatInfo(
      formatDisplayName(
        createdByParticipant.title,
        createdByParticipant.forename,
        createdByParticipant.surname,
        createdByParticipant.displayNameFormat
      ),
      thread.about[0].forename,
      participantNames,
      thread.chat.title
    );
    return info;
  }

  private getOtherParticipantNamesAtTimestamp(thread: ChatThreadDto, refDate: string): string[] {
    const participantNames = thread.participants
      .filter((p) => !p.isMe && Date.parse(p.joinedOn) <= Date.parse(refDate))
      .map((p) => formatDisplayName(p.title ?? '', p.forename, p.surname, p.displayNameFormat));
    return participantNames;
  }

  // Initialise a series of messages from a participant
  private createParticipantMessages(
    removed: boolean,
    participants: ChatThreadParticipantDto[],
    participantId: number
  ): ParticipantMessages {
    const participant = participants.find((p) => p.participantId === participantId);
    return participant?.isMe || removed
      ? new ParticipantMessages(null, null, [])
      : new ParticipantMessages(
          formatDisplayName(
            participant.title,
            participant.forename,
            participant.surname,
            participant.displayNameFormat
          ),
          participant.userId,
          []
        );
  }
  private transformMessage(message: string, title: string, referenceDate: Date): string {
    let messageHtml = this.text2HTML(message);
    messageHtml = linkifyHtml(messageHtml, {
      target: '_blank',
      className: 'chat-linkified'
    });
    return messageHtml;
  }

  private text2HTML(text): string {
    // 1: Escape special characters
    const div = document.createElement('div');
    div.appendChild(document.createTextNode(text));
    text = div.innerHTML;

    const lines = text.split(/\r\n|\r|\n/);
    const paras = lines.map((l) => (l === '' ? '<p></p>' : `<p>${l}</p>`));

    return paras.join('');
  }
}
