import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Platform, ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, forkJoin, from as fromPromise, interval, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  filter,
  last,
  map,
  merge,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Item } from 'src/app/models/item.model';
import { ReadReceipt } from 'src/app/models/read-receipt.model';
import { environment } from 'src/environments/environment';
import { StateStoreService } from 'src/external/ngrx-store-ionic-storage';
import { CancelTokenRefresh, getToken } from '../../auth/store';
import * as authActions from '../../auth/store/actions/auth.action';
import { Channel } from '../../models/channel.model';
import { PublisherAvatar } from '../../models/publisher.model';
import { School, SchoolAsset } from '../../models/school.model';
import { Student } from '../../models/student.model';
import { PushService } from '../../push/push.service';
import * as pushActions from '../../push/store/actions';
import { PushTeardown } from '../../push/store/actions';
import { AccountApiService } from '../../services/account.api.service';
import { ApiService, ItemSearchResults, SearchParams } from '../../services/api.service';
import { AttachmentService, FILE_OPEN_HANDLER_NOT_FOUND, PERMISSION_DENIED } from '../../services/attachment.service';
import { CalendarService } from '../../services/calendar.service';
import { ChannelService } from '../../services/channel.service';
import { NavigationService } from '../../services/navigation.service';
import { OnboardingService } from '../../services/onboarding.service';
import { SharingService } from '../../services/sharing.service';
import { BadgeActions } from '../actions/badge.action';
import * as channelActions from '../actions/channel.action';
import * as coreActions from '../actions/core.action';
import { ClearAttachments, ClearPaymentMethodId } from '../actions/core.action';
import * as schoolActions from '../actions/school.action';
import * as studentsActions from '../actions/students.action';
import { AppState } from '../reducers';
import { ChannelState, SchoolState, StudentState } from '../reducers/core.reducer';
import * as fromStore from '../selectors';

class Base64OperationResult {
  constructor(
    public retry: boolean,
    public data: any
  ) {}
}

const { ParentHubStripe } = Plugins;

@Injectable()
export class CoreEffects {
  hasFileSystem: boolean;
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiService: ApiService,
    private accountApiService: AccountApiService,
    private navService: NavigationService,
    private onboardingService: OnboardingService,
    private attachmentService: AttachmentService,
    private channelService: ChannelService,
    private calendarService: CalendarService,
    private pushService: PushService,
    private sharingService: SharingService,
    private toastController: ToastController,
    private platform: Platform,
    private stateStorageService: StateStoreService
  ) {
    this.hasFileSystem = this.platform.is('hybrid');
  }

  refreshCorePurgeItemCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RefreshCore>(coreActions.REFRESH_CORE),
      withLatestFrom(this.store.select(fromStore.getLastItemPurge)),
      switchMap(([action, state]) => {
        const nowUtc = moment().utc();
        const purgeFrequencyInMinutes = environment.testMode ? 2 : 1440;
        if (
          action.payload.reason === 'startup' &&
          (!state || moment(state).utc().add(purgeFrequencyInMinutes, 'm').diff(nowUtc) < 0)
        ) {
          return of(new coreActions.PurgeItems({ date: nowUtc.toDate() }));
        } else {
          return of({ type: 'NULL' });
        }
      })
    )
  );

  refreshCoreUnreadCountCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RefreshCore>(coreActions.REFRESH_CORE),
      withLatestFrom(this.store.select(fromStore.getUnreadItemsState)),
      switchMap(([action, unreadItemsState]) => {
        const nowUtc = moment().utc();
        const fetchUnreadItemCountFrecuencyInMinutes = environment.testMode ? 2 : 1440; // 44640;

        if (
          (unreadItemsState && !unreadItemsState.lastTotalUnreadFetch) ||
          moment(unreadItemsState.lastTotalUnreadFetch)
            .utc()
            .add(fetchUnreadItemCountFrecuencyInMinutes, 'm')
            .diff(nowUtc) < 0
        ) {
          return of(new coreActions.FetchUnreadItemCount());
        } else {
          return of({ type: 'NULL' });
        }
      })
    )
  );

  refreshCore$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RefreshCore>(coreActions.REFRESH_CORE),
      map((action: coreActions.RefreshCore) => action),
      withLatestFrom(this.store.select(fromStore.getCoreState)),
      switchMap(([action, coreState]) => {
        return forkJoin([this.apiService.getSchools(), this.apiService.getChannels()]).pipe(
          switchMap((data) => {
            const [schools, channels] = data;
            const schoolStudentRequests: Observable<Student[]>[] = [];
            schools.forEach((element) => {
              if (element.subscriptionState === 3) {
                schoolStudentRequests.push(this.apiService.getSchoolSudents(element.id));
              } else {
                schoolStudentRequests.push(of([]));
              }
            });
            if (schoolStudentRequests.length === 0) {
              schoolStudentRequests.push(of(null));
            }
            return forkJoin([...schoolStudentRequests]).pipe(
              switchMap((schoolStudents) => {
                for (let index = 0; index < schools.length; index++) {
                  // TODO: A bit nasty! types are a bit mixed up
                  const school: any = schools[index];
                  school.students = schoolStudents[index];
                  school.students.forEach(
                    (student) => (student.form = student.form === 'Not Specified' ? '-' : student.form)
                  );
                }
                // TODO: A bit nasty! types are a bit mixed up
                const mappedChannels = channels.map((c) => c as unknown as Channel);
                const resetRequired =
                  action.payload.reason !== 'onboarding' &&
                  (this.hasChannelStateChanged(mappedChannels, coreState.channels) ||
                    this.hasSchoolStateChanged(schools, coreState.schools, coreState.students));
                const payload = {
                  schools,
                  channels: mappedChannels,
                  reason: action.payload.reason
                };
                const results = [
                  ...(resetRequired
                    ? [
                        new coreActions.RefreshCoreSubscriptionChanged(payload),
                        new coreActions.LoadNewItems({
                          pageId: 'hub-todo',
                          done: false
                        }),
                        new coreActions.FetchUnreadItemCount()
                      ]
                    : [new coreActions.RefreshCoreSuccess(payload)]),
                  new coreActions.LoadPublisherAvatars(),
                  new coreActions.LoadSchoolAssets()
                ];

                if (resetRequired && action.payload.reason === 'resume') {
                  this.store.dispatch(new coreActions.ResetTabs());
                }

                return results;
              }),
              catchError((err) =>
                of(
                  new coreActions.RefreshCoreFail({
                    reason: action.payload.reason,
                    noInternet: true
                  })
                )
              )
            );
          }),
          catchError((err) =>
            of(
              new coreActions.RefreshCoreFail({
                reason: action.payload.reason,
                noInternet: true
              })
            )
          )
        );
      })
    )
  );

  loadNewItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.LoadNewItems>(coreActions.LOAD_NEW_ITEMS),
      map((action: coreActions.LoadNewItems) => action),
      withLatestFrom(this.store.select(fromStore.getCurrentPageState)),
      switchMap(([action, pageState]) => {
        const searchParams: SearchParams = {};

        if (action.payload.ignoreSinceDate) {
          searchParams.sinceDate = undefined;
        } else {
          searchParams.sinceDate = pageState.loadingNew ? pageState.loadingNew.queryTimestamp : undefined;
        }

        searchParams.filter = {
          done: action.payload.done,
          channelId: action.payload.channelId,
          studentId: action.payload.studentId,
          presub: action.payload.presub,
          unread: action.payload.unread
        };

        if (action.payload.itemType) {
          searchParams.filter = {
            ...searchParams.filter,
            itemType: action.payload.itemType
          };
        }

        let results: ItemSearchResults;
        const loadItems: (SearchParams) => Observable<unknown> = (params) =>
          this.apiService.loadItems(params).pipe(
            concatMap((data) => {
              if (!results) {
                results = data;
              } else {
                results.results.push(...data.results);
              }
              // We may miss some updates if we load changes since a date since the api only returns first n results
              // so load more until we have them all. Note: Don't do this if we are performing initial load i.e.
              // we have no since date
              if (searchParams.sinceDate && data.hasMoreItems) {
                searchParams.maxId = data.results[data.results.length - 1].sequenceNumber;
                return loadItems(searchParams);
              } else {
                return of(null);
              }
            }),
            switchMap((data) => {
              return of({});
            }),
            catchError((error) => {
              throw error;
            })
          );
        return loadItems(searchParams).pipe(
          switchMap((result) => {
            const actions = [
              new coreActions.LoadItemsSuccess({
                pageId: action.payload.pageId,
                type: coreActions.LOAD_NEW_ITEMS,
                transient: false,
                itemSearchResults: results,
                showInFeed: action.payload.showInFeed
              }),
              new coreActions.LoadPublisherAvatars(),
              new pushActions.PushClear()
            ];
            return actions;
          }),
          catchError((error) => {
            let actions = [];

            if (error.status === 0) {
              actions = [
                new coreActions.LoadItemsFail({
                  pageId: action.payload.pageId,
                  type: coreActions.LOAD_NEW_ITEMS,
                  showToastOnError: action.payload.showToastOnError,
                  noInternet: true
                }),
                new pushActions.PushClear()
              ];
            } else {
              actions = [
                new coreActions.LoadItemsFail({
                  pageId: action.payload.pageId,
                  type: coreActions.LOAD_NEW_ITEMS,
                  showToastOnError: action.payload.showToastOnError
                }),
                new pushActions.PushClear()
              ];
            }

            return actions;
          })
        );
      })
    )
  );

  badgeRecalculation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(coreActions.LOAD_ITEMS_SUCCESS, coreActions.MARK_ITEM_READ, coreActions.DELETE_ITEMS),
      map(() => {
        return BadgeActions.recalculate();
      })
    )
  );

  loadItemsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.LoadItemsFail>(coreActions.LOAD_ITEMS_FAIL),
        // only show toast if logged in
        withLatestFrom(this.store.select(getToken)),
        tap(([action, token]) => {
          if (action.payload.showToastOnError && token) {
            let toastMessage = '';
            let toastCssClass = '';

            if (action.payload.noInternet) {
              toastMessage = 'Please check your internet connection and try again later';
              toastCssClass = 'noInternetToast';
            } else if (environment.testMode) {
              toastMessage = 'Something went wrong, please try again later';
              toastCssClass = 'somethingWrongToast';
            }

            if (toastMessage.length > 0) {
              const toast = this.toastController.create({
                message: toastMessage,
                duration: 2000,
                position: 'top',
                cssClass: toastCssClass
              });

              toast.then((t) => t.present());
            }
          }
        })
      ),
    { dispatch: false }
  );

  // Wait for at least 1 second and until LOAD_ITEMS has completed before
  // removing the navbar progress spinner
  // Note: On resuming we refresh core which may fail and stop load items
  //       being called so check for refresh core failing also

  showCheckNewItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ShowCheckNewItems>(coreActions.SHOW_CHECK_NEW_ITEMS),
      mergeMap((action) => {
        const timeout = timer(1000, 0).pipe(take(1));
        const actionResult$: Observable<Action> = this.actions$.pipe(
          ofType(
            coreActions.LOAD_ITEMS_SUCCESS,
            coreActions.LOAD_ITEMS_FAIL,
            coreActions.REFRESH_CORE_FAIL,
            authActions.REFRESH_TOKEN_UNAVAILABLE
          ),
          take(1)
        );
        const result = actionResult$.pipe(
          merge(timeout),
          last(),
          withLatestFrom(actionResult$),
          mergeMap(([, actionResult]) => {
            let showError = false;
            let noInternet: boolean;
            if (actionResult.type === coreActions.LOAD_ITEMS_FAIL) {
              showError = true;
              noInternet = (actionResult as coreActions.LoadItemsFail).payload.noInternet;
            } else if (actionResult.type === coreActions.REFRESH_CORE_FAIL) {
              showError = true;
              noInternet = (actionResult as coreActions.RefreshCoreFail).payload.noInternet;
            } else if (actionResult.type === authActions.REFRESH_TOKEN_UNAVAILABLE) {
              showError = true;
              noInternet = true; // assume we are offline
            }
            return of(
              new coreActions.HideCheckNewItems({
                showError,
                noInternet
              })
            );
          })
        );
        return result;
      })
    )
  );
  // Wait for at least 1 second and until LOAD_ITEMS has completed before
  // removing the navbar progress spinner

  showFirstContentLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ShowFirstContentLoad>(coreActions.SHOW_FIRST_CONTENT_LOAD),
      switchMap((action) => {
        const timeout = interval(1000).pipe(take(1));
        const actionResult$: Observable<Action> = this.actions$.pipe(
          ofType(coreActions.LOAD_ITEMS_SUCCESS, coreActions.LOAD_ITEMS_FAIL),
          take(1)
        );

        return forkJoin({ timer: timeout, action: actionResult$ }).pipe(
          map((result) => {
            let showError = false;
            let noInternet: boolean;
            if (result.action.type === coreActions.LOAD_ITEMS_FAIL) {
              showError = true;
              noInternet = (result.action as coreActions.LoadItemsFail).payload.noInternet;
            }
            return new coreActions.HideFirstContentLoad({
              showError,
              noInternet
            });
          })
        );
      })
    )
  );
  // If we've shown the navbar progress spinner, we always want toast on fail

  hideCheckNewItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<any>(coreActions.HIDE_CHECK_NEW_ITEMS, coreActions.HIDE_FIRST_CONTENT_LOAD),
        // only show toast if logged in
        withLatestFrom(this.store.select(getToken)),
        tap(([action, token]) => {
          if (action.payload.showError && token) {
            let toastMessage = '';
            let toastCssClass = '';

            if (action.payload.noInternet) {
              toastMessage = 'Please check your internet connection and try again later';
              toastCssClass = 'noInternetToast';
            } else if (environment.testMode) {
              toastMessage = 'Something went wrong, please try again later';
              toastCssClass = 'somethingWrongToast';
            }

            if (toastMessage.length > 0) {
              const toast = this.toastController.create({
                message: toastMessage,
                duration: 2000,
                position: 'top',
                cssClass: toastCssClass
              });

              toast.then((t) => t.present());
            }
          }
        })
      ),
    { dispatch: false }
  );

  loadMoreItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.LoadMoreItems>(coreActions.LOAD_MORE_ITEMS),
      map((action: coreActions.LoadMoreItems) => action),
      switchMap((action) => {
        const searchParams: SearchParams = {};
        searchParams.maxId = action.payload.maxId;
        searchParams.searchTerm = action.payload.searchTerm;
        searchParams.filter = {
          done: action.payload.done,
          channelId: action.payload.channelId,
          studentId: action.payload.studentId,
          presub: action.payload.presub,
          unread: action.payload.unread
        };

        if (action.payload.itemType) {
          searchParams.filter = {
            ...searchParams.filter,
            itemType: action.payload.itemType
          };
        }

        return this.apiService.loadItems(searchParams).pipe(
          switchMap((result) => {
            const results = [
              new coreActions.LoadItemsSuccess({
                pageId: action.payload.pageId,
                type: coreActions.LOAD_MORE_ITEMS,
                transient: false,
                itemSearchResults: result,
                showInFeed: action.payload.showInFeed
              }),
              new coreActions.LoadPublisherAvatars()
            ];
            return results;
          }),
          catchError((error) => {
            return of(
              new coreActions.LoadItemsFail({
                pageId: action.payload.pageId,
                type: coreActions.LOAD_MORE_ITEMS
              })
            );
          })
        );
      })
    )
  );

  addPublishers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.AddPublishers>(coreActions.ADD_PUBLISHERS),
      map(() => {
        return new coreActions.LoadPublisherAvatars();
      })
    )
  );

  loadPublisherAvatars$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(coreActions.LOAD_PUBLISHER_AVATARS, coreActions.ADD_CHANNEL_TO_STATE_SUCCESS),
      withLatestFrom(this.store.select(fromStore.getAllPublishers)),
      withLatestFrom(this.store.select(fromStore.getPublisherAvatarEntities)),
      switchMap(([[action, publishers], avatars]) => {
        const requests: Observable<Base64OperationResult>[] = [];
        const ids: string[] = [];

        publishers.forEach((element) => {
          // Only get avatars we don't already have
          if (element !== undefined) {
            if (element.avatarUrl && (!avatars[element.avatarUrl] || avatars[element.avatarUrl].retry)) {
              ids.push(element.avatarUrl);
              requests.push(
                this.apiService.getImage(element.avatarUrl).pipe(
                  switchMap((blob) => {
                    return this.apiService.toBase64(blob).pipe(
                      map((base64) => {
                        return new Base64OperationResult(false, base64);
                      })
                    );
                  }),
                  catchError((error) => {
                    // Don't retry if we've been told it doesn't exist
                    const retry = error.status !== 404;
                    return of(new Base64OperationResult(retry, null));
                  })
                )
              );
            }
          }
        });
        return forkJoin<any>(of(ids), ...requests);
      }),
      switchMap((data) => {
        const [ids] = data;
        let index = 1;
        const publisherAvatars = [];
        ids.forEach((id) => {
          const avatar = new PublisherAvatar();
          avatar.id = id;
          avatar.avatarBase64 = data[index].data;
          avatar.retry = data[index].error;
          publisherAvatars.push(avatar);

          index++;
        });
        return of(
          new coreActions.LoadPublisherAvatarsSuccess({
            avatars: publisherAvatars
          })
        );
      })
    )
  );

  loadSchoolAssets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(coreActions.LOAD_SCHOOL_ASSETS, coreActions.ADD_SCHOOL_TO_STATE_SUCCESS),
      withLatestFrom(this.store.select(fromStore.getAllSchools)),
      withLatestFrom(this.store.select(fromStore.getSchoolAssetEntities)),
      switchMap(([[action, schools], logos]) => {
        const requests = [];
        const assets = [];
        schools.forEach((school) => {
          if (school.logoUrl) {
            assets.push(school.logoUrl);
          }
          if (school.webLinks) {
            school.webLinks.forEach((weblink) => assets.push(weblink.iconUrl));
          }
        });

        assets.forEach((asset) => {
          // Only get logos we don't already have
          if (!logos[asset]) {
            requests.push(
              this.apiService.getImage(asset).pipe(
                switchMap((result) => {
                  return this.apiService.toBase64(result).pipe(
                    map((base64) => {
                      return { asset, data: base64 };
                    })
                  );
                }),
                catchError((error) => of({ error }))
              )
            );
          }
        });

        return forkJoin<any>(...requests);
      }),
      switchMap((data) => {
        const assets = [];
        data
          .filter((i) => !i.error)
          .forEach((element) => {
            // The logo may not exist, may want to apply some kind of retry strategy here
            const asset = new SchoolAsset();
            asset.id = element.asset;
            asset.dataUrl = element.data;
            assets.push(asset);
          });
        return of(
          new coreActions.LoadSchoolAssetsSuccess({
            assets
          })
        );
      })
    )
  );

  clearSearchItems$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ClearSearchItems>(coreActions.CLEAR_SEARCH_ITEMS),
      withLatestFrom(this.store.select(fromStore.getCurrentItems)),
      switchMap(([action, state]) => {
        const itemsToRemove = state.filter((item) => item.pageSource === action.payload.pageId);
        const attachmentIdsToRemove = [];
        const itemIdsToRemove = itemsToRemove.reduce<number[]>((accumulator, item) => {
          (accumulator || []).push(item.sequenceNumber);
          attachmentIdsToRemove.push(...item.attachments);
          return accumulator;
        }, []);

        return [
          new coreActions.DeleteItems({ ids: itemIdsToRemove }),
          new coreActions.DeleteAttachments({ ids: attachmentIdsToRemove })
        ];
      })
    )
  );

  purgeItems$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.PurgeItems>(coreActions.PURGE_ITEMS),
      withLatestFrom(this.store.select(fromStore.getAllItems)),
      withLatestFrom(this.store.select(fromStore.getRetentionThresholdDate)),
      withLatestFrom(this.store.select(fromStore.getAllPageStateEntities)),
      switchMap(([[[action, items], retentionThreshold], pageStateEntities]) => {
        const itemsToRemove = items.filter((item) => moment(item.createdOn).utc().toDate() < retentionThreshold);
        const attachmentIdsToRemove = [];
        const itemIdsToRemove = itemsToRemove.reduce<number[]>((accumulator, item) => {
          (accumulator || []).push(item.sequenceNumber);
          attachmentIdsToRemove.push(...item.attachments);
          return accumulator;
        }, []);
        // get latest item for each page source
        const latestItemByPageSource: { [index: string]: Item } = {};
        const pageSources: { [index: string]: any } = {};
        const addItemByPageSource = (source: string, item: Item): void => {
          if (!latestItemByPageSource[source]) {
            latestItemByPageSource[source] = item;
          }
          pageSources[source] = {};
        };
        // An item is originally loaded through the pageSource, however may have been shown in
        // a feed or done page so need to consider those also
        const getItemPageSources = (item: Item): string[] => {
          const sources = [];
          if (item.pageSource.startsWith('hub-')) {
            sources.push(item.done ? 'hub-done' : 'hub-todo');
          } else {
            sources.push(item.pageSource);
          }
          if (item.showInFeed && item.itemType <= 1) {
            const pageSource = item.itemType === 0 ? 'channel-' + item.channelId : 'student-' + item.studentId;
            sources.push(pageSource);
          }
          return sources;
        };
        items.forEach((item) => {
          const sources = getItemPageSources(item);
          sources.forEach((source) => addItemByPageSource(source, item));
        });
        // get latest item removed for each page source
        const latestRemovedItemByPageSource: { [index: string]: Item } = {};
        itemsToRemove.forEach((item) => {
          const sources = getItemPageSources(item);
          sources.forEach((source) => {
            if (!latestRemovedItemByPageSource[source]) {
              latestRemovedItemByPageSource[source] = item;
            }
          });
        });
        const pageStateChanges: { [index: string]: boolean } = {};
        for (const key in latestRemovedItemByPageSource) {
          // If we've removed the latest item for a page then do a full page state reset
          // otherwise just clear out load more state
          if (Object.prototype.hasOwnProperty.call(latestRemovedItemByPageSource, key)) {
            pageStateChanges[key] =
              latestRemovedItemByPageSource[key].sequenceNumber === latestItemByPageSource[key].sequenceNumber;
          }
        }
        // Reset any other 'dangling' (i.e we have a page state but no items for that page) page states
        // excluding the unread page
        for (const key in pageStateEntities) {
          if (
            !Object.prototype.hasOwnProperty.call(pageStateChanges, key) &&
            !Object.prototype.hasOwnProperty.call(pageSources, key) &&
            key !== 'unread-items'
          ) {
            pageStateChanges[key] = !Object.prototype.hasOwnProperty.call(pageSources, key);
          }
        }

        return [
          new coreActions.ResetItemLoadingState({ changes: pageStateChanges }),
          new coreActions.DeleteItems({ ids: itemIdsToRemove }),
          new coreActions.DeleteAttachments({ ids: attachmentIdsToRemove })
        ];
      })
    )
  );

  deleteAttachments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.DeleteAttachments>(coreActions.DELETE_ATTACHMENTS),
        tap((action) => {
          const itemsToRemove = action.payload.ids.reduce<any>((accumulator, id) => {
            (accumulator || []).push(
              this.attachmentService.deleteAttachment(id, false),
              this.attachmentService.deleteAttachment(id, true)
            );
            return accumulator;
          }, []);
          forkJoin(itemsToRemove)
            .pipe(catchError((err) => of(null)))
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  selectItem$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.SelectItem>(coreActions.SELECT_ITEM),
      withLatestFrom(this.store.select(fromStore.getItemEntities)),
      map(([action, items]) => {
        const item = items[action.payload.id];
        let pageName = '';

        switch (item.itemType) {
          case 0:
            pageName = 'Article';
            break;

          case 1:
            pageName = 'Student Dm';
            break;

          case 2:
            pageName = 'Attendance Alert';
            break;

          case 3:
            pageName = 'Payment Message';
            break;

          default:
            pageName = 'Unknown Item';
            break;
        }

        return new coreActions.NavigateTo({
          page: pageName,
          setRoot: false,
          payload: { itemId: action.payload.id }
        });
      })
    )
  );

  toggleItemDone$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ToggleItemDone>(coreActions.TOGGLE_ITEM_DONE),
      withLatestFrom(this.store.select(fromStore.getItemEntities)),
      switchMap(([action, items]) => {
        const item = items[action.payload.id];
        let apiAction: Observable<void>;
        if (item.itemType === 0) {
          apiAction = this.apiService.markArticleDone(item.id, !item.done);
        } else {
          apiAction = this.apiService.markAnnouncementDone(item.schoolId, item.id, item.studentId, !item.done);
        }
        return apiAction.pipe(
          map((response: any) => {
            return new coreActions.ToggleItemDoneSuccess({
              id: action.payload.id,
              done: !item.done
            });
          }),
          catchError((error) => {
            switch (error.status) {
              case 0: {
                return of(
                  new coreActions.ToggleItemDoneFail({
                    id: action.payload.id,
                    message: 'Offline'
                  })
                );
              }
              default: {
                return of(
                  new coreActions.ToggleItemDoneFail({
                    id: action.payload.id,
                    message: 'Unkown error'
                  })
                );
              }
            }
          })
        );
      })
    )
  );

  navigateTo$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.NavigateTo>(coreActions.NAVIGATE_TO),
        tap((action: coreActions.NavigateTo) => {
          this.store
            .select(fromStore.getCoreInitialLoadingActive)
            .pipe(
              filter((x) => !x),
              take(1),
              map(() => {
                this.navService.navigate(
                  action.payload.page,
                  action.payload.setRoot,
                  action.payload.setRootAndNav,
                  action.payload.setRootNoTabs,
                  action.payload.navOutsideTabs,
                  action.payload.payload
                );
              })
            )
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  onboardingCheck: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.OnboardingCheck>(coreActions.ONBOARDING_CHECK),
      withLatestFrom(this.store.select(fromStore.getCoreProfileExists)),
      map(([action, profileExists]) => {
        if (profileExists) {
          return new coreActions.OnboardingComplete({ reason: action.payload.reason });
        } else {
          return new coreActions.ProfileCheck();
        }
      })
    )
  );

  pushStatusCheck: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.PushStatusCheck>(coreActions.PUSH_STATUS_CHECK),
      switchMap((action) => {
        return fromPromise(this.pushService.pushEnabled()).pipe(
          map((result) =>
            result.isEnabled
              ? new coreActions.OnboardingComplete()
              : new coreActions.NavigateTo({
                  page: 'Create Account Complete',
                  setRoot: true
                })
          )
        );
      })
    )
  );

  profileCheck = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ProfileCheck>(coreActions.PROFILE_CHECK),
      mergeMap((action) =>
        this.onboardingService.profileCheck().pipe(
          switchMap(() => {
            return fromPromise(this.pushService.pushEnabled()).pipe(
              switchMap((pushStatus) => {
                const actions: Action[] = [];
                actions.push(
                  pushStatus.isEnabled || !this.pushService.hasPush()
                    ? new coreActions.OnboardingComplete()
                    : new coreActions.NavigateTo({
                        page: 'Create Account Complete',
                        setRoot: true
                      })
                );
                actions.push(new coreActions.ProfileCheckSuccess());
                return [...actions];
              })
            );
          }),
          catchError((error) => {
            if (error.status === 404) {
              this.store.dispatch(new coreActions.ProfileCheckFail(null));

              return of(
                new coreActions.NavigateTo({
                  page: 'Create Account Name',
                  setRoot: true
                })
              );
            } else {
              return of(new coreActions.ProfileCheckFail('Profile check failed'));
            }
          })
        )
      )
    )
  );

  onboardingComplete: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.OnboardingComplete>(coreActions.ONBOARDING_COMPLETE),
      switchMap((action) => [
        new pushActions.PushInit(),
        new coreActions.RefreshCore({
          reason: action.payload ? action.payload.reason : 'onboarding'
        })
      ])
    )
  );

  refreshCoreComplete: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RefreshCoreSuccess>(
        coreActions.REFRESH_CORE_SUCCESS,
        coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED
      ),
      withLatestFrom(this.store.select(fromStore.getAllChannels)),
      withLatestFrom(this.store.select(fromStore.getAllSchools)),
      withLatestFrom(this.store.select(fromStore.getCoreProfileExists)),
      withLatestFrom(this.store.select(fromStore.getAttendaceSessionUpdateDate)),
      withLatestFrom(this.store.select(fromStore.getColdStartTab)),
      switchMap(([[[[[action, channels], schools], profileExists], attendanceSessionUpdateDate], coldStartTab]) => {
        if (channels.length === 0 && schools.length === 0 && profileExists) {
          return of(
            new coreActions.NavigateTo({
              page: 'First Onboarding Choice'
            })
          );
        } else if ((action.payload.reason === 'onboarding' || action.payload.reason === 'startup') && profileExists) {
          schools.forEach((school) => {
            if (
              school.features.find(
                (feature) => feature === 'studentattendancerecentv2' || feature === 'studentattendancestatisticsv2'
              )
            ) {
              school.students.forEach((student) => {
                this.store.dispatch(
                  new studentsActions.GetAttendanceSessions({
                    studentId: student,
                    schoolId: school.id
                  })
                );
              });
            }
          });
          const actions: Action[] = [
            new coreActions.NavigateTo({
              page: coldStartTab,
              setRoot: true
            })
          ];
          if (coldStartTab === 'Chats') {
            // The app can cold start in response to a chat notification in which case the hub item load
            // is skipped since it is done in the hub todo page. In the case where we also have a pending Hub
            // notification we load items here so that the red dot would appear on hub tab if applicable
            actions.push(new coreActions.LoadNewItems({ pageId: 'hub-todo' }));
          }
          return actions;
        } else if (action.payload.reason === 'resume' && profileExists) {
          if (attendanceSessionUpdateDate) {
            if (environment.testMode && moment().diff(attendanceSessionUpdateDate, 'minutes') >= 3) {
              schools.forEach((school) => {
                if (
                  school.features.find(
                    (feature) => feature === 'studentattendancerecentv2' || feature === 'studentattendancestatisticsv2'
                  )
                ) {
                  school.students.forEach((student) => {
                    this.store.dispatch(
                      new studentsActions.GetAttendanceSessions({
                        studentId: student,
                        schoolId: school.id
                      })
                    );
                  });
                }
              });

              return of(new studentsActions.SetAttendanceSessionUpdateDate());
            } else if (moment().diff(attendanceSessionUpdateDate, 'minutes') >= 15) {
              schools.forEach((school) => {
                if (
                  school.features.find(
                    (feature) => feature === 'studentattendancerecentv2' || feature === 'studentattendancestatisticsv2'
                  )
                ) {
                  school.students.forEach((student) => {
                    this.store.dispatch(
                      new studentsActions.GetAttendanceSessions({
                        studentId: student,
                        schoolId: school.id
                      })
                    );
                  });
                }
              });

              return of(new studentsActions.SetAttendanceSessionUpdateDate());
            } else {
              return of(new coreActions.NullAction());
            }
          } else {
            schools.forEach((school) => {
              if (
                school.features.find(
                  (feature) => feature === 'studentattendancerecentv2' || feature === 'studentattendancestatisticsv2'
                )
              ) {
                school.students.forEach((student) => {
                  this.store.dispatch(
                    new studentsActions.GetAttendanceSessions({
                      studentId: student,
                      schoolId: school.id
                    })
                  );
                });
              }
            });

            return of(new studentsActions.SetAttendanceSessionUpdateDate());
          }
        } else {
          return of(new coreActions.NullAction());
        }
      })
    )
  );

  refreshCoreFail: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RefreshCoreFail>(coreActions.REFRESH_CORE_FAIL),
      withLatestFrom(this.store.select(fromStore.getAllChannels)),
      withLatestFrom(this.store.select(fromStore.getAllSchools)),
      withLatestFrom(this.store.select(fromStore.getCoreProfileExists)),
      withLatestFrom(this.store.select(fromStore.getColdStartTab)),
      switchMap(([[[[action, channels], schools], profileExists], coldStartTab]) => {
        if (action.payload.reason === 'onboarding' || action.payload.reason === 'startup') {
          if (channels.length === 0 && schools.length === 0 && profileExists) {
            return [
              new coreActions.NavigateTo({ page: 'First Onboarding Choice', setRoot: true }),
              new coreActions.InitialLoadingComplete()
            ];
          } else {
            return [
              new coreActions.NavigateTo({
                page: coldStartTab,
                setRoot: true
              }),
              new coreActions.InitialLoadingComplete()
            ];
          }
        } else {
          return of(new coreActions.NullAction());
        }
      })
    )
  );

  startItemReadReceiptProcessing$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.StartItemReadReceiptProcessing>(coreActions.START_ITEM_READ_RECEIPT_PROCESSING),
      switchMap(() => {
        return timer(10000, 10000).pipe(
          withLatestFrom(this.store.select(fromStore.getPendingReadReceipts)),
          mergeMap(([, receipts]) => {
            return this.getNextItemReadReceiptCommands(receipts);
          })
        );
      })
    )
  );

  // Gets the next receipt and transform it to an appropriate command
  private getNextItemReadReceiptCommands = (receipts: ReadReceipt[]): Action[] => {
    return receipts
      .filter((r) => r.retryNext == null || r.retryNext < new Date())
      .map(
        (r) =>
          new coreActions.ProcessItemReadReceipt({
            id: r.id,
            item: r.item
          })
      )
      .slice(0, 1);
  };

  queueItemReadReceipt$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.QueueItemReadReceipt>(coreActions.QUEUE_ITEM_READ_RECEIPT),
      mergeMap((action) => {
        return of(new coreActions.MarkItemRead({ id: action.payload.id })).pipe(delay(2000));
      })
    )
  );

  processItemReadReceipt$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ProcessItemReadReceipt>(coreActions.PROCESS_ITEM_READ_RECEIPT),
      withLatestFrom(this.store.select(fromStore.getItemEntities)),
      mergeMap(([action, items]) => {
        // Latest codebase snapshots the item in state but there may be some legacy ones still in state
        // so look item up in that case
        const item = action.payload.item ?? items[action.payload.id];
        if (!item) {
          return of({ type: 'NULL' });
        }
        const request =
          item.itemType === 0
            ? this.apiService.markArticleRead(item.id)
            : this.apiService.markAnnouncementRead(item.schoolId, item.id, item.studentId);
        return request.pipe(
          map((result) => {
            if (item.pageSource === 'search-') {
              this.store.dispatch(new coreActions.FetchUnreadItemCount());
            }

            return new coreActions.ProcessItemReadReceiptSuccess({
              id: item.sequenceNumber,
              readOn: result.readOn
            });
          }),
          catchError((error) => {
            return of(
              new coreActions.ProcessItemReadReceiptFail({
                id: item.sequenceNumber,
                reason: error.status
              })
            );
          })
        );
      })
    )
  );

  // After processing a receipt, kick off a check for another pending one
  processReadReceiptSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(coreActions.PROCESS_ITEM_READ_RECEIPT_SUCCESS),
      withLatestFrom(this.store.select(fromStore.getPendingReadReceipts)),
      mergeMap(([, receipts]) => {
        return this.getNextItemReadReceiptCommands(receipts);
      })
    )
  );

  processReadReceiptFail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ProcessItemReadReceiptFail>(coreActions.PROCESS_ITEM_READ_RECEIPT_FAIL),
      withLatestFrom(this.store.select(fromStore.getPendingReadReceipts)),
      mergeMap(([action, receipts]) => {
        // If we are offline don't bother trying any more, we'll get kicked again
        // via the startItemReadReceiptProcessing
        if (action.payload.reason === 0 || action.payload.reason === 503) {
          return [];
        } else {
          return this.getNextItemReadReceiptCommands(receipts);
        }
      })
    )
  );

  openAttachment: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.OpenAttachment>(coreActions.OPEN_ATTACHMENT),
      withLatestFrom(this.store.select(fromStore.getAttachmentEntities)),
      map(([action, attachments]) => {
        const attachment = attachments[action.payload.id];
        if (attachment) {
          if (attachment.downloaded) {
            return new coreActions.ViewAttachment({ id: action.payload.id });
          } else {
            return new coreActions.DownloadAttachment({
              id: action.payload.id
            });
          }
        }
        return new coreActions.OpenAttachmentFail({
          id: action.payload.id,
          reason: 'Not found'
        });
      })
    )
  );

  downloadAttachment = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.DownloadAttachment>(coreActions.DOWNLOAD_ATTACHMENT),
      withLatestFrom(this.store.select(fromStore.getAttachmentEntities)),
      switchMap(([action, attachments]) => {
        const attachment = attachments[action.payload.id];
        if (attachment) {
          return this.apiService.downloadAttachment(attachment.url).pipe(
            mergeMap((result) =>
              this.attachmentService.saveAttachment(attachment, false, result).pipe(
                map(
                  (uri) =>
                    new coreActions.DownloadAttachmentSuccess({
                      id: action.payload.id,
                      url: uri,
                      data: result
                    })
                )
              )
            ),
            catchError((e) =>
              of(
                new coreActions.OpenAttachmentFail({
                  id: action.payload.id,
                  reason: 'Not found'
                })
              )
            )
          );
        }
        return of(
          new coreActions.OpenAttachmentFail({
            id: action.payload.id,
            reason: 'Not found'
          })
        );
      })
    )
  );

  downloadAttachmentImage = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.DownloadAttachmentImage>(coreActions.DOWNLOAD_ATTACHMENT_IMAGE),
      withLatestFrom(this.store.select(fromStore.getAttachmentEntities)),
      mergeMap(([action, attachments]) => {
        const attachment = attachments[action.payload.id];
        if (attachment) {
          const url = attachment.url + (action.payload.thumb ? '?thumb=true' : '');
          return this.apiService.downloadAttachment(url).pipe(
            mergeMap((result) =>
              this.attachmentService.saveAttachment(attachment, action.payload.thumb, result).pipe(
                map(
                  (uri) =>
                    new coreActions.DownloadAttachmentImageSuccess({
                      id: action.payload.id,
                      thumb: action.payload.thumb,
                      url: uri,
                      data: result
                    })
                )
              )
            ),
            catchError((e) =>
              of(
                new coreActions.DownloadAttachmentImageFail({
                  id: action.payload.id,
                  thumb: action.payload.thumb,
                  reason: 'Not found'
                })
              )
            )
          );
        }
        return of(
          new coreActions.DownloadAttachmentImageFail({
            id: action.payload.id,
            thumb: action.payload.thumb,
            reason: 'Not found'
          })
        );
      })
    )
  );

  downloadAttachmentSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.DownloadAttachmentSuccess>(coreActions.DOWNLOAD_ATTACHMENT_SUCCESS),
      withLatestFrom(this.store.select(fromStore.getAttachmentEntities)),
      mergeMap(([action, attachments]) => {
        // gte todo
        const attachment = attachments[action.payload.id];
        if (attachment) {
          if (!this.hasFileSystem) {
            return this.attachmentService
              .openBlob(attachment.name, attachment.attachmentMimeType, action.payload.data)
              .pipe(map(() => new coreActions.NullAction()));
          } else {
            //     return this.attachmentService.saveAttachment(attachment, false, action.payload.data).pipe(
            //       map(() => {
            return of(
              new coreActions.ViewAttachment({
                id: action.payload.id
              })
            );
          }
        }
      })
    )
  );
  // }),
  // catchError((error) => {
  //   return of(
  //     new coreActions.OpenAttachmentFail({
  //       id: action.payload.id,
  //       reason: error,
  //     })
  //   );
  // })
  //     );
  //   }
  // }
  // return of(
  //   new coreActions.OpenAttachmentFail({
  //     id: action.payload.id,
  //     reason: 'Not found',
  //   })
  // );
  //})
  //));

  shareAttachmentImage = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.ShareAttachmentImage>(coreActions.SHARE_ATTACHMENT_IMAGE),
        withLatestFrom(this.store.select(fromStore.getAttachmentEntities)),
        mergeMap(([action, attachments]) => {
          const attachment = attachments[action.payload.id];
          return this.sharingService.shareAttachment(attachment);
        })
      ),
    { dispatch: false }
  );

  shareItem = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.ShareItem>(coreActions.SHARE_ITEM),
        withLatestFrom(this.store.select(fromStore.getItemEntities)),
        withLatestFrom(this.store.select(fromStore.getAttachmentEntities)),
        withLatestFrom(this.store.select(fromStore.selectItemTranslationEntities)),
        mergeMap(([[[action, items], attachments], itemTranslations]) => {
          const item = items[action.payload.id];
          const itemTranslation = itemTranslations[action.payload.id];
          const itemAttachments = [];
          item.attachments.forEach((id) => itemAttachments.push(attachments[id]));
          const title = itemTranslation?.showingTranslation ? itemTranslation.title : item.title;
          const message = itemTranslation?.showingTranslation ? itemTranslation.message : item.message;
          return this.sharingService.shareItem(title, message, itemAttachments);
        })
      ),
    { dispatch: false }
  );

  viewAttachment: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.ViewAttachment>(coreActions.VIEW_ATTACHMENT),
      withLatestFrom(this.store.select(fromStore.getAttachmentEntities)),
      switchMap(([action, attachments]) => {
        const attachment = attachments[action.payload.id];

        if (attachment) {
          const operation = action.payload.content
            ? this.attachmentService
                .openBlob(attachment.name, attachment.attachmentMimeType, action.payload.content)
                .pipe(
                  map(() => {
                    return new coreActions.NullAction();
                  })
                )
            : this.attachmentService.openDocument(attachment, false).pipe(
                map(() => {
                  return new coreActions.NullAction();
                }),
                catchError((error) => {
                  return of(
                    new coreActions.OpenAttachmentFail({
                      id: action.payload.id,
                      reason: error
                    })
                  );
                })
              );
          return operation;
        }

        return of(
          new coreActions.OpenAttachmentFail({
            id: action.payload.id,
            reason: 'Not found'
          })
        );
      })
    )
  );

  openAttachmentFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.OpenAttachmentFail>(coreActions.OPEN_ATTACHMENT_FAIL),
        tap((action) => {
          let msg = `Couldn't open file: ` + action.payload.reason;
          if (action.payload.reason === PERMISSION_DENIED) {
            msg = `To open files we need access to storage`;
          } else if (action.payload.reason === FILE_OPEN_HANDLER_NOT_FOUND) {
            msg = `Couldn't open file, you may need to install a reader for the document type from the store.`;
          }
          this.navService.showAlert('Oops', msg);
        })
      ),
    { dispatch: false }
  );

  clearAttachments = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.ClearAttachments>(coreActions.CLEAR_ATTACHMENTS),
        tap((action) => {
          this.attachmentService.clearDocuments(true);
        })
      ),
    { dispatch: false }
  );

  clearAttachmentDownloads = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.ClearAttachmentDownloads>(coreActions.CLEAR_ATTACHMENT_DOWNLOADS),
        tap((action) => {
          this.attachmentService.clearDocuments(false);
        })
      ),
    { dispatch: false }
  );

  addChannelstoState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.SubscribeToSchoolChannelsSuccess>(
        schoolActions.SUBSCRIBE_TO_SCHOOL_CHANNELS_SUCCESS,
        channelActions.SUBSCRIBE_CHANNELS_SUCCESS,
        schoolActions.GET_SCHOOL_FEED_SUCCESS,
        channelActions.SUBSCRIBE_CHANNEL_SUCCESS
      ),
      withLatestFrom(this.store.select(fromStore.getAllChannels)),
      mergeMap(([action, channels]) =>
        this.channelService.getSubscribedChannels().pipe(
          map((response: Channel[]) => {
            const newChannels: Channel[] = [];

            response.forEach((channel) => {
              if (channels.findIndex((stateChannel) => stateChannel.id === channel.id) === -1) {
                newChannels.push(channel);
              }
            });

            if (action.payload && typeof action.payload === 'string') {
              this.store.dispatch(
                new coreActions.NavigateTo({
                  page: action.payload,
                  setRoot: true
                })
              );
            }

            return new coreActions.AddChannelsToStateSuccess(newChannels);
          })
        )
      )
    )
  );

  leaveChannelSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<channelActions.LeaveChannelSuccess>(channelActions.LEAVE_CHANNEL_SUCCESS),
      withLatestFrom(this.store.select(fromStore.getAllItems)),
      switchMap(([action, items]) => {
        const itemsToRemove = items.filter((item) => item.channelId === action.payload);
        const attachmentIdsToRemove = [];
        const itemIdsToRemove = itemsToRemove.reduce<number[]>((accumulator, item) => {
          (accumulator || []).push(item.sequenceNumber);
          attachmentIdsToRemove.push(...item.attachments);
          return accumulator;
        }, []);
        // console.log('removing items', itemIdsToRemove);
        // console.log('removing attachments', attachmentIdsToRemove);
        // console.log('removing channel', attachmentIdsToRemove);
        return [
          new coreActions.DeleteItems({ ids: itemIdsToRemove }),
          new coreActions.DeleteAttachments({ ids: attachmentIdsToRemove }),
          new coreActions.DeleteChannel({ id: action.payload })
        ];
      })
    )
  );

  addSchoolToState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSchoolFeedSuccess>(schoolActions.GET_SCHOOL_FEED_SUCCESS),
      switchMap((action) => {
        return of(new coreActions.AddSchoolToStateSuccess(action.payload.school));
      })
    )
  );

  completeSchoolSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.CompleteSchoolSubscription>(coreActions.COMPLETE_SCHOOL_SUBSCRIPTION),
      switchMap((action) => {
        return [
          new coreActions.GetSchoolStudents({
            schoolId: action.payload.schoolId
          }),
          new schoolActions.GetSchoolChannelsAndChangeStep(action.payload.schoolId)
        ];
      }),
      catchError(() => {
        return of(new coreActions.CompleteSchoolSubscriptionFail());
      })
    )
  );

  getSchoolSudents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.GetSchoolStudents>(coreActions.GET_SCHOOL_STUDENTS),
      switchMap((action) =>
        this.apiService.getSchoolSudents(action.payload.schoolId).pipe(
          map((response) => {
            response.forEach((student) => (student.form = student.form === 'Not Specified' ? '-' : student.form));

            return response;
          }),
          map((response) => {
            return new coreActions.AddSchoolStudents({
              schoolId: action.payload.schoolId,
              students: response
            });
          })
        )
      ),
      catchError(() => {
        return of(new coreActions.GetSchoolStudentsFail());
      })
    )
  );

  clearPaymentCache = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.ClearPaymentMethodId>(coreActions.CLEAR_PAYMENT_METHOD_ID),
        tap(() => {
          if (this.platform.is('hybrid')) {
            // console.log('CACHE CLEARED PAYMENTS');
            ParentHubStripe.clearCache();
          }
        })
      ),
    { dispatch: false }
  );

  logOut = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.LogOut>(coreActions.LOG_OUT),
      mergeMap((action) => [
        new CancelTokenRefresh(),
        new ClearAttachments(),
        new PushTeardown(),
        new ClearPaymentMethodId(),
        { type: '[Meta] CLEAR STORE' },
        new coreActions.NavigateTo({
          page: 'Welcome',
          setRoot: true
        })
      ])
    )
  );

  logOutNoNav = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.LogOutNoNav>(coreActions.LOG_OUT_NO_NAV),
      mergeMap((action) => [
        new CancelTokenRefresh(),
        new ClearAttachments(),
        new PushTeardown(),
        new ClearPaymentMethodId(),
        { type: '[Meta] CLEAR STORE' }
      ])
    )
  );

  clearStore = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Meta] CLEAR STORE'),
        switchMap((action) =>
          fromPromise(
            this.stateStorageService.clear().catch((reason) => {
              console.log(reason);
            })
          )
        )
      ),
    { dispatch: false }
  );

  saveCalendarEvent = createEffect(
    () =>
      this.actions$.pipe(
        ofType<coreActions.SaveCalendarEvent>(coreActions.SAVE_CALENDAR_EVENT),
        switchMap((action) => {
          return this.calendarService.SaveEvent(action.payload.event);
        })
      ),
    { dispatch: false }
  );

  requestPasswordReset: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RequestPasswordReset>(coreActions.REQUEST_PASSWORD_RESET),
      switchMap((action) =>
        this.accountApiService.requestPasswordReset(action.payload.email).pipe(
          map(() => new coreActions.RequestPasswordResetSuccess()),
          catchError(() =>
            of(
              new coreActions.RequestPasswordResetFail({
                email: action.payload.email
              })
            )
          )
        )
      )
    )
  );

  initialLoading$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.InitialLoadingStart>(coreActions.INITIAL_LOADING_START),
      mergeMap(() => {
        return timer(2000).pipe(mergeMap(() => of(new coreActions.InitialLoadingComplete())));
      })
    )
  );

  prepareFilePathUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.PrepareFilePathUpdate>(coreActions.PREPARE_FILE_PATH_UPDATE),
      switchMap(() =>
        this.attachmentService.getDataDirectory().then(
          (uri) =>
            new coreActions.UpdateFilePaths({
              path: uri
            })
        )
      )
    )
  );

  updateFilePaths$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.UpdateFilePaths>(coreActions.UPDATE_FILE_PATHS),
      map(() => {
        return new coreActions.FilePathsUpdated();
      })
    )
  );

  fetchUnreadItemCount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.FetchUnreadItemCount>(coreActions.FETCH_UNREAD_ITEM_COUNT),
      debounceTime(5000),
      switchMap((action) =>
        this.apiService.getUnreadItemCount().pipe(
          map((data) => {
            return new coreActions.FetchUnreadItemCountSuccess({
              unreadItemFetchDate: data.queryTimestamp,
              remoteUnreadItemCount: data.totalItems
            });
          }),
          catchError(() => of(new coreActions.FetchUnreadItemCountFail()))
        )
      )
    )
  );

  hasSchoolStateChanged(schools: School[], schoolState: SchoolState, studentState: StudentState): boolean {
    const newSchoolIds = _.map(schools, 'id').sort((n1, n2) => n1 - n2);
    if (!_.isEqual(newSchoolIds, schoolState.ids)) {
      return true;
    }

    const newStudentIds = schools
      .reduce((accum, cv) => {
        // The School type used here is not quite right since it assumes normalization
        // has already happened which doesn't take place until reducer gets it hence
        // the students array is actually array of Student
        return accum.concat(cv.students.map((s: any) => s.id));
      }, [])
      .sort((n1, n2) => n1 - n2);
    if (!_.isEqual(newStudentIds, studentState.ids)) {
      return true;
    }

    return false;
  }

  hasChannelStateChanged(channels: Channel[], channelState: ChannelState): boolean {
    const newChannelIds = _.map(channels, 'id').sort((n1, n2) => n1 - n2);
    if (!_.isEqual(newChannelIds, channelState.ids)) {
      return true;
    }
    return false;
  }
}
