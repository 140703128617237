// Modules
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from as fromPromise, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
// State
import * as authActions from '../../auth/store/actions';
// Services
import { PushService } from 'src/app/push/push.service';
import { OnboardingService } from '../../services/onboarding.service';
import * as coreActions from '../actions/core.action';
import * as onboardingActions from '../actions/onboarding.action';
import { getOnboardingEmail } from '../selectors/onboarding.selector';

@Injectable()
export class OnboardingEffects {
  constructor(
    private actions$: Actions,
    private onboardingService: OnboardingService,
    private store: Store<any>,
    private platform: Platform,
    private pushService: PushService
  ) {}

  createAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<onboardingActions.CreateAccount>(onboardingActions.CREATE_ACCOUNT),
      map((action: onboardingActions.CreateAccount) => action),
      withLatestFrom(this.store.select(getOnboardingEmail)),
      switchMap(([action, email]) =>
        this.onboardingService.createAccount(email, action.payload, true, true).pipe(
          map((response) => {
            const newUser = {
              username: email,
              password: action.payload
            };

            this.store.dispatch(
              new coreActions.NavigateTo({
                page: 'Create Account Name',
                setRoot: true
              })
            );

            return new onboardingActions.CreateAccountSuccess(newUser);
          }),
          catchError(() => {
            const passwordError = 'Passwords must contain at least 8 characters.';

            return of(new onboardingActions.CreateAccountFail(passwordError));
          })
        )
      )
    )
  );

  // TODO: Create a separate effect for updating the profile. Probably create a function to reduce code duplication

  createProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<onboardingActions.CreateProfile>(onboardingActions.CREATE_PROFILE),
      map((action: onboardingActions.CreateProfile) => action),
      switchMap((action) =>
        this.onboardingService.createProfile(action.payload.firstname, action.payload.lastname).pipe(
          switchMap((response) => {
            this.store.dispatch(new onboardingActions.CreateProfileSuccess());

            return fromPromise(this.pushService.pushEnabled()).pipe(
              map((result) =>
                // Android 33+ requires new permission to be granted for push, similar to iOS
                // so present our notification warning prior to getting native permission
                !this.platform.is('hybrid') || result.isEnabled
                  ? new coreActions.OnboardingComplete()
                  : new coreActions.NavigateTo({
                      page: 'Create Account Complete',
                      setRoot: true
                    })
              )
            );
          }),
          catchError((error) => {
            return of(new onboardingActions.CreateProfileFail());
          })
        )
      )
    )
  );

  loginNewAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<onboardingActions.CreateAccountSuccess>(onboardingActions.CREATE_ACCOUNT_SUCCESS),
      map((action: onboardingActions.CreateAccountSuccess) => action),
      map((action) => {
        return new authActions.LoginUser({
          username: action.payload.username,
          password: action.payload.password
        });
      })
    )
  );

  checkEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<onboardingActions.CheckEmail>(onboardingActions.CHECK_EMAIL),
      map((action: onboardingActions.CheckEmail) => action),
      switchMap((action) =>
        this.onboardingService.checkEmailRegistered(action.payload).pipe(
          map((response) => {
            return new onboardingActions.CheckEmailFail(
              `The email you've entered is already registered.<br><br>Try logging in with this email instead.`
            );
          }),
          catchError((error) => {
            return of(new onboardingActions.CheckEmailSuccess());
          })
        )
      )
    )
  );
}
