import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

export const placeholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  transform(url: string): Observable<string | ArrayBuffer> {
    return new Observable<string | ArrayBuffer>((observer) => {
      // This is a tiny blank image
      observer.next(placeholder);

      this.http.get(url, { responseType: 'blob' }).subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          observer.next(reader.result);
        };
      });

      return { unsubscribe() {} };
    });
  }
}
