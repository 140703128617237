import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  public devicePlatform = '';
  public deviceModel = '';
  public deviceOsVersion = '';
  public appVersion = environment.versionName;
  constructor() {
    Device.getInfo().then((info) => {
      this.devicePlatform = info.platform;
      this.deviceModel = info.model;
      this.deviceOsVersion = info.osVersion;
    });
  }
}
