/* eslint-disable no-console */
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MomentModule } from 'ngx-moment';
import { environment } from '../environments/environment';
import { StateStoreService, StorageSyncEffects, storageSync } from '../external/ngrx-store-ionic-storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import * as fromAuth from './auth/store';
import { ComponentsModule } from './components/components.module';
import { HeaderEnrichmentInterceptor } from './header-enrichment.interceptor';
import { InitialLoadingPageModule } from './pages/initial-loading/initial-loading.module';
import { InitialPageModule } from './pages/initial/initial.module';
import { PushModule } from './push/push.module';
import * as fromPush from './push/store';
import { AccountApiService } from './services/account.api.service';
import { ApiService } from './services/api.service';
import { AttachmentService } from './services/attachment.service';
import { CalendarService } from './services/calendar.service';
import { ChannelService } from './services/channel.service';
import { DeviceService } from './services/device.service';
import { InitializeAppService } from './services/initialise.app.service';
import { LoggingService } from './services/logging.service';
import { ModalService } from './services/modal.service';
import { NavigationService } from './services/navigation.service';
import { OnboardingService } from './services/onboarding.service';
import { SchoolService } from './services/school.service';
import { SharingService } from './services/sharing.service';
import * as fromStore from './store';
import { BadgeActions } from './store/actions/badge.action';
import { ChatMessageReceiptsActions } from './store/actions/chat-message-receipts.action';
import { ChatThreadReplyCacheActions } from './store/actions/chat-thread-reply-cache.action';
import { LifecycleActions } from './store/actions/lifecycle.action';
import { DEFAULT_TIMEOUT, SimulateTimeoutInterceptor, TimeoutInterceptor } from './timeout.interceptor';

export function initializeLogging(service: LoggingService) {
  return (): Promise<any> => {
    return service.init();
  };
}
export const storageSyncOptions = {
  keys: [
    'auth',
    'push',
    'coreState',
    'settingsState',
    'studentsState',
    'paymentIntentState',
    'slipPaymentIntentState',
    'slipSettingsState',
    'appVersionState',
    'itemTranslationState',
    'languagesState',
    'slipQuestionResponseState',
    'chatThreadsState',
    'chatThreadsFilterSettingsState',
    'chatMessageReceiptsState',
    'chatThreadReplyState'
  ], // Only sync the `collection` state
  // Don't sync when these actions occur
  // Note: The whole state is synced on each action
  ignoreActions: [
    '@ngrx/store-devtools/recompute',
    fromAuth.INITIALISE,
    fromAuth.LOGIN_USER,
    fromAuth.LOGIN_USER_FAIL,
    fromAuth.QUEUE_REFRESH_TOKEN,
    fromAuth.REFRESH_TOKEN,
    fromAuth.REFRESH_TOKEN_UNAVAILABLE,
    fromAuth.PRIME_TOKEN_REFRESH,
    fromAuth.VALIDATE_TOKEN,
    fromPush.PUSH_INIT,
    fromPush.PUSH_TEARDOWN,

    fromStore.NULL,
    fromStore.REFRESH_CORE,
    fromStore.REFRESH_CORE_FAIL,
    fromStore.LOAD_PUBLISHER_AVATARS,
    fromStore.LOAD_SCHOOL_ASSETS,
    fromStore.LOAD_NEW_ITEMS,
    fromStore.LOAD_MORE_ITEMS,
    fromStore.LOAD_ITEMS_FAIL,

    // TBC
    fromStore.INITIAL_LOADING_START,
    fromStore.INITIAL_LOADING_COMPLETE,
    fromStore.ONBOARDING_CHECK,
    fromStore.ONBOARDING_COMPLETE,
    fromStore.START_ITEM_READ_RECEIPT_PROCESSING,
    fromStore.PROCESS_NEXT_ITEM_READ_RECEIPT,
    fromStore.NAVIGATE_TO,

    ChatMessageReceiptsActions.markMessageRead.type,
    BadgeActions.recalculate.type,
    ChatThreadReplyCacheActions.replyChanged.type,
    LifecycleActions.paused.type,
    LifecycleActions.resumed.type,

    'NULL'
  ],
  hydratedStateKey: 'hydrated', // Add this key to the state
  onSyncError // If a sync fails
};

export function onSyncError(err): void {
  console.log(err);
}

// Meta reducers
export function logging(reducer): (state: any, action: any) => any {
  if (isDevMode()) {
    return function loggingReducer(state, action) {
      // invoke following, "wrapped" reducer in the chain
      const nextState = reducer(state, action);
      if (action.type !== 'NULL') {
        console.groupCollapsed(action.type);
        console.log(`%c prev state`, `color: #9E9E9E`, state);
        console.log(`%c action`, `color: #03A9F4`, action);
        console.log(`%c next state`, `color: #4CAF50`, nextState);
        console.groupEnd();
      }
      // return wrapped reducer output
      return nextState;
    };
  } else {
    return (state, action) => {
      if (action.type !== 'NULL') {
        // Need to stringify so it reaches native log
        console.log(JSON.stringify({ type: action.type, ...action }));
      }
      return reducer(state, action);
    };
  }
}

export function storeMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === '[Meta] CLEAR STORE') {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export function storageMetaReducerFactory(storage: StateStoreService): MetaReducer<any> {
  return (reducer: ActionReducer<any>): ActionReducer<any> => {
    return storageSync(storage, storageSyncOptions)(reducer);
  };
}

export function getMetaReducers(storage: StateStoreService): MetaReducer<any>[] {
  return [logging, storageMetaReducerFactory(storage), storeMetaReducer];
}

export function initializeApp(init: InitializeAppService) {
  return () => init.initializeApp();
}

@Injectable()
export class LoggingErrorHandler extends ErrorHandler {
  handleError(error): void {
    super.handleError(error);

    try {
      // Can't inject insights service here
      const insights = (window as any).appInsights;
      if (insights) {
        insights.trackException(error.originalError || error);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true,
      scrollAssist: true,
      scrollPadding: true,
      swipeBackEnabled: false,
      backButtonIcon: '/assets/icon/back-icon-dark.svg',
      backButtonText: ''
    }),
    AppRoutingModule,
    HttpClientModule,
    EffectsModule.forRoot([...fromStore.effects, StorageSyncEffects]),
    StoreModule.forRoot(fromStore.reducers, {
      initialState: {
        hydrated: false
      }
    }),
    environment.testMode ? StoreDevtoolsModule.instrument({ actionsBlocklist: ['NULL'] }) : [],
    AuthModule,
    PushModule,
    MomentModule,
    InitialPageModule,
    InitialLoadingPageModule,
    ComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.useServiceWorker
    }),
    BrowserAnimationsModule
  ],
  providers: [
    LoggingService,
    { provide: APP_INITIALIZER, useFactory: initializeLogging, deps: [LoggingService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [InitializeAppService], multi: true },
    { provide: ErrorHandler, useClass: LoggingErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderEnrichmentInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SimulateTimeoutInterceptor,
      multi: true
    },
    [{ provide: DEFAULT_TIMEOUT, useValue: 20000 }],
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [StateStoreService],
      useFactory: getMetaReducers
    },
    DeviceService,
    OnboardingService,
    ApiService,
    AccountApiService,
    AttachmentService,
    NavigationService,
    SchoolService,
    ChannelService,
    LoggingService,
    ModalService,
    CalendarService,
    SharingService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
