// State
import { Action } from '@ngrx/store';

export const INITIALISE_SETTINGS = '[Settings] Initialise';
export const UPDATE_RETENTION_PERIOD = '[Settings] Update Retention Period';
export const SET_LANGUAGE = '[Settings] Set Language';

export class InitialiseSettings implements Action {
  readonly type = INITIALISE_SETTINGS;
}

export class UpdateRetentionPeriod implements Action {
  readonly type = UPDATE_RETENTION_PERIOD;
  constructor(public payload: { period: string }) {}
}

export class SetLanguage implements Action {
  readonly type = SET_LANGUAGE;
  constructor(public payload: { id: string }) {}
}

export type SettingsAction = InitialiseSettings | UpdateRetentionPeriod | SetLanguage;
