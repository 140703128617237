import { createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../store';
import * as fromAuth from './auth.reducer';
export interface AuthModuleState {
  authState: fromAuth.AuthState;
}

export interface AppState extends fromRoot.AppState {
  auth: AuthModuleState;
}

export const reducers = {
  authState: fromAuth.reducer
};

export const getAuthModuleState = createFeatureSelector<AuthModuleState>('auth');
