import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonSpinner, Platform } from '@ionic/angular';
import { merge, of, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

/**
 * Attempt to fix animation stopping in Safari
 *
 * @see https://github.com/ionic-team/ionic/issues/17202#issuecomment-519127330
 *
 * @usage `<ion-spinner appSafariAnimate></ion-spinner>`
 */
@Directive({
  selector: '[appSafariAnimate]'
})
export class SafariAnimateDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<true>();

  constructor(
    private router: Router,
    private platform: Platform,
    private elementRef: ElementRef<HTMLElement | IonSpinner | any>
  ) {}

  ngOnInit(): void {
    if (!this.platform.is('ios')) {
      return;
    }

    const url = this.router.routerState.snapshot.url;

    merge(
      of(true),
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .pipe(filter((event: NavigationEnd) => event.url === url))
    )
      .pipe(tap(() => this.pauseElement()))
      .pipe(debounceTime(620))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.pauseElement(false));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private pauseElement(paused = true): void {
    this.elementRef.nativeElement.paused = paused;

    if (paused) {
      this.elementRef.nativeElement.style.animationPlayState = 'paused';
    } else {
      this.elementRef.nativeElement.style.animationPlayState = 'running';
    }
  }
}
