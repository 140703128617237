import * as moment from 'moment';
export class DateFunctions {
  public static toRelativeTimeString(value: string): string {
    return moment(value).calendar({
      sameDay: 'HH:mm',
      lastDay: '[Yesterday]',
      lastWeek: 'DD/MM/YYYY',
      sameElse: 'DD/MM/YYYY'
    });
  }

  public static toRelativeDateString(value: string): string {
    return moment(value).calendar({
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'ddd DD MMM',
      sameElse: 'ddd DD MMM'
    });
  }

  public static toTimeString(value: string): string {
    return moment(value).format('HH:mm');
  }
}
