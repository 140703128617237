import jwt_decode from 'jwt-decode';
import * as fromAuth from '../actions';
import { Token } from '../token.model';

export interface AuthState {
  loggingIn: boolean;
  errorMessage: string;
  errorTitle: string;
  token: Token;
  sub: string;
  validatingToken: boolean;
  refreshingToken: boolean;
}

export const initialState: AuthState = {
  loggingIn: false,
  errorMessage: null,
  errorTitle: null,
  token: null,
  sub: null,
  validatingToken: false,
  refreshingToken: false
};

export function reducer(state = initialState, action: fromAuth.AuthAction): AuthState {
  switch (action.type) {
    case fromAuth.INITIALISE: {
      return {
        ...state,
        loggingIn: false,
        errorMessage: null,
        errorTitle: null,
        validatingToken: false,
        refreshingToken: false
      };
    }
    case fromAuth.LOGIN_USER: {
      return {
        ...state,
        loggingIn: true,
        errorMessage: null,
        errorTitle: null
      };
    }

    case fromAuth.LOGIN_USER_SUCCESS: {
      let decodedToken;
      try {
        decodedToken = jwt_decode(action.payload.token.access_token);
      } catch (error) {
        /* empty */
      }
      return {
        ...state,
        loggingIn: false,
        token: action.payload.token,
        sub: decodedToken['sub']
      };
    }

    case fromAuth.LOGIN_USER_FAIL: {
      return {
        ...state,
        loggingIn: false,
        errorMessage: action.payload.error,
        errorTitle: action.payload.title
      };
    }
    case fromAuth.VALIDATE_TOKEN: {
      return {
        ...state,
        validatingToken: true
      };
    }
    case fromAuth.VALIDATE_TOKEN_SUCCESS: {
      return {
        ...state,
        validatingToken: false
      };
    }
    case fromAuth.VALIDATE_TOKEN_FAILED: {
      return {
        ...state,
        token: null,
        sub: null,
        validatingToken: false
      };
    }
    case fromAuth.REFRESH_TOKEN: {
      return {
        ...state,
        refreshingToken: true
      };
    }
    case fromAuth.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        validatingToken: false,
        refreshingToken: false
      };
    }
    case fromAuth.REFRESH_TOKEN_FAILED: {
      return {
        ...state,
        token: null,
        sub: null,
        validatingToken: false,
        refreshingToken: false
      };
    }
    case fromAuth.REFRESH_TOKEN_UNAVAILABLE: {
      return {
        ...state,
        validatingToken: false,
        refreshingToken: false
      };
    }
    default: {
      return state;
    }
  }
}
