import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-back-button',
  templateUrl: './modal-back-button.component.html',
  styleUrls: ['./modal-back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalBackButtonComponent implements OnInit, OnDestroy {
  @Input() priority = 9999;
  @Input() iconSrc = '/assets/icon/back-icon-dark.svg';
  @Output() activated = new EventEmitter<void>();
  sub: Subscription;

  constructor(private platform: Platform) {}
  ngOnInit(): void {
    this.sub = this.platform.backButton.subscribeWithPriority(this.priority, () => {
      this.activated.emit();
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
