import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { appHydrated } from '../actions/hydrated.action';
import * as actions from '../actions/slip-question-response.action';
import { selectAllSlipQuestionResponse } from '../selectors';

@Injectable()
export class SlipQuestionResponseEffects {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}

  // 2 minutes for test mode or 7 days otherwise
  threshold: number = environment.testMode ? 2 * 60 * 1000 : 7 * 24 * 60 * 60 * 1000;

  startMonitoring$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appHydrated),
      switchMap(() => {
        return timer(10000, 10000).pipe(
          withLatestFrom(this.store.select(selectAllSlipQuestionResponse)),
          mergeMap(([, responses]) =>
            responses
              .filter((s) => s.modifiedTimestamp <= Date.now() - this.threshold)
              .map((s) => actions.deleteQuestionResponse({ id: s.id }))
          )
        );
      })
    )
  );
}
