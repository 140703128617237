// Modules
import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';

// Pages
// import { ChannelViewPage } from '../pages/channels/channel-view/channel-view';
// import { SingleArticlePage } from '../pages/articles/single-article/single-article';
// import { ChannelsPage } from '../pages/channels/channels';
// import { RegistrationNamePage } from '../pages/registration/registration-name/registration-name';
// import { RegistrationCompletePage } from '../pages/registration/registration-complete/registration-complete';
// import { HubPage } from '../pages/hub/hub';
// import { SchoolsTabPage } from '../pages/hub/schools-tab/schools-tab';
// import { AddChannelSchoolPage } from '../pages/hub/add-channel-school/add-channel-school';
// import { DoneItemsPage } from '../pages/hub/done-items/done-items';
// import { SchoolContactDetailsPage } from '../pages/schools/contact-details/contact-details';
// import { ItemSearchPage } from '../pages/hub/item-search/item-search';
// import { LoginPage } from '../pages/login/login';
// import { RegistrationEmailPage } from '../pages/registration/registration-email/registration-email';
// import { ForgotPasswordPage } from '../pages/forgot-password/forgot-password';
// import { SchoolDetailsPage } from '../pages/schools/school-details/school-details';
// import { SchoolAllChannelsPage } from '../pages/schools/school-all-channels/school-all-channels';
// import { StudentViewPage } from '../pages/students/student-view/student-view';
// import { StudentDmPage } from '../pages/students/student-dm/student-dm';
// import { StudentsDmsPage } from '../pages/students/student-dms/students-dms';
// import { StudentAttendancePage } from '../pages/students/student-attendance/student-attendance';
// import { StudentTimetablePage } from '../pages/students/student-timetable/student-timetable';
// import { StudentReportAbsencePage } from '../pages/students/student-report-absence/student-report-absence';
// import { ParentContactDetailsPage } from '../pages/schools/parent-contact-details/parent-contact-details';
// import { StudentUnauthorizedAbsencesPage } from '../pages/students/student-unauthorized-absences/student-unauthorized-absences';
// import { AddSchoolPage } from '../pages/schools/school-onboarding/add-school/add-school';
// import { ConfirmSchoolPage } from '../pages/schools/school-onboarding/confirm-school/confirm-school';
// import { ConfirmVerificationPage } from '../pages/schools/school-onboarding/confirm-verification/confirm-verification';
// import { ConfirmDOBPage } from '../pages/schools/school-onboarding/confirm-dob/confirm-dob';
// import { NotParentPage } from '../pages/schools/school-onboarding/not-parent/not-parent';
// import { SchoolCallPage } from '../pages/schools/school-onboarding/school-call/school-call';
// import { VerifySchoolPage } from '../pages/schools/school-onboarding/verify-school/verify-school';
// import { AddChannelPage } from '../pages/channels/channel-onboarding/add-channel/add-channel';
// import { ConfirmChannelPage } from '../pages/channels/channel-onboarding/confirm-channel/confirm-channel';
// import { StartupPage } from '../pages/startup/startup-screen/startup';
// import { RegistrationPasswordPage } from '../pages/registration/registration-password/registration-password';
// import { InitialLoadingPage } from '../pages/startup/initial-loading/initial-loading';
// import { StudentAttendanceInfoPage } from '../pages/students/student-attendance-info/student-attendance-info';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(
    // private app: App,
    private alertCtrl: AlertController,
    private nav: NavController
  ) {}

  public navigate(
    pageName: string,
    setRoot?: boolean,
    setRootAndNav?: {
      rootPage: string;
    },
    setRootNoTabs?: boolean,
    navOutsideTabs?: boolean,
    payload?: { [key: string]: any }
  ): void {
    // let page = this.getPage(pageName);
    // if (!page) return;
    // if (page === StartupPage) {
    //   this.app.getRootNav().setRoot(page);
    //   return;
    // }
    // let navs = this.app.getActiveNavs();
    // navs.forEach(nav => {
    //   if (nav.isActive) {
    //     if (setRootAndNav) {
    //       nav.setRoot(this.getPage(setRootAndNav.rootPage));
    //       nav.push(page, payload);
    //     } else if (setRootNoTabs) {
    //       this.app.getRootNav().setRoot(page, payload);
    //     } else if (setRoot) {
    //       nav.setRoot(page, payload);
    //     } else if (navOutsideTabs) {
    //       this.app.getRootNav().push(page, payload);
    //     } else {
    //       nav.push(page, payload);
    //     }
    //   }
    // });

    const url = this.getPageUrl(pageName, payload);
    if (setRoot) {
      this.nav.navigateRoot(url);
    } else {
      this.nav.navigateForward(url);
    }
  }

  public async showAlert(title: string, message: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }

  private getPageUrl(pageName, payload): string {
    switch (pageName) {
      case 'Loading':
        return '/loading';
      case 'Welcome':
        return '/welcome';
      case 'Create Account Email':
        return '/create-account-email';
      case 'Create Account Password':
        return '/create-account-password';
      case 'Create Account Name':
        return '/create-account-name';
      case 'Create Account Complete':
        return '/create-account-complete';
      case 'Sign In':
        return '/sign-in';
      case 'Forgotten Password':
        return '/forgotten-password';
      case 'First Onboarding Choice':
        return '/first-onboarding-choice';
      case 'Join Channel Handle':
        return '/join-channel-handle';
      case 'Join Channel Confirmation':
        return '/join-channel-confirmation';
      case 'Join School Handle':
        return '/join-school-handle';
      case 'Join School Confirmation':
        return '/join-school-confirmation';
      case 'Join School Verification':
        return '/join-school-verification';
      case 'Join School Verification Confirmation':
        return '/join-school-verification-confirmation';
      case 'Join School DOB Confirmation':
        return '/join-school-dob-confirmation';
      case 'Join School Call':
        return `/join-school-call/${payload.errorCode}`;
      case 'Join School Channel Picker':
        return `/join-school-channel-picker/${payload.schoolId}${payload.hideSkip ? '/true' : ''}`;
      case 'Hub':
        return '/app/tabs/hub/todo';
      case 'Chats':
        return '/app/tabs/chats';
      case 'Account':
        return '/account';
      case 'Edit Profile':
        return '/edit-profile';
      case 'Close Account':
        return '/close-account';
      case 'Change Email':
        return '/change-email';
      case 'Change Password':
        return '/change-password';
      case 'Item Search':
        console.log('Item Search not implemented');
        break;
      case 'Unknown Item':
        return '/app/tabs/hub/unknown-item/' + payload.itemId;
      case 'Channel':
        return '/app/tabs/hub/channel/' + payload.channelId;
      case 'Article':
        return '/app/tabs/hub/channel-post/' + payload.itemId;
      case 'Attendance Alert':
        return '/app/tabs/hub/attendance-alert/' + payload.itemId;
      case 'School':
        return `/app/tabs/schools/${payload.schoolId}`;
      case 'Contact Details':
        return `/app/tabs/schools/${payload.schoolId}/contact`;
      case 'Student View':
        return `/app/tabs/schools/${payload.schoolId}/student/${payload.studentId}`;
      case 'Student Dm':
        return '/app/tabs/hub/direct-message/' + payload.itemId;
      case 'Student Dms':
        return `/app/tabs/schools/${payload.schoolId}/student/${payload.studentId}/dms`;
      case 'Student Attendance':
        return `/app/tabs/schools/${payload.schoolId}/student/${payload.studentId}/attendance`;
      case 'Student Report Absence':
        return `/app/tabs/schools/${payload.schoolId}/student/${payload.studentId}/report-absence`;
      case 'Student Timetable':
        return `/app/tabs/schools/${payload.schoolId}/student/${payload.studentId}/timetable`;
      case 'Parent Contact Details':
        return `/app/tabs/schools/${payload.schoolId}/contact-details`;
      //   case 'Student Unauthorized Absences':
      //     return StudentUnauthorizedAbsencesPage;
      default:
        console.log(`${pageName} not implemented in navigation`);

        break;
    }
  }
}
