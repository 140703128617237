// State
import { Action } from '@ngrx/store';
import { Item } from 'src/app/models/item.model';
import { Channel } from '../../models/channel.model';
import { Publisher, PublisherAvatar } from '../../models/publisher.model';
import { School, SchoolAsset } from '../../models/school.model';
import { Student } from '../../models/student.model';
import { ItemSearchResults } from '../../services/api.service';
import { CalendarEvent } from '../../services/calendar.service';

export const NULL = '[Core] Null';
export const INITIALISE_CORE = '[Core] Initialise';
export const REFRESH_CORE = '[Core] Refresh Core';
export const REFRESH_CORE_SUCCESS = '[Core] Refresh Core Success';
export const REFRESH_CORE_FAIL = '[Core] Refresh Core Fail';
// school/student/channel changes detected
export const REFRESH_CORE_SUBSCRIPTION_CHANGED = '[Core] Refresh Core Subscription Changed';
export const SET_SUBSCRIBED_CHANNELS = '[Core] Set Subscribed Channels';
export const LOAD_PUBLISHER_AVATARS = '[Core] Load Publisher Avatars';
export const LOAD_PUBLISHER_AVATARS_SUCCESS = '[Core] Load Publisher Avatars Success';
export const LOAD_SCHOOL_ASSETS = '[Core] Load School Assets';
export const LOAD_SCHOOL_ASSETS_SUCCESS = '[Core] Load School Assets Success';
export const LOAD_NEW_ITEMS = '[Core] Load New Items';
export const LOAD_MORE_ITEMS = '[Core] Load More Items';
export const LOAD_ITEMS_SUCCESS = '[Core] Load Items Success';
export const LOAD_ITEMS_FAIL = '[Core] Load Items Fail';
export const CLEAR_SEARCH_ITEMS = '[Core] Clear Search Items';
export const PURGE_ITEMS = '[Core] Purge Items';
export const RESET_ITEM_LOADING_STATE = '[Core] Reset Item Loading State';
export const DELETE_ITEMS = '[Core] Delete Items';
export const SELECT_ITEM = '[Core] Select Item';
export const SHARE_ITEM = '[Core] Share Item';
export const TOGGLE_ITEM_DONE = '[Core] Toggle Item Done';
export const TOGGLE_ITEM_DONE_SUCCESS = '[Core] Toggle Item Done Success';
export const TOGGLE_ITEM_DONE_FAIL = '[Core] Toggle Item Done Fail';
export const QUEUE_ITEM_READ_RECEIPT = '[Core] Queue Item Read Receipt';
export const MARK_ITEM_READ = '[Core] Mark Item Read';
export const UPDATE_ITEM = '[Core] Update Item';
export const START_ITEM_READ_RECEIPT_PROCESSING = '[Core] Start Item Read Receipt Processing';
export const PROCESS_NEXT_ITEM_READ_RECEIPT = '[Core] Process Next Item Read Receipt';
export const PROCESS_ITEM_READ_RECEIPT = '[Core] Process Item Read Receipt';
export const PROCESS_ITEM_READ_RECEIPT_SUCCESS = '[Core] Process Item Read Receipt Success';
export const PROCESS_ITEM_READ_RECEIPT_FAIL = '[Core] Process Item Read Receipt Fail';
export const NAVIGATE_TO = '[Core] Navigate To';
export const ONBOARDING_CHECK = '[Core] Onboarding Check';
export const ONBOARDING_COMPLETE = '[Core] Onboarding Complete';
export const ONBOARDING_CHECK_FAIL = '[Core] Onboarding Check Fail';
export const PROFILE_CHECK = '[Core] Profile Check';
export const PROFILE_CHECK_SUCCESS = '[Core] Profile Check Success';
export const PROFILE_CHECK_FAIL = '[Core] Profile Check Fail';
export const PUSH_STATUS_CHECK = '[Core] Push Status Check';
export const OPEN_ATTACHMENT = '[Core] Open Attachment';
export const OPEN_ATTACHMENT_FAIL = '[Core] Open Attachment Fail';
export const VIEW_ATTACHMENT = '[Core] View Attachment';
export const DOWNLOAD_ATTACHMENT = '[Core] Download Attachment';
export const DOWNLOAD_ATTACHMENT_SUCCESS = '[Core] Download Attachment Success';
export const DOWNLOAD_ATTACHMENT_FAIL = '[Core] Download Attachment Fail';
export const DOWNLOAD_ATTACHMENT_IMAGE = '[Core] Download Attachment Image';
export const DOWNLOAD_ATTACHMENT_IMAGE_SUCCESS = '[Core] Download Attachment Image Success';
export const DOWNLOAD_ATTACHMENT_IMAGE_FAIL = '[Core] Download Attachment Image Fail';
export const SHARE_ATTACHMENT_IMAGE = '[Core] Share Attachment Image';
export const CLEAR_ATTACHMENTS = '[Core] Clear Attachments';
export const CLEAR_ATTACHMENT_DOWNLOADS = '[Core] Clear Attachment Downloads';
export const DELETE_ATTACHMENTS = '[Core] Delete Attachments';
export const ADD_CHANNEL_TO_STATE_SUCCESS = '[Core] Add channel to state Success';
export const ADD_CHANNELS_TO_STATE_SUCCESS = '[Core] Add channels to state Success';
export const DELETE_CHANNEL = '[Core] Delete Channel';
export const ADD_SCHOOL_TO_STATE_SUCCESS = '[Core] Add school to state Success';
export const REMOVE_SCHOOL_FROM_STATE_SUCCESS = '[Core] Remove school from state Success';
export const COMPLETE_SCHOOL_SUBSCRIPTION = '[School] Complete school subscription';
export const COMPLETE_SCHOOL_SUBSCRIPTION_FAIL = '[School] Complete school subscription Fail';
export const GET_SCHOOL_STUDENTS = '[School] Get school students';
export const ADD_SCHOOL_STUDENTS = '[School] Add School Students';
export const GET_SCHOOL_STUDENTS_FAIL = '[School] Get school students Fail';
export const LOG_OUT = '[Core] Log out';
export const LOG_OUT_NO_NAV = '[Core] Log out no nav';
export const SAVE_CALENDAR_EVENT = '[Core] Save Calendar Event';
export const REQUEST_PASSWORD_RESET = '[Core] Request Password Reset';
export const REQUEST_PASSWORD_RESET_SUCCESS = '[Core] Request Password Reset Success';
export const REQUEST_PASSWORD_RESET_FAIL = '[Core] Request Password Reset Fail';
export const TURN_OFF_WELCOME = '[Core] Turn off welcome';
export const PREPARE_FILE_PATH_UPDATE = '[Core] Prepare File Path Update';
export const UPDATE_FILE_PATHS = '[Core] Update File Paths';
export const FILE_PATHS_UPDATED = '[Core] File Paths Updated';
export const SHOW_CHECK_NEW_ITEMS = '[Core] Show Check New Items';
export const HIDE_CHECK_NEW_ITEMS = '[Core] Hide Check New Items';
export const SHOW_FIRST_CONTENT_LOAD = '[Core] Show First Content Load';
export const HIDE_FIRST_CONTENT_LOAD = '[Core] Hide First Content Load';
export const FETCH_UNREAD_ITEM_COUNT = '[Core] Fetch Unread Item Count';
export const FETCH_UNREAD_ITEM_COUNT_SUCCESS = '[Core] Fetch Unread Item Count Success';
export const FETCH_UNREAD_ITEM_COUNT_FAIL = '[Core] Fetch Unread Item Count Fail';
export const UPDATE_PAYMENT_METHOD_ID = '[Core] Update Payment Method Id';
export const CLEAR_PAYMENT_METHOD_ID = '[Core] Clear Payment Method Id';
export const RESET_TABS = '[Core] Reset Tabs';
export const ADD_PUBLISHERS = '[Core] Add Publishers';

export const INITIAL_LOADING_START = '[Core] Initial loading Start';
export const INITIAL_LOADING_COMPLETE = '[Core] Initial loading Complete';
export const SET_COLD_START_TAB = '[Core] Set Cold Start Tab';

export class NullAction implements Action {
  readonly type = NULL;
}

export class InitialiseCore implements Action {
  readonly type = INITIALISE_CORE;
}

export class RefreshCore implements Action {
  readonly type = REFRESH_CORE;

  constructor(public payload: { reason: string }) {}
}

export class RefreshCoreSuccess implements Action {
  readonly type = REFRESH_CORE_SUCCESS;

  constructor(
    public payload: {
      schools: School[];
      channels: Channel[];
      reason: string;
    }
  ) {}
}

export class RefreshCoreFail implements Action {
  readonly type = REFRESH_CORE_FAIL;

  constructor(
    public payload: {
      reason: string;
      noInternet?: boolean;
    }
  ) {}
}

export class RefreshCoreSubscriptionChanged implements Action {
  readonly type = REFRESH_CORE_SUBSCRIPTION_CHANGED;

  constructor(
    public payload: {
      schools: School[];
      channels: Channel[];
      reason: string;
    }
  ) {}
}

export class SetSubscribedChannels implements Action {
  readonly type = SET_SUBSCRIBED_CHANNELS;

  constructor(
    public payload: {
      channels: Channel[];
    }
  ) {}
}

export class LoadPublisherAvatars implements Action {
  readonly type = LOAD_PUBLISHER_AVATARS;
}

export class LoadPublisherAvatarsSuccess implements Action {
  readonly type = LOAD_PUBLISHER_AVATARS_SUCCESS;

  constructor(public payload: { avatars: PublisherAvatar[] }) {}
}

export class LoadSchoolAssets implements Action {
  readonly type = LOAD_SCHOOL_ASSETS;
}

export class LoadSchoolAssetsSuccess implements Action {
  readonly type = LOAD_SCHOOL_ASSETS_SUCCESS;

  constructor(public payload: { assets: SchoolAsset[] }) {}
}

export class LoadNewItems implements Action {
  readonly type = LOAD_NEW_ITEMS;

  constructor(
    public payload: {
      pageId: string;
      done?: boolean;
      unread?: boolean;
      itemType?: number;
      channelId?: number;
      studentId?: number;
      presub?: boolean;
      showToastOnError?: boolean;
      showInFeed?: boolean;
      ignoreSinceDate?: boolean;
    }
  ) {}
}

export class LoadMoreItems implements Action {
  readonly type = LOAD_MORE_ITEMS;

  constructor(
    public payload: {
      pageId: string;
      done?: boolean;
      unread?: boolean;
      itemType?: number;
      channelId?: number;
      studentId?: number;
      searchTerm?: string;
      maxId: number;
      presub?: boolean;
      showInFeed?: boolean;
    }
  ) {}
}

export class LoadItemsSuccess implements Action {
  readonly type = LOAD_ITEMS_SUCCESS;

  constructor(
    public payload: {
      pageId: string;
      type: string;
      transient: boolean;
      showInFeed: boolean;
      itemSearchResults: ItemSearchResults;
    }
  ) {}
}

export class LoadItemsFail implements Action {
  readonly type = LOAD_ITEMS_FAIL;

  constructor(
    public payload: {
      pageId: string;
      type: string;
      noInternet?: boolean;
      showToastOnError?: boolean;
    }
  ) {}
}

export class ClearSearchItems implements Action {
  readonly type = CLEAR_SEARCH_ITEMS;
  constructor(public payload: { pageId: string }) {}
}

export class PurgeItems implements Action {
  readonly type = PURGE_ITEMS;
  constructor(public payload: { date: Date }) {}
}

export class ResetItemLoadingState implements Action {
  readonly type = RESET_ITEM_LOADING_STATE;
  constructor(public payload: { changes: { [index: string]: boolean } }) {}
}

export class DeleteItems implements Action {
  readonly type = DELETE_ITEMS;
  constructor(public payload: { ids: number[] }) {}
}

export class SelectItem implements Action {
  readonly type = SELECT_ITEM;

  constructor(public payload: { id: number }) {}
}

export class ShareItem implements Action {
  readonly type = SHARE_ITEM;

  constructor(public payload: { id: number }) {}
}

export class ToggleItemDone implements Action {
  readonly type = TOGGLE_ITEM_DONE;

  constructor(public payload: { id: number }) {}
}

export class ToggleItemDoneSuccess implements Action {
  readonly type = TOGGLE_ITEM_DONE_SUCCESS;

  constructor(public payload: { id: number; done: boolean }) {}
}

export class ToggleItemDoneFail implements Action {
  readonly type = TOGGLE_ITEM_DONE_FAIL;

  constructor(public payload: { id: number; message: string }) {}
}

export class QueueItemReadReceipt implements Action {
  readonly type = QUEUE_ITEM_READ_RECEIPT;

  constructor(public payload: { id: number }) {}
}

export class MarkItemRead implements Action {
  readonly type = MARK_ITEM_READ;

  constructor(public payload: { id: number }) {}
}

export class StartItemReadReceiptProcessing implements Action {
  readonly type = START_ITEM_READ_RECEIPT_PROCESSING;
}

export class ProcessNextItemReadReceipt implements Action {
  readonly type = PROCESS_NEXT_ITEM_READ_RECEIPT;
}

export class ProcessItemReadReceipt implements Action {
  readonly type = PROCESS_ITEM_READ_RECEIPT;
  constructor(public payload: { id: number; item: Item }) {}
}
export class ProcessItemReadReceiptSuccess implements Action {
  readonly type = PROCESS_ITEM_READ_RECEIPT_SUCCESS;
  constructor(public payload: { id: number; readOn?: string }) {}
}

export class ProcessItemReadReceiptFail implements Action {
  readonly type = PROCESS_ITEM_READ_RECEIPT_FAIL;
  constructor(public payload: { id: number; reason: number }) {}
}

export class UpdateItem implements Action {
  readonly type = UPDATE_ITEM;
  constructor(public payload: { id: number; changes: Partial<Item> }) {}
}

export class NavigateTo implements Action {
  readonly type = NAVIGATE_TO;

  constructor(
    public payload: {
      page: string;
      setRoot?: boolean;
      setRootAndNav?: {
        rootPage: string;
      };
      setRootNoTabs?: boolean;
      navOutsideTabs?: boolean;
      payload?: any;
    }
  ) {}
}

export class OnboardingCheck implements Action {
  readonly type = ONBOARDING_CHECK;
  constructor(public payload: { reason: string }) {}
}

export class OnboardingComplete implements Action {
  readonly type = ONBOARDING_COMPLETE;
  constructor(public payload?: { reason: string }) {}
}

export class OnboardingCheckFail implements Action {
  readonly type = ONBOARDING_CHECK_FAIL;
}

export class ProfileCheck implements Action {
  readonly type = PROFILE_CHECK;
}

export class ProfileCheckSuccess implements Action {
  readonly type = PROFILE_CHECK_SUCCESS;
}

export class ProfileCheckFail implements Action {
  readonly type = PROFILE_CHECK_FAIL;

  constructor(public payload: string) {}
}

export class PushStatusCheck implements Action {
  readonly type = PUSH_STATUS_CHECK;
}

export class OpenAttachment implements Action {
  readonly type = OPEN_ATTACHMENT;
  constructor(public payload: { id: string }) {}
}

export class ViewAttachment implements Action {
  readonly type = VIEW_ATTACHMENT;
  constructor(public payload: { id: string; content?: Blob }) {}
}

export class OpenAttachmentFail implements Action {
  readonly type = OPEN_ATTACHMENT_FAIL;
  constructor(public payload: { id: string; reason: string }) {}
}

export class DownloadAttachment implements Action {
  readonly type = DOWNLOAD_ATTACHMENT;
  constructor(public payload: { id: string }) {}
}

export class DownloadAttachmentSuccess implements Action {
  readonly type = DOWNLOAD_ATTACHMENT_SUCCESS;
  constructor(public payload: { id: string; url: string; data: Blob }) {}
}

export class DownloadAttachmentFail implements Action {
  readonly type = DOWNLOAD_ATTACHMENT_FAIL;
  constructor(public payload: { reason: string }) {}
}

export class DownloadAttachmentImage implements Action {
  readonly type = DOWNLOAD_ATTACHMENT_IMAGE;
  constructor(public payload: { id: string; thumb: boolean }) {}
}

export class DownloadAttachmentImageSuccess implements Action {
  readonly type = DOWNLOAD_ATTACHMENT_IMAGE_SUCCESS;
  constructor(public payload: { id: string; thumb: boolean; url: string; data: Blob }) {}
}

export class DownloadAttachmentImageFail implements Action {
  readonly type = DOWNLOAD_ATTACHMENT_IMAGE_FAIL;
  constructor(public payload: { id: string; thumb: boolean; reason: string }) {}
}

export class ShareAttachmentImage implements Action {
  readonly type = SHARE_ATTACHMENT_IMAGE;
  constructor(public payload: { id: string }) {}
}

export class ClearAttachments implements Action {
  readonly type = CLEAR_ATTACHMENTS;
}

export class ClearAttachmentDownloads implements Action {
  readonly type = CLEAR_ATTACHMENT_DOWNLOADS;
}

export class DeleteAttachments implements Action {
  readonly type = DELETE_ATTACHMENTS;
  constructor(public payload: { ids: string[] }) {}
}

export class AddChannelToStateSuccess implements Action {
  readonly type = ADD_CHANNEL_TO_STATE_SUCCESS;

  constructor(public payload: Channel) {}
}

export class AddChannelsToStateSuccess implements Action {
  readonly type = ADD_CHANNELS_TO_STATE_SUCCESS;

  constructor(public payload: Channel[]) {}
}

export class DeleteChannel implements Action {
  readonly type = DELETE_CHANNEL;

  constructor(public payload: { id: number }) {}
}

export class AddSchoolToStateSuccess implements Action {
  readonly type = ADD_SCHOOL_TO_STATE_SUCCESS;

  constructor(public payload: School) {}
}

export class RemoveSchoolFromStateSuccess implements Action {
  readonly type = REMOVE_SCHOOL_FROM_STATE_SUCCESS;

  constructor(public payload: School[]) {}
}

export class CompleteSchoolSubscription implements Action {
  readonly type = COMPLETE_SCHOOL_SUBSCRIPTION;

  constructor(public payload: { schoolId: number }) {}
}

export class CompleteSchoolSubscriptionFail implements Action {
  readonly type = COMPLETE_SCHOOL_SUBSCRIPTION_FAIL;
}

export class GetSchoolStudents implements Action {
  readonly type = GET_SCHOOL_STUDENTS;

  constructor(public payload: { schoolId: number }) {}
}

export class AddSchoolStudents implements Action {
  readonly type = ADD_SCHOOL_STUDENTS;

  constructor(public payload: { schoolId: number; students: Student[] }) {}
}

export class GetSchoolStudentsFail implements Action {
  readonly type = GET_SCHOOL_STUDENTS_FAIL;
}

export class LogOut implements Action {
  readonly type = LOG_OUT;
}

export class LogOutNoNav implements Action {
  readonly type = LOG_OUT_NO_NAV;
}

export class SaveCalendarEvent implements Action {
  readonly type = SAVE_CALENDAR_EVENT;
  constructor(public payload: { event: CalendarEvent }) {}
}

export class RequestPasswordReset implements Action {
  readonly type = REQUEST_PASSWORD_RESET;
  constructor(public payload: { email: string }) {}
}

export class RequestPasswordResetSuccess implements Action {
  readonly type = REQUEST_PASSWORD_RESET_SUCCESS;
}

export class RequestPasswordResetFail implements Action {
  readonly type = REQUEST_PASSWORD_RESET_FAIL;
  constructor(public payload: { email: string }) {}
}

export class TurnOffWelcome implements Action {
  readonly type = TURN_OFF_WELCOME;
}

export class InitialLoadingStart implements Action {
  readonly type = INITIAL_LOADING_START;
}

export class InitialLoadingComplete implements Action {
  readonly type = INITIAL_LOADING_COMPLETE;
}

export class PrepareFilePathUpdate implements Action {
  readonly type = PREPARE_FILE_PATH_UPDATE;
}

export class UpdateFilePaths implements Action {
  readonly type = UPDATE_FILE_PATHS;

  constructor(public payload: { path: string }) {}
}

export class FilePathsUpdated implements Action {
  readonly type = FILE_PATHS_UPDATED;
}

export class ShowCheckNewItems implements Action {
  readonly type = SHOW_CHECK_NEW_ITEMS;
}

export class HideCheckNewItems implements Action {
  readonly type = HIDE_CHECK_NEW_ITEMS;
  constructor(public payload: { showError: boolean; noInternet?: boolean }) {}
}

export class ShowFirstContentLoad implements Action {
  readonly type = SHOW_FIRST_CONTENT_LOAD;

  constructor(public payload: { page: string }) {}
}

export class HideFirstContentLoad implements Action {
  readonly type = HIDE_FIRST_CONTENT_LOAD;
  constructor(public payload: { showError: boolean; noInternet?: boolean }) {}
}

export class FetchUnreadItemCount implements Action {
  readonly type = FETCH_UNREAD_ITEM_COUNT;
}

export class FetchUnreadItemCountSuccess implements Action {
  readonly type = FETCH_UNREAD_ITEM_COUNT_SUCCESS;

  constructor(
    public payload: {
      unreadItemFetchDate: string;
      remoteUnreadItemCount: number;
    }
  ) {}
}

export class FetchUnreadItemCountFail implements Action {
  readonly type = FETCH_UNREAD_ITEM_COUNT_FAIL;
}

export class UpdatePaymentMethodId implements Action {
  readonly type = UPDATE_PAYMENT_METHOD_ID;

  constructor(
    public payload: {
      paymentMethodId: string;
    }
  ) {}
}

export class ClearPaymentMethodId implements Action {
  readonly type = CLEAR_PAYMENT_METHOD_ID;
}

export class ResetTabs implements Action {
  readonly type = RESET_TABS;
}

export class AddPublishers implements Action {
  readonly type = ADD_PUBLISHERS;

  constructor(
    public payload: {
      publishers: Publisher[];
    }
  ) {}
}

export class SetColdStartTab implements Action {
  readonly type = SET_COLD_START_TAB;

  constructor(
    public payload: {
      tab: 'Hub' | 'Chats';
    }
  ) {}
}

export type CoreAction =
  | NullAction
  | InitialiseCore
  | RefreshCore
  | RefreshCoreSuccess
  | RefreshCoreFail
  | RefreshCoreSubscriptionChanged
  | SetSubscribedChannels
  | LoadPublisherAvatars
  | LoadPublisherAvatarsSuccess
  | LoadSchoolAssets
  | LoadSchoolAssetsSuccess
  | LoadNewItems
  | LoadMoreItems
  | LoadItemsSuccess
  | LoadItemsFail
  | DeleteItems
  | ClearSearchItems
  | PurgeItems
  | SelectItem
  | ShareItem
  | ToggleItemDone
  | ToggleItemDoneSuccess
  | ToggleItemDoneFail
  | QueueItemReadReceipt
  | MarkItemRead
  | StartItemReadReceiptProcessing
  | ProcessNextItemReadReceipt
  | ProcessItemReadReceiptSuccess
  | ProcessItemReadReceiptFail
  | UpdateItem
  | NavigateTo
  | OnboardingCheck
  | OnboardingComplete
  | OnboardingCheckFail
  | ProfileCheck
  | ProfileCheckSuccess
  | ProfileCheckFail
  | PushStatusCheck
  | OpenAttachment
  | OpenAttachmentFail
  | ViewAttachment
  | DownloadAttachment
  | DownloadAttachmentSuccess
  | DownloadAttachmentFail
  | DownloadAttachmentImage
  | DownloadAttachmentImageSuccess
  | DownloadAttachmentImageFail
  | ShareAttachmentImage
  | ClearAttachments
  | ClearAttachmentDownloads
  | DeleteAttachments
  | AddChannelToStateSuccess
  | AddChannelsToStateSuccess
  | DeleteChannel
  | AddSchoolToStateSuccess
  | RemoveSchoolFromStateSuccess
  | CompleteSchoolSubscription
  | CompleteSchoolSubscriptionFail
  | GetSchoolStudents
  | AddSchoolStudents
  | GetSchoolStudentsFail
  | LogOut
  | LogOutNoNav
  | SaveCalendarEvent
  | RequestPasswordReset
  | RequestPasswordResetSuccess
  | RequestPasswordResetFail
  | TurnOffWelcome
  | InitialLoadingStart
  | InitialLoadingComplete
  | PrepareFilePathUpdate
  | UpdateFilePaths
  | FilePathsUpdated
  | ShowCheckNewItems
  | HideCheckNewItems
  | ShowFirstContentLoad
  | HideFirstContentLoad
  | FetchUnreadItemCount
  | FetchUnreadItemCountSuccess
  | FetchUnreadItemCountFail
  | UpdatePaymentMethodId
  | ClearPaymentMethodId
  | AddPublishers
  | ResetTabs
  | ResetItemLoadingState
  | SetColdStartTab;
