import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ChatThreadsFilterSettingsActions } from '../actions/chat-threads-filter-settings.action';
import * as coreActions from '../actions/core.action';
import { getChatThreadsFilters } from '../selectors/chat-threads-filter-settings.selector';
import { getAllStudents } from '../selectors/core.selector';

@Injectable()
export class ChatThreadsFilterSettingsEffects {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}

  // Available about options could change after any of these event so
  // make sure they are updated
  aboutOptionsChanged$ = createEffect(() =>
    this.actions$.pipe(
      filter(
        (action) =>
          // This catches the initial sign in or initial setting after upgrade
          action.type === coreActions.REFRESH_CORE_SUCCESS ||
          // This catches changes due to remove school and also
          // being blocked, losing PR, student off-roll
          action.type === coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED ||
          // This catches the initial onboarding or adding school
          action.type === coreActions.ADD_SCHOOL_STUDENTS
      ),
      concatLatestFrom((action) => this.store.select(getChatThreadsFilters)),
      concatLatestFrom((action) => this.store.select(getAllStudents)),
      mergeMap(([[action, currentState], students]) => {
        const options = students.map((s) => ({
          studentId: s.id,
          forename: s.forename,
          surname: s.surname
        }));
        const newOptions = currentState.aboutOptions
          ? options.filter((o) => !currentState.aboutOptions.find((co) => co.studentId == o.studentId))
          : options;
        const removedOptions = currentState.aboutOptions
          ? currentState.aboutOptions.filter((co) => !options.find((o) => o.studentId == co.studentId))
          : [];
        const newFilters = currentState.aboutFilter
          ? currentState.aboutFilter.filter((af) => !removedOptions.find((ro) => ro.studentId == af.studentId))
          : [];
        newFilters.push(...newOptions);
        return of(
          ChatThreadsFilterSettingsActions.aboutOptionsChanged({
            aboutFilter: newFilters,
            options: options
          })
        );
      })
    )
  );
}
