import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentIntentTracking, selectAll, selectEntities, State } from '../reducers/payment-intent.reducer';

export const getPaymentIntentState = createFeatureSelector<State>('paymentIntentState');

export const selectPaymentIntentEntities = createSelector(getPaymentIntentState, (state) => selectEntities(state));
export const selectAllPaymentIntents = createSelector(getPaymentIntentState, (state) => selectAll(state));
export const selectPaymentIntent = createSelector(
  selectPaymentIntentEntities,
  (intents, props) => intents[props.id] as PaymentIntentTracking
);
