import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceService } from './services/device.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderEnrichmentInterceptor implements HttpInterceptor {
  constructor(private deviceService: DeviceService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;
    if (req.url.indexOf('/api/') >= 0) {
      headers = headers.set('Accept', 'application/vnd.parenthub.app.v4+json');
      headers = headers
        .set('X-PH-AppVersion', this.deviceService.appVersion)
        .set('X-PH-Device', this.deviceService.deviceModel)
        .set('X-PH-OSPlatform', this.deviceService.devicePlatform)
        .set('X-PH-OSVersion', this.deviceService.deviceOsVersion);
      const newReq = req.clone({
        headers
      });
      return next.handle(newReq);
    }

    return next.handle(req);
  }
}
