import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OnboardingState } from '../reducers/onboarding.reducer';

export const getOnboardingState = createFeatureSelector<OnboardingState>('onboardingState');

export const getOnboardingLoading = createSelector(getOnboardingState, (state) => state.loading);

export const getOnboardingEmail = createSelector(getOnboardingState, (state) => state.email);

export const getOnboardingProfileCreated = createSelector(getOnboardingState, (state) => state.profileCreated);
