import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ChatPushLoadProgressActions } from '../actions/chat-push-load-progress.action';
import * as coreActions from '../actions/core.action';

// When tapping on a notification (either system tray or in app) we want to track
// the associated threads load progress and outcome so we can notify on failure

export interface ChatPushLoadProgress {
  id: number;
  threadId: number;
  loading: boolean;
  loadingError: string;
}

export interface State extends EntityState<ChatPushLoadProgress> {
  // Each time we start or resume or tap on notification toast we generate
  // an ambient context so we can track associated load status for the thread
  // in question
  ambientSessionId: number;
}

export const chatPushLoadProgressAdapter: EntityAdapter<ChatPushLoadProgress> =
  createEntityAdapter<ChatPushLoadProgress>();

export const initialState: State = chatPushLoadProgressAdapter.getInitialState({
  ambientSessionId: Date.now()
});

export const theReducer = createReducer(
  initialState,
  on(ChatPushLoadProgressActions.setAmbientSessionId, (state, action) => ({ ...state, ambientSessionId: action.id })),
  on(ChatPushLoadProgressActions.track, (state, action) =>
    chatPushLoadProgressAdapter.addOne(
      {
        id: state.ambientSessionId,
        threadId: action.threadId,
        loading: true,
        loadingError: null
      },
      state
    )
  ),
  on(ChatPushLoadProgressActions.updateProgress, (state, action) =>
    chatPushLoadProgressAdapter.updateOne(action.update, state)
  )
);

export function reducer(state: State | undefined, action: Action): any {
  switch (action.type) {
    case coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED:
      return initialState;
    default:
      return theReducer(state, action);
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = chatPushLoadProgressAdapter.getSelectors();
