// State
import { Action } from '@ngrx/store';

export const GET_PROFILE = '[Account] Get Profile';
export const GET_PROFILE_SUCCESS = '[Account] Get Profile Success';
export const GET_PROFILE_FAIL = '[Account] Get Profile Fail';
export const UPDATE_PROFILE = '[Account] Update Profile';
export const UPDATE_PROFILE_SUCCESS = '[Account] Update Profile Success';
export const UPDATE_PROFILE_FAIL = '[Account] Update Profile Fail';
export const CHANGE_PASSWORD = '[Account] Change Password';
export const CHANGE_PASSWORD_SUCCESS = '[Account] Change Password Success';
export const CHANGE_PASSWORD_FAIL = '[Account] Change Password Fail';
export const CHANGE_EMAIL = '[Account] Change Email';
export const CHANGE_EMAIL_SUCCESS = '[Account] Change Email Success';
export const CHANGE_EMAIL_FAIL = '[Account] Change Email Fail';
export const GET_USER_INFO = '[Account] Get User Info';
export const GET_USER_INFO_SUCCESS = '[Account] Get User Info Success';
export const GET_USER_INFO_FAIL = '[Account] Get User Info Fail';
export const CLOSE_ACCOUNT = '[Account] Close Account';
export const CLOSE_ACCOUNT_SUCCESS = '[Account] Close Account Success';
export const CLOSE_ACCOUNT_FAIL = '[Account] Close Account Fail';
export class GetProfile implements Action {
  readonly type = GET_PROFILE;
}

export class GetProfileSuccess implements Action {
  readonly type = GET_PROFILE_SUCCESS;
  constructor(public payload: { forename: string; surname: string }) {}
}

export class GetProfileFail implements Action {
  readonly type = GET_PROFILE_FAIL;
}

export class UpdateProfile implements Action {
  readonly type = UPDATE_PROFILE;
  constructor(public payload: { forename: string; surname: string }) {}
}

export class UpdateProfileSuccess implements Action {
  readonly type = UPDATE_PROFILE_SUCCESS;
  constructor(public payload: { forename: string; surname: string }) {}
}

export class UpdateProfileFail implements Action {
  readonly type = UPDATE_PROFILE_FAIL;
  constructor(public payload: { forename: string; surname: string }) {}
}

export class ChangePassword implements Action {
  readonly type = CHANGE_PASSWORD;
  constructor(public payload: { password: string; newPassword: string }) {}
}

export class ChangePasswordSuccess implements Action {
  readonly type = CHANGE_PASSWORD_SUCCESS;
  constructor(public payload: { password: string; newPassword: string }) {}
}

export class ChangePasswordFail implements Action {
  readonly type = CHANGE_PASSWORD_FAIL;
  constructor(public payload: { password: string; newPassword: string; reason: string }) {}
}

export class ChangeEmail implements Action {
  readonly type = CHANGE_EMAIL;
  constructor(public payload: { password: string; newEmail: string }) {}
}

export class ChangeEmailSuccess implements Action {
  readonly type = CHANGE_EMAIL_SUCCESS;
  constructor(public payload: { password: string; newEmail: string }) {}
}

export class ChangeEmailFail implements Action {
  readonly type = CHANGE_EMAIL_FAIL;
  constructor(public payload: { password: string; newEmail: string; reason: string }) {}
}

export class GetUserInfo implements Action {
  readonly type = GET_USER_INFO;
}

export class GetUserInfoSuccess implements Action {
  readonly type = GET_USER_INFO_SUCCESS;
  constructor(public payload: { email: string; emailVerified: boolean }) {}
}

export class GetUserInfoFail implements Action {
  readonly type = GET_USER_INFO_FAIL;
}

export class CloseAccount implements Action {
  readonly type = CLOSE_ACCOUNT;
  constructor(public payload: { password: string }) {}
}

export class CloseAccountSuccess implements Action {
  readonly type = CLOSE_ACCOUNT_SUCCESS;
  constructor(public payload: { password: string }) {}
}

export class CloseAccountFail implements Action {
  readonly type = CLOSE_ACCOUNT_FAIL;
  constructor(public payload: { password: string; errorCode: string; reason: string }) {}
}
export type AccountAction =
  | GetProfile
  | GetProfileSuccess
  | GetProfileFail
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFail
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFail
  | ChangeEmail
  | ChangeEmailSuccess
  | ChangeEmailFail
  | GetUserInfo
  | GetUserInfoSuccess
  | GetUserInfoFail
  | CloseAccount
  | CloseAccountSuccess
  | CloseAccountFail;
