import { Injectable } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem, GetUriResult } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { Observable, Observer } from 'rxjs';
import { Attachment } from '../models/item.model';

export const PERMISSION_DENIED = 'Permission Denied';
export const FILE_OPEN_ERROR = 'File Open Error';
export const FILE_OPEN_FAIL = 'File Open Fail';
export const FILE_OPEN_HANDLER_NOT_FOUND = 'File Open Handler Not Found';
export const FILE_WRITE_ERROR = 'File Write Error';
@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  private hasFileSystem = false;

  constructor(private platform: Platform) {
    this.hasFileSystem = platform.is('hybrid');
  }

  openDocument(attachment: Attachment, thumb?: boolean): Observable<boolean> {
    return Observable.create((observer) => {
      if (this.hasFileSystem) {
        this.open(observer, attachment.attachmentId, attachment.name);
      }
    });
  }

  async getDataUrl(attachment: Attachment): Promise<string> {
    const pathRoot = attachment.fullUrl ? 'attachments/' : 'thumbs/';
    const { data } = await Filesystem.readFile({
      directory: Directory.Data,
      path: pathRoot + attachment.attachmentId
    });
    return data;
  }

  async getFileUrl(attachment: Attachment): Promise<string> {
    const getUriResult = await this.stageFile(attachment.attachmentId, attachment.name);

    return getUriResult.uri;
  }

  getDataDirectory(): Promise<string> {
    // Note: Browser will fail and hence return empty string
    return Filesystem.stat({
      directory: Directory.Data,
      path: '/'
    }).then(
      (result) => result.uri,
      (_) => ''
    );
  }

  convertFileSrc(path: string): string {
    return Capacitor.convertFileSrc(path);
  }

  saveAttachment(attachment: Attachment, thumb: boolean, data: Blob): Observable<string> {
    const folder = thumb ? 'thumbs' : 'attachments';
    return Observable.create((observer) => {
      if (this.hasFileSystem) {
        this.save(observer, Directory.Data, folder, attachment.attachmentId, data);
      } else {
        observer.next();
      }
    });
  }

  deleteAttachment(id: string, thumb: boolean): Observable<boolean> {
    const folder = thumb ? 'thumbs' : 'attachments';
    return Observable.create(async (observer) => {
      if (this.hasFileSystem) {
        try {
          await this.deleteIfExists(folder, id);
          observer.next();
        } catch (e) {
          observer.error(e);
        }
      } else {
        observer.next();
      }
    });
  }

  clearDocuments(clearThumbs: boolean): void {
    if (this.hasFileSystem) {
      Filesystem.rmdir({
        directory: Directory.Data,
        path: 'temp',
        recursive: true
      }).catch((err) => console.log('Error removing temp', err));
      Filesystem.rmdir({
        directory: Directory.Data,
        path: 'attachments',
        recursive: true
      }).catch((err) => console.log('Error removing attachments', err));
      if (clearThumbs) {
        Filesystem.rmdir({
          directory: Directory.Data,
          path: 'thumbs',
          recursive: true
        }).catch((err) => console.log('Error removing thumbs', err));
      }
    }
  }

  private readAsBinaryString(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        resolve(reader.result as string);
      };
      reader.onerror = (event) => {
        reject(reader.error);
      };
      reader.readAsBinaryString(file);
    });
  }

  private async save(
    observer: Observer<string>,
    directory: Directory,
    folder: string,
    fileName: string,
    data: Blob
  ): Promise<void> {
    try {
      const binaryString = await this.readAsBinaryString(data);
      const base64String = btoa(binaryString);
      const result = await Filesystem.writeFile({
        data: base64String,
        directory: directory,
        path: folder + '/' + fileName,
        recursive: true
      });
      // console.log(result);
      observer.next(this.convertFileSrc(result.uri));
    } catch (error) {
      // console.error(error);
      observer.error(FILE_WRITE_ERROR);
    }
  }

  private async deleteIfExists(folder: string, fileName: string): Promise<boolean> {
    const path = folder + '/' + fileName;
    try {
      await Filesystem.stat({
        directory: Directory.Data,
        path: path
      });
    } catch (checkDirException) {
      return false;
    }

    try {
      await Filesystem.deleteFile({
        directory: Directory.Data,
        path: path
      });
      console.log('removed: ' + path);
      return true;
    } catch (deleteFileException) {
      console.log('Error removing', path, deleteFileException);
      throw deleteFileException;
    }
  }

  private async stageFile(fileName: string, originalName: string): Promise<GetUriResult> {
    try {
      await Filesystem.mkdir({
        directory: Directory.Data,
        path: 'temp'
      });
    } catch {
      /* empty */
    }

    let fullImageAvailable = false;
    try {
      await Filesystem.stat({
        path: 'attachments/' + fileName,
        directory: Directory.Data
      });
      fullImageAvailable = true;
    } catch {
      /* empty */
    }

    const folder = fullImageAvailable ? 'attachments/' : 'thumbs/';

    const ret = await Filesystem.copy({
      from: folder + fileName,
      to: 'temp/' + originalName,
      directory: Directory.Data
    });

    const getUriResult = await Filesystem.getUri({
      path: 'temp/' + originalName,
      directory: Directory.Data
    });

    return getUriResult;
  }

  private async open(observer: Observer<boolean>, fileName: string, originalName: string): Promise<void> {
    try {
      await Filesystem.rmdir({
        directory: Directory.Data,
        path: 'temp',
        recursive: true
      });
    } catch {
      /* empty */
    }

    const getUriResult = await this.stageFile(fileName, originalName);

    FileOpener.open({
      filePath: getUriResult.uri
    })
      .then(() => observer.next(true))
      .catch((e) => {
        observer.error(e.code === '8' ? FILE_OPEN_HANDLER_NOT_FOUND : e.message);
      });
  }

  openBlob(filename: string, mimeType: string, blob: Blob): Observable<boolean> {
    return Observable.create((observer) => {
      if (this.platform.is('hybrid')) {
        // const path = this.getDataDirectory();
        // this.doOpenBlob(observer, path, filename, mimeType, blob);
      } else {
        this.doOpenBlobBrowser(filename, mimeType, blob);
        observer.next(true);
      }
    });
  }

  doOpenBlobBrowser(fileName: string, mimeType: string, blob: Blob): boolean {
    // Based on https://stackoverflow.com/a/36899900
    // if (blob !== null && navigator.msSaveBlob) {
    //   return navigator.msSaveBlob(blob, fileName);
    // }
    const a = document.createElement('a');
    a.style.display = 'none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.target = '_blank';
    a.download = fileName;
    document.getElementsByTagName('body')[0].append(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

    return true;
  }
}
