// State
import * as fromSettings from '../actions/settings.action';

export interface Language {
  id: string;
  legend: string;
}

export interface SettingsState {
  // Force users to take a new default retention period
  // by changing state field name
  retentionPeriod1: string;

  languageId: string;
}

export const initialState: SettingsState = {
  retentionPeriod1: 'P1M',
  languageId: null
};

export function reducer(state = initialState, action: fromSettings.SettingsAction): SettingsState {
  switch (action.type) {
    case fromSettings.INITIALISE_SETTINGS: {
      return {
        ...state,
        retentionPeriod1: state.retentionPeriod1 ? state.retentionPeriod1 : initialState.retentionPeriod1
      };
    }
    case fromSettings.UPDATE_RETENTION_PERIOD: {
      return {
        ...state,
        retentionPeriod1: action.payload.period
      };
    }
    case fromSettings.SET_LANGUAGE: {
      return {
        ...state,
        languageId: action.payload.id
      };
    }
    default: {
      return state;
    }
  }
}
