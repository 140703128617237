import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ItemTranslationDto } from 'src/app/services/translation.api.service';
import { ItemTranslation } from '../reducers/item-translations.reducer';

export const addItemTranslation = createAction(
  '[Item Translations] Add Item Translation',
  props<{
    itemTranslation: ItemTranslation;
  }>()
);
export const updateItemTranslation = createAction(
  '[Item Translations] Update Item Translation',
  props<{
    update: Update<ItemTranslation>;
  }>()
);
export const deleteItemTranslations = createAction(
  '[Item Translations] Delete Item Translations',
  props<{
    ids: number[];
  }>()
);
export const showOriginalItem = createAction(
  '[Item Translations] Show Original Item',
  props<{
    id: number;
  }>()
);
export const prepareTranslateItem = createAction(
  '[Item Translations] Prepare Translate Item',
  props<{
    id: number;
  }>()
);
export const translateItem = createAction(
  '[Item Translations] Translate Item',
  props<{
    id: number;
  }>()
);
export const translateItemSuccess = createAction(
  '[Item Translations] Translate Item Success',
  props<{
    id: number;
    direction: string;
    result: ItemTranslationDto;
  }>()
);
export const translateItemFail = createAction(
  '[Item Translations] Translate Item Fail',
  props<{
    id: number;
    error: string;
  }>()
);
