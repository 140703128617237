// Models
export class ProfileViewModel {
  constructor(
    public forename: string,
    public surname: string
  ) {}
}

export interface Profile {
  title: number;
  forename: string;
  surname: string;
  pushAlias: string;
}
