import { Action, createReducer, on } from '@ngrx/store';
import { AppReleaseDto } from 'src/app/services/app-version.api.service';
import * as appVersionActions from '../actions/app-version.action';

export interface AppVersionState {
  latestRelease: AppReleaseDto;
  availableUpdateVersionShown: string;
  lastCheck: number;
}

export const initialState: AppVersionState = {
  latestRelease: null,
  availableUpdateVersionShown: null,
  lastCheck: 0
};

export const theReducer = createReducer(
  initialState,
  on(appVersionActions.checkVersionApiSuccess, (state, { result }) => ({
    ...state,
    latestRelease: result,
    lastCheck: Date.now()
  })),
  on(appVersionActions.showUpdateAvailable, (state, { version }) => ({
    ...state,
    availableUpdateVersionShown: version
  })),
  on(appVersionActions.clearAvailableUpdateVersion, (state) => ({
    ...state,
    availableUpdateVersionShown: null
  }))
);

export function reducer(state: AppVersionState | undefined, action: Action): any {
  return theReducer(state, action);
}
