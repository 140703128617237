import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CalendarEvent } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-calendar-link',
  templateUrl: './calendar-link.component.html',
  styleUrls: ['./calendar-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarLinkComponent {
  @Input() title: string;
  @Input() starting: string;
  @Input() ending: string;
  @ViewChild('innerContent', { static: true }) innerContent: ElementRef;

  // dynamicOnMount(attr: Map<string, string>, content: string): void {
  //   this.title = attr.get('title');
  //   this.starting = attr.get('starting');
  //   this.ending = attr.get('ending');
  //   this.innerContent.nativeElement.innerHTML = content;
  // }

  save(event): void {
    event.stopPropagation();
    event.target.dispatchEvent(
      new CustomEvent('saveCalendar', {
        bubbles: true,
        detail: new CalendarEvent(this.title, this.starting, this.ending)
      })
    );
  }
}
