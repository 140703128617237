<div class="container">
  <div *ngIf="showSpinner && ((spinnerState$ | async)?.active === true || loading); else xxx" class="spinner-container">
    <ion-spinner name="crescent" appSafariAnimate></ion-spinner>
    <p>Loading chat...</p>
  </div>
  <ng-template #xxx>
    <div *ngIf="loadFailReason" class="load-failed-container">
      <p class="error">
        We weren't able to load the chat this time.<br /><br />Please check your wifi or mobile data connection and try
        again.
      </p>
      <ion-button type="button" color="secondary" class="retryButton" (click)="onRetryLoad()">Try again</ion-button>
    </div>
  </ng-template>
</div>
