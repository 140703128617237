import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PendingMessages, TimeLineEntry } from '../../chat-modal.store';

@Component({
  selector: 'app-chat-timeline-pending-messages-entry',
  templateUrl: './chat-timeline-pending-messages-entry.component.html',
  styleUrls: ['./chat-timeline-pending-messages-entry.component.scss', '../chat-timeline-entry-bubble.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTimelinePendingMessagesEntryComponent {
  @Input() entry: TimeLineEntry;
  @Output() retrySendMessage = new EventEmitter<number>();
  @Output() messageAdded = new EventEmitter<string>();
  get pendingMessages(): PendingMessages {
    return this.entry as PendingMessages;
  }

  onRetry(id: number, canRetry: boolean): void {
    if (canRetry) {
      this.retrySendMessage.emit(id);
    }
  }

  onDomChange(event: MutationRecord): void {
    event.addedNodes.forEach((node) => {
      const id = (node as HTMLElement)?.id;
      if (id?.startsWith('msg-')) {
        if ((node as HTMLElement).clientHeight > 0) {
          this.messageAdded.emit(id);
        }
      }
    });
  }
}
