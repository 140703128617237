import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ChatThreadPendingReplyActions } from '../actions/chat-thread-pending-reply.action';
import * as coreActions from '../actions/core.action';

export interface ChatThreadPendingReply {
  id: number;
  threadId: number;
  schoolId: number;
  date: string;
  content: string;
  status: 'in-progress' | 'failed';
  error: string;
}
export interface State extends EntityState<ChatThreadPendingReply> {}

export const chatThreadPendingReplyAdapter: EntityAdapter<ChatThreadPendingReply> =
  createEntityAdapter<ChatThreadPendingReply>();

export const initialState: State = chatThreadPendingReplyAdapter.getInitialState({});

export const theReducer = createReducer(
  initialState,
  on(ChatThreadPendingReplyActions.reply, (state, action) => {
    const now = new Date();
    return chatThreadPendingReplyAdapter.addOne(
      {
        id: action.id,
        threadId: action.threadId,
        schoolId: action.schoolId,
        date: now.toUTCString(),
        content: action.content,
        status: 'in-progress',
        error: null
      },
      state
    );
  }),
  on(ChatThreadPendingReplyActions.replySuccess, (state, action) => {
    return chatThreadPendingReplyAdapter.removeOne(action.id, state);
  }),
  on(ChatThreadPendingReplyActions.replyFail, (state, action) => {
    return chatThreadPendingReplyAdapter.updateOne(
      { id: action.id, changes: { status: 'failed', error: action.error } },
      state
    );
  }),
  on(ChatThreadPendingReplyActions.retryReply, (state, action) => {
    return chatThreadPendingReplyAdapter.updateOne(
      { id: action.id, changes: { status: 'in-progress', error: null } },
      state
    );
  }),
  on(ChatThreadPendingReplyActions.clearFailed, (state, action) => {
    return chatThreadPendingReplyAdapter.removeMany(
      (a) => a.threadId === action.threadId && a.status === 'failed',
      state
    );
  })
);
export function reducer(state: State | undefined, action: Action): any {
  switch (action.type) {
    case coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED:
      return initialState;
    default:
      return theReducer(state, action);
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = chatThreadPendingReplyAdapter.getSelectors();
