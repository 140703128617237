import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppState } from '../../auth/store/reducers';
import {
  AttendanceSession,
  AttendanceSessions,
  AttendanceSessionViewModel,
  AttendanceSessionWithReason,
  AttendanceSummary
} from '../../models/attendance.model';
import {
  StudentTimetable,
  TimetableDay,
  TimetablePeriod,
  TimetablePeriodViewModel,
  TimetableViewModel
} from '../../models/timetable.model';
import * as fromStudents from '../reducers/students.reducer';
import { StudentsState } from '../reducers/students.reducer';

export const getStudentsState = createFeatureSelector<StudentsState>('studentsState');

export const getAttendanceSummaryEntitiesState = createSelector(getStudentsState, (state) => state.attendanceSummary);

export const {
  selectIds: getAttendanceSummaryIds,
  selectEntities: getAttendanceSummaryEntities,
  selectAll: getAllAttendanceSummaries,
  selectTotal: getTotalAttendanceSummaries
} = fromStudents.attendanceSummaryAdapter.getSelectors(getAttendanceSummaryEntitiesState);
export const getStudentAttendanceSummary = (id: number): MemoizedSelector<AppState, AttendanceSummary> =>
  createSelector(getAttendanceSummaryEntities, (state) => transformAttendanceSummary(state[id]));
export const transformAttendanceSummary = (summary: AttendanceSummary): AttendanceSummary => {
  if (!summary) {
    return null;
  }
  const result = { ...summary };
  const normalizedPercentages = normalizePercentages([
    summary.percentageOnTime,
    summary.percentageLates,
    summary.percentageAuthAbsences,
    summary.percentageUnAuthAbsences
  ]);
  result.percentageOnTime = normalizedPercentages[0];
  result.percentageLates = normalizedPercentages[1];
  result.percentageAuthAbsences = normalizedPercentages[2];
  result.percentageUnAuthAbsences = normalizedPercentages[3];
  return result;
};

// https://stackoverflow.com/a/13483573
export const normalizePercentages = (numbers: number[]): number[] => {
  let carry = 0.5;
  const result = [];
  numbers.forEach((numberVar) => {
    const temp = numberVar + carry;
    const trunced = Math.trunc(temp);
    carry = temp - trunced;
    result.push(trunced);
  });
  return result;
};

export const getAttendanceSessionsEntitiesState = createSelector(getStudentsState, (state) => state.attendanceSessions);

export const {
  selectIds: getAttendanceSessionsIds,
  selectEntities: getAttendanceSessionsEntities,
  selectAll: getAllAttendanceSessions,
  selectTotal: getTotalAttendanceSessions
} = fromStudents.attendanceSessionsAdapter.getSelectors(getAttendanceSessionsEntitiesState);

export const getStudentAttendanceSessions = (id: number): MemoizedSelector<AppState, AttendanceSessions> =>
  createSelector(getAttendanceSessionsEntities, (state) => state[id]);

export const getStudentAttendanceSessionsViewModel = (
  id: number
): MemoizedSelector<AppState, AttendanceSessionViewModel[]> =>
  createSelector(getAttendanceSessionsEntities, (state) => {
    const attendanceSessionsEntity = state[id];
    if (!attendanceSessionsEntity) {
      return null;
    }
    const studentSessions = attendanceSessionsEntity.sessions;
    const result = _.chain(studentSessions)
      .groupBy('date')
      .map((v, k) => {
        const am = _.find(v, ['session', 'AM']);
        const pm = _.find(v, ['session', 'PM']);
        return {
          dateOrder: moment(k).format('YYYY-MM-DD'),
          date: moment(k).format('ddd Do MMM'),
          am: getAttandanceMark(am),
          pm: getAttandanceMark(pm)
        };
      })
      .orderBy(['dateOrder'], ['desc'])
      .map((v) => {
        return {
          date: v.date,
          am: v.am,
          pm: v.pm
        };
      })
      .value();

    return result;
  });
export const getAttandanceMark = (session: AttendanceSession): { class: string; reason: string } => {
  if (session === null) {
    return attendanceMarks['*'];
  }
  const result = attendanceMarks[session.code];
  if (result) {
    return result;
  }
  return { class: 'unknown', reason: 'unknown mark' };
};

export const createAttendanceSessionViewModel = (session: AttendanceSession): AttendanceSessionWithReason => {
  const lu = attendanceMarks[session.code];
  return {
    date: moment(session.date).format('YYYY-MM-DD'),
    session: session.session,
    code: session.code,
    reason: lu.reason
  };
};

export const getUnauthorizedAbsencesEntitiesState = createSelector(
  getStudentsState,
  (state) => state.unauthorizedAbsences
);

export const {
  selectIds: getUnauthorizedAbsencesIds,
  selectEntities: getUnauthorizedAbsencesEntities,
  selectAll: getAllUnauthorizedAbsences,
  selectTotal: getTotalUnauthorizedAbsences
} = fromStudents.unauthorizedAbsencesAdapter.getSelectors(getUnauthorizedAbsencesEntitiesState);

export const getStudentUnauthorizedAbsences = (id: number): MemoizedSelector<AppState, AttendanceSessions> =>
  createSelector(getUnauthorizedAbsencesEntities, (state) => state[id]);

export const getStudentUnauthorizedAbsencesViewModel = (id: number): MemoizedSelector<AppState, AttendanceSessions> =>
  createSelector(getUnauthorizedAbsencesEntities, (state) => {
    const unauthorizedAbsencesEntity = state[id];
    if (!unauthorizedAbsencesEntity) {
      return null;
    }
    const studentSessions = unauthorizedAbsencesEntity.sessions;
    const orderedSessions = _.orderBy(studentSessions, ['date', 'session'], ['desc', 'desc']);
    const result = _.map(orderedSessions, (session) => createAttendanceSessionViewModel(session));
    return result;
  });

export const getTimetableEntitiesState = createSelector(getStudentsState, (state) => state.timetable);

export const getAttendaceSessionUpdateDate = createSelector(
  getStudentsState,
  (state) => state.attendanceSessionUpdateDate
);

export const {
  selectIds: getTimetableIds,
  selectEntities: getTimetableEntities,
  selectAll: getAllTimetables,
  selectTotal: getTotalTimetables
} = fromStudents.timetableAdapter.getSelectors(getTimetableEntitiesState);

export const getStudentTimetable = (id: number): MemoizedSelector<AppState, StudentTimetable> =>
  createSelector(getTimetableEntities, (state) => state[id]);
export const getTimetableLoading = (): MemoizedSelector<AppState, boolean> =>
  createSelector(getTimetableEntitiesState, (state) => state.loading);
export const getStudentTimetableLoadedOn = (id: number): MemoizedSelector<AppState, Date> =>
  createSelector(getStudentTimetable(id), (state) => (state ? state.generatedOn : null));

export const getStudentTimetableViewModel = (id: number): MemoizedSelector<AppState, TimetableViewModel> =>
  createSelector(getTimetableEntities, (state) => {
    const entity = state[id];
    if (!entity) {
      return null;
    }
    const orderedTimetableDays = _.orderBy(entity.days, ['day'], ['asc']);
    return createTimetableViewModel(orderedTimetableDays);
  });
export const createTimetableViewModel = (timetableDays: TimetableDay[]): TimetableViewModel => {
  const days = _.map(timetableDays, (timetableDay) => createTimetableDay(timetableDay));
  const result = new TimetableViewModel();
  result.initialSlide = 0;
  result.days = days;
  return {
    initialSlide: calculateInitialSlide(timetableDays),
    days
  };
};
export const calculateInitialSlide = (days: TimetableDay[]): number => {
  let today = moment();
  // if weekend, use next monday
  if (today.day() === 0) {
    today = today.day(1);
  } else if (today.day() === 6) {
    today = today.day(2);
  }
  const todayIndex = _.findIndex(days, (day) => moment(day.day).isSame(today, 'd'));
  return todayIndex >= 0 ? todayIndex : 0;
};
export const createTimetableDay = (timetableDay: TimetableDay): { dayParts: string[]; periods: TimetablePeriod[] } => {
  return {
    dayParts: createDayParts(timetableDay.day),
    periods: _.map(timetableDay.periods, (period) => createPeriodView(period))
  };
};
export const createPeriodView = (period: TimetablePeriod): TimetablePeriodViewModel => {
  return {
    start: period.start,
    end: period.end,
    teacherName: period.teacherName,
    className: period.className,
    subject: period.subject
  };
};
// Note: To handle the superscript part of the day we need to break it up
export const createDayParts = (day: Date): string[] => {
  const dayString = moment(day).format('dddd Do MMMM');
  const parts = dayString.split(' ');
  const dayPart = parts[1].slice(0, parts[1].length - 2);
  const daySupPart = parts[1].slice(parts[1].length - 2);
  const result = [];
  result.push(parts[0] + ' ' + dayPart);
  result.push(daySupPart);
  result.push(parts[2]);
  return result;
};

export const attendanceMarks: {
  [mark: string]: { class: string; reason: string };
} = {
  '/': { class: 'present', reason: 'Present' },
  N: { class: 'unauth', reason: 'No reason yet provided for absence' },
  B: { class: 'present', reason: 'Educated off site (NOT Dual reg.)' },
  C: { class: 'auth', reason: 'Other Authorised Circumstances' },
  D: { class: 'none', reason: 'Dual registration (attend elsewhere)' },
  E: { class: 'auth', reason: 'Excluded (no alternative provision)' },
  F: { class: 'auth', reason: 'Extended family holiday (agreed)' },
  G: { class: 'unauth', reason: 'Family holiday (NOT agreed)' },
  H: { class: 'auth', reason: 'Family holiday (agreed)' },
  I: { class: 'auth', reason: 'Illness (NOT medical or dental)' },
  J: { class: 'present', reason: 'Interview' },
  M: { class: 'auth', reason: 'Medical/Dental appointments' },
  O: { class: 'unauth', reason: 'Unauthorised absence' },
  P: { class: 'present', reason: 'Approved sporting activity' },
  R: { class: 'auth', reason: 'Religious observance' },
  S: { class: 'auth', reason: 'Study leave' },
  T: { class: 'auth', reason: 'Traveller absence' },
  V: { class: 'present', reason: 'Educational visit or trip' },
  W: { class: 'present', reason: 'Work experience' },
  L: { class: 'late', reason: 'Late (before registers closed)' },
  U: { class: 'late', reason: 'Late (after registers closed)' },
  X: { class: 'none', reason: 'Non-compulsory school age absence' },
  Y: {
    class: 'none',
    reason: 'Unable to attend due to exceptional circumstances'
  },
  Z: { class: 'none', reason: 'Pupil not on roll' },
  '7': { class: 'auth', reason: '' },
  '8': { class: 'none', reason: '' },
  '9': { class: 'none', reason: '' },
  '*': { class: 'none', reason: 'Not Yet Marked' },
  '-': { class: 'auth', reason: 'Attendance Not Required' },
  '#': { class: 'closed', reason: 'Planned whole or partial school closure' }
};
