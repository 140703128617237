// State
import { ChannelPublisherAvatars } from '../../models/channel.model';
import * as fromChannel from '../actions/channel.action';

export interface ChannelState {
  loading: boolean;
  error: string;
  channel: ChannelPublisherAvatars;
  currentStep: number;
}

export const initialState: ChannelState = {
  loading: false,
  error: null,
  channel: null,
  currentStep: null
};

export function reducer(state = initialState, action: fromChannel.ChannelAction): ChannelState {
  switch (action.type) {
    case fromChannel.FIND_CHANNEL_BY_CODE: {
      return {
        ...state,
        loading: true,
        error: null,
        channel: null,
        currentStep: null
      };
    }

    case fromChannel.FIND_CHANNEL_BY_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }

    case fromChannel.FIND_CHANNEL_BY_CODE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    }

    case fromChannel.GET_PUBLISHERS_AVATARS_SUCCESS: {
      return {
        ...state,
        channel: action.payload
      };
    }

    case fromChannel.CHANNEL_CHANGE_STEP: {
      return {
        ...state,
        currentStep: action.payload.step
      };
    }

    default: {
      return state;
    }
  }
}

export const getChannelLoading = (state: ChannelState): boolean => state.loading;
export const getChannelError = (state: ChannelState): string => state.error;
export const getChannelChannel = (state: ChannelState): ChannelPublisherAvatars => state.channel;
export const getChannelCurrentStep = (state: ChannelState): number => state.currentStep;
