// Modules
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Channel, ChannelComplete } from '../models/channel.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {
  constructor(private http: HttpClient) {}

  findChannelByCode(code: string): Observable<ChannelComplete> {
    return this.http.get<ChannelComplete>(environment.apiBaseUrl + '/api/channel/findByCode?code=' + code);
  }

  subscribeChannel(code: number): Observable<string> {
    const body = {
      channelId: code
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.apiBaseUrl + '/api/channelsubscription', JSON.stringify(body), {
      headers: headers,
      responseType: 'text'
    });
  }

  leaveChannel(id: number): Observable<string> {
    return this.http.delete(environment.apiBaseUrl + '/api/channelsubscription/' + id, { responseType: 'text' });
  }

  getSubscribedChannels(): Observable<Channel[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<Channel[]>(environment.apiBaseUrl + '/api/channel/subscribed', { headers: headers });
  }

  getSubscribedChannel(channelId: number): Observable<Channel> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<Channel>(environment.apiBaseUrl + '/api/channel/subscribed/' + channelId, {
      headers: headers
    });
  }
}
