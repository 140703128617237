/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/chat-thread-pending-reply.reducer';
import { getChatThreadId } from './chat-thread.selector';

export const getChatThreadPendingReplyState = createFeatureSelector<State>('chatThreadPendingReplyState');
export const getChatThreadPendingReplyById = (props: { id: number }) =>
  createSelector(getChatThreadPendingReplyState, (state) => {
    return state.entities[props.id];
  });
export const getChatThreadPendingRepliesByThreadId = (props: { chatThreadId: number }) =>
  createSelector(getChatThreadPendingReplyState, (state) => {
    return Object.values(state.entities).filter((e) => e.threadId === props.chatThreadId);
  });

export const getChatThreadPendingReplies = createSelector(
  getChatThreadPendingReplyState,
  getChatThreadId,
  (state, threadId) => {
    return Object.values(state.entities).filter((e) => e.threadId === threadId);
  }
);
