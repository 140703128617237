import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../actions/payment-intent.action';

export interface PaymentIntentTracking {
  id: number;
  paymentId: number;
  studentId: number;
  tenant: number;
  attempt: number;
  nextCheckTimestamp: number;
}
export type State = EntityState<PaymentIntentTracking>;
export const paymentIntentAdapter: EntityAdapter<PaymentIntentTracking> = createEntityAdapter<PaymentIntentTracking>();

export const initialState: State = paymentIntentAdapter.getInitialState();

export const theReducer = createReducer(
  initialState,
  on(actions.addIntent, (state, action) => paymentIntentAdapter.addOne(action.intent, state)),
  on(actions.updateIntent, (state, action) => paymentIntentAdapter.updateOne(action.update, state)),
  on(actions.deleteIntent, (state, action) => paymentIntentAdapter.removeOne(action.id, state))
);
export function reducer(state: State | undefined, action: Action): any {
  return theReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = paymentIntentAdapter.getSelectors();
