import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimeLineEntry } from '../../chat-modal.store';

@Component({
  selector: 'app-chat-timeline-unread-tidemark',
  templateUrl: './chat-timeline-unread-tidemark.component.html',
  styleUrls: ['./chat-timeline-unread-tidemark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTimelineUnreadTidemarkComponent {
  @Input() entry: TimeLineEntry;
}
