// Models

export class User {
  constructor(username: string) {}
}

export class UserInfoViewModel {
  constructor(
    public email: string,
    public emailVerified: boolean
  ) {}
}
