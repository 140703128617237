// Modules
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// State
import { Action, Store } from '@ngrx/store';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Channel } from '../../models/channel.model';
import { ApiService } from '../../services/api.service';
import { ChannelService } from '../../services/channel.service';
// Services
import { SchoolService } from '../../services/school.service';
import * as coreActions from '../actions/core.action';
import * as schoolActions from '../actions/school.action';
import * as schoolSelectors from '../selectors';

@Injectable()
export class SchoolEffects {
  constructor(
    private actions$: Actions,
    private schoolService: SchoolService,
    private apiService: ApiService,
    private channelService: ChannelService,
    private store: Store<any>
  ) {}

  findSchoolByHandle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.FindSchoolByHandle>(schoolActions.FIND_SCHOOL_BY_HANDLE),
      map((action: schoolActions.FindSchoolByHandle) => action),
      switchMap((action) =>
        this.schoolService.findSchoolByHandle(action.payload).pipe(
          map((response) => {
            this.store.dispatch(new schoolActions.FindSchoolByHandleSuccess({ school: response }));

            return new schoolActions.CheckAddSchoolProgress({
              schoolId: response.id,
              resumeOnboarding: false
            });
          }),
          catchError((error) => {
            let message = null;
            let header = 'Oops!';

            if (error.status === 404) {
              header = 'Incorrect school handle';
              message = `We couldn't find a school with this handle. Please try again, or contact the school.`;
            }

            if (error.status === 403) {
              header = 'Contact School';
              message = 'Please contact the school before continuing.';
            }

            return of(new schoolActions.FindSchoolByHandleFail({ header, message }));
          })
        )
      )
    )
  );

  checkAddSchoolProgress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.CheckAddSchoolProgress>(schoolActions.CHECK_ADD_SCHOOL_PROGRESS),
      map((action: schoolActions.CheckAddSchoolProgress) => action),
      switchMap((action) =>
        this.schoolService.checkAddSchoolProgress(action.payload.schoolId).pipe(
          map((response) => {
            this.store.dispatch(
              new schoolActions.GetSchoolLogo({
                schoolId: action.payload.schoolId,
                subscriptionState: response.subscriptionState,
                resumeOnboarding: action.payload.resumeOnboarding
              })
            );

            return new schoolActions.CheckAddSchoolProgressSuccess();
          }),
          catchError(() => {
            this.store.dispatch(
              new schoolActions.GetSchoolLogo({
                schoolId: action.payload.schoolId,
                subscriptionState: undefined,
                resumeOnboarding: action.payload.resumeOnboarding
              })
            );

            return of(new schoolActions.CheckAddSchoolProgressFail());
          })
        )
      )
    )
  );

  getSchoolLogoAndNavigate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSchoolLogo>(schoolActions.GET_SCHOOL_LOGO),
      withLatestFrom(this.store.select(schoolSelectors.getSchoolState)),
      switchMap(([action, store]) =>
        this.apiService.getImageAsBase64(store.school.logoUrl).pipe(
          map((logoData) => {
            // If the subscription state is 3, which is already subscribed send 7 as the step to close the modal
            if (action.payload.subscriptionState >= 0) {
              // If theres a subscription state and the modal opens from the side menu dismiss it
              if (action.payload.resumeOnboarding === false) {
                return new schoolActions.GetSchoolLogoSuccess({
                  schoolId: action.payload.schoolId,
                  logoData,
                  page: 'Dismiss',
                  alreadySubscribed: true
                });
              }

              let page: string;

              switch (action.payload.subscriptionState) {
                case 0:
                  page = 'Join School Verification';
                  break;
                case 1:
                  page = 'Join School Verification Confirmation';
                  break;
                case 2:
                  page = 'Join School DOB Confirmation';
                  break;
                case 3:
                  page = 'Dismiss';
                  break;
              }

              return new schoolActions.GetSchoolLogoSuccess({
                schoolId: action.payload.schoolId,
                logoData,
                page,
                alreadySubscribed: false
              });
            } else {
              return new schoolActions.GetSchoolLogoSuccess({
                schoolId: action.payload.schoolId,
                logoData,
                page: 'Confirm School',
                alreadySubscribed: false
              });
            }
          }),
          catchError((error) => {
            if (error.status === 0) {
              return of(
                new schoolActions.GetSchoolLogoFail({
                  page: 'Dismiss',
                  alreadySubscribed: false
                })
              );
            } else {
              // If the subscription state is 3, which is already subscribed send 7 as the step to close the modal
              if (action.payload.subscriptionState >= 0) {
                // If theres a subscription state and the modal opens from the side menu dismiss it
                if (action.payload.resumeOnboarding === false) {
                  return of(
                    new schoolActions.GetSchoolLogoFail({
                      page: 'Dismiss',
                      alreadySubscribed: true
                    })
                  );
                }

                let page: string;

                switch (action.payload.subscriptionState) {
                  case 0:
                    page = 'Join School Verification';
                    break;
                  case 1:
                    page = 'Join School Verification Confirmation';
                    break;
                  case 2:
                    page = 'Join School DOB Confirmation';
                    break;
                  case 3:
                    page = 'Dismiss';
                    break;
                }

                return of(
                  new schoolActions.GetSchoolLogoFail({
                    page,
                    alreadySubscribed: false
                  })
                );
              } else {
                return of(
                  new schoolActions.GetSchoolLogoFail({
                    page: 'Confirm School',
                    alreadySubscribed: false
                  })
                );
              }
            }
          })
        )
      )
    )
  );

  sendSchoolVerificationSMS$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.VerifySchool>(schoolActions.VERIFY_SCHOOL),
      map((action: schoolActions.VerifySchool) => action),
      switchMap((action) =>
        this.schoolService.sendVerificationSMS(action.payload.schoolId, action.payload.mobileNumber).pipe(
          map(() => {
            return new schoolActions.VerifySchoolSuccess();
          }),
          catchError((error) => {
            const errorCode = error.status === 0 ? 0 : JSON.parse(error.error).ErrorCode;

            return of(
              new schoolActions.VerifySchoolFail({
                errorCode
              })
            );
          })
        )
      )
    )
  );

  resendSchoolVerificationSMS$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.VerifyReminderSchool>(schoolActions.VERIFY_REMINDER_SCHOOL),
      map((action: schoolActions.VerifyReminderSchool) => action),
      switchMap((action) =>
        this.schoolService.resendVerificationSMS(action.payload).pipe(
          map(() => {
            return new schoolActions.VerifyReminderSchoolSuccess();
          }),
          catchError((error) => {
            let errorTitle = null;
            let errorMsg = null;

            if (error.status === 429) {
              errorTitle = 'SMS Request Limit Reached';
              errorMsg = `
              <p>You've reached the limit on the number of SMS codes you can request
              , so we've temporarily suspended your verification. Please wait an hour before trying again.</p>
              <p>In the meantime, you won't be able to view any messages and information specific to your child
              , but you will be able to see any general updates sent out by the school.</p>
              `;

              return of(
                new schoolActions.VerifyReminderSchoolFail({
                  errorTitle,
                  errorMsg
                })
              );
            } else if (error.status === 0) {
              errorTitle = 'Offline';
              errorMsg = 'There was an error requesting the SMS, make sure you are online.';

              new schoolActions.VerifyReminderSchoolFail({
                errorTitle,
                errorMsg
              });
            }

            return of(new schoolActions.VerifyReminderSchoolFail());
          })
        )
      )
    )
  );

  confirmSchoolVerificationSMS$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.ConfirmVerificationSchool>(schoolActions.CONFIRM_VERIFICATION_SCHOOL),
      map((action: schoolActions.ConfirmVerificationSchool) => action),
      switchMap((action) =>
        this.schoolService.confirmVerification(action.payload.schoolId, action.payload.confirmationCode).pipe(
          map(() => {
            return new schoolActions.ConfirmVerificationSchoolSuccess();
          }),
          catchError((error) => {
            let errorMsg = null;
            const attemptsError = `Sorry, you've entered an incorrect verification code too many times. <br/>
                <br/>Please contact the school before trying again.`;
            let canRetry = true;

            if (error.status === 400) {
              const errorObj = JSON.parse(error.error);
              switch (errorObj.ErrorCode) {
                case 2000:
                  errorMsg =
                    errorObj.ErrorInfo.AttemptsRemaining !== 0
                      ? `<p>The code you've entered doesn't match the one we've sent you.</p><p>Please try again. You have ` +
                        errorObj.ErrorInfo.AttemptsRemaining +
                        ' attempts remaining.</p>'
                      : attemptsError;
                  canRetry = errorObj.ErrorInfo.AttemptsRemaining !== 0;
                  break;
              }
            } else if (error.status === 404) {
              errorMsg = attemptsError;
              canRetry = false;
            } else if (error.status === 0) {
              errorMsg = 'There was an error checking the verification code provided, make sure you are online.';
              canRetry = true;
            }

            return of(
              new schoolActions.ConfirmVerificationSchoolFail({
                errorMessage: errorMsg,
                canRetry
              })
            );
          })
        )
      )
    )
  );

  confirmDOB: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.ConfirmDOBSchool>(schoolActions.CONFIRM_DOB_SCHOOL),
      map((action: schoolActions.ConfirmDOBSchool) => action),
      switchMap((action) =>
        this.schoolService.confirmDOB(action.payload.schoolId, action.payload.enteredDate).pipe(
          map(() => {
            this.store.dispatch(
              new coreActions.CompleteSchoolSubscription({
                schoolId: action.payload.schoolId
              })
            );

            return new schoolActions.ConfirmDOBSchoolSuccess();
          }),
          catchError((error) => {
            let errorHeader = null;
            let errorMsg = null;
            const attemptsError = `Sorry, you've entered an incorrect date too many times. <br/><br/>Please contact the school before trying again.`;
            let canRetry = true;
            if (error.status === 400) {
              const errorObj = JSON.parse(error.error);

              switch (errorObj.ErrorCode) {
                case 1000:
                  errorHeader = 'Date missing';
                  errorMsg = 'Please try again with a valid date format';
                  break;
                case 2000:
                  errorMsg =
                    errorObj.ErrorInfo.AttemptsRemaining !== 0
                      ? `<p>We couldn't find a match for the date of birth you've entered. Please try again.</p><p>You have ` +
                        errorObj.ErrorInfo.AttemptsRemaining +
                        ` attempts remaining.</p><p>If you have more than 1 child at the school, you can enter the date of birth of any one of them.</p>`
                      : attemptsError;
                  canRetry = errorObj.ErrorInfo.AttemptsRemaining !== 0;
                  break;
              }
            } else if (error.status === 404) {
              errorMsg = attemptsError;
            } else if (error.status === 0) {
              errorMsg = 'There was an error checking the date of birth provided, make sure you are online.';
            }

            return of(
              new schoolActions.ConfirmDOBSchoolFail({
                errorHeader: errorHeader,
                errorMessage: errorMsg,
                canRetry
              })
            );
          })
        )
      )
    )
  );

  confirmSchool$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.ConfirmSchool>(schoolActions.CONFIRM_SCHOOL),
      withLatestFrom(this.store.select(schoolSelectors.getSchoolState)),
      switchMap(([action, state]) =>
        this.schoolService.subscribeToSchoolFeed(state.school.feedChannelId).pipe(
          map(() => {
            this.store.dispatch(new schoolActions.ConfirmSchoolSuccess());

            return new schoolActions.GetSchoolFeed({
              schoolId: action.payload.schoolId
            });
          }),
          catchError(() => {
            return of(new schoolActions.ConfirmSchoolFail());
          })
        )
      )
    )
  );

  getSchoolFeedChannel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSchoolFeed>(schoolActions.GET_SCHOOL_FEED),
      withLatestFrom(this.store.select(schoolSelectors.getSchoolState)),
      switchMap(([action, state]) =>
        this.channelService.getSubscribedChannel(state.school.feedChannelId).pipe(
          map((response: Channel) => {
            return new schoolActions.GetSchoolFeedSuccess({
              channel: response,
              school: state.school
            });
          }),
          catchError(() => {
            return of(new schoolActions.GetSchoolFeedFail());
          })
        )
      )
    )
  );

  getSchoolChannelsAndChangeStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSchoolChannelsAndChangeStep>(schoolActions.GET_SCHOOL_CHANNELS_AND_CHANGE_STEP),
      map((action: schoolActions.GetSchoolChannelsAndChangeStep) => action),
      mergeMap((action) =>
        this.schoolService.getSchoolChannels(action.payload).pipe(
          map((response: Channel[]) => {
            return new schoolActions.GetSchoolChannelsAndChangeStepSuccess(response);
          }),
          catchError(() => {
            return of(new schoolActions.GetSchoolChannelsAndChangeStepFail());
          })
        )
      )
    )
  );

  getSchoolChannelsAndNavigate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSchoolChannelsAndNavigate>(schoolActions.GET_SCHOOL_CHANNELS_AND_NAVIGATE),
      map((action: schoolActions.GetSchoolChannelsAndNavigate) => action),
      switchMap((action) =>
        this.schoolService.getSchoolChannels(action.payload.schoolId).pipe(
          map((response: Channel[]) => {
            this.store.dispatch(new schoolActions.GetSchoolChannelsAndNavigateSuccess(response));

            if (response.length <= 1) {
              return new coreActions.NavigateTo({
                page: 'Hub',
                setRoot: true
              });
            } else {
              return new schoolActions.GetSubscribedChannelsForSchool(action.payload.page);
            }
          }),
          catchError(() => {
            return of(new schoolActions.GetSchoolChannelsAndNavigateFail());
          })
        )
      )
    )
  );

  // We could be auto subscribed to channels we don't know about in core state e.g. My Channels
  // so update core state here
  GetSubscribedChannelsForSchoolSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSubscribedChannelsForSchoolSuccess>(
        schoolActions.GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL_SUCCESS
      ),
      map((action: schoolActions.GetSubscribedChannelsForSchoolSuccess) => action),
      switchMap((action) => of(new coreActions.SetSubscribedChannels({ channels: action.payload })))
    )
  );

  subscribeSchoolChannels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.SubscribeToSchoolChannels>(schoolActions.SUBSCRIBE_TO_SCHOOL_CHANNELS),
      map((action: schoolActions.SubscribeToSchoolChannels) => action),
      switchMap((action) => {
        const requests: Observable<string>[] = [];
        action.payload.forEach((element) => {
          requests.push(
            this.channelService.subscribeChannel(element).pipe(
              map((result) => result),
              catchError(() => {
                return of(null);
              })
            )
          );
        });
        return forkJoin<any>(...requests);
      }),
      switchMap(() => {
        return of(new schoolActions.SubscribeToSchoolChannelsSuccess());
      }),
      catchError(() => {
        return of(new schoolActions.SubscribeToSchoolChannelsFail());
      })
    )
  );

  LeaveSchool$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.LeaveSchool>(schoolActions.LEAVE_SCHOOL),
      withLatestFrom(this.store.select(schoolSelectors.getSchoolEntities)),
      switchMap(([action, schools]) => {
        // Leaving school feed channel removes all other channel subscriptions
        // for that school and also the school subscription
        const school = schools[action.payload.id];
        return this.channelService.leaveChannel(school.feedChannelId).pipe(
          switchMap(() => {
            return [
              new schoolActions.LeaveSchoolSuccess(),
              new coreActions.FetchUnreadItemCount(),
              new coreActions.RefreshCore({
                reason: 'leaveschool'
              })
            ];
          }),
          catchError(() => {
            return of(new schoolActions.LeaveSchoolFail({ id: action.payload.id }));
          })
        );
      })
    )
  );

  getSchoolChannels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSchoolChannels>(schoolActions.GET_SCHOOL_CHANNELS),
      map((action: schoolActions.GetSchoolChannels) => action),
      switchMap((action) =>
        this.schoolService.getSchoolChannels(action.payload).pipe(
          map((response: Channel[]) => {
            this.store.dispatch(new schoolActions.GetSubscribedChannelsForSchool());
            return new schoolActions.GetSchoolChannelsSuccess(response);
          }),
          catchError(() => {
            return of(new schoolActions.GetSchoolChannelsFail());
          })
        )
      )
    )
  );

  getSubscribedChannelsForSchool$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<schoolActions.GetSubscribedChannelsForSchool>(schoolActions.GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL),
      map((action: schoolActions.GetSubscribedChannelsForSchool) => action),
      switchMap((action) =>
        this.channelService.getSubscribedChannels().pipe(
          map((response: Channel[]) => {
            this.store.dispatch(new schoolActions.GetSubscribedChannelsForSchoolSuccess(response));

            if (action.payload) {
              this.store.dispatch(new schoolActions.GetSubscribedChannelsForSchoolSuccess(response));

              return new coreActions.NavigateTo({
                page: action.payload,
                setRoot: true
              });
            } else {
              return new schoolActions.GetSubscribedChannelsForSchoolSuccess(response);
            }
          }),
          catchError(() => {
            return of(new schoolActions.GetSubscribedChannelsForSchoolFail());
          })
        )
      )
    )
  );
}
