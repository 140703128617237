// Modules
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Publisher } from '../models/publisher.model';
import { API_VERSION, CreateIntentResponse } from './payment.service';

export interface SlipFilters {
  slipTypeFilter: string[];
  slipStatusFilter: string[];
  slipProgressFilter: string[];
}

export enum SlipStatus {
  Draft,
  Scheduled,
  Active,
  Finished
}

export interface SlipDto {
  id: number;
  category: string;
  title: string;
  description: string;
  status: SlipStatus;
  createdOn: string;
  recipientPersonId: number;
  removedOn: string;
  publisher: Publisher;
  balance: {
    due: number;
    amount: number;
  };
  milestones: MilestoneDto[];
  componentResponses: ComponentResponseDto[];
}

export interface MilestoneDto {
  id: number;
  description: string;
  dueOn: string;
  components: ComponentDto[];
}

export const ConsentV1ComponentType = 'consent';
export type Flexibility = 'required' | 'optional';
export interface ConsentV1ComponentPayload {
  quickReference: string;
  approvalWording: string;
  flexibility: Flexibility;
  declineOption: boolean;
}
export type ConsentAnswer = 'granted' | 'declined';
export interface ConsentV1ComponentResponsePayload {
  answer: ConsentAnswer;
}

export const CostV1ComponentType = 'cost';
export interface CostV1ComponentPayload {
  amount: number;
  minimumAmount: number;
}

export const QuestionsV1ComponentType = 'questions';
export type QuestionItemType = Question | Instruction;
export interface QuestionsV1ComponentPayload {
  questions: QuestionItemType[];
}

export interface QuestionsV1ComponentResponsePayload {
  answers: any[];
}

export interface Instruction {
  questionWording: string;
}

export function isQuestion(arg: any): arg is Question {
  return (
    arg && arg.flexibility && typeof arg.flexibility == 'string' && arg.answerType && typeof arg.answerType == 'string'
  );
}
export type AnswerType =
  | 'date'
  | 'short-text'
  | 'long-text'
  | 'yes-no'
  | 'email'
  | 'phone'
  | 'number'
  | 'multiple-choice';
export interface Question {
  questionWording: string;
  flexibility: Flexibility;
  answerType: AnswerType;
  options?: {
    answerOptions: string[];
    hasOther: boolean;
    isMultiSelect: boolean;
  };
}

export interface ComponentDto {
  id: number;
  type: string;
  payload: any;
}

export interface ComponentResponseDto {
  id: number;
  componentId: number;
  personId: number;
  response: any;
}

export interface SlipsResponse {
  slips: SlipDto[];
  total: number;
}
export interface SlipPaymentIntentDto {
  id: number;
  slipId: number;
  studentId: number;
  contactId: number;
  amount: number;
  intentId: string;
  createdOn: string;
  completedOn: string;
  status: string;
  tenant: number;
}

export interface ApiError {
  ErrorCode: number;
  ErrorMessage: string;
  ErrorInfo: any;
}

export class ServiceError {
  stage: number;
  httpStatus: number;
  apiError: ApiError;

  constructor(init: { stage?: number; httpStatus?: number; apiError?: ApiError }) {
    this.stage = init.stage;
    this.httpStatus = init.httpStatus;
    this.apiError = init.apiError;
  }
}
@Injectable({
  providedIn: 'root'
})
export class SlipService {
  constructor(private http: HttpClient) {}

  getSlip(schoolId: number, studentId: number, slipId: number): Observable<SlipDto> {
    console.log('[HTTP REQUEST] Load Slip', `/api/school/${schoolId}/student/${studentId}/slip/${slipId}`);

    return this.http.get<SlipDto>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/slip/${slipId}`
    );
  }

  submitComponentResponse(
    schoolId: number,
    studentId: number,
    slipId: number,
    componentId: number,
    payload: any
  ): Observable<SlipDto> {
    console.log(
      '[HTTP REQUEST] Submit Component Response',
      `/api/school/${schoolId}/student/${studentId}/slip/${slipId}/component/${componentId}`
    );

    return this.http
      .post<void>(
        environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/slip/${slipId}/component/${componentId}`,
        { payload: payload }
      )
      .pipe(
        switchMap(() => {
          return this.http
            .get<SlipDto>(environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/slip/${slipId}`)
            .pipe(
              catchError((errorResponse: HttpErrorResponse | TimeoutError) => {
                return throwError(
                  new ServiceError({
                    stage: 1,
                    httpStatus: errorResponse instanceof HttpErrorResponse ? errorResponse.status : -1,
                    apiError: errorResponse instanceof HttpErrorResponse ? errorResponse.error : null
                  })
                );
              })
            );
        }),
        catchError((error: HttpErrorResponse | TimeoutError | ServiceError) => {
          const e =
            error instanceof ServiceError
              ? error
              : new ServiceError({
                  stage: 0,
                  httpStatus: error instanceof HttpErrorResponse ? error.status : -1,
                  apiError: error instanceof HttpErrorResponse ? error.error : null
                });
          return throwError(e);
        })
      );
  }

  loadSlips(schoolId: number, studentId: number, filters: SlipFilters, sortBy: string): Observable<SlipsResponse> {
    const filterString = this.getFilterString(filters);
    console.log(
      '[HTTP REQUEST] Load Slips',
      `/api/school/${schoolId}/student/${studentId}/slip?${filterString}&sortBy=${sortBy}`
    );

    return this.http.get<SlipsResponse>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/slip?${filterString}&sortBy=${sortBy}`
    );
  }

  loadMoreSlips(
    schoolId: number,
    studentId: number,
    filters: SlipFilters,
    sortBy: string,
    skip: number,
    take: number
  ): Observable<SlipsResponse> {
    const filterString = this.getFilterString(filters);

    console.log(
      '[HTTP REQUEST] Load More Slips',
      `/api/school/${schoolId}/student/${studentId}/slip?${filterString}&sortBy=${sortBy}&skip=${skip}&take=${take}`
    );

    return this.http.get<SlipsResponse>(
      environment.apiBaseUrl +
        `/api/school/${schoolId}/student/${studentId}/slip?${filterString}&sortBy=${sortBy}&skip=${skip}&take=${take}`
    );
  }

  createRecipientBalanceIntent(
    schoolId: number,
    studentId: number,
    slipId: number,
    amount: number
  ): Observable<CreateIntentResponse> {
    const body = {
      amount,
      apiVersion: API_VERSION
    };

    return this.http.post<CreateIntentResponse>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/slip/${slipId}/balanceintent`,
      body
    );
  }

  loadRecipientBalanceIntent(
    schoolId: number,
    studentId: number,
    slipId: number,
    intentId: number
  ): Observable<SlipPaymentIntentDto> {
    console.log(
      '[HTTP REQUEST] Load Slip Payment Intent',
      `/api/school/${schoolId}/student/${studentId}/slip/${slipId}/intent/${intentId}`
    );

    return this.http.get<SlipPaymentIntentDto>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/slip/${slipId}/intent/${intentId}`
    );
  }

  // Helpers
  getFilterString(filters: SlipFilters): string {
    let filter = 'filters=';
    filters.slipStatusFilter.forEach((f) => {
      filter += `status:${f},`;
    });
    filters.slipTypeFilter.forEach((f) => {
      filter += `category:${f},`;
    });
    filters.slipProgressFilter.forEach((f) => {
      filter += `progress:${f},`;
    });
    return filter;
  }
}
