// State
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChannelState } from '../reducers/channel.reducer';

export const getChannelState = createFeatureSelector<ChannelState>('channelState');

export const getChannelLoading = createSelector(getChannelState, (state) => state.loading);

export const getChannelError = createSelector(getChannelState, (state) => state.error);

export const getChannelChannel = createSelector(getChannelState, (state) => state.channel);

export const getChannelCurrentStep = createSelector(getChannelState, (state) => state.currentStep);
