import { createActionGroup, props } from '@ngrx/store';
import { ReceiptProgress } from '../reducers/chat-message-receipts.reducer';

export const ChatMessageReceiptsActions = createActionGroup({
  source: 'Chat Message Receipts',
  events: {
    setLatestReadMessage: props<{ schoolId: number; threadId: number; messageId: number }>(),
    upsert: props<{ item: ReceiptProgress }>(),
    markMessageRead: props<{ item: ReceiptProgress }>(),
    markMessageReadSuccess: props<{ item: ReceiptProgress }>(),
    markMessageReadFail: props<{ item: ReceiptProgress; resultCode: number }>()
  }
});
