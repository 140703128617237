import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { ChatPushLoadProgressActions } from '../actions/chat-push-load-progress.action';
import { ChatThreadsActions } from '../actions/chat-threads.action';
import { getChatPushLoadProgressById } from '../selectors/chat-push-load-progress.selector';

@Injectable()
export class ChatPushLoadProgressEffects {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}

  threadsLoadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.loadSuccess),
      concatLatestFrom((a) => this.store.select(getChatPushLoadProgressById)),
      filter(([action, entity]) => !!entity),
      map(([action, entity]) => {
        return ChatPushLoadProgressActions.updateProgress({
          update: { id: action.loadRefId, changes: { loading: false, loadingError: null } }
        });
      })
    )
  );

  threadsLoadFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.loadFailure),
      concatLatestFrom((a) => this.store.select(getChatPushLoadProgressById)),
      filter(([action, entity]) => !!entity),
      map(([action, entity]) => {
        return ChatPushLoadProgressActions.updateProgress({
          update: { id: action.loadRefId, changes: { loading: false, loadingError: action.errorMsg } }
        });
      })
    )
  );
}
