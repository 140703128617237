import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';
import { PushService } from 'src/app/push/push.service';
import { BadgeActions } from '../actions/badge.action';
import { getChatsBadgeCount } from '../selectors/chat-threads.selector';
import { getItemBadgeCount } from '../selectors/core.selector';

@Injectable()
export class BadgeEffects {
  constructor(
    private pushService: PushService,
    private store: Store,
    private actions$: Actions
  ) {}

  recalculate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BadgeActions.recalculate),
        withLatestFrom(this.store.select(getItemBadgeCount)),
        withLatestFrom(this.store.select(getChatsBadgeCount)),
        tap(([[action, itemBadgeCount], chatsBadgeCount]) => {
          console.log('Item Badge count: ' + itemBadgeCount.value);
          console.log('Chats Badge count: ' + chatsBadgeCount);
          this.pushService.setBadgeCount(itemBadgeCount.value > 0 || chatsBadgeCount > 0 ? 1 : 0);
        })
      ),
    { dispatch: false }
  );

  clearBadge$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Meta] CLEAR STORE'),
        tap(() => {
          this.pushService.setBadgeCount(0);
        })
      ),
    { dispatch: false }
  );
}
