<div [ngSwitch]="entryType">
  <app-chat-timeline-info-entry *ngSwitchCase="'ChatInfo'" [entry]="entry"></app-chat-timeline-info-entry>
  <app-chat-timeline-participant-messages-entry
    *ngSwitchCase="'ParticipantMessages'"
    [entry]="entry"
    (markAsRead)="markAsRead.emit($event)"
    (messageAdded)="participantMessageAdded.emit($event)"
    (upgradeClicked)="upgradeClicked.emit()"></app-chat-timeline-participant-messages-entry>
  <app-chat-timeline-participant-change-entry
    *ngSwitchCase="'ParticipantChange'"
    [entry]="entry"></app-chat-timeline-participant-change-entry>
  <app-chat-timeline-pending-messages-entry
    *ngSwitchCase="'PendingMessages'"
    [entry]="entry"
    (retrySendMessage)="retrySendMessage.emit($event)"
    (messageAdded)="pendingMessageAdded.emit($event)"></app-chat-timeline-pending-messages-entry>
  <app-chat-timeline-unread-tidemark
    *ngSwitchCase="'UnreadTidemark'"
    [entry]="entry"></app-chat-timeline-unread-tidemark>
</div>
