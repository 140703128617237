import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const { NativeLog } = Plugins;
@Injectable()
export class LoggingService {
  private filename = `/log/${new Date().toISOString().replace(/\D/g, '')}.log`;
  private content = new Subject<string>();

  constructor(private platform: Platform) {}

  async init(): Promise<void> {
    if (!this.platform.is('hybrid')) {
      return Promise.resolve();
    }
    try {
      await Filesystem.mkdir({
        directory: Directory.Data,
        path: 'log'
      });
    } catch {
      // ignore error if directory exists
    }
    this.content.subscribe(async (item) => await this.write(item).catch());
  }

  log(...messages: Array<any>): Promise<void> {
    if (!environment.testMode) {
      return;
    }
    this.store(...messages);
  }

  private store(...messages: Array<any>): Promise<void> {
    if (!this.platform.is('hybrid')) {
      return;
    }

    const date = new Date().toISOString();

    const serialized = messages.map((message) => {
      if (typeof message === 'object') {
        try {
          return JSON.stringify(message, (key, value) => {
            // Redact sensitive information
            if (key.toLowerCase().search('password') >= 0 || key.search('token') > 0) {
              return '[redacted]';
            } else {
              return value;
            }
          });
        } catch (error) {
          /* empty */
        }
      }
      return message;
    });

    serialized.forEach((item) => this.content.next(`[${date}] ` + item + '\r\n'));
  }

  private write(data: string): Promise<void> {
    NativeLog.log({ level: 'info', message: data });

    return Filesystem.appendFile({
      directory: Directory.Data,
      encoding: Encoding.ASCII,
      path: this.filename,
      data: data
    });
  }

  async share(): Promise<void> {
    const result = await Filesystem.stat({
      directory: Directory.Data,
      path: this.filename
    });
    await Share.share({
      title: 'Subject',
      text: 'Message',
      files: [result.uri]
    });
  }
}
