// Modules
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
// Services
import { ApiService } from '../../services/api.service';
// State
import * as contactDetailsActions from '../actions/contact-details.action';

@Injectable()
export class ContactDetailsEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService
  ) {}

  getContactDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<contactDetailsActions.GetContactDetails>(contactDetailsActions.GET_CONTACT_DETAILS),
      switchMap((action) =>
        this.apiService.getContactDetails(action.payload.schoolId).pipe(
          delay(1000),
          map((result) => {
            return new contactDetailsActions.GetContactDetailsSuccess({
              contactDetails: result
            });
          }),
          catchError((error) => {
            return of(new contactDetailsActions.GetContactDetailsFail());
          })
        )
      )
    )
  );
}
