import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { effects, reducers } from './store';

@NgModule({
  imports: [StoreModule.forFeature('auth', reducers), EffectsModule.forFeature(effects), CommonModule],
  declarations: [],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {}
