import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ChatModalComponent } from 'src/app/components/chat-modal/chat-modal.component';
import { Publisher } from 'src/app/models/publisher.model';
import { ChatService } from 'src/app/services/chat.service';
import { NullAction } from '../actions';
import { ChatThreadPendingReplyActions } from '../actions/chat-thread-pending-reply.action';
import { ChatThreadActions } from '../actions/chat-thread.action';
import { ChatThreadsActions } from '../actions/chat-threads.action';
import {
  getChatPushLoadProgressAmbientSessionId,
  getChatPushLoadProgressById
} from '../selectors/chat-push-load-progress.selector';
import { getChatThread, getChatThreadId, getChatThreadState, getLoadMethod } from '../selectors/chat-thread.selector';
import { getChatThreadById, getNextLocalSequenceNumber } from '../selectors/chat-threads.selector';
@Injectable()
export class ChatThreadEffects {
  constructor(
    private chatService: ChatService,
    private store: Store,
    private actions$: Actions,
    private modalController: ModalController
  ) {}

  // We always have the thread in this case
  openFromChatsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.open),
      filter((action) => action.reason === 'chats'),
      concatLatestFrom((action) => this.store.select(getChatThreadById({ threadId: action.threadId }))),
      mergeMap(([action, thread]) => {
        return of(ChatThreadActions.primeChat({ thread: thread, reason: action.reason }));
      })
    )
  );

  // We may not have the thread in this case
  // We don't know if it falls within the current chat filter so it needs to be explicitly loaded
  // however it may also be returned in the ChatThreadsActions.load result
  openFromNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.open),
      filter((action) => action.reason !== 'chats'),
      concatLatestFrom((action) => this.store.select(getChatThreadById({ threadId: action.threadId }))),
      mergeMap(([action, thread]) => {
        return thread
          ? of(ChatThreadActions.primeChat({ thread: thread, reason: action.reason }))
          : of(ChatThreadActions.loadThread());
      })
    )
  );

  // If we've refreshed our chats (chats.reducer), check if this one is affected and update
  chatsLoadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.loadSuccess),
      concatLatestFrom((action) => this.store.select(getChatThreadId)),
      map(([action, thread]) => {
        return { action: action, thread: action.threads.find((t) => t.threadId === thread) };
      }),
      filter((data) => !!data.thread),
      mergeMap((data) => {
        return of(
          ChatThreadActions.loadedChatUpdate({
            thread: data.thread,
            reason: data.action.reason === 'push-action' ? 'notification-tap' : null
          })
        );
      })
    )
  );

  // If we've updated our chats with e.g. newly sent reply (chats.reducer), check if this one is affected and update
  // Note: This is the normal case when chat is covered by current filter
  updateThreadWhenInChatsStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.updateThread),
      concatLatestFrom((action) => this.store.select(getChatThreadId)),
      filter(([action, threadId]) => action.update.id === threadId),
      concatLatestFrom(([action, threadId]) => this.store.select(getChatThreadById({ threadId: +action.update.id }))),
      mergeMap(([[action, threadId], threadDto]) => {
        return of(ChatThreadActions.updateChat({ thread: threadDto }));
      })
    )
  );

  // If we've updated our chats with e.g. newly sent reply (chats.reducer), check if this one is affected and update
  // This is the case when the chat will not be found and returned when fetching chat updates due to the current filter
  // This only happens when the chat is shown by tapping on a notification for an item that doesn't match filter.
  updateThreadWhenNotInChatsStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadPendingReplyActions.replySuccess),
      concatLatestFrom((action) => this.store.select(getChatThreadById({ threadId: action.threadId }))),
      filter(([action, thread]) => !thread),
      withLatestFrom(this.store.select(getNextLocalSequenceNumber)),
      withLatestFrom(this.store.select(getChatThread)),
      mergeMap(([[[action, thread], nextLocalSequenceNumber], localThread]) => {
        const messages = [...localThread.messages, action.dto];
        return of(
          ChatThreadActions.updateChat({
            thread: { ...localThread, changeSequenceNumber: nextLocalSequenceNumber, messages: messages }
          })
        );
      })
    )
  );

  retryLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.retryLoad),
      withLatestFrom(this.store.select(getLoadMethod)),
      mergeMap(([action, method]) => {
        return method === 'thread'
          ? of(ChatThreadActions.loadThread())
          : of(ChatThreadsActions.load({ reason: 'notification-retry' }));
      })
    )
  );

  startMonitorNotificationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.open),
      filter((action) => ['notification-tap', 'notification-toast'].includes(action.reason)),
      concatLatestFrom((action) => this.store.select(getChatPushLoadProgressAmbientSessionId)),
      mergeMap(([action, sessionId]) => {
        return of(ChatThreadActions.monitorNotificationLoad({ id: sessionId }));
      })
    )
  );

  monitorNotificationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.monitorNotificationLoad),
      switchMap((action) =>
        this.store
          .select(getChatPushLoadProgressById({ id: action.id }))
          .pipe(
            mergeMap((progress) =>
              of(ChatThreadActions.monitorUpdate({ loading: progress.loading, loadingError: progress.loadingError }))
            )
          )
      )
    )
  );

  loadThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.loadThread),
      withLatestFrom(this.store.select(getChatThreadState)),
      mergeMap(([action, state]) => {
        return this.chatService.loadChat(state.id, state.schoolId).pipe(
          switchMap((result) => {
            return of(ChatThreadActions.loadThreadSuccess({ thread: result, reason: state.loadReason }));
          }),
          catchError((result) => of(ChatThreadActions.loadThreadFail({ reason: 'TBD' })))
        );
      })
    )
  );

  loadThreadSuccessUpdatePublisher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.loadThreadSuccess),
      mergeMap((action) => {
        const participants = action.thread.participants.filter((p) => !!p.userId);
        const uniqueParticipants = [...new Map(participants.map((p) => [p.userId, p])).values()];

        const publishers = uniqueParticipants.map(
          (uniqueParticipant) =>
            ({
              id: uniqueParticipant.userId,
              title: uniqueParticipant.title,
              forename: uniqueParticipant.forename,
              surname: uniqueParticipant.surname,
              displayNameFormat: uniqueParticipant.displayNameFormat,
              avatarUrl: uniqueParticipant.avatarUrl
            }) as Publisher
        );
        return of(ChatThreadsActions.processPublishers({ publishers: publishers }));
      })
    )
  );

  openModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.open),
      mergeMap(async (action) => {
        // We could be showing the modal as a result of a tap on
        // system tray notification or from in app notification toast
        // There may be nested modals from any tab on display so remove those first
        // to prevent them appearing after closing this one
        let topModal = await this.modalController.getTop();
        if (topModal?.component === ChatModalComponent) return new NullAction();
        while (topModal) {
          await this.modalController.dismiss();
          topModal = await this.modalController.getTop();
        }

        const modal = await this.modalController.create({
          component: ChatModalComponent,
          componentProps: {
            reason: action.reason
          },
          backdropDismiss: false
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();

        return ChatThreadActions.closed();
      })
    )
  );
}
