import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';

export const getAuthState = createSelector(fromFeature.getAuthModuleState, (state) => state.authState);

export const getAuthLoggingIn = createSelector(getAuthState, (state) => state.loggingIn);
export const getAuthValidatingToken = createSelector(getAuthState, (state) => state.validatingToken);
export const getAuthRefreshingToken = createSelector(getAuthState, (state) => state.refreshingToken);
export const getAuthErrorMessage = createSelector(getAuthState, (state) => state.errorMessage);
export const getAuthErrorTitle = createSelector(getAuthState, (state) => state.errorTitle);
export const getToken = createSelector(getAuthState, (state) => state.token);
export const getUserId = createSelector(getAuthState, (state) => state.sub);
