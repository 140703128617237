import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ChatMessageReceiptsActions } from '../actions/chat-message-receipts.action';
import { ChatThreadsActions } from '../actions/chat-threads.action';
import * as coreActions from '../actions/core.action';

// We reduce the number of message read api calls by keeping note of the latest message read
// and sending that to the server which marks that and all earlier unread messages as read
export interface ReceiptProgress {
  chatThreadId: number;
  schoolId: number;
  latestMessageReadId: number;
  failureCode: number;
  retryNext: Date;
  sent: boolean;
}

export interface State extends EntityState<ReceiptProgress> {}

export const chatMessageReceiptsAdapter: EntityAdapter<ReceiptProgress> = createEntityAdapter<ReceiptProgress>({
  selectId: (receiptProgress: ReceiptProgress) => receiptProgress.chatThreadId
});

export const initialState: State = chatMessageReceiptsAdapter.getInitialState({});

export const theReducer = createReducer(
  initialState,
  on(ChatMessageReceiptsActions.upsert, (state, action) => {
    return chatMessageReceiptsAdapter.upsertOne(action.item, state);
  }),
  on(ChatMessageReceiptsActions.markMessageReadSuccess, (state, action) => {
    return chatMessageReceiptsAdapter.updateOne({ id: action.item.chatThreadId, changes: { sent: true } }, state);
  }),
  on(ChatMessageReceiptsActions.markMessageReadFail, (state, action) => {
    return chatMessageReceiptsAdapter.updateOne(
      { id: action.item.chatThreadId, changes: { failureCode: action.resultCode } },
      state
    );
  }),
  // It is possible that we could have read a message from a newly created thread
  // but that thread has not reached the chat threads store yet, so keep the receipt
  // around until the next load items success and apply it after whereupon we can remove it
  on(ChatThreadsActions.applySentMessageReceiptChanges, (state, action) => {
    const entriesToDelete = Object.entries(state.entities)
      .filter(([k, v]) => v.sent)
      .map(([k, v]) => v.chatThreadId);
    return chatMessageReceiptsAdapter.removeMany(entriesToDelete, state);
  })
);
export function reducer(state: State | undefined, action: Action): any {
  switch (action.type) {
    case coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED:
      return initialState;
    default:
      return theReducer(state, action);
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = chatMessageReceiptsAdapter.getSelectors();
