import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { MomentModule } from 'ngx-moment';
import { InViewportModule } from 'src/external/angular-inviewport/src';
import { VirtualScrollerModule } from 'src/external/ngx-virtual-scroller/projects/ngx-virtual-scroller/src/public-api';
import { SwiperModule } from 'swiper/angular';
import { AnnouncementItemComponent } from './announcement-item/announcement-item.component';
import { AnnouncementPublisherComponent } from './announcement-publisher/announcement-publisher.component';
import { AppUpdateModalComponent } from './app-update-modal/app-update-modal.component';
import { ArticleItemComponent } from './article-item/article-item.component';
import { ArticlePublisherComponent } from './article-publisher/article-publisher.component';
import { AttachmentCollageComponent } from './attachment-collage/attachment-collage.component';
import { AttachmentImageColumnComponent } from './attachment-image-column/attachment-image-column.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { AttendanceItemComponent } from './attendance-item/attendance-item.component';
import { AttendancePublisherComponent } from './attendance-publisher/attendance-publisher.component';
import { AutoFocusDirective } from './autofocus.directive';
import { AutoscrollSelectDirective } from './autoscroll-select.directive';
import { AvatarOrInitialsComponent } from './avatar-or-initials/avatar-or-initials.component';
import { ButtonPillComponent } from './button-pill/button-pill.component';
import { CalendarLinkComponent } from './calendar-link/calendar-link.component';
import { ChannelCheckboxListComponent } from './channel-checkbox-list/channel-checkbox-list.component';
import { ChatModalOverlayComponent } from './chat-modal/chat-modal-overlay/chat-modal-overlay.component';
import { ChatModalComponent } from './chat-modal/chat-modal.component';
import { ChatTimelineEntryComponent } from './chat-modal/chat-timeline-entry/chat-timeline-entry.component';
import { ChatTimelineInfoEntryComponent } from './chat-modal/chat-timeline-entry/chat-timeline-info-entry/chat-timeline-info-entry.component';
import { ChatTimelineParticipantChangeEntryComponent } from './chat-modal/chat-timeline-entry/chat-timeline-participant-change-entry/chat-timeline-participant-change-entry.component';
import { ChatTimelineParticipantMessagesEntryComponent } from './chat-modal/chat-timeline-entry/chat-timeline-participant-messages-entry/chat-timeline-participant-messages-entry.component';
import { ChatTimelinePendingMessagesEntryComponent } from './chat-modal/chat-timeline-entry/chat-timeline-pending-messages-entry/chat-timeline-pending-messages-entry.component';
import { ChatTimelineUnreadTidemarkComponent } from './chat-modal/chat-timeline-entry/chat-timeline-unread-tidemark/chat-timeline-unread-tidemark.component';
import { CustomSpinnerComponent } from './custom-spinner/custom-spinner.component';
import { DocumentAttachmentComponent } from './document-attachment/document-attachment.component';
import { DomChangeDirective } from './dom-change.directive';
import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';
import { EditPaymentAmountModalComponent } from './edit-payment-amount-modal/edit-payment-amount-modal.component';
import { HubItemListComponent } from './hub-item-list/hub-item-list.component';
import { HubItemMessageComponent } from './hub-item-message/hub-item-message.component';
import { HubItemComponent } from './hub-item/hub-item.component';
import { InfoPillComponent } from './info-pill/info-pill.component';
import { ItemReactionComponent } from './item-reaction/item-reaction.component';
import { ItemToolbarComponent } from './item-toolbar/item-toolbar.component';
import { ListItemHeaderComponent } from './list-item-header/list-item-header.component';
import { LozengeButtonComponent } from './lozenge-button/lozenge-button.component';
import { ModalBackButtonComponent } from './modal-back-button/modal-back-button.component';
import { MonetaryValueComponent } from './monetary-value/monetary-value.component';
import { NewStudentItemComponent } from './new-student-item/new-student-item.component';
import { PageMenuButtonComponent } from './page-menu-button/page-menu-button.component';
import { PaymentItemComponent } from './payment-item/payment-item.component';
import { PaymentPublisherComponent } from './payment-publisher/payment-publisher.component';
import { PublisherAvatarComponent } from './publisher-avatar/publisher-avatar.component';
import { QuicklinkComponent } from './quicklink/quicklink.component';
import { ResizeObserverDirective } from './resize-observer.directive';
import { RoundImageClearButtonComponent } from './round-image-clear-button/round-image-clear-button.component';
import { SafariAnimateDirective } from './safari-animate.directive';
import { SchoolChannelItemListComponent } from './school-channel-item-list/school-channel-item-list.component';
import { SchoolChannelItemComponent } from './school-channel-item/school-channel-item.component';
import { SchoolDetailComponent } from './school-detail/school-detail.component';
import { SchoolLogoComponent } from './school-logo/school-logo.component';
import { SecurePipe } from './secure.pipe';
import { SetLanguageModalComponent } from './set-language-modal/set-language-modal.component';
import { SingleUnknownItemComponent } from './single-unknown-item/single-unknown-item.component';
import { SlipItemComponent } from './slip-item/slip-item.component';
import { StudentAvatarInitialsComponent } from './student-avatar-initials/student-avatar-initials.component';
import { StudentItemListComponent } from './student-item-list/student-item-list.component';
import { StudentItemComponent } from './student-item/student-item.component';
import { StudentPaymentFormNativeComponent } from './student-payment-form-native/student-payment-form-native.component';
import { StudentPaymentFormComponent } from './student-payment-form/student-payment-form.component';
import { StudentPaymentModalComponent } from './student-payment-modal/student-payment-modal.component';
import { SlipComponentHeaderComponent } from './student-slip-modal/slip-component-header/slip-component-header.component';
import { SlipComponentStatusReasonComponent } from './student-slip-modal/slip-component-status-reason/slip-component-status-reason.component';
import { SlipComponentStatusComponent } from './student-slip-modal/slip-component-status/slip-component-status.component';
import { SlipTypeComponent } from './student-slip-modal/slip-type/slip-type.component';
import { StudentSlipConsentFormComponent } from './student-slip-modal/student-slip-consent/student-slip-consent-form/student-slip-consent-form.component';
import { StudentSlipConsentInfoComponent } from './student-slip-modal/student-slip-consent/student-slip-consent-info/student-slip-consent-info.component';
import { StudentSlipConsentComponent } from './student-slip-modal/student-slip-consent/student-slip-consent.component';
import { ExpandableParaComponent } from './student-slip-modal/student-slip-details/expandable-para/expandable-para.component';
import { StudentSlipDetailsComponent } from './student-slip-modal/student-slip-details/student-slip-details.component';
import { StudentSlipLoadingComponent } from './student-slip-modal/student-slip-loading/student-slip-loading.component';
import { StudentSlipModalComponent } from './student-slip-modal/student-slip-modal.component';
import { HybridPaymentFormComponent } from './student-slip-modal/student-slip-payment/hybrid-payment-form/hybrid-payment-form.component';
import { StudentSlipPaymentInfoComponent } from './student-slip-modal/student-slip-payment/student-slip-payment-info/student-slip-payment-info.component';
import { StudentSlipPaymentComponent } from './student-slip-modal/student-slip-payment/student-slip-payment.component';
import { WebPaymentFormComponent } from './student-slip-modal/student-slip-payment/web-payment-form/web-payment-form.component';
import { StudentSlipQuestionsSummaryComponent } from './student-slip-modal/student-slip-questions-summary/student-slip-questions-summary.component';
import { SsqwCheckboxFormComponent } from './student-slip-modal/student-slip-questions-wizard/ssqw-form-slide/ssqw-checkbox-form/ssqw-checkbox-form.component';
import { SsqwFormSlideComponent } from './student-slip-modal/student-slip-questions-wizard/ssqw-form-slide/ssqw-form-slide.component';
import { SsqwInputFormComponent } from './student-slip-modal/student-slip-questions-wizard/ssqw-form-slide/ssqw-input-form/ssqw-input-form.component';
import { SsqwLongTextFormComponent } from './student-slip-modal/student-slip-questions-wizard/ssqw-form-slide/ssqw-long-text-form/ssqw-long-text-form.component';
import { SsqwRadioFormComponent } from './student-slip-modal/student-slip-questions-wizard/ssqw-form-slide/ssqw-radio-form/ssqw-radio-form.component';
import { SsqwInstructionsSlideComponent } from './student-slip-modal/student-slip-questions-wizard/ssqw-instructions-slide/ssqw-instructions-slide.component';
import { SsqwSummarySlideComponent } from './student-slip-modal/student-slip-questions-wizard/ssqw-summary-slide/ssqw-summary-slide.component';
import { StudentSlipQuestionsWizardComponent } from './student-slip-modal/student-slip-questions-wizard/student-slip-questions-wizard.component';
import { StudentSlipQuestionsComponent } from './student-slip-modal/student-slip-questions/student-slip-questions.component';
import { TimedSpinnerComponent } from './timed-spinner/timed-spinner.component';
import { UnknownItemComponent } from './unknown-item/unknown-item.component';
import { UserBadgeComponent } from './user-badge/user-badge.component';

const componentParsers: Array<HookParserEntry> = [
  {
    component: CalendarLinkComponent
  }
];
@NgModule({
  declarations: [
    SecurePipe,
    AnnouncementItemComponent,
    AnnouncementPublisherComponent,
    ArticleItemComponent,
    ArticlePublisherComponent,
    AttachmentCollageComponent,
    AttachmentImageColumnComponent,
    AttachmentsComponent,
    ButtonPillComponent,
    CalendarLinkComponent,
    ChannelCheckboxListComponent,
    CustomSpinnerComponent,
    DocumentAttachmentComponent,
    HubItemComponent,
    HubItemListComponent,
    InfoPillComponent,
    ItemToolbarComponent,
    ListItemHeaderComponent,
    LozengeButtonComponent,
    NewStudentItemComponent,
    PublisherAvatarComponent,
    RoundImageClearButtonComponent,
    SchoolChannelItemComponent,
    SchoolChannelItemListComponent,
    SchoolLogoComponent,
    StudentAvatarInitialsComponent,
    StudentItemComponent,
    StudentItemListComponent,
    SchoolDetailComponent,
    UnknownItemComponent,
    SingleUnknownItemComponent,
    AttendanceItemComponent,
    AttendancePublisherComponent,
    SafariAnimateDirective,
    HubItemMessageComponent,
    PaymentItemComponent,
    PaymentPublisherComponent,
    SlipItemComponent,
    StudentPaymentFormComponent,
    StudentPaymentFormNativeComponent,
    StudentPaymentModalComponent,
    QuicklinkComponent,
    StudentPaymentModalComponent,
    EditPaymentAmountModalComponent,
    AutoFocusDirective,
    AppUpdateModalComponent,
    StudentSlipModalComponent,
    WebPaymentFormComponent,
    HybridPaymentFormComponent,
    SetLanguageModalComponent,
    AutoscrollSelectDirective,
    MonetaryValueComponent,
    StudentSlipConsentComponent,
    SlipComponentStatusComponent,
    SlipComponentHeaderComponent,
    StudentSlipPaymentComponent,
    StudentSlipLoadingComponent,
    StudentSlipConsentFormComponent,
    StudentSlipConsentInfoComponent,
    StudentSlipPaymentInfoComponent,
    SlipComponentStatusReasonComponent,
    StudentSlipDetailsComponent,
    AvatarOrInitialsComponent,
    ExpandableParaComponent,
    SlipTypeComponent,
    ModalBackButtonComponent,
    StudentSlipQuestionsComponent,
    StudentSlipQuestionsWizardComponent,
    SsqwInstructionsSlideComponent,
    SsqwFormSlideComponent,
    SsqwSummarySlideComponent,
    StudentSlipQuestionsSummaryComponent,
    SsqwRadioFormComponent,
    SsqwCheckboxFormComponent,
    SsqwLongTextFormComponent,
    SsqwInputFormComponent,
    ItemReactionComponent,
    DynamicContentComponent,
    PageMenuButtonComponent,
    DomChangeDirective,
    ResizeObserverDirective,
    UserBadgeComponent,
    ChatModalComponent,
    ChatModalOverlayComponent,
    TimedSpinnerComponent,
    ChatTimelineEntryComponent,
    ChatTimelineInfoEntryComponent,
    ChatTimelineParticipantMessagesEntryComponent,
    ChatTimelineParticipantChangeEntryComponent,
    ChatTimelinePendingMessagesEntryComponent,
    ChatTimelineUnreadTidemarkComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MomentModule,
    DynamicHooksModule.forRoot({ globalParsers: componentParsers, globalOptions: { sanitize: false } }),
    ReactiveFormsModule,
    VirtualScrollerModule,
    InViewportModule,
    SwiperModule
  ],
  providers: [SecurePipe],
  exports: [
    AnnouncementItemComponent,
    AnnouncementPublisherComponent,
    ArticleItemComponent,
    ArticlePublisherComponent,
    AttachmentCollageComponent,
    AttachmentImageColumnComponent,
    AttachmentsComponent,
    AutoscrollSelectDirective,
    ButtonPillComponent,
    CalendarLinkComponent,
    ChannelCheckboxListComponent,
    CustomSpinnerComponent,
    DocumentAttachmentComponent,
    HubItemComponent,
    HubItemListComponent,
    InfoPillComponent,
    ItemToolbarComponent,
    ListItemHeaderComponent,
    LozengeButtonComponent,
    NewStudentItemComponent,
    PublisherAvatarComponent,
    RoundImageClearButtonComponent,
    SchoolChannelItemComponent,
    SchoolChannelItemListComponent,
    SchoolLogoComponent,
    StudentAvatarInitialsComponent,
    StudentItemComponent,
    StudentItemListComponent,
    SchoolDetailComponent,
    UnknownItemComponent,
    SingleUnknownItemComponent,
    AttendanceItemComponent,
    AttendancePublisherComponent,
    SafariAnimateDirective,
    HubItemMessageComponent,
    PaymentItemComponent,
    PaymentPublisherComponent,
    SlipItemComponent,
    StudentPaymentFormComponent,
    StudentPaymentFormNativeComponent,
    StudentPaymentModalComponent,
    QuicklinkComponent,
    AvatarOrInitialsComponent,
    SlipTypeComponent,
    MonetaryValueComponent,
    SlipComponentStatusComponent,
    ModalBackButtonComponent,
    DynamicContentComponent,
    PageMenuButtonComponent,
    DomChangeDirective,
    ResizeObserverDirective,
    AutoFocusDirective,
    UserBadgeComponent,
    TimedSpinnerComponent
  ]
})
export class ComponentsModule {}
