import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { ModalController, Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppUpdateModalComponent } from 'src/app/components/app-update-modal/app-update-modal.component';
import { DeviceService } from 'src/app/services/device.service';
import { environment } from 'src/environments/environment';
import { NullAction } from '..';
import { AppVersionApiService } from '../../services/app-version.api.service';
import * as appVersionActions from '../actions/app-version.action';
import * as appVersionSelectors from '../selectors/app-version.selector';

@Injectable()
export class AppVersionEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private appVersionApiService: AppVersionApiService,
    private deviceService: DeviceService,
    private modalController: ModalController,
    private platform: Platform
  ) {}
  coldStartCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appVersionActions.coldStartCheck),
      withLatestFrom(this.store.select(appVersionSelectors.getAvailableUpdateVersionShown)),
      withLatestFrom(this.store.select(appVersionSelectors.getLatestRelease)),
      mergeMap(([[action, availableUpdateVersion], latestRelease]) => {
        const currentVersion = this.deviceService.appVersion;
        const diff = currentVersion.localeCompare(availableUpdateVersion, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
        // Once an update has been applied clear we don't want to keep showing an update being available
        // Note: we may have skipped an update so check for current version >= last seen update
        // version (availableUpdateVersion)
        const updateApplied = diff >= 0;
        if (updateApplied) {
          return of(appVersionActions.clearAvailableUpdateVersion());
        } else {
          const minAppVersion = latestRelease?.minAppVersion ?? '';
          const forced =
            minAppVersion.localeCompare(this.deviceService.appVersion, undefined, {
              numeric: true,
              sensitivity: 'base'
            }) === 1;
          const result = [];
          // If we are starting from cold and have been notified of a forced update,
          // show the update modal
          if (forced) {
            result.push(appVersionActions.showUpdateAvailable({ version: availableUpdateVersion }));
          }
          result.push(appVersionActions.resumeCheck());
          return result;
        }
      })
    )
  );

  resumeCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appVersionActions.resumeCheck),
      withLatestFrom(this.store.select(appVersionSelectors.getLastCheck)),
      mergeMap(([action, lastCheck]) => {
        const checkPeriodMinutes = environment.testMode ? 2 : 1440;
        const checkDue = Date.now() >= lastCheck + checkPeriodMinutes * 60000;
        if (checkDue) {
          return of(appVersionActions.checkVersionApi());
        } else {
          return of(new NullAction());
        }
      })
    )
  );

  checkVersionApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appVersionActions.checkVersionApi),
      mergeMap((action) =>
        this.appVersionApiService.getAppVersion(this.platform.is('ios') ? 'ios' : 'android').pipe(
          map((result) =>
            appVersionActions.checkVersionApiSuccess({
              result: result
            })
          ),
          catchError((err: HttpErrorResponse) => {
            return of(
              appVersionActions.checkVersionApiFailure({
                message: 'check version failed'
              })
            );
          })
        )
      )
    )
  );

  checkVersionApiSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appVersionActions.checkVersionApiSuccess),
      withLatestFrom(this.store.select(appVersionSelectors.getAvailableUpdateVersionShown)),
      // Don't proceed if we've shown this version to the user before
      filter(
        ([action, availableUpdateVersion]) => action.result.appVersion.localeCompare(availableUpdateVersion) !== 0
      ),
      mergeMap(([action]) => {
        const currentVersion = this.deviceService.appVersion;
        const newVersion = action.result.appVersion;
        const diff = newVersion.localeCompare(currentVersion, undefined, { numeric: true, sensitivity: 'base' });
        if (diff === 1) {
          return of(appVersionActions.showUpdateAvailable({ version: newVersion }));
        } else {
          return of(new NullAction());
        }
      })
    )
  );

  showUpdateAvailable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appVersionActions.showUpdateAvailable),
        withLatestFrom(this.store.select(appVersionSelectors.getLatestRelease)),
        mergeMap(async ([action, latestRelease]) => {
          const minAppVersion = latestRelease.minAppVersion ?? '';
          const forced =
            minAppVersion.localeCompare(this.deviceService.appVersion, undefined, {
              numeric: true,
              sensitivity: 'base'
            }) === 1;
          const minOsVersion = latestRelease.minOsVersion ?? '';
          const currentOsVersion = this.deviceService.deviceOsVersion;
          const osSupported =
            currentOsVersion.localeCompare(minOsVersion, undefined, {
              numeric: true,
              sensitivity: 'base'
            }) >= 0;
          const modal = await this.modalController.create({
            component: AppUpdateModalComponent,
            componentProps: {
              forced: forced,
              osSupported: osSupported,
              latestRelease: latestRelease
            },
            backdropDismiss: !forced
          });
          return from(modal.present()).pipe(catchError(() => of(null)));
        })
      ),
    { dispatch: false }
  );

  visitAppStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appVersionActions.visitAppStore),
        mergeMap((action) => {
          const url = this.platform.is('ios')
            ? 'itms-apps://itunes.apple.com/app/apple-store/id1048152859?mt=8'
            : this.platform.is('android')
            ? 'market://details?id=uk.co.parenthub.parenthub'
            : null;
          return AppLauncher.openUrl({ url: url });
        })
      ),
    { dispatch: false }
  );
}
