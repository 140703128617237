import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, mergeMap, skip, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { NullAction } from 'src/app/store';
import { AuthResponse, AuthService } from '../../auth.service';
import * as authActions from '../actions';
import * as fromFeature from '../reducers';
import { getAuthRefreshingToken, getAuthState, getToken } from '../selectors';
import { Token } from '../token.model';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<fromFeature.AppState>,
    private monitoringService: MonitoringService
  ) {}

  login$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.LoginUser>(authActions.LOGIN_USER),
      switchMap((action) =>
        this.authService.login(action.payload.username, action.payload.password).pipe(
          map((response: AuthResponse) => {
            const token: Token = {
              access_token: response.access_token,
              expires_in: response.expires_in,
              refresh_token: response.refresh_token,
              token_type: response.token_type,
              localExpiry: Date.now() + response.expires_in * 1000
            };

            return new authActions.LoginUserSuccess({ token });
          }),
          catchError((error) => {
            let title: string;
            let message: string;

            switch (error.status) {
              case 0: {
                title = 'Check Internet Connection';
                message =
                  '<p>It looks like you are unable to sign in because your phone is not currently connecting ' +
                  'to the internet.</p><p>Please check your wifi and mobile data connections before trying again.</p>';
                break;
              }

              case 400: {
                title = 'Unable to sign in';
                message = `You've entered an incorrect email address or password. Please try again.`;
                break;
              }

              default: {
                title = 'Unable to Sign In';
                message = `<p>We weren't able to sign you in this time.</p><p>Please try again.</p>`;
                break;
              }
            }

            return of(new authActions.LoginUserFail({ title, error: message }));
          })
        )
      )
    )
  );

  refreshToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.RefreshToken>(authActions.REFRESH_TOKEN),
      withLatestFrom(this.store.select(getToken)),
      mergeMap(([action, token]) => {
        return this.authService.refreshToken(token.refresh_token).pipe(
          map((response: AuthResponse) => {
            const newToken: Token = {
              access_token: response.access_token,
              expires_in: response.expires_in,
              refresh_token: response.refresh_token,
              token_type: response.token_type,
              localExpiry: Date.now() + response.expires_in * 1000
            };

            return new authActions.RefreshTokenSuccess({
              token: newToken,
              reason: action.payload.reason
            });
          }),
          catchError((error) => {
            switch (error.status) {
              case 400: {
                return of(
                  new authActions.RefreshTokenFailed({
                    reason: action.payload.reason
                  })
                );
              }
              default: {
                return of(
                  new authActions.RefreshTokenUnavailable({
                    reason: action.payload.reason
                  })
                );
              }
            }
          })
        );
      })
    )
  );

  loginUserSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.LoginUserSuccess>(authActions.LOGIN_USER_SUCCESS),
      map((action: authActions.LoginUserSuccess) => {
        return new authActions.PrimeTokenRefresh();
      })
    )
  );

  loginUserSuccessSetInsightsContext$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authActions.LoginUserSuccess>(authActions.LOGIN_USER_SUCCESS),
        withLatestFrom(this.store.select(getAuthState)),
        tap(([action, state]) => {
          this.monitoringService.appInsights?.setAuthenticatedUserContext(state.sub);
        }),
        map(() => new NullAction())
      ),
    { dispatch: false }
  );

  refreshTokenSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.RefreshTokenSuccess>(authActions.REFRESH_TOKEN_SUCCESS),
      map((action: authActions.RefreshTokenSuccess) => {
        return new authActions.PrimeTokenRefresh();
      })
    )
  );

  validateTokenSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.ValidateTokenSuccess>(authActions.VALIDATE_TOKEN_SUCCESS),
      map((action: authActions.ValidateTokenSuccess) => {
        return new authActions.PrimeTokenRefresh();
      })
    )
  );

  validateTokenSuccessSetInsightsContext$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authActions.ValidateTokenSuccess>(authActions.VALIDATE_TOKEN_SUCCESS),
        withLatestFrom(this.store.select(getAuthState)),
        tap(([action, state]) => {
          this.monitoringService.appInsights?.setAuthenticatedUserContext(state.sub);
        }),
        map(() => new NullAction())
      ),
    { dispatch: false }
  );

  // Set up timer to refresh token before it expires

  primeTokenRefresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.PrimeTokenRefresh>(authActions.PRIME_TOKEN_REFRESH),
      // map((action: authActions.ValidateToken) => action),
      withLatestFrom(this.store.select(getToken)),
      switchMap(([action, token]) => {
        const interval = token.localExpiry - Date.now() - 30000;
        return timer(interval > 0 ? interval : 30000).pipe(
          takeUntil(this.actions$.pipe(ofType(authActions.CANCEL_TOKEN_REFRESH))),
          mergeMap(() => of(new authActions.QueueRefreshToken({ reason: 'refresh' })))
        );
      })
    )
  );

  // This happens as a result of being logged out
  cancelTokenRefresh$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<authActions.PrimeTokenRefresh>(authActions.CANCEL_TOKEN_REFRESH),
        tap(() => {
          this.monitoringService.appInsights.clearAuthenticatedUserContext();
        })
      ),
    { dispatch: false }
  );

  // Validate token or trigger refresh attempt

  tokenCheck$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.ValidateToken>(authActions.VALIDATE_TOKEN),
      map((action: authActions.ValidateToken) => action),
      withLatestFrom(this.store.select(getToken)),
      mergeMap(([action, token]) => {
        if (!token) {
          return of(
            new authActions.ValidateTokenFailed({
              reason: action.payload.reason
            })
          );
        }
        if (Date.now() < token.localExpiry) {
          return of(
            new authActions.ValidateTokenSuccess({
              token,
              reason: action.payload.reason
            })
          );
        }
        return of(
          new authActions.QueueRefreshToken({
            reason: action.payload.reason
          })
        );
      })
    )
  );

  queueRefreshToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<authActions.QueueRefreshToken>(authActions.QUEUE_REFRESH_TOKEN),
      map((action: authActions.QueueRefreshToken) => action),
      withLatestFrom(this.store.select(getAuthRefreshingToken)),
      mergeMap(([action, refreshing]) => {
        if (refreshing) {
          console.log('queue refresh but refresh in progress');
          return this.store.select(getAuthRefreshingToken).pipe(
            skip(1),
            take(1),
            map(() => {
              console.log('refresh complete, dispatching new refresh token request');
              return new authActions.RefreshToken({
                reason: action.payload.reason
              });
            })
          );
        }
        return of(
          new authActions.RefreshToken({
            reason: action.payload.reason
          })
        );
      })
    )
  );
}
