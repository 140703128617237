import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';

export const createTable: string = `
CREATE TABLE IF NOT EXISTS _ionickv (id INTEGER PRIMARY KEY, key unique, value);
`;

@Injectable({
  providedIn: 'root'
})
export class MigrationService {
  constructor(private databaseService: DatabaseService) {}

  async migrate(): Promise<any> {
    await this.databaseService.renameLegacyDb();
    await this.createTable();
  }

  async createTable(): Promise<any> {
    await this.databaseService.executeQuery(async (db) => {
      await db.execute(createTable);
    });
  }
}
