// Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Publisher, PublisherViewModel } from '../models/publisher.model';
import {
  PaymentStatusFilter,
  RequestStatusFilter
} from '../pages/student-payments/student-payment-list-filter-modal/student-payment-list-filter-modal.component';

export const API_VERSION = '2019-12-03';

export interface CreateIntentResponse {
  intentId: number;
  amount: number;
  intentSecretKey: string;
  ephemeralKey: string;
}

export enum PaymentStatus {
  Draft,
  Scheduled,
  Active,
  Finished
}

export interface Payment {
  id: number;
  studentId: number;
  title: string;
  description: string;
  amount: number;
  minimumAmount: number;
  balance: number;
  dueOn: Date;
  createdOn: Date;
  status: PaymentStatus;
  contact: Publisher;
  paymentBeingConfirmed: boolean;
  removedOn: Date;
}

export interface PaymentViewModel {
  id: number;
  studentId: number;
  title: string;
  description: string;
  amount: number;
  minimumAmount: number;
  balance: number;
  dueOn: Date;
  createdOn: Date;
  status: PaymentStatus;
  contact: PublisherViewModel;
  paymentBeingConfirmed: boolean;
  removedOn: Date;
}

export interface PaymentIntent {
  id: number;
  paymentId: number;
  studentId: number;
  contactId: number;
  amount: number;
  intentId: string;
  createdOn: string;
  completedOn: string;
  status: string;
  tenant: number;
}

export interface PaymentsResponse {
  payments: Payment[];
  total: number;
}

export interface EphemeralKeyResponse {
  ephemeralKey: string;
}

export interface ConfigResponse {
  stripePublishableKey: string;
  stripeTestPublishableKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private paymentSubject = new BehaviorSubject<PaymentsResponse>(null);
  public payments$: Observable<PaymentsResponse> = this.paymentSubject.asObservable();
  private paymentErrorSubject: Subject<any> = new Subject();
  public paymentError$: Observable<any> = this.paymentErrorSubject.asObservable();

  constructor(private http: HttpClient) {}

  createIntent(
    schoolId: number,
    studentId: number,
    paymentId: number,
    amount: number
  ): Observable<CreateIntentResponse> {
    const body = {
      studentId,
      paymentId,
      amount,
      apiVersion: API_VERSION
    };

    return this.http.post<CreateIntentResponse>(
      environment.apiBaseUrl + `/api/school/${schoolId}/payment/intent`,
      body
    );
  }

  loadPayment(schoolId: number, studentId: number, paymentId: number): Observable<Payment> {
    console.log('[HTTP REQUEST] Load Payment', `/api/school/${schoolId}/student/${studentId}/payment/${paymentId}`);

    return this.http.get<Payment>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/payment/${paymentId}`
    );
  }

  loadPaymentIntent(
    schoolId: number,
    studentId: number,
    paymentId: number,
    intentId: number
  ): Observable<PaymentIntent> {
    console.log(
      '[HTTP REQUEST] Load Payment Intent',
      `/api/school/${schoolId}/student/${studentId}/payment/${paymentId}/intent/${intentId}`
    );

    return this.http.get<PaymentIntent>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/payment/${paymentId}/intent/${intentId}`
    );
  }

  loadPayments(schoolId: number, studentId: number, filter: number[]): Observable<PaymentsResponse> {
    console.log(
      '[HTTP REQUEST] Load Payments',
      `/api/school/${schoolId}/student/${studentId}/payment${this.getFilterString(filter)}`
    );

    return this.http.get<PaymentsResponse>(
      environment.apiBaseUrl + `/api/school/${schoolId}/student/${studentId}/payment${this.getFilterString(filter)}`
    );
  }

  loadMorePayments(
    schoolId: number,
    studentId: number,
    filter: number[],
    skip: number,
    take: number
  ): Observable<PaymentsResponse> {
    const filterString = this.getFilterString(filter);
    const filterParam = filterString === '' ? '?' : `${filterString}&`;

    console.log(
      '[HTTP REQUEST] Load More Payments',
      `/api/school/${schoolId}/student/${studentId}/payment${filterParam}skip=${skip}&take=${take}`
    );

    return this.http.get<PaymentsResponse>(
      environment.apiBaseUrl +
        `/api/school/${schoolId}/student/${studentId}/payment${filterParam}skip=${skip}&take=${take}`
    );
  }

  getEphemeralKey(testMode: boolean = environment.testMode): Observable<EphemeralKeyResponse> {
    return this.http.get<EphemeralKeyResponse>(
      environment.apiBaseUrl + `/api/customer?apiVersion=${API_VERSION}&testmode=${testMode}`
    );
  }

  getConfig(): Observable<ConfigResponse> {
    return this.http.get<ConfigResponse>(environment.apiBaseUrl + `/api/config`);
  }

  // Helpers
  getFilterString(filterChoice: number[]): string {
    let filter = '?filters=';
    const requestStatusFilter = filterChoice[0];
    const paymentStatusFilter = filterChoice[1];

    if (requestStatusFilter !== null && paymentStatusFilter !== null) {
      switch (requestStatusFilter) {
        case RequestStatusFilter.Active:
          filter += 'request-status:active,';
          break;
        case RequestStatusFilter.Closed:
          filter += 'request-status:closed,';
          break;
        case RequestStatusFilter.All:
          filter += 'request-status:all,';
          break;
      }

      switch (paymentStatusFilter) {
        case PaymentStatusFilter.Owing:
          filter += 'payment-status:owing,';
          break;
        case PaymentStatusFilter.Paid:
          filter += 'payment-status:paid,';
          break;
        case PaymentStatusFilter.All:
          filter += 'payment-status:all,';
          break;
      }

      return filter;
    } else {
      return '';
    }
  }
}
