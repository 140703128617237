import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/chat-threads-filter-settings.reducer';

export const getChatThreadsFilterSettingsState = createFeatureSelector<State>('chatThreadsFilterSettingsState');

export const getChatThreadsFilters = createSelector(getChatThreadsFilterSettingsState, (state) => ({
  aboutFilter: state.aboutFilter,
  aboutOptions: state.aboutOptions
}));

export const getChatThreadsFiltersInitialState = createSelector(getChatThreadsFilterSettingsState, (state) => ({
  statusFilter: ['open', 'closed'],
  aboutFilter: state.aboutOptions
}));

export const getCustomFilterApplied = createSelector(
  getChatThreadsFilterSettingsState,
  getChatThreadsFiltersInitialState,
  (currentState, initialState) =>
    currentState.aboutFilter &&
    (currentState.aboutFilter.length !== initialState.aboutFilter.length ||
      currentState.aboutFilter.filter(
        (currentItem) => !initialState.aboutFilter.find((initialItem) => currentItem.studentId == initialItem.studentId)
      ).length > 0)
);

export const getChatThreadsFiltersApiExpression = createSelector(getChatThreadsFilterSettingsState, (state) => {
  let filter = '';
  if (!state.aboutFilter) return '';
  if (state.aboutFilter.length !== state.aboutOptions.length) {
    state.aboutFilter.forEach((f) => {
      filter += `about:${f.studentId},`;
    });
  }

  return filter;
});
