import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Publisher } from 'src/app/models/publisher.model';
import { ChatThreadMessageDto, ExtendedChatThreadDto } from 'src/app/services/chat.service';

export type ChatThreadLoadReason =
  | 'foreground-push'
  | 'push-action'
  | 'refresh-core'
  | 'refresh'
  | 'filter-change'
  | 'notification-retry';

export const ChatThreadsActions = createActionGroup({
  source: 'Chat Threads',
  events: {
    load: props<{ reason: ChatThreadLoadReason }>(),
    loadSuccess: props<{
      loadRefId: number;
      threads: ExtendedChatThreadDto[];
      maxChangeSequenceNumber: number;
      reason: ChatThreadLoadReason;
    }>(),
    loadFailure: props<{ loadRefId: number; reason: ChatThreadLoadReason; errorMsg: string }>(),
    loadMore: emptyProps(),
    loadMoreSuccess: props<{
      threads: ExtendedChatThreadDto[];
      hasMore: boolean;
    }>(),
    loadMoreFailure: props<{ errorMsg: string }>(),
    updateThread: props<{ update: Update<ExtendedChatThreadDto> }>(),
    updateThreads: props<{ updates: Update<ExtendedChatThreadDto>[] }>(),
    processPublishers: props<{ publishers: Publisher[] }>(),
    applySentMessageReceiptChanges: props<{ updates: Update<ExtendedChatThreadDto>[] }>(),
    removeThreads: props<{ ids: number[] }>(),

    setSearchTerm: props<{ term: string }>(),

    // A new message has been created through the chat modals
    messageCreated: props<{ threadId: number; dto: ChatThreadMessageDto }>(),
    foregroundNotificationReceived: props<{ schoolId: number; threadId: number; message: string }>(),
    showNotificationToast: props<{ schoolId: number; threadId: number; message: string }>(),
    showTappedNotification: props<{ schoolId: number; threadId: number }>()
  }
});
