import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, takeUntil, tap, timer } from 'rxjs';
import { DestroyService } from 'src/app/services/destroy.service';

@Component({
  selector: 'app-chat-modal-overlay',
  templateUrl: './chat-modal-overlay.component.html',
  styleUrls: ['./chat-modal-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService]
})
export class ChatModalOverlayComponent implements OnInit, OnChanges {
  @Input() showSpinner: boolean;
  @Input() loading: boolean;
  @Input() loadFailReason: string;
  @Input() initialScrollComplete: boolean;
  @Output() retry = new EventEmitter<void>();

  spinnerState = new BehaviorSubject<{ active: boolean }>({ active: false });
  spinnerState$ = this.spinnerState.asObservable().pipe(tap(console.log));
  recalculate = new Subject<void>();

  hidden: boolean = false;

  @HostBinding('class') get class(): string {
    return this.hidden ? 'hidden' : '';
  }

  constructor(private destroy$: DestroyService) {}

  ngOnInit(): void {
    if (this.showSpinner) {
      this.startSpinner();
    }
    combineLatest([this.recalculate, this.spinnerState$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([, spinnerState]) => {
        this.hidden = !spinnerState.active && !this.loading && !this.loadFailReason && this.initialScrollComplete;
      });
  }

  // Need to reset the timer if we've switched to different thread id
  // while the modal is showing
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    const change = changes.initialScrollComplete;
    if (change && !change.isFirstChange() && change.currentValue === false) {
      this.startSpinner();
    }
    this.recalculate.next();
  }

  onRetryLoad(): void {
    this.startSpinner();
    this.retry.emit();
  }

  private startSpinner(): void {
    console.log('Starting spinner');
    this.spinnerState.next({ active: true });
    timer(1000).subscribe((_) => this.spinnerState.next({ active: false }));
  }
}
