export enum DisplayNameFormat {
  Firstname,
  FirstnameLastname,
  TitleFirstnameLastname,
  TitleSurname,
  TitleInitialSurname,
  InitialSurname
}
export class Publisher {
  id: string;
  title: string;
  forename: string;
  surname: string;
  displayNameFormat: DisplayNameFormat;
  avatarUrl: string;
}

export const formatDisplayName = (
  title: string,
  forename: string,
  surname: string,
  format: DisplayNameFormat
): string => {
  return formatPublisherName({
    id: null,
    title: title,
    forename: forename,
    surname: surname,
    displayNameFormat: format,
    avatarUrl: null
  });
};

export const formatPublisherName = (publisher: Publisher): string => {
  let publisherTitle;

  if (publisher.title.toLowerCase() === 'blank') {
    publisherTitle = '';
  } else {
    publisherTitle = publisher.title;
  }

  switch (publisher.displayNameFormat) {
    case DisplayNameFormat.Firstname:
      return publisher.forename;
    case DisplayNameFormat.FirstnameLastname:
      return publisher.forename + ' ' + publisher.surname;
    case DisplayNameFormat.InitialSurname:
      return publisher.forename[0] + ' ' + publisher.surname;
    case DisplayNameFormat.TitleFirstnameLastname:
      return !publisherTitle
        ? '' + publisher.forename + ' ' + publisher.surname
        : publisherTitle + ' ' + publisher.forename + ' ' + publisher.surname;
    case DisplayNameFormat.TitleInitialSurname:
      return !publisherTitle
        ? '' + publisher.forename + ' ' + publisher.surname
        : publisherTitle + ' ' + publisher.forename[0] + ' ' + publisher.surname;
    case DisplayNameFormat.TitleSurname:
      return !publisherTitle
        ? '' + publisher.forename + ' ' + publisher.surname
        : publisherTitle + ' ' + publisher.surname;
    default:
      return publisher.forename + ' ' + publisher.surname;
  }
};

export class PublisherAvatar {
  retry: boolean;
  id: string;
  avatarBase64: string;
}

export class PublisherViewModel {
  constructor(
    public id: string,
    public displayName: string,
    public forename: string,
    public surname: string,
    public avatarBase64: string,
    public avatarUrl: string
  ) {}
}
