import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../actions/item-reactions.action';

export interface ItemReaction {
  id: number;
  reactionId: string;
  inProgress: boolean;
}
export type State = EntityState<ItemReaction>;
export const itemReactionsAdapter: EntityAdapter<ItemReaction> = createEntityAdapter<ItemReaction>();

export const initialState: State = itemReactionsAdapter.getInitialState();

export const theReducer = createReducer(
  initialState,
  on(actions.reactToItem, (state, action) => {
    if (state.entities[action.id]) {
      return itemReactionsAdapter.updateOne(
        { id: action.id, changes: { inProgress: true, reactionId: action.reactionId } },
        state
      );
    } else {
      return itemReactionsAdapter.addOne({ id: action.id, inProgress: true, reactionId: action.reactionId }, state);
    }
  }),
  on(actions.reactToItemSuccess, (state, action) => {
    return itemReactionsAdapter.removeOne(action.id, state);
  }),
  on(actions.reactToItemFail, (state, action) => {
    return itemReactionsAdapter.removeOne(action.id, state);
  })
);
export function reducer(state: State | undefined, action: Action): any {
  return theReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = itemReactionsAdapter.getSelectors();
