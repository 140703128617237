export class StudentTimetable {
  studentId: number;
  generatedOn: Date;
  days: TimetableDay[];
}

export class TimetableDay {
  day: Date;
  periods: TimetablePeriod[];
}

export class TimetablePeriod {
  start: string;
  end: string;
  teacherName: string;
  className: string;
  subject: string;
  roomNumber: string;
  periodNumber: string;
}

export class TimetablePeriodViewModel {
  start: string;
  end: string;
  teacherName: string;
  className: string;
  subject: string;
}

export class TimetableViewModel {
  initialSlide: number;
  days: {
    dayParts: string[];
    periods: {
      start: string;
      end: string;
      teacherName: string;
      className: string;
      subject: string;
    }[];
  }[];
}
