import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfileViewModel } from '../../models/profile.model';
import { UserInfoViewModel } from '../../models/user.model';
import { AccountState } from '../reducers/account.reducer';

export const getAccountState = createFeatureSelector<AccountState>('accountState');

export const getProfileViewModel = createSelector(
  getAccountState,
  (state) => new ProfileViewModel(state.forename, state.surname)
);

export const getUserInfoViewModel = createSelector(
  getAccountState,
  (state) => new UserInfoViewModel(state.email, state.emailVerified)
);

export const getLoadingAccount = createSelector(
  getAccountState,
  (state) => state.loadingProfile || state.loadingUserInfo
);

export const getLoadingAccountFailed = createSelector(
  getAccountState,
  (state) => state.loadingProfileFailed || state.loadingUserInfoFailed
);
