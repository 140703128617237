import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ChatInfo,
  ParticipantChange,
  ParticipantMessages,
  PendingMessages,
  TimeLineEntry,
  UnreadTidemark
} from '../chat-modal.store';

@Component({
  selector: 'app-chat-timeline-entry',
  templateUrl: './chat-timeline-entry.component.html',
  styleUrls: ['./chat-timeline-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTimelineEntryComponent {
  @Input() entry: TimeLineEntry;
  @Output() markAsRead = new EventEmitter<number>();
  @Output() retrySendMessage = new EventEmitter<number>();
  @Output() participantMessageAdded = new EventEmitter<string>();
  @Output() pendingMessageAdded = new EventEmitter<string>();
  @Output() upgradeClicked = new EventEmitter<void>();

  get entryType(): string {
    if (this.entry instanceof ChatInfo) return 'ChatInfo';
    if (this.entry instanceof ParticipantMessages) return 'ParticipantMessages';
    if (this.entry instanceof PendingMessages) return 'PendingMessages';
    if (this.entry instanceof ParticipantChange) return 'ParticipantChange';
    if (this.entry instanceof UnreadTidemark) return 'UnreadTidemark';
    return 'unknown';
  }
}
