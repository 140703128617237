import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatThreadDto } from 'src/app/services/chat.service';

export const ChatThreadActions = createActionGroup({
  source: 'Chat Thread',
  events: {
    open: props<{ threadId: number; schoolId?: number; reason: 'chats' | 'notification-tap' | 'notification-toast' }>(),
    closed: emptyProps(),
    primeChat: props<{ thread: ChatThreadDto; reason: 'chats' | 'notification-tap' | 'notification-toast' }>(),
    loadedChatUpdate: props<{ thread: ChatThreadDto; reason: 'chats' | 'notification-tap' | 'notification-toast' }>(),
    updateChat: props<{ thread: ChatThreadDto }>(),
    monitorNotificationLoad: props<{ id: number }>(),
    monitorUpdate: props<{ loading: boolean; loadingError: string }>(),
    loadThread: emptyProps(),
    loadThreadSuccess: props<{ thread: ChatThreadDto; reason: 'chats' | 'notification-tap' | 'notification-toast' }>(),
    loadThreadFail: props<{ reason: string }>(),
    retryLoad: emptyProps()
  }
});
