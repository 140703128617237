// State
import { ContactDetails } from '../../models/contact-details.model';
import * as fromContactDetails from '../actions/contact-details.action';

export interface ContactDetailsState {
  loading: boolean;
  loadingFailed: boolean;
  details: ContactDetails;
}

export const initialState: ContactDetailsState = {
  loading: false,
  loadingFailed: false,
  details: null
};

export function reducer(state = initialState, action: fromContactDetails.ContactDetailsAction): ContactDetailsState {
  switch (action.type) {
    case fromContactDetails.GET_CONTACT_DETAILS: {
      return {
        ...state,
        loading: true,
        loadingFailed: false,
        details: null
      };
    }
    case fromContactDetails.GET_CONTACT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingFailed: false,
        details: action.payload.contactDetails
      };
    }
    case fromContactDetails.GET_CONTACT_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        loadingFailed: true
      };
    }
    default: {
      return state;
    }
  }
}
