import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, TimeoutError } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { SetLanguageModalComponent } from 'src/app/components/set-language-modal/set-language-modal.component';
import { applyTimer } from 'src/app/services/timer';
import { TranslationApiService } from 'src/app/services/translation.api.service';
import { getItemEntities, NullAction, selectLanguageEntities } from '..';
import * as coreActions from '../actions/core.action';
import * as actions from '../actions/item-translations.action';
import { State } from '../reducers/payment-intent.reducer';
import { selectItemTranslationEntities } from '../selectors/item-translation.selector';
import { getLanguageId } from '../selectors/settings.selector';

@Injectable()
export class ItemTranslationEffects {
  constructor(
    private translationService: TranslationApiService,
    private modalController: ModalController,
    private toastController: ToastController,
    private store: Store<State>,
    private actions$: Actions
  ) {}

  // Decide whether to show the initial language selection modal before translating
  // Also detemine whether we need to retranslate (based on new language setting) or
  // show the existing translation
  prepareTranslateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.prepareTranslateItem),
      withLatestFrom(this.store.select(selectItemTranslationEntities)),
      withLatestFrom(this.store.select(getLanguageId)),
      withLatestFrom(this.store.select(selectLanguageEntities)),
      mergeMap(([[[action, translations], languageId], languages]) => {
        if (languageId) {
          if (languageId === translations[action.id]?.language) {
            return of(
              actions.updateItemTranslation({
                update: {
                  id: action.id,
                  changes: { showingTranslation: true, direction: languages[languageId]?.dir ?? 'ltr' }
                }
              })
            );
          } else {
            return of(actions.translateItem({ id: action.id }));
          }
        } else {
          return from(this.openSetLanguageModal()).pipe(
            map((result) => {
              return result ? actions.translateItem({ id: action.id }) : new NullAction();
            })
          );
        }
      })
    )
  );

  translateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.translateItem),
      withLatestFrom(this.store.select(getItemEntities)),
      withLatestFrom(this.store.select(getLanguageId)),
      withLatestFrom(this.store.select(selectLanguageEntities)),
      mergeMap(([[[action, items], languageId], languages]) => {
        const item = items[action.id];
        const api = !item.channelId
          ? this.translationService.translateAnnouncement(item.schoolId, item.id, item.studentId, languageId)
          : this.translationService.translateArticle(item.id, languageId);
        return applyTimer(api).pipe(
          map((result) => {
            return result instanceof TimeoutError
              ? actions.translateItemFail({ id: action.id, error: 'unknown' })
              : actions.translateItemSuccess({
                  id: action.id,
                  result: result,
                  direction: languages[languageId]?.dir ?? 'ltr'
                });
          }),
          catchError((err) => {
            const error = err.status === 0 ? 'offline' : err.error?.ErrorCode === 2001 ? 'not-supported' : 'unknown';
            return of(actions.translateItemFail({ id: action.id, error: error }));
          })
        );
      })
    )
  );

  openSetLanguageModal = async (): Promise<boolean> => {
    const modal = await this.modalController.create({
      component: SetLanguageModalComponent
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  };

  // Remove any translation when their corresponding item is removed
  coreDeleteITems$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.DeleteItems>(coreActions.DELETE_ITEMS),
      mergeMap((action) => {
        return of(actions.deleteItemTranslations({ ids: action.payload.ids }));
      })
    )
  );

  loadItemsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.translateItemFail),
        tap((action) => {
          const errors: { [id: string]: { message: string; class: string } } = {
            offline: {
              message: 'Unable to translate this time. Please check your internet connection and try again later.',
              class: 'noInternetToast'
            },
            'not-supported': {
              message:
                'Your selected language is no longer supported. Please choose a new language in Settings and try again.',
              class: 'noInternetToast'
            },
            unknown: { message: 'Unable to translate this time. Please try again later.', class: 'noInternetToast' }
          };

          const toast = this.toastController.create({
            message: errors[action.error].message,
            duration: 2000,
            position: 'top',
            cssClass: errors[action.error].class
          });

          toast.then((t) => t.present());
        })
      ),
    { dispatch: false }
  );
}
