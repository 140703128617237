import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  expand,
  filter,
  map,
  mergeMap,
  reduce,
  takeWhile,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Publisher } from 'src/app/models/publisher.model';
import { ChatService, ChatsResponse, ExtendedChatThreadDto } from 'src/app/services/chat.service';
import { applyTimer } from 'src/app/services/timer';
import { BadgeActions } from '../actions/badge.action';
import { ChatMessageReceiptsActions } from '../actions/chat-message-receipts.action';
import { ChatThreadActions } from '../actions/chat-thread.action';
import { ChatThreadsFilterSettingsActions } from '../actions/chat-threads-filter-settings.action';
import { ChatThreadsActions } from '../actions/chat-threads.action';
import * as coreActions from '../actions/core.action';
import * as schoolActions from '../actions/school.action';
import { getPendingReadReceipts } from '../selectors/chat-message-receipts.selector';
import { getChatPushLoadProgressAmbientSessionId } from '../selectors/chat-push-load-progress.selector';
import { getChatThreadId } from '../selectors/chat-thread.selector';
import { getChatThreadsFiltersApiExpression } from '../selectors/chat-threads-filter-settings.selector';
import {
  getChatThreadById,
  getChatThreadsArray,
  getMaxChangeSequenceNumber,
  getMinOrderSequenceNumber,
  getNextLocalSequenceNumber,
  getSearchTerm,
  getSortedChatThreadLatestMessagesArray
} from '../selectors/chat-threads.selector';
import * as fromSettings from '../selectors/settings.selector';

@Injectable()
export class ChatThreadsEffects {
  constructor(
    private chatService: ChatService,
    private store: Store,
    private actions$: Actions,
    private toastController: ToastController,
    private router: Router
  ) {}

  loadOnRefreshCoreSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RefreshCoreSuccess>(
        coreActions.REFRESH_CORE_SUCCESS,
        // This action triggers reducers to reset to initial state so we can load
        // fresh data
        coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED,
        // Load chats on completing school verification
        schoolActions.CONFIRM_DOB_SCHOOL_SUCCESS
      ),
      map((action) => {
        return ChatThreadsActions.load({ reason: 'refresh-core' });
      })
    )
  );

  refreshCoreFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.RefreshCoreFail>(coreActions.REFRESH_CORE_FAIL),
      delay(1000),
      withLatestFrom(this.store.select(getChatPushLoadProgressAmbientSessionId)),
      map(([action, pushSessionId]) => {
        return ChatThreadsActions.loadFailure({ loadRefId: pushSessionId, reason: 'refresh-core', errorMsg: 'tbd' });
      })
    )
  );

  loadChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.load),
      withLatestFrom(this.store.select(getMaxChangeSequenceNumber)),
      withLatestFrom(this.store.select(getMinOrderSequenceNumber)),
      withLatestFrom(this.store.select(getChatThreadsFiltersApiExpression)),
      withLatestFrom(this.store.select(getChatPushLoadProgressAmbientSessionId)),
      mergeMap(([[[[action, maxChangeSequenceNumber], minOrderSequenceNumber], filterExpression], pushSessionId]) => {
        let workingMaxChangeSequenceNumber = maxChangeSequenceNumber;
        const apiCall = (seq: number): Observable<ChatsResponse> =>
          this.chatService.loadChats(seq, null, null, filterExpression);
        return applyTimer(
          apiCall(workingMaxChangeSequenceNumber).pipe(
            expand(() => apiCall(workingMaxChangeSequenceNumber)),
            takeWhile((response) => workingMaxChangeSequenceNumber && response.hasMore, true),
            tap((response) => {
              workingMaxChangeSequenceNumber =
                response.chatThreads.length > 0
                  ? Math.max(...response.chatThreads.map((ar) => ar.changeSequenceNumber))
                  : maxChangeSequenceNumber;
            }),
            reduce(
              (acc, response) =>
                (acc = {
                  ...acc,
                  chatThreads: [...acc.chatThreads, ...response.chatThreads]
                })
            ),
            map((result) => {
              const qualifyingResultThreads = result.chatThreads.filter(
                // We may get updates from items we haven't got loaded
                (ct) => ct.orderSequenceNumber >= (minOrderSequenceNumber ?? 0)
              );
              const newMaxChangeSequenceNumber =
                qualifyingResultThreads.length > 0
                  ? Math.max(...qualifyingResultThreads.map((ar) => ar.changeSequenceNumber))
                  : maxChangeSequenceNumber;
              return ChatThreadsActions.loadSuccess({
                loadRefId: pushSessionId,
                threads: qualifyingResultThreads,
                maxChangeSequenceNumber: newMaxChangeSequenceNumber,
                reason: action.reason
              });
            }),
            catchError((result) =>
              of(ChatThreadsActions.loadFailure({ loadRefId: pushSessionId, reason: action.reason, errorMsg: 'tbd' }))
            )
          )
        );
      })
    )
  );

  addPublishersAfterLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.loadSuccess, ChatThreadsActions.loadMoreSuccess),
      mergeMap((action) => {
        const participants = action.threads.flatMap((ct) => ct.participants.filter((p) => !!p.userId));
        const uniqueParticipants = [...new Map(participants.map((p) => [p.userId, p])).values()];

        const publishers = uniqueParticipants.map(
          (uniqueParticipant) =>
            ({
              id: uniqueParticipant.userId,
              title: uniqueParticipant.title,
              forename: uniqueParticipant.forename,
              surname: uniqueParticipant.surname,
              displayNameFormat: uniqueParticipant.displayNameFormat,
              avatarUrl: uniqueParticipant.avatarUrl
            }) as Publisher
        );
        return of(ChatThreadsActions.processPublishers({ publishers: publishers }));
      })
    )
  );

  processPublishersAddCore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.processPublishers),
      map((action) => {
        return new coreActions.AddPublishers({ publishers: action.publishers });
      })
    )
  );

  processPublishersUpdateThreads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.processPublishers),
      withLatestFrom(this.store.select(getChatThreadsArray)),
      mergeMap(([action, threads]) => {
        const updates = threads.map((thread) => {
          const updatedParticipants = thread.participants.map((participant) => {
            const publisherUpdate = action.publishers.find((p) => p.id === participant.userId);
            if (publisherUpdate) {
              return {
                ...participant,
                forename: publisherUpdate.forename,
                surname: publisherUpdate.surname,
                title: publisherUpdate.title,
                displayNameFormat: publisherUpdate.displayNameFormat,
                avatarUrl: publisherUpdate.avatarUrl
              };
            } else {
              return participant;
            }
          });
          return { id: thread.threadId, changes: { participants: updatedParticipants } };
        });
        return of(ChatThreadsActions.updateThreads({ updates: updates }));
      })
    )
  );

  purgeChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType<coreActions.PurgeItems>(coreActions.PURGE_ITEMS),
      withLatestFrom(this.store.select(getSortedChatThreadLatestMessagesArray)),
      withLatestFrom(this.store.select(fromSettings.getRetentionThresholdDate)),
      mergeMap(([[action, chatThreadLatestMessages], retentionDate]) => {
        // Retain top 10
        chatThreadLatestMessages.splice(0, 10);
        const itemsToRemove = chatThreadLatestMessages.filter(
          (item) => Date.parse(item.latestMessageCreatedOn) < retentionDate.getTime()
        );
        return of(ChatThreadsActions.removeThreads({ ids: itemsToRemove.map((i) => i.threadId) }));
      })
    )
  );

  messageCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.messageCreated),
      concatLatestFrom((action) => this.store.select(getChatThreadById({ threadId: action.threadId }))),
      filter(([action, thread]) => !!thread),
      withLatestFrom(this.store.select(getNextLocalSequenceNumber)),
      mergeMap(([[action, thread], nextLocalSequenceNumber]) => {
        const messages = [...thread.messages, action.dto];
        return of(
          ChatThreadsActions.updateThread({
            update: {
              id: thread.threadId,
              changes: { messages: messages, localSequenceNumber: nextLocalSequenceNumber }
            }
          })
        );
      })
    )
  );

  updateFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsFilterSettingsActions.updateFilters),
      map((v) => {
        return ChatThreadsActions.load({ reason: 'filter-change' });
      })
    )
  );

  loadMore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.loadMore),
      withLatestFrom(this.store.select(getMinOrderSequenceNumber)),
      withLatestFrom(this.store.select(getSearchTerm)),
      withLatestFrom(this.store.select(getChatThreadsFiltersApiExpression)),
      mergeMap(([[[action, minOrderSequenceNumber], searchTerm], filterExpression]) => {
        return this.chatService.loadChats(null, minOrderSequenceNumber, searchTerm, filterExpression).pipe(
          map((result) => {
            return ChatThreadsActions.loadMoreSuccess({
              threads: result.chatThreads,
              hasMore: result.hasMore
            });
          }),
          catchError((result) => of(ChatThreadsActions.loadMoreFailure({ errorMsg: 'tbd' })))
        );
      })
    )
  );

  foregroundNotificationReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.foregroundNotificationReceived),
      concatLatestFrom((action) => this.store.select(getChatThreadById({ threadId: action.threadId }))),
      withLatestFrom(this.store.select(getChatThreadId)),
      filter(([[action, thread], threadId]) => {
        // Only notify if we are not on the chats tab or
        // we do not viewing the chat overlay (for notified thread)
        // we don't have the thread in state
        const currentPath = this.router.url;
        if (!currentPath.startsWith('/app/tabs/chats')) return true;
        if (threadId > 0 && threadId !== action.threadId) return true;
        if (!thread) return true;
        return false;
      }),
      mergeMap(([[action, thread], threadId]) => {
        return of(
          ChatThreadsActions.showNotificationToast({
            schoolId: action.schoolId,
            threadId: action.threadId,
            message: action.message
          })
        );
      })
    )
  );

  showNotificationToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatThreadsActions.showNotificationToast),
        mergeMap(async (action) => {
          const toast = await this.toastController.create({
            message: action.message,
            cssClass: 'chat-notification-toast',
            duration: 4000,
            position: 'top',
            buttons: [
              {
                text: 'SHOW',
                handler: () => {
                  this.store.dispatch(
                    ChatThreadActions.open({
                      threadId: action.threadId,
                      schoolId: action.schoolId,
                      reason: 'notification-toast'
                    })
                  );
                  this.store.dispatch(
                    new coreActions.NavigateTo({
                      page: 'Chats',
                      setRoot: true
                    })
                  );
                }
              },
              { text: 'CLEAR', role: 'cancel' }
            ]
          });
          await toast.present();
        })
      ),
    { dispatch: false }
  );

  showTappedNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatThreadsActions.showTappedNotification),
        map((action) => {
          this.store.dispatch(
            ChatThreadActions.open({ threadId: action.threadId, schoolId: action.schoolId, reason: 'notification-tap' })
          );
          this.store.dispatch(
            new coreActions.NavigateTo({
              page: 'Chats',
              setRoot: true
            })
          );
        })
      ),
    { dispatch: false }
  );

  clearSearchOnOpenThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadActions.open),
      filter((action) => action.reason !== 'chats'),
      map(() => {
        return ChatThreadsActions.setSearchTerm({ term: null });
      })
    )
  );

  badgeRecalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.loadSuccess, ChatThreadsActions.loadMoreSuccess),
      map(() => {
        return BadgeActions.recalculate();
      })
    )
  );

  // A thread message (and earlier messages) has been marked as read so update
  // our stored copy of that thread until we get the next update from API
  updateDueToLatestThreadMessageRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessageReceiptsActions.upsert),
      concatLatestFrom((action) => this.store.select(getChatThreadById({ threadId: action.item.chatThreadId }))),
      filter(([action, threadDto]) => !!threadDto),
      mergeMap(([action, threadDto]) => {
        const myParticipant = threadDto.participants.find((p) => p.isMe);
        const clonedMessages = JSON.parse(JSON.stringify(threadDto.messages));
        clonedMessages.forEach((m) => {
          if (
            m.messageId <= action.item.latestMessageReadId &&
            m.createdByParticipantId !== myParticipant?.participantId &&
            !m.deletedOn
          ) {
            m.reactions.push({
              participantId: myParticipant?.participantId,
              reactionId: null,
              reactionOn: null,
              readOn: 'now'
            });
          }
        });
        return of(
          ChatThreadsActions.updateThread({ update: { id: threadDto.threadId, changes: { messages: clonedMessages } } })
        );
      })
    )
  );

  mergeSentMessageReceipts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadsActions.loadSuccess),
      concatLatestFrom((action) => this.store.select(getPendingReadReceipts)),
      mergeMap(([action, pendingReceipts]) => {
        const updates: Update<ExtendedChatThreadDto>[] = [];
        for (const x of pendingReceipts) {
          const thread = action.threads.find((t) => t.threadId === x.chatThreadId);
          if (thread) {
            const myParticipant = thread.participants.find((p) => p.isMe);
            const clonedMessages = JSON.parse(JSON.stringify(thread.messages));
            clonedMessages.forEach((m) => {
              if (
                m.messageId <= x.latestMessageReadId &&
                m.createdByParticipantId !== myParticipant?.participantId &&
                !m.deletedOn
              ) {
                m.reactions.push({
                  participantId: myParticipant?.participantId,
                  reactionId: null,
                  reactionOn: null,
                  readOn: 'ksnow'
                });
              }
            });
            updates.push({ id: thread.threadId, changes: { messages: clonedMessages } });
          }
        }

        return of(ChatThreadsActions.applySentMessageReceiptChanges({ updates: updates }));
      })
    )
  );
}
