import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[appResizeObserver]'
})
export class ResizeObserverDirective implements OnDestroy {
  private observer: ResizeObserver;

  @Output()
  public appResizeObserver = new EventEmitter<ResizeObserverEntry>();

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;

    this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      entries.forEach((mutation: ResizeObserverEntry) => this.appResizeObserver.emit(mutation));
    });

    this.observer.observe(element);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
