/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatThreadCompatibilityLevel } from 'src/app/services/chat.service';
import { State, selectAll } from '../reducers/chat-threads.reducer';

export const getChatThreadsState = createFeatureSelector<State>('chatThreadsState');

export const getChatThreadsArray = createSelector(getChatThreadsState, (state) => selectAll(state));
export const getSortedChatThreadLatestMessagesArray = createSelector(getChatThreadsArray, (state) =>
  state
    .map((t) => {
      const sortedMessages = [...t.messages].sort((a, b) => Date.parse(b.createdOn) - Date.parse(a.createdOn));
      return {
        threadId: t.threadId,
        latestMessageCreatedOn: sortedMessages[0].createdOn
      };
    })
    .sort((a, b) => Date.parse(b.latestMessageCreatedOn) - Date.parse(a.latestMessageCreatedOn))
);

export const getChatThreadsLoading = createSelector(getChatThreadsState, (state) => state.loading);

export const getChatThreadsPageState = createSelector(getChatThreadsState, (state) => ({
  primed: state.primed,
  loading: state.loading,
  loadingReason: state.loadingReason,
  loadingError: state.loadingError,
  loadingMore: state.loadingMore,
  canLoadMore: state.canLoadMore,
  searchTerm: state.searchTerm,
  canSearchMore: state.canSearchMore,
  chats: selectAll(state).filter(
    (c) =>
      state.searchTerm == null ||
      (state.searchTerm.length > 0 &&
        (c.chat.title.toLocaleLowerCase().includes(state.searchTerm.toLocaleLowerCase()) ||
          c.messages.find(
            (m) => m.content && m.content.toLocaleLowerCase().includes(state.searchTerm.toLocaleLowerCase())
          )))
  )
}));

export const getSearchTerm = createSelector(getChatThreadsState, (state) => {
  return state.searchTerm;
});

export const getMaxChangeSequenceNumber = createSelector(getChatThreadsState, (state) => {
  return state.maxChangeSequenceNumber;
});

export const getNextLocalSequenceNumber = createSelector(getChatThreadsState, (state) => {
  const ids = Object.values(state.entities)
    .filter((e) => e.localSequenceNumber >= 0)
    .map((e) => e.localSequenceNumber);
  return ids.length > 0 ? Math.max(...ids) + 1 : 1;
});

export const getMinOrderSequenceNumber = createSelector(getChatThreadsState, (state) => {
  const ids = Object.values(state.entities).map((e) => e.orderSequenceNumber);
  return ids.length > 0 ? Math.min(...ids) : null;
});

export const getChatThreadById = (props: { threadId: number }) =>
  createSelector(getChatThreadsState, (state) => {
    return state.entities[props.threadId];
  });

export const getChatThreadForMessageId = (props: { chatMessageId: number }) =>
  createSelector(getChatThreadsState, (state) => {
    return Object.values(state.entities).find((e) => e.messages.find((m) => m.messageId === props.chatMessageId));
  });

export const getChatsBadgeCount = createSelector(getChatThreadsArray, (state) =>
  state.reduce((partialSum, item) => {
    if (item.isSearchResult) return partialSum;
    // Exclude unsupported threads from badge count
    if (item.compatibilityLevel > ChatThreadCompatibilityLevel) return partialSum;
    const myParticipant = item.participants.find((p) => p.isMe);
    if (!myParticipant) return partialSum;
    const unreadByMe =
      partialSum +
      item.messages.filter(
        (m) =>
          !m.deletedOn &&
          m.createdByParticipantId !== myParticipant.participantId &&
          !m.reactions.find((r) => r.participantId === myParticipant.participantId)
      ).length;
    return unreadByMe;
  }, 0)
);
