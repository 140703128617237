// State
import * as fromAccount from '../actions/account.action';

export interface AccountState {
  loadingProfile: boolean;
  loadingUserInfo: boolean;
  loadingProfileFailed: boolean;
  loadingUserInfoFailed: boolean;
  forename: string;
  surname: string;
  email: string;
  emailVerified: boolean;
}

export const initialState: AccountState = {
  loadingProfile: false,
  loadingUserInfo: false,
  loadingProfileFailed: false,
  loadingUserInfoFailed: false,
  forename: null,
  surname: null,
  email: null,
  emailVerified: false
};

export function reducer(state = initialState, action: fromAccount.AccountAction): AccountState {
  switch (action.type) {
    case fromAccount.GET_PROFILE: {
      return {
        ...state,
        loadingProfile: true,
        loadingProfileFailed: false,
        forename: null,
        surname: null
      };
    }
    case fromAccount.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        loadingProfile: false,
        loadingProfileFailed: false,
        forename: action.payload.forename,
        surname: action.payload.surname
      };
    }
    case fromAccount.GET_PROFILE_FAIL: {
      return {
        ...state,
        loadingProfile: false,
        loadingProfileFailed: true
      };
    }
    case fromAccount.GET_USER_INFO: {
      return {
        ...state,
        loadingUserInfo: true,
        loadingUserInfoFailed: false,
        email: null,
        emailVerified: false
      };
    }
    case fromAccount.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        loadingUserInfo: false,
        loadingUserInfoFailed: false,
        email: action.payload.email,
        emailVerified: action.payload.emailVerified
      };
    }
    case fromAccount.GET_USER_INFO_FAIL: {
      return {
        ...state,
        loadingUserInfo: false,
        loadingUserInfoFailed: true
      };
    }
    default: {
      return state;
    }
  }
}
