import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Plugins } from '@capacitor/core';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { Badge } from '@capawesome/capacitor-badge';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromStore from '../store';
import * as pushActions from './store/actions';
const { PushNotificationsEx } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class PushService {
  private registered = false;
  constructor(
    private http: HttpClient,
    private store: Store<fromStore.AppState>,
    private platform: Platform
  ) {}

  public hasPush(): boolean {
    const available = this.platform.is('hybrid');
    if (!available) {
      console.log('No push on this platform ', this.platform);
    }
    return available;
  }

  registerPlatform(): void {
    if (!this.hasPush()) {
      return;
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      // The unregister call actually causes a reregistration event but we
      // want to ignore until we have called registerPlatform again (i.e. logged in again)
      if (this.registered) return;

      this.registered = true;

      const registration = {
        registrationId: token.value,
        registrationType: this.platform.is('android') ? 'fcm' : 'apns'
      };
      const version = '2';
      this.store.dispatch(new pushActions.DeviceRegistered({ registration, version }));
      console.log('Device registered', registration);

      PushNotifications.createChannel({
        id: 'IncomingMessage',
        name: 'Incoming message',
        description: 'Incoming message alerts',
        importance: 3
      }).then(
        () => {
          console.log('created Push Channel');
        },
        (e) => {
          console.log('error creating Push Channel', e);
        }
      );
      PushNotifications.removeAllDeliveredNotifications();
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration:', error);
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      const state = await App.getState();
      this.store.dispatch(new pushActions.PushReceived({ notification: notification, isForeground: state.isActive }));
      console.log('Received a notification', notification);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      console.log('Push action performed:', action);
      this.store.dispatch(new pushActions.PushActionPerformed({ action: action }));
    });

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
  }

  // We unregister on logout and remove listeners to prevent us showing any future
  // delivered notifications
  unregisterPlatform(): void {
    if (!this.hasPush()) {
      return;
    }
    PushNotifications.removeAllListeners();
    PushNotificationsEx.unRegister().then(
      () => {
        console.log('unregisterPlatform success');
        this.registered = false;
      },
      () => {
        console.log('unregisterPlatform error');
      }
    );
  }

  getInstallationId(pushChannel: string, version: string): Observable<string> {
    const url =
      environment.apiBaseUrl +
      '/api/deviceinstallationid?pushChannel=' +
      encodeURIComponent(pushChannel) +
      '&version=' +
      version;
    return this.http.get<string>(url);
  }
  deleteInstallation(installationId: string): Observable<string> {
    const url = environment.apiBaseUrl + '/api/deviceinstallation?id=' + encodeURIComponent(installationId);
    return this.http.delete(url, { responseType: 'text' });
  }
  registerInstallation(
    installationId: string,
    pushChannel: string,
    platform: string,
    tags: string[],
    version: string
  ): Observable<string> {
    const body = {
      installationId,
      pushChannel,
      platform,
      tags
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put(
      environment.apiBaseUrl + '/api/deviceinstallation?version=' + version,
      JSON.stringify(body),
      // https://github.com/angular/angular/issues/18680
      { headers, responseType: 'text' }
    );
  }

  setBadgeCount(count: number): void {
    if (!this.registered) return;

    Badge.set({ count: count });
    console.log(`badge set to: ${count}`);
  }

  pushEnabled(): Promise<{ isEnabled: boolean }> {
    if (!this.hasPush()) {
      return Promise.resolve({ isEnabled: false });
    }
    return PushNotifications.checkPermissions().then((result) => {
      return { isEnabled: result.receive === 'granted' };
    });
  }
}
