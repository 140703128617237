// State
import { Action } from '@ngrx/store';
// Models
import { Token } from '../token.model';

export const LOGIN_USER = '[Auth] Login User';
export const LOGIN_USER_SUCCESS = '[Auth] Login User Success';
export const LOGIN_USER_FAIL = '[Auth] Login User Fail';

export const INITIALISE = '[Token] Initialise';
export const VALIDATE_TOKEN = '[Token] Validate Token';
export const VALIDATE_TOKEN_SUCCESS = '[Token] Validate Token Success';
export const VALIDATE_TOKEN_FAILED = '[Token] Validate Token Failed';
export const QUEUE_REFRESH_TOKEN = '[Token] Queue Refresh Token';
export const REFRESH_TOKEN = '[Token] Refresh Token';
export const REFRESH_TOKEN_SUCCESS = '[Token] Refresh Token Success';
export const REFRESH_TOKEN_FAILED = '[Token] Refresh Token Failed';
export const REFRESH_TOKEN_UNAVAILABLE = '[Token] Refresh Token Unavailable';
export const PRIME_TOKEN_REFRESH = '[Token] Prime Token Refresh';
export const CANCEL_TOKEN_REFRESH = '[Token] Cancel Token Refresh';

export class Initialise implements Action {
  readonly type = INITIALISE;
}
export class LoginUser implements Action {
  readonly type = LOGIN_USER;
  constructor(public payload: { username: string; password: string }) {}
}
export class LoginUserSuccess implements Action {
  readonly type = LOGIN_USER_SUCCESS;
  constructor(public payload: { token: Token }) {}
}
export class LoginUserFail implements Action {
  readonly type = LOGIN_USER_FAIL;
  constructor(public payload: { title: string; error: string }) {}
}
export class ValidateToken implements Action {
  readonly type = VALIDATE_TOKEN;
  constructor(public payload: { reason: string }) {}
}
export class ValidateTokenSuccess implements Action {
  readonly type = VALIDATE_TOKEN_SUCCESS;
  constructor(public payload: { token: Token; reason: string }) {}
}
export class ValidateTokenFailed implements Action {
  readonly type = VALIDATE_TOKEN_FAILED;
  constructor(public payload: { reason: string }) {}
}
export class QueueRefreshToken implements Action {
  readonly type = QUEUE_REFRESH_TOKEN;
  constructor(public payload: { reason: string }) {}
}
export class RefreshToken implements Action {
  readonly type = REFRESH_TOKEN;
  constructor(public payload: { reason: string }) {}
}
export class RefreshTokenSuccess implements Action {
  readonly type = REFRESH_TOKEN_SUCCESS;
  constructor(public payload: { token: Token; reason: string }) {}
}
export class RefreshTokenFailed implements Action {
  readonly type = REFRESH_TOKEN_FAILED;
  constructor(public payload: { reason: string }) {}
}
export class RefreshTokenUnavailable implements Action {
  readonly type = REFRESH_TOKEN_UNAVAILABLE;
  constructor(public payload: { reason: string }) {}
}
export class PrimeTokenRefresh implements Action {
  readonly type = PRIME_TOKEN_REFRESH;
}
export class CancelTokenRefresh implements Action {
  readonly type = CANCEL_TOKEN_REFRESH;
}
export type AuthAction =
  | Initialise
  | LoginUser
  | LoginUserSuccess
  | LoginUserFail
  | ValidateToken
  | ValidateTokenSuccess
  | ValidateTokenFailed
  | QueueRefreshToken
  | RefreshToken
  | RefreshTokenSuccess
  | RefreshTokenFailed
  | RefreshTokenUnavailable
  | PrimeTokenRefresh
  | CancelTokenRefresh;
