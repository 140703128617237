// Modules
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
// Services
import { ApiService } from '../../services/api.service';
import { NullAction } from '../actions';
// State
import * as studentsActions from '../actions/students.action';
import * as coreSelectors from '../selectors/core.selector';
import * as studentSelectors from '../selectors/students.selector';

@Injectable()
export class StudentsEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>
  ) {}

  getAttendanceSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<studentsActions.GetAttendanceSummary>(studentsActions.GET_ATTENDANCE_SUMMARY),
      withLatestFrom(this.store.select(coreSelectors.getAllSchools)),
      switchMap(([action, schools]) => {
        const school = schools.find((s) => s.students.indexOf(action.payload.studentId) >= 0);
        return this.apiService.getAttendanceSummary(school.id, action.payload.studentId).pipe(
          map((result) => {
            return new studentsActions.GetAttendanceSummarySuccess({
              studentId: action.payload.studentId,
              schoolId: action.payload.schoolId,
              summary: result
            });
          }),
          catchError((error) => {
            return of(
              new studentsActions.GetAttendanceSummaryFail({
                studentId: action.payload.studentId,
                schoolId: action.payload.schoolId
              })
            );
          })
        );
      })
    )
  );

  getAttendanceSessions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<studentsActions.GetAttendanceSessions>(studentsActions.GET_ATTENDANCE_SESSIONS),
      withLatestFrom(this.store.select(coreSelectors.getAllSchools)),
      mergeMap(([action, schools]) => {
        const school = schools.find((s) => s.students.indexOf(action.payload.studentId) >= 0);
        const toDate = moment();
        const fromDate = toDate.clone().subtract(14, 'days');
        return this.apiService
          .getSessionAttendance(
            school.id,
            action.payload.studentId,
            encodeURIComponent(fromDate.format()),
            encodeURIComponent(toDate.format()),
            null
          )
          .pipe(
            map((result) => {
              return new studentsActions.GetAttendanceSessionsSuccess({
                studentId: action.payload.studentId,
                schoolId: action.payload.schoolId,
                sessions: result
              });
            }),
            catchError((error) => {
              return of(
                new studentsActions.GetAttendanceSessionsFail({
                  studentId: action.payload.studentId,
                  schoolId: action.payload.schoolId
                })
              );
            })
          );
      })
    )
  );

  getUnauthorizedAbsences$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<studentsActions.GetUnauthorizedAbsences>(studentsActions.GET_UNAUTHORIZED_ABSENCES),
      withLatestFrom(this.store.select(coreSelectors.getAllSchools)),
      switchMap(([action, schools]) => {
        const school = schools.find((s) => s.students.indexOf(action.payload.studentId) >= 0);
        const refDate = moment('2018-08-25');
        const toDate = moment();
        let fromDate: moment.Moment;
        if (toDate.dayOfYear > refDate.dayOfYear) {
          fromDate = refDate;
        } else {
          fromDate = refDate.subtract(1, 'years');
        }
        return this.apiService
          .getSessionAttendance(
            school.id,
            action.payload.studentId,
            fromDate.format('YYYY-MM-DD'),
            toDate.format('YYYY-MM-DD'),
            'N,G,O,U'
          )
          .pipe(
            map((result) => {
              return new studentsActions.GetUnauthorizedAbsencesSuccess({
                studentId: action.payload.studentId,
                schoolId: action.payload.schoolId,
                sessions: result
              });
            }),
            catchError((error) => {
              return of(
                new studentsActions.GetUnauthorizedAbsencesFail({
                  studentId: action.payload.studentId,
                  schoolId: action.payload.schoolId
                })
              );
            })
          );
      })
    )
  );

  refreshTimetable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<studentsActions.RefreshTimetable>(studentsActions.REFRESH_TIMETABLE),
      withLatestFrom(this.store.select(studentSelectors.getTimetableEntities)),
      switchMap(([action, timetables]) => {
        const timetable = timetables[action.payload.studentId];
        const now = moment();
        if (!timetable || now.diff(moment(timetable.generatedOn), 'hours') > 24) {
          return of(new studentsActions.GetTimetable(action.payload));
        } else {
          return of(new NullAction());
        }
      })
    )
  );

  getTimetable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<studentsActions.GetTimetable>(studentsActions.GET_TIMETABLE),
      switchMap((action) => {
        return this.apiService.getStudentTimetable(action.payload.schoolId, action.payload.studentId).pipe(
          map((result) => {
            return new studentsActions.GetTimetableSuccess({
              studentId: action.payload.studentId,
              schoolId: action.payload.schoolId,
              timetable: result
            });
          }),
          catchError((error) => {
            return of(
              new studentsActions.GetTimetableFail({
                studentId: action.payload.studentId,
                schoolId: action.payload.schoolId
              })
            );
          })
        );
      })
    )
  );

  submitAbsenceReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<studentsActions.ReportAbsence>(studentsActions.REPORT_ABSENCE),
      mergeMap((action) => {
        return this.apiService
          .submitAbsenceReport(
            action.payload.schoolId,
            action.payload.studentId,
            action.payload.date,
            action.payload.session,
            action.payload.reason
          )
          .pipe(
            map(() => new studentsActions.ReportAbsenceSuccess()),
            catchError((error) => {
              return of(
                new studentsActions.ReportAbsenceFail({
                  schoolId: action.payload.schoolId,
                  studentId: action.payload.studentId,
                  reason: error.status === 400 && error.error && error.error.ErrorCode === 2000 ? 'exists' : 'unknown'
                })
              );
            })
          );
      })
    )
  );
}
