import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { Publisher } from 'src/app/models/publisher.model';
import { getPublisherAvatarById, getPublisherById } from 'src/app/store';

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserBadgeComponent implements OnInit {
  @Input() userId: string;

  initials: string;
  avatarBase64: string;

  constructor(private store: Store) {}
  ngOnInit(): void {
    this.store
      .select(getPublisherById({ id: this.userId }))
      .pipe(take(1))
      .subscribe((publisher) => {
        this.initials = this.extractInitials(publisher);
        if (!publisher?.avatarUrl) return;
        this.store
          .select(getPublisherAvatarById({ id: publisher.avatarUrl }))
          .pipe(take(1))
          .subscribe((avatar) => {
            this.avatarBase64 = avatar.avatarBase64;
          });
      });
  }

  extractInitials(publisher: Publisher): string {
    if (publisher) {
      return publisher.forename.charAt(0).toUpperCase() + publisher.surname.charAt(0).toUpperCase();
    } else {
      return 'U';
    }
  }
}
