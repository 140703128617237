import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { SlipDto } from 'src/app/services/slip.service';
import { SlipPaymentIntentTracking } from '../reducers/slip-payment-intent.reducer';

export const startIntentProcessing = createAction('[Slip Payment Intent] Start Intent Processing');
export const addIntent = createAction(
  '[Slip Payment Intent] Add Intent',
  props<{
    intent: SlipPaymentIntentTracking;
  }>()
);
export const updateIntent = createAction(
  '[Slip Payment Intent] Update Intent',
  props<{
    update: Update<SlipPaymentIntentTracking>;
  }>()
);
export const deleteIntent = createAction(
  '[Slip Payment Intent] Delete Intent',
  props<{
    id: number;
  }>()
);
export const checkIntent = createAction(
  '[Slip Payment Intent] Check Intent',
  props<{
    intent: SlipPaymentIntentTracking;
  }>()
);
export const checkIntentSuccess = createAction(
  '[Slip Payment Intent] Check Intent Success',
  props<{
    id: number;
    amount: number;
    slip: SlipDto;
  }>()
);
export const checkIntentFail = createAction(
  '[Slip Payment Intent] Check Intent Fail',
  props<{
    id: number;
    code: number;
    reason: string;
  }>()
);
