import { combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Apply a timer to an observable such that the outer observable does not
// complete or error until the time and action both complete or error
// Note: default behaviour of combineLatest will complete as soon as an
//       error occurs
export function applyTimer<T>(action: Observable<T>, timeout?: number): Observable<T> {
  return combineLatest([
    timer(timeout ?? 1000),
    action.pipe(
      catchError((err) => {
        return of({ error: true, payload: err });
      })
    )
  ]).pipe(
    map(([_, result]) => {
      const resultAsError = result as { error: boolean; payload: any };
      if (resultAsError && 'error' in resultAsError) {
        throw resultAsError.payload;
      }
      return result as T;
    })
  );
}
