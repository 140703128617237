// State
import { Action } from '@ngrx/store';

export const CREATE_ACCOUNT = '[Onboarding] Create Account';
export const CREATE_ACCOUNT_SUCCESS = '[Onboarding] Create Account Success';
export const CREATE_ACCOUNT_FAIL = '[Onboarding] Create Account Fail';
export const CREATE_PROFILE = '[Onboarding] Create Profile';
export const CREATE_PROFILE_SUCCESS = '[Onboarding] Create Profile Success';
export const CREATE_PROFILE_FAIL = '[Onboarding] Create Profile Fail';
export const NEW_ACCOUNT_LOGIN = '[Onboarding] New Account Login';
export const CHECK_EMAIL = '[Onboarding] Check Email';
export const CHECK_EMAIL_SUCCESS = '[Onboarding] Check Email Success';
export const CHECK_EMAIL_FAIL = '[Onboarding] Check Email Fail';

export class CreateAccount implements Action {
  readonly type = CREATE_ACCOUNT;

  constructor(public payload: string) {}
}

export class CreateAccountSuccess implements Action {
  readonly type = CREATE_ACCOUNT_SUCCESS;

  constructor(public payload: { username: string; password: string }) {}
}

export class CreateAccountFail implements Action {
  readonly type = CREATE_ACCOUNT_FAIL;

  constructor(public payload: string) {}
}

export class CreateProfile implements Action {
  readonly type = CREATE_PROFILE;

  constructor(public payload: { firstname: string; lastname: string }) {}
}

export class CreateProfileSuccess implements Action {
  readonly type = CREATE_PROFILE_SUCCESS;
}

export class CreateProfileFail implements Action {
  readonly type = CREATE_PROFILE_FAIL;
}

export class NewAccountLogin implements Action {
  readonly type = NEW_ACCOUNT_LOGIN;
}

export class CheckEmail implements Action {
  readonly type = CHECK_EMAIL;

  constructor(public payload: string) {}
}

export class CheckEmailSuccess implements Action {
  readonly type = CHECK_EMAIL_SUCCESS;
}

export class CheckEmailFail implements Action {
  readonly type = CHECK_EMAIL_FAIL;

  constructor(public payload: string) {}
}

export type OnboardingAction =
  | CreateAccount
  | CreateAccountSuccess
  | CreateAccountFail
  | CreateProfile
  | CreateProfileSuccess
  | CreateProfileFail
  | NewAccountLogin
  | CheckEmail
  | CheckEmailSuccess
  | CheckEmailFail;
