import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, interval, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ChatService } from 'src/app/services/chat.service';
import { BadgeActions } from '../actions/badge.action';
import { ChatMessageReceiptsActions } from '../actions/chat-message-receipts.action';
import { getById, getNextReceipt } from '../selectors/chat-message-receipts.selector';

@Injectable()
export class ChatMessageReceiptsEffects {
  constructor(
    private chatService: ChatService,
    private store: Store,
    private actions$: Actions
  ) {}

  startProcessing$ = createEffect(() =>
    interval(10000).pipe(
      map(() => new Date()),
      concatLatestFrom((date) => this.store.select(getNextReceipt({ refDate: date }))),
      filter(([, receipt]) => !!receipt),
      map(([, receipt]) => ChatMessageReceiptsActions.markMessageRead({ item: receipt }))
    )
  );

  setLatestReadMessage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessageReceiptsActions.setLatestReadMessage),
      concatLatestFrom((action) => this.store.select(getById({ id: action.threadId }))),
      filter(([action, receipt]) => !receipt || action.messageId > receipt.latestMessageReadId),
      mergeMap(([action, receipt]) => {
        // Note: upsert also causes a temporary (until next load new) update to the thread
        //       in the chat-threads store
        return of(
          ChatMessageReceiptsActions.upsert({
            item: {
              chatThreadId: action.threadId,
              schoolId: action.schoolId,
              latestMessageReadId: action.messageId,
              failureCode: null,
              // We may get multiple setLatestMessage actions when scrolling through
              // a thread so hold off sending for a short time for a better chance of
              // only sending latest
              retryNext: new Date(new Date().getTime() + 1000 * 2),
              sent: false
            }
          })
        );
      })
    )
  );

  markMessageRead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessageReceiptsActions.markMessageRead),
      mergeMap((action) => {
        return this.chatService
          .putChatThreadLatestMessageRead(
            action.item.schoolId,
            action.item.chatThreadId,
            action.item.latestMessageReadId
          )
          .pipe(
            map((result) => ChatMessageReceiptsActions.markMessageReadSuccess({ item: action.item })),
            catchError((result) =>
              of(ChatMessageReceiptsActions.markMessageReadFail({ item: action.item, resultCode: result.status }))
            )
          );
      })
    )
  );

  // After processing a receipt, kick off a check for another pending one
  markMessageReadSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessageReceiptsActions.markMessageReadSuccess),
      concatLatestFrom((action) => this.store.select(getNextReceipt({ refDate: new Date() }))),
      filter(([action, receipt]) => !!receipt),
      mergeMap(([, receipt]) => of(ChatMessageReceiptsActions.markMessageRead({ item: receipt })))
    )
  );

  badgeRecalculation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatMessageReceiptsActions.markMessageReadSuccess),
      map(() => {
        return BadgeActions.recalculate();
      })
    )
  );
}
