// Modules
import { Injectable } from '@angular/core';
import { Share, ShareResult } from '@capacitor/share';
import { Observable, empty, from as fromPromise } from 'rxjs';
import { Attachment } from '../models/item.model';
import { AttachmentService } from './attachment.service';
@Injectable({
  providedIn: 'root'
})
export class SharingService {
  constructor(public attachmentService: AttachmentService) {}

  async shareAttachment(attachment: Attachment): Promise<Observable<ShareResult>> {
    const canShare = await Share.canShare();
    if (!canShare.value) {
      return empty();
    }
    if (!attachment.fullUrl && !attachment.thumbUrl) {
      return empty();
    }
    return fromPromise(
      this.attachmentService.getFileUrl(attachment).then((dataUrl) =>
        Share.share({
          title: attachment.name,
          files: [dataUrl]
        })
      )
    );
  }
  async shareItem(title: string, message: string, attachments: Attachment[]): Promise<Observable<ShareResult>> {
    const canShare = await Share.canShare();
    if (!canShare.value) {
      return empty();
    }
    const promises = [];
    attachments.forEach((attachment) => {
      if (attachment.fullUrl || attachment.thumbUrl) {
        promises.push(this.attachmentService.getFileUrl(attachment));
      }
    });

    return fromPromise(
      Promise.all(promises).then((attachmentDataURls) =>
        Share.share({
          title: title,
          text: message,
          files: attachmentDataURls
        })
      )
    );
  }
}
