import { createActionGroup, props } from '@ngrx/store';
import { ChatThreadReply } from '../reducers/chat-thread-reply-cache.reducer';

// Used for caching chat reply content
export const ChatThreadReplyCacheActions = createActionGroup({
  source: 'Chat Thread Reply Cache',
  events: {
    replyChanged: props<{ chatThreadReply: ChatThreadReply }>(),
    upsert: props<{ chatThreadReply: ChatThreadReply }>(),
    delete: props<{ chatThreadId }>()
  }
});
