import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as hydratedActions from '../actions/hydrated.action';
import * as actions from '../actions/item-translations.action';

export interface ItemTranslation {
  id: number;
  language: string;
  title: string;
  direction: string;
  message: string;
  inProgress: boolean;
  showingTranslation: boolean;
  error: string;
}
export const defaultItemTranslation = {
  id: null,
  language: null,
  title: null,
  direction: null,
  message: null,
  inProgress: true,
  showingTranslation: false,
  error: null
};
export type State = EntityState<ItemTranslation>;
export const itemTranslationAdapter: EntityAdapter<ItemTranslation> = createEntityAdapter<ItemTranslation>();

export const initialState: State = itemTranslationAdapter.getInitialState();

export const theReducer = createReducer(
  initialState,
  // we could be restarted while translation in progress so make sure to initialise after hydration
  on(hydratedActions.appHydrated, (state, action) => {
    const updates = Object.entries(state.entities)
      .filter(([key, value]) => value.inProgress)
      .map(([key, value]) => ({ id: key, changes: { inProgress: false } }));
    return itemTranslationAdapter.updateMany(updates, state);
  }),
  on(actions.addItemTranslation, (state, action) => itemTranslationAdapter.addOne(action.itemTranslation, state)),
  on(actions.addItemTranslation, (state, action) => itemTranslationAdapter.addOne(action.itemTranslation, state)),
  on(actions.updateItemTranslation, (state, action) => itemTranslationAdapter.updateOne(action.update, state)),
  on(actions.deleteItemTranslations, (state, action) => itemTranslationAdapter.removeMany(action.ids, state)),
  on(actions.showOriginalItem, (state, action) =>
    itemTranslationAdapter.updateOne({ id: action.id, changes: { showingTranslation: false, direction: 'ltr' } }, state)
  ),
  on(actions.translateItem, (state, action) => {
    if (state.entities[action.id]) {
      return itemTranslationAdapter.updateOne({ id: action.id, changes: { inProgress: true } }, state);
    } else {
      return itemTranslationAdapter.addOne({ ...defaultItemTranslation, id: action.id, inProgress: true }, state);
    }
  }),
  on(actions.translateItemSuccess, (state, action) =>
    itemTranslationAdapter.updateOne(
      {
        id: action.id,
        changes: {
          inProgress: false,
          showingTranslation: true,
          title: action.result.title,
          message: action.result.message,
          direction: action.direction,
          language: action.result.language,
          error: null
        }
      },
      state
    )
  ),
  on(actions.translateItemFail, (state, action) =>
    itemTranslationAdapter.updateOne({ id: action.id, changes: { inProgress: false } }, state)
  )
);
export function reducer(state: State | undefined, action: Action): any {
  return theReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = itemTranslationAdapter.getSelectors();
