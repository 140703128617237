import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as hydratedActions from '../actions/hydrated.action';
import * as actions from '../actions/languages.action';

export interface SupportedLanguage {
  id: string;
  name: string;
  nativeName: string;
  dir: string;
}
export interface State extends EntityState<SupportedLanguage> {
  loading: boolean;
  lastLoaded: number;
}
export const languagesAdapter: EntityAdapter<SupportedLanguage> = createEntityAdapter<SupportedLanguage>({
  sortComparer: (a, b) => {
    return a.name.localeCompare(b.name);
  }
});
export const initialState: State = languagesAdapter.getInitialState({
  loading: false,
  lastLoaded: 0
});

export const theReducer = createReducer(
  initialState,
  // we could be restarted while loading in progress so make sure to initialise after hydration
  on(hydratedActions.appHydrated, (state, action) => {
    return { ...state, loading: false };
  }),
  on(actions.load, (state) => ({
    ...state,
    loading: true
  })),
  on(actions.loadSuccess, (state, action) =>
    languagesAdapter.upsertMany(action.result, { ...state, loading: false, lastLoaded: Date.now() })
  ),
  on(actions.loadFail, (state) => ({ ...state, loading: false }))
);
export function reducer(state: State | undefined, action: Action): any {
  return theReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = languagesAdapter.getSelectors();
