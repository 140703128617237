import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { InitialPage } from './initial.page';

const routes: Routes = [
  {
    path: '',
    component: InitialPage
  }
];

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule.forChild(routes)],
  declarations: [InitialPage]
})
export class InitialPageModule {}
