<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button ionBackButton (click)="onDismiss()">
        <ion-icon src="/assets/icon/back-icon-dark.svg"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title> Select Language </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item
    lines="none"
    *ngIf="{
      languageId: languageId$ | async,
      supportedLanguages: supportedLanguages$ | async,
      loading: loading$ | async
    } as vm">
    <div class="spinner-container" *ngIf="vm.loading === true; else loaded">
      <ion-spinner name="crescent"></ion-spinner>
    </div>
    <ng-template #loaded>
      <ion-text *ngIf="vm.supportedLanguages.length === 0">Couldn't load languages, please try again later.</ion-text>
      <ion-radio-group *ngIf="vm.supportedLanguages.length > 0" #languageRadioGroup value="vm.languageId">
        <ion-item *ngFor="let language of vm.supportedLanguages">
          <ion-label>{{ language.name }}</ion-label>
          <ion-radio slot="end" [value]="language.id"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ng-template>
  </ion-item>
</ion-content>
<ion-footer mode="ios">
  <ion-button mode="md" [disabled]="!itemSelected" class="submitButton" color="secondary" (click)="onOk()"
    >Apply
  </ion-button>
</ion-footer>
