<div class="messages-container pending">
  <div class="messages" (appDomChange)="onDomChange($event)">
    <ng-container *ngFor="let message of pendingMessages.messages; index as i">
      <div [id]="'msg-' + message.id" class="message-bubble me" (click)="retrySendMessage.emit(message.id)">
        <div class="message-content">
          <div *ngIf="i === 0" class="author">{{ pendingMessages.name }}</div>
          <div class="box">
            <div class="inner-box">
              <app-dynamic-content class="chat-message" [content]="message.content"></app-dynamic-content>
              <div *ngIf="message.status === 'in-progress'" class="in-progress">
                <ion-spinner></ion-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="message.canRetry" class="error">Unable to send. Please tap the message to try again.</div>
    </ng-container>
  </div>
</div>
