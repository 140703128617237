import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ParticipantMessages, TimeLineEntry } from '../../chat-modal.store';

@Component({
  selector: 'app-chat-timeline-participant-messages-entry',
  templateUrl: './chat-timeline-participant-messages-entry.component.html',
  styleUrls: ['./chat-timeline-participant-messages-entry.component.scss', '../chat-timeline-entry-bubble.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTimelineParticipantMessagesEntryComponent {
  @Input() entry: TimeLineEntry;
  @Output() markAsRead = new EventEmitter<number>();
  @Output() messageAdded = new EventEmitter<string>();
  @Output() upgradeClicked = new EventEmitter<void>();
  root: any;
  constructor(element: ElementRef) {
    this.root = element.nativeElement.closest('ion-content');
  }

  get participantMessages(): ParticipantMessages {
    return this.entry as ParticipantMessages;
  }

  get inViewportOptions(): any {
    return {
      root: this.root,
      threshold: 0.2
    };
  }

  onDomChange(event: MutationRecord): void {
    event.addedNodes.forEach((node) => {
      const id = (node as HTMLElement)?.id;
      if (id?.startsWith('msg-')) {
        this.messageAdded.emit(id);
      }
    });
  }

  onInViewPortChange(notMine: boolean, messageId: number, unread: boolean, removed: boolean, value: boolean): void {
    if (notMine && unread && !removed && value) this.markAsRead.emit(messageId);
  }
}
