/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Dictionary } from '@ngrx/entity/src/models';
import {
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  defaultMemoize,
  MemoizedSelector
} from '@ngrx/store';
import * as chrono from 'chrono-node';
import linkifyHtml from 'linkify-html';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AttendanceSessions } from '../../models/attendance.model';
import { Channel, ChannelCheckboxViewModel, MyChannelsViewModel } from '../../models/channel.model';
import {
  AnnouncementItemViewModel,
  Attachment,
  AttachmentsViewModel,
  AttendanceItemViewModel,
  ChannelArticleItemViewModel,
  Item,
  ItemViewModel,
  PaymentItemViewModel,
  SlipItemViewModel,
  UnknownItemViewModel
} from '../../models/item.model';
import { formatPublisherName, Publisher, PublisherAvatar, PublisherViewModel } from '../../models/publisher.model';
import {
  School,
  SchoolAsset,
  SchoolAttendanceViewModel,
  SchoolViewModel,
  SchoolWebLinkViewModel
} from '../../models/school.model';
import { Student, StudentItemViewModel } from '../../models/student.model';
import { AppState } from '../reducers';
import * as fromCore from '../reducers/core.reducer';
import { ItemPageLoadingInfo } from '../reducers/core.reducer';
import { ItemReaction } from '../reducers/item-reactions.reducer';
import { ItemTranslation } from '../reducers/item-translations.reducer';
import * as fromSchool from '../selectors/school.selector';
import { selectItemReactionsEntities } from './item-reactions.selector';
import { selectItemTranslationEntities } from './item-translation.selector';
import { getAttandanceMark, getAttendanceSessionsEntities } from './students.selector';

export const getCoreState = createFeatureSelector<fromCore.CoreState>('coreState');

export const getCoreFirstTimeOpening = createSelector(getCoreState, (state) => state.firstTimeOpening);

export const getCoreLoading = createSelector(getCoreState, (state) => state.loading);

export const getCoreLoadingFailed = createSelector(getCoreState, (state) => state.loadingFailed);

export const getCoreRefreshCoreUnderway = createSelector(getCoreState, (state) => state.refreshCoreUnderway);

export const getColdStartTab = createSelector(getCoreState, (state) => state.coldStartTab);

export const getCoreInitialLoadingActive = createSelector(getCoreState, (state) => state.initialLoadingActive);

export const getCoreSchools = createSelector(getCoreState, (state) => state.schools);

export const getCoreChannels = createSelector(getCoreState, (state) => state.channels);

export const getItemEntitiesState = createSelector(getCoreState, (state) => state.items);

export const getAllPageStateEntities = createSelector(getItemEntitiesState, (state) => state.pageState.entities);

export const getCurrentPageState = createSelector(
  getItemEntitiesState,
  (state) => state.pageState.entities[state.currentPageId]
);
export const getPageState = (pageId: string): MemoizedSelector<AppState, ItemPageLoadingInfo> =>
  createSelector(getItemEntitiesState, (state) => state.pageState.entities[pageId]);
export const {
  selectIds: getItemIds,
  selectEntities: getItemEntities,
  selectAll: getItems,
  selectTotal: getTotalItems
} = fromCore.itemAdapter.getSelectors(getItemEntitiesState);

export const getItem = (id: number): MemoizedSelector<AppState, Item> =>
  createSelector(getItemEntitiesState, (state) => state.entities[id]);

export const getChannelEntitiesState = createSelector(getCoreState, (state) => state.channels);

export const {
  selectIds: getChannelIds,
  selectEntities: getChannelEntities,
  selectAll: getAllChannels,
  selectTotal: getTotalChannels
} = fromCore.channelAdapter.getSelectors(getChannelEntitiesState);

export const getChannel = (id: number): MemoizedSelector<AppState, Channel> =>
  createSelector(getChannelEntities, (state) => state[id]);

export const getPublisherEntitiesState = createSelector(getCoreState, (state) => state.publishers);

export const {
  selectIds: getPublisherIds,
  selectEntities: getPublisherEntities,
  selectAll: getAllPublishers,
  selectTotal: getTotalPublishers
} = fromCore.publisherAdapter.getSelectors(getPublisherEntitiesState);

export const getPublisherAvatarEntitiesState = createSelector(getCoreState, (state) => state.publisherAvatars);
export const getPublisherAvatarById = (props: { id: string }) =>
  createSelector(getPublisherAvatarEntities, (state) => {
    return state[props.id];
  });

export const {
  selectIds: getPublisherAvatarIds,
  selectEntities: getPublisherAvatarEntities,
  selectAll: getAllPublisherAvatars,
  selectTotal: getTotalPublisherAvatars
} = fromCore.publisherAvatarAdapter.getSelectors(getPublisherAvatarEntitiesState);

export const getPublisherById = (props: { id: string }) =>
  createSelector(getPublisherEntities, (state) => {
    return state[props.id];
  });

export const getPublisherViewModel = (id: string): MemoizedSelector<AppState, PublisherViewModel> =>
  createSelector(getPublisherEntities, getPublisherAvatarEntities, (publishers, avatars) => {
    return createPublisherViewModel(id, publishers, avatars);
  });

export const getAllPublisherViewModels = createSelector(
  getPublisherEntities,
  getPublisherAvatarEntities,
  (publishers, avatars) => {
    const publisherViewModels: PublisherViewModel[] = [];

    Object.keys(publishers).map((index) => {
      const publisher: Publisher = publishers[index];
      const avatar = avatars[publisher.avatarUrl];

      publisherViewModels.push(
        new PublisherViewModel(
          publisher.id,
          formatPublisherName(publisher),
          publisher.forename,
          publisher.surname,
          avatar ? avatar.avatarBase64 : null,
          publisher.avatarUrl
        )
      );
    });

    return publisherViewModels;
  }
);

export const getAttachmentEntitiesState = createSelector(getCoreState, (state) => state.attachments);

export const {
  selectIds: getAttachmentIds,
  selectEntities: getAttachmentEntities,
  selectAll: getAllAttachments,
  selectTotal: getTotalAttachments
} = fromCore.attachmentAdapter.getSelectors(getAttachmentEntitiesState);

export const getSchoolEntitiesState = createSelector(getCoreState, (state) => state.schools);

export const {
  selectIds: getSchoolIds,
  selectEntities: getSchoolEntities,
  selectAll: getAllSchools,
  selectTotal: getTotalSchools
} = fromCore.schoolAdapter.getSelectors(getSchoolEntitiesState);

export const getSchoolDefaultChannels = (
  schoolId: number,
  schoolName: string
): MemoizedSelector<AppState, ChannelCheckboxViewModel[]> =>
  createSelector(
    fromSchool.getSubscribedChannelsForSchool,
    fromSchool.getSchoolChannels,
    (subscribedChannels, schoolChannels) => {
      const defaultChannelCheckboxArray = [];
      const schoolChannelsWithSub: Channel[] = [];
      const notSubcribedSchoolChannels: Channel[] = [];

      schoolChannels.forEach((schoolChannel) => {
        subscribedChannels.forEach((subscribedChannel) => {
          if (
            subscribedChannels.findIndex((subscribedChannelIn) => subscribedChannelIn.id === schoolChannel.id) !== -1
          ) {
            if (
              schoolChannelsWithSub.findIndex(
                (schoolChannelWithSub) => schoolChannelWithSub.id === subscribedChannel.id
              ) === -1
            ) {
              schoolChannelsWithSub.push(subscribedChannel);
            }
          } else {
            if (
              schoolChannelsWithSub.findIndex(
                (schoolChannelWithSub) => schoolChannelWithSub.id === schoolChannel.id
              ) === -1
            ) {
              schoolChannelsWithSub.push(schoolChannel);
            }
          }
        });
      });

      schoolChannelsWithSub.forEach((channel) => {
        if (subscribedChannels.findIndex((subscribedChannel) => subscribedChannel.id === channel.id) === -1) {
          notSubcribedSchoolChannels.push(channel);
        }
      });

      notSubcribedSchoolChannels.forEach((channel) => {
        const channelCheckbox: ChannelCheckboxViewModel = {
          ...channel,
          checked: false,
          subscribed: false,
          isFeed: channel.name.toLowerCase() === schoolName.toLowerCase()
        };

        if (channel.subscriptionSource > 0 || channelCheckbox.isFeed) {
          defaultChannelCheckboxArray.push(channelCheckbox);
        }
      });

      subscribedChannels.forEach((subscribedChannel: any) => {
        if (
          subscribedChannel.schoolInfo &&
          (subscribedChannel.schoolInfo.id === schoolId || subscribedChannel.schoolInfo === schoolId)
        ) {
          const subscribedChannelCheckbox: ChannelCheckboxViewModel = {
            ...subscribedChannel,
            checked: true,
            subscribed: true,
            isFeed: subscribedChannel.name.toLowerCase() === schoolName.toLowerCase()
          };

          if (subscribedChannel.subscriptionSource > 0 || subscribedChannelCheckbox.isFeed) {
            defaultChannelCheckboxArray.push(subscribedChannelCheckbox);
          }
        }
      });

      defaultChannelCheckboxArray.sort((channelA: ChannelCheckboxViewModel, channelB: ChannelCheckboxViewModel) => {
        return +channelB.isFeed - +channelA.isFeed || channelA.name.localeCompare(channelB.name);
      });

      return defaultChannelCheckboxArray;
    }
  );

export const getSchoolOptionalChannels = (
  schoolId: number,
  schoolName: string
): MemoizedSelector<AppState, ChannelCheckboxViewModel[]> =>
  createSelector(
    fromSchool.getSubscribedChannelsForSchool,
    fromSchool.getSchoolChannels,
    (subscribedChannels, schoolChannels) => {
      const optionalChannelCheckboxArray = [];
      const schoolChannelsWithSub: Channel[] = [];
      const notSubcribedSchoolChannels: Channel[] = [];

      schoolChannels.forEach((schoolChannel) => {
        subscribedChannels.forEach((subscribedChannel) => {
          if (
            subscribedChannels.findIndex((subscribedChannelIn) => subscribedChannelIn.id === schoolChannel.id) !== -1
          ) {
            if (
              schoolChannelsWithSub.findIndex(
                (schoolChannelWithSub) => schoolChannelWithSub.id === subscribedChannel.id
              ) === -1
            ) {
              schoolChannelsWithSub.push(subscribedChannel);
            }
          } else {
            if (
              schoolChannelsWithSub.findIndex(
                (schoolChannelWithSub) => schoolChannelWithSub.id === schoolChannel.id
              ) === -1
            ) {
              schoolChannelsWithSub.push(schoolChannel);
            }
          }
        });
      });

      schoolChannelsWithSub.forEach((channel) => {
        if (subscribedChannels.findIndex((subscribedChannel) => subscribedChannel.id === channel.id) === -1) {
          notSubcribedSchoolChannels.push(channel);
        }
      });

      notSubcribedSchoolChannels.forEach((channel) => {
        const channelCheckbox: ChannelCheckboxViewModel = {
          ...channel,
          checked: false,
          subscribed: false,
          isFeed: channel.name.toLowerCase() === schoolName.toLowerCase()
        };

        if (!channelCheckbox.isFeed && (channel.subscriptionSource === 0 || !channel.subscriptionSource)) {
          optionalChannelCheckboxArray.push(channelCheckbox);
        }
      });

      subscribedChannels.forEach((subscribedChannel: any) => {
        if (
          subscribedChannel.schoolInfo &&
          (subscribedChannel.schoolInfo.id === schoolId || subscribedChannel.schoolInfo === schoolId)
        ) {
          const subscribedChannelCheckbox: ChannelCheckboxViewModel = {
            ...subscribedChannel,
            checked: true,
            subscribed: true,
            isFeed: subscribedChannel.name.toLowerCase() === schoolName.toLowerCase()
          };

          if (
            !subscribedChannelCheckbox.isFeed &&
            (subscribedChannel.subscriptionSource === 0 || !subscribedChannel.subscriptionSource)
          ) {
            optionalChannelCheckboxArray.push(subscribedChannelCheckbox);
          }
        }
      });

      optionalChannelCheckboxArray.sort((channelA: ChannelCheckboxViewModel, channelB: ChannelCheckboxViewModel) => {
        return channelA.name.localeCompare(channelB.name);
      });

      return optionalChannelCheckboxArray;
    }
  );

export const getAllMyChannels = createSelector(getChannelEntities, getSchoolEntities, (channels, schools) => {
  const myChannels: MyChannelsViewModel[] = [];
  const nonSchoolChannels: Channel[] = [];
  const channelArray: Channel[] = [];
  const schoolsObj = {};

  // Merge channels and schools into a single entity and push it into an array
  Object.keys(channels).map((key) => {
    const currentChannel = channels[key];

    if (currentChannel.schoolInfo) {
      Object.keys(schools).map((keyIn) => {
        const currentSchoolInfo = schools[keyIn];

        if (currentChannel.schoolInfo === currentSchoolInfo.id) {
          channelArray.push({
            code: currentChannel.code,
            description: currentChannel.description,
            id: currentChannel.id,
            name: currentChannel.name,
            publishers: currentChannel.publishers,
            schoolInfo: currentSchoolInfo.id,
            subscribedOn: currentChannel.subscribedOn,
            subscriptionSource: currentChannel.subscriptionSource
          });
        }
      });
    } else {
      channelArray.push({
        code: currentChannel.code,
        description: currentChannel.description,
        id: currentChannel.id,
        name: currentChannel.name,
        publishers: currentChannel.publishers,
        schoolInfo: null,
        subscribedOn: currentChannel.subscribedOn,
        subscriptionSource: currentChannel.subscriptionSource
      });
    }
  });

  // Organize channels according to their schools
  channelArray.forEach((channel) => {
    // If its a school channel
    if (channel.schoolInfo) {
      const currentSchoolId = channel.schoolInfo;

      // Create a property on the schoolsObj object with the school id as key
      // and another property inside said property with channels as key
      // to store its channels. This way school channels will be organized
      // under their school no matter how they are received/stored
      if (!schoolsObj[currentSchoolId]) {
        schoolsObj[currentSchoolId] = {
          channels: []
        };
      }

      schoolsObj[currentSchoolId].channels.push(channel);
    } else {
      nonSchoolChannels.push(channel);
    }
  });

  // Add the channels to the main array after sorting them like: isSchoolFeed > channel name
  Object.keys(schoolsObj).map((key) => {
    const schoolChannels = schoolsObj[key].channels;
    let schoolName;

    Object.keys(schools).map((schoolKey) => {
      if (schools[schoolKey].id === schoolsObj[key].channels[0].schoolInfo) {
        schoolName = schools[schoolKey].name;
      }
    });

    if (schoolChannels.length > 1) {
      schoolChannels.sort((channelA: Channel, channelB: Channel) => {
        return (
          +(channelB.name.toLowerCase() === schoolName.toLowerCase() && channelB.subscriptionSource > 0) -
            +(channelA.name.toLowerCase() === schoolName.toLowerCase() && channelA.subscriptionSource > 0) ||
          channelA.name.localeCompare(channelB.name)
        );
      });
    } else if (schoolChannels[0].isSchoolFeed) {
      schoolName = schoolChannels[0].name;
    }

    myChannels.push({
      id: parseInt(key, 10),
      name: schoolName,
      school: true,
      channels: schoolChannels
    });
  });

  // Sort the main array alphabetically before adding the nonschool channels
  myChannels.sort((schoolA, schoolB) => {
    return schoolA.name.localeCompare(schoolB.name);
  });

  // Finally sort the non-school channels and add the last element to the main array if there are any
  if (nonSchoolChannels.length > 0) {
    nonSchoolChannels.sort((channelA, channelB) => {
      return channelA.name.localeCompare(channelB.name);
    });

    myChannels.push({
      id: null,
      name: 'Non-School Channels',
      school: false,
      channels: nonSchoolChannels
    });
  }

  return myChannels;
});

export const getSchoolAssetEntitiesState = createSelector(getCoreState, (state) => state.schoolAssets);

export const {
  selectIds: getSchoolAssetIds,
  selectEntities: getSchoolAssetEntities,
  selectAll: getAllSchoolAssets,
  selectTotal: getTotalSchoolAssets
} = fromCore.schoolAssetAdapter.getSelectors(getSchoolAssetEntitiesState);

export const getSchool = (id: number): MemoizedSelector<AppState, School> =>
  createSelector(getSchoolEntitiesState, (state) => {
    return state.entities[id];
  });

export const getSchools = createSelector(getAllSchools, (state) => state);

export const getStudentEntitiesState = createSelector(getCoreState, (state) => state.students);

export const {
  selectIds: getStudentIds,
  selectEntities: getStudentEntities,
  selectAll: getAllStudents,
  selectTotal: getTotalStudents
} = fromCore.studentAdapter.getSelectors(getStudentEntitiesState);

export const getStudent = (id: number): MemoizedSelector<AppState, Student> =>
  createSelector(getStudentEntitiesState, (state) => {
    return state.entities[id];
  });
export const getLatestItem = createSelector(getItemEntitiesState, (state) => {
  // Note: ths relies on the ordering of items defined in the item reducer
  if (state.ids.length === 0) {
    return null;
  }
  return state.entities[state.ids[0]];
});

// Only items loaded through todo/done pages contribute to badge count
// Because our push payload contains a badge count we need to ensure this
// selector fires even when the unread item count does not change after
// items are loaded so the badge can be cleared, so mixin the query timestamp
export const getItemBadgeCount = createSelector(getItems, getPageState('hub-todo'), (items, pageState) => {
  const timestamp = pageState ? (pageState.loadingNew ? pageState.loadingNew.queryTimestamp : null) : null;
  const value = items
    .filter((item) => item.pageSource.startsWith('hub-') && !item.archivedOn)
    .reduce<number>((prev, curr) => prev + (!curr.readOn ? 1 : 0), 0);
  return { timestamp, value };
});

export const getAllItems = createSelector(getItems, (items) => items);

export const getCurrentItems = createSelector(getAllItems, (items) => items.filter((item) => !item.archivedOn));

export const getAllItemViewModels = createSelectorFactory(defaultMemoize)(
  getCurrentItems,
  getPublisherEntities,
  getPublisherAvatarEntities,
  getChannelEntities,
  getStudentEntities,
  getAttachmentEntities,
  getSchoolEntities,
  selectItemTranslationEntities,
  selectItemReactionsEntities,
  (state, publishers, avatars, channels, students, attachments, schools, itemTranslations, itemReactions) => {
    const result: ItemViewModel[] = [];
    state.forEach((item: Item) => {
      const channel = item.channelId ? channels[item.channelId] : null;
      const school = item.schoolId ? schools[item.schoolId] : null;

      const publisher = createPublisherViewModel(item.createdBy, publishers, avatars);
      const translation = itemTranslations[item.sequenceNumber];
      const reaction = itemReactions[item.sequenceNumber];
      const schoolId = item.schoolId ?? channel?.schoolInfo ?? null;
      const hasTranslationFeature = schoolId
        ? !!schools[schoolId].features.find((feature) => feature === 'messagetranslation')
        : false;

      switch (item.itemType) {
        // Channel Post
        case 0:
          if (channel) {
            result.push(
              createChannelArticleItemViewModel(
                item,
                publisher,
                channel,
                attachments,
                true,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;

        // Direct Message
        case 1:
          if (school) {
            result.push(
              createAnnouncementItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                true,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;

        // Attendance
        case 2:
          if (school) {
            result.push(
              createAttendanceItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                true,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;

        // Payment Message
        case 3:
          if (school) {
            result.push(
              createPaymentItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                true,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;

        // Slip Message
        case 4:
          if (school) {
            result.push(
              createSlipItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                true,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;
        // Unknown Item
        default:
          if (school || channel) {
            result.push(
              createUnknownItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                channel,
                true,
                hasTranslationFeature,
                translation
              )
            );
          }

          break;
      }
    });
    return result;
  }
);

export const getAllItemPlainTextViewModels = createSelectorFactory(defaultMemoize)(
  getCurrentItems,
  getPublisherEntities,
  getPublisherAvatarEntities,
  getChannelEntities,
  getStudentEntities,
  getAttachmentEntities,
  getSchoolEntities,
  selectItemTranslationEntities,
  selectItemReactionsEntities,
  (state, publishers, avatars, channels, students, attachments, schools, itemTranslations, itemReactions) => {
    const result: ItemViewModel[] = [];
    state.forEach((item) => {
      const channel = item.channelId ? channels[item.channelId] : null;
      const school = item.schoolId ? schools[item.schoolId] : null;

      const publisher = createPublisherViewModel(item.createdBy, publishers, avatars);
      const translation = itemTranslations[item.sequenceNumber];
      const reaction = itemReactions[item.sequenceNumber];
      const schoolId = item.schoolId ?? channel?.schoolInfo ?? null;
      const hasTranslationFeature = schoolId
        ? !!schools[schoolId].features.find((feature) => feature === 'messagetranslation')
        : false;

      switch (item.itemType) {
        // Channel Post
        case 0:
          if (channel) {
            result.push(
              createChannelArticleItemViewModel(
                item,
                publisher,
                channel,
                attachments,
                false,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;

        // Direct Message
        case 1:
          if (school) {
            result.push(
              createAnnouncementItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                false,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;

        // Attendance
        case 2:
          if (school) {
            result.push(
              createAttendanceItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                false,
                hasTranslationFeature,
                translation,
                reaction
              )
            );
          }
          break;

        // Unknown Item
        default:
          if (school || channel) {
            result.push(
              createUnknownItemViewModel(
                item,
                publisher,
                students,
                attachments,
                school,
                channel,
                false,
                hasTranslationFeature,
                translation
              )
            );
          }

          break;
      }
    });
    return result;
  }
);

export const getItemSearchViewModels = (
  searchTerm: string,
  channelId?: number,
  studentId?: number,
  itemType?: number
): MemoizedSelector<AppState, ItemViewModel[]> =>
  createSelector(getAllItemPlainTextViewModels, getAllItemViewModels, (plainTextViewModels, viewModels) =>
    plainTextViewModels
      .filter((item) => !item.presub || (item.presub && (channelId || studentId)))
      .filter((item) =>
        channelId ? item.itemType === 0 && (item as ChannelArticleItemViewModel).channel.id === channelId : true
      )
      .filter((item) =>
        studentId && itemType
          ? item.itemType === itemType &&
            (item as AnnouncementItemViewModel).student &&
            (item as AnnouncementItemViewModel).student.id === studentId
          : true
      )
      .filter((item) => {
        let displayNameFound = false;

        if (item.createdBy && item.createdBy.displayName) {
          displayNameFound = item.createdBy.displayName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }

        return (
          item.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          item.message.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          displayNameFound ||
          item.attachments.documents.find((doc) => doc.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
          item.attachments.images.find((doc) => doc.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
        );
      })
      .map((item) => {
        const transformedItem = viewModels.filter((viewModel) => viewModel.sequenceNumber === item.sequenceNumber)[0];

        return transformedItem as ItemViewModel;
      })
  );

export const getAllChannelViewModels = createSelector(getAllItemViewModels, (viewModels) =>
  viewModels.filter((item) => item.itemType === 0).map((item) => item as ChannelArticleItemViewModel)
);

export const getChannelViewModels = (id: number): MemoizedSelector<AppState, ChannelArticleItemViewModel[]> =>
  createSelector(getAllChannelViewModels, (viewModels) => viewModels.filter((item) => item.channel.id === id));

export const getTodoItemViewModels = createSelector(getAllItemViewModels, (items) =>
  items.filter((item) => !item.done && item.pageSource.startsWith('hub-'))
);

export const getUnreadItemViewModels = (loadDate: string): MemoizedSelector<AppState, ItemViewModel[]> =>
  createSelector(getAllItemViewModels, (items) => {
    const itemsExcludingOldPosts = items.filter((item) => !item.presub && !item.pageSource.startsWith('search-'));

    return itemsExcludingOldPosts.filter(
      (item) => (item.localReadOn && moment(item.localReadOn).isAfter(loadDate)) || !item.read
    );
  });

export const getDoneItemViewModels = createSelector(getAllItemViewModels, (items) =>
  items.filter((item) => item.done && item.pageSource.startsWith('hub-'))
);

export const getAllAnnouncementViewModels = createSelector(getAllItemViewModels, (viewModels) =>
  viewModels.filter((item) => item.itemType === 1).map((item) => item as AnnouncementItemViewModel)
);

export const getAllAttendanceViewModels = createSelector(getAllItemViewModels, (viewModels) =>
  viewModels.filter((item) => item.itemType === 2).map((item) => item as AttendanceItemViewModel)
);

export const getStudentAttendanceAlertsViewModels = (
  id: number
): MemoizedSelector<AppState, AttendanceItemViewModel[]> =>
  createSelector(getAllAttendanceViewModels, getStudentEntitiesState, (viewModels, state) =>
    viewModels.filter((item) => {
      if (item.student !== undefined) {
        return item.student.id === id;
      } else {
        return false;
      }
    })
  );

export const getStudentViewModels = (id: number): MemoizedSelector<AppState, AnnouncementItemViewModel[]> =>
  createSelector(getAllAnnouncementViewModels, getStudentEntitiesState, (viewModels, state) =>
    viewModels.filter((item) => {
      if (item.student !== undefined) {
        return item.student.id === id;
      } else {
        return false;
      }
    })
  );

export const getItemViewModel = (id: number): MemoizedSelector<AppState, ItemViewModel> =>
  createSelectorFactory(defaultMemoize)(
    getItemEntities,
    getPublisherEntities,
    getPublisherAvatarEntities,
    getChannelEntities,
    getStudentEntities,
    getAttachmentEntities,
    getSchoolEntities,
    selectItemTranslationEntities,
    selectItemReactionsEntities,
    (items, publishers, avatars, channels, students, attachments, schools, itemTranslations, itemReactions) => {
      const item = items[id];

      if (!item) {
        return null;
      }

      let result: ItemViewModel = null;

      const channel = item.channelId ? channels[item.channelId] : null;
      const school = item.schoolId ? schools[item.schoolId] : null;

      const publisher = createPublisherViewModel(item.createdBy, publishers, avatars);
      const translation = itemTranslations[item.sequenceNumber];
      const reaction = itemReactions[item.sequenceNumber];
      const schoolId = item.schoolId ?? channel?.schoolInfo ?? null;
      const hasTranslationFeature = schoolId
        ? !!schools[schoolId].features.find((feature) => feature === 'messagetranslation')
        : false;

      switch (item.itemType) {
        // Channel Post
        case 0:
          if (!channel) {
            return null;
          }

          result = createChannelArticleItemViewModel(
            item,
            publisher,
            channel,
            attachments,
            true,
            hasTranslationFeature,
            translation,
            reaction
          );

          break;

        // Direct Message
        case 1:
          if (!school) {
            return null;
          }

          result = createAnnouncementItemViewModel(
            item,
            publisher,
            students,
            attachments,
            school,
            true,
            hasTranslationFeature,
            translation,
            reaction
          );

          break;

        // Attendance Alert
        case 2:
          if (!school) return null;
          result = createAttendanceItemViewModel(
            item,
            publisher,
            students,
            attachments,
            school,
            true,
            hasTranslationFeature,
            translation,
            reaction
          );

          break;

        // Payment Message
        case 3:
          if (!school) {
            return null;
          }

          result = createPaymentItemViewModel(
            item,
            publisher,
            students,
            attachments,
            school,
            true,
            hasTranslationFeature,
            translation,
            reaction
          );

          break;

        // Slip Message
        case 4:
          if (!school) {
            return null;
          }

          result = createSlipItemViewModel(
            item,
            publisher,
            students,
            attachments,
            school,
            true,
            hasTranslationFeature,
            translation,
            reaction
          );

          break;

        // Unknown Items
        default:
          if (!school && !channel) {
            return null;
          }

          result = createUnknownItemViewModel(
            item,
            publisher,
            students,
            attachments,
            school,
            channel,
            true,
            hasTranslationFeature,
            translation
          );

          break;
      }

      return result;
    }
  );

export const getAllSchoolViewModels = createSelector(
  getAllSchools,
  getSchoolAssetEntities,
  getStudentEntities,
  (state, assetEntities, studentEntities) => {
    const result: SchoolViewModel[] = [];
    state.forEach((item) => {
      result.push(createSchoolViewModel(item, assetEntities, studentEntities));
    });

    return result;
  }
);

export const getSchoolAttendanceViewModels = createSelector(
  getAllSchools,
  getSchoolAssetEntities,
  getStudentEntities,
  getAttendanceSessionsEntities,
  (state, assetEntities, studentEntities, attendanceSessionsEntities) => {
    const result: SchoolAttendanceViewModel[] = [];
    state.forEach((item) => {
      result.push(createSchoolAttendanceViewModel(item, assetEntities, studentEntities, attendanceSessionsEntities));
    });

    return result;
  }
);

export const getCoreProfileExists = createSelector(getCoreState, (state) => state.profileExists);

export const getChannelsSchoolsLoaded = createSelector(getCoreState, (state) => state.channelsSchoolsLoaded);

export const getLastItemPurge = createSelector(getCoreState, (state) => state.lastItemPurge);

export const getSchoolViewModel = (id: number): MemoizedSelector<AppState, SchoolViewModel> =>
  createSelector(
    getSchoolEntities,
    getSchoolAssetEntities,
    getStudentEntities,
    (schoolEntities, assetEntities, studentEntities) => {
      if (!schoolEntities[id]) {
        return null;
      }
      return createSchoolViewModel(schoolEntities[id], assetEntities, studentEntities);
    }
  );

export const getSchoolAttendanceViewModel = (id: number): MemoizedSelector<AppState, SchoolAttendanceViewModel> =>
  createSelector(
    getSchoolEntities,
    getSchoolAssetEntities,
    getStudentEntities,
    getAttendanceSessionsEntities,
    (schoolEntities, assetEntities, studentEntities, attendanceSessionsEntities) => {
      if (!schoolEntities[id]) {
        return null;
      }
      return createSchoolAttendanceViewModel(
        schoolEntities[id],
        assetEntities,
        studentEntities,
        attendanceSessionsEntities
      );
    }
  );

const createSchoolViewModel = (
  school: School,
  assetEntities: Dictionary<SchoolAsset>,
  studentEntities: Dictionary<Student>
): SchoolViewModel => {
  const logo = assetEntities[school.logoUrl];
  const students = [];
  if (school.students) {
    school.students.forEach((studentId) => students.push(studentEntities[studentId]));
  }

  const sortedWeblinks = school.webLinks.slice().sort((a, b) => a.displayOrder - b.displayOrder);

  return new SchoolViewModel(
    school.id,
    school.name,
    school.address,
    school.headteacher,
    school.telephone,
    school.email,
    school.feedChannelId,
    logo ? logo.dataUrl : null,
    school.websiteUrl,
    school.features,
    students,
    school.subscriptionState,
    sortedWeblinks
      ? sortedWeblinks.map<SchoolWebLinkViewModel>((weblink) => {
          return {
            name: weblink.name,
            linkUrl: weblink.linkUrl,
            iconUrl: weblink.iconUrl,
            iconDataUrl: assetEntities[weblink.iconUrl] ? assetEntities[weblink.iconUrl].dataUrl : null,
            displayOrder: weblink.displayOrder
          };
        })
      : []
  );
};

const createSchoolAttendanceViewModel = (
  school: School,
  assetEntities: Dictionary<SchoolAsset>,
  studentEntities: Dictionary<Student>,
  attendanceSessionsEntities: Dictionary<AttendanceSessions>
): SchoolAttendanceViewModel => {
  const logo = assetEntities[school.logoUrl];
  const students = [];
  if (school.students) {
    school.students.forEach((studentId) => {
      const student: Student = studentEntities[studentId];

      if (student !== undefined) {
        if (attendanceSessionsEntities) {
          const studentItemViewModel: StudentItemViewModel = {
            forename: student.forename,
            surname: student.surname,
            form: student.form,
            id: student.id
          };

          if (attendanceSessionsEntities[studentId]) {
            const lastSession = _.chain(attendanceSessionsEntities[studentId].sessions)
              .groupBy('date')
              .map((v, k) => {
                const am = _.find(v, ['session', 'AM']);
                const pm = _.find(v, ['session', 'PM']);
                return {
                  dateOrder: moment(k).format('YYYY-MM-DD'),
                  date: moment(k).format('ddd Do MMM'),
                  am: getAttandanceMark(am),
                  pm: getAttandanceMark(pm)
                };
              })
              .orderBy(['dateOrder'], ['desc'])
              .map((v) => {
                return {
                  date: v.date,
                  am: v.am,
                  pm: v.pm
                };
              })
              .value()[0];

            const lastSessionDate = moment(lastSession.date, 'ddd Do MMM');

            if (moment().diff(lastSessionDate, 'days') === 0) {
              studentItemViewModel.lastSession = lastSession;
            } else {
              studentItemViewModel.lastSession = null;
            }
          } else {
            studentItemViewModel.lastSession = null;
          }

          students.push(studentItemViewModel);
        } else {
          students.push(studentEntities[studentId]);
        }
      }
    });
  }

  const sortedWeblinks = school.webLinks.slice().sort((a, b) => a.displayOrder - b.displayOrder);

  return new SchoolAttendanceViewModel(
    school.id,
    school.name,
    school.address,
    school.headteacher,
    school.telephone,
    school.email,
    school.feedChannelId,
    logo ? logo.dataUrl : null,
    school.websiteUrl,
    school.features,
    students,
    school.subscriptionState,
    sortedWeblinks
      ? sortedWeblinks.map<SchoolWebLinkViewModel>((weblink) => {
          return {
            name: weblink.name,
            linkUrl: weblink.linkUrl,
            iconUrl: weblink.iconUrl,
            iconDataUrl: assetEntities[weblink.iconUrl] ? assetEntities[weblink.iconUrl].dataUrl : null,
            displayOrder: weblink.displayOrder
          };
        })
      : [],
    school.paymentKey
  );
};

export const transformMessage = (message: string, title: string, referenceDate: Date): string => {
  let messageHtml = text2HTML(message);
  messageHtml = linkifyHtml(messageHtml, {
    target: '_blank',
    className: 'linkified'
  });
  return decorateMessageBody(messageHtml, title, referenceDate);
};

const createChannelArticleItemViewModel = (
  item: Item,
  publisher: PublisherViewModel,
  channel: Channel,
  attachments: Dictionary<Attachment>,
  doTransformMessage: boolean,
  hasTranslationFeature: boolean,
  translation: ItemTranslation,
  reaction: ItemReaction
): ChannelArticleItemViewModel => {
  const documents: Attachment[] = [];
  const images: Attachment[] = [];
  item.attachments.forEach((element) => {
    const attachment = attachments[element];
    if (attachment) {
      if (attachment.attachmentMimeType.indexOf('image') >= 0) {
        images.push(attachment);
      } else {
        documents.push(attachment);
      }
    }
  });
  const hasTranslation = translation && !translation?.error;
  const showTranslate = (hasTranslation || hasTranslationFeature) && !translation?.showingTranslation;
  const showRevert = hasTranslation && translation?.showingTranslation;
  let title = translation?.showingTranslation ? translation.title : item.title;
  let message = translation?.showingTranslation ? translation.message : item.message;
  // For search we construct an item with plaing text content. We also want to be able to search
  // against both original and translated text. Since we never display this type of result we can
  // concatenate original and trranslated text for search purposes
  if (!doTransformMessage) {
    title = title + ' ' + (translation?.showingTranslation ? item.title : translation?.title);
    message = message + ' ' + (translation?.showingTranslation ? item.message : translation?.message);
  }
  return new ChannelArticleItemViewModel(
    item.pageSource,
    item.showInFeed,
    item.itemType,
    item.sequenceNumber,
    item.id,
    title,
    doTransformMessage ? transformMessage(message, title, item.createdOn) : message,
    {
      showTranslate: showTranslate,
      showRevert: showRevert,
      inProgress: translation?.inProgress,
      direction: translation?.direction ?? 'ltr'
    },
    item.createdOn,
    !!item.readOn || !!item.localReadOn,
    item.readOn,
    item.localReadOn,
    item.done,
    item.createdOn < channel.subscribedOn,
    publisher,
    new AttachmentsViewModel(
      images.sort((a, b) => a.order - b.order),
      documents.sort((a, b) => a.order - b.order)
    ),
    `channel/${channel.id}/post/${item.sequenceNumber}`,
    channel,
    item.reaction
      ? { id: item.reaction.id, description: item.reaction.name, inProgress: reaction?.inProgress }
      : reaction?.inProgress
      ? { id: null, description: null, inProgress: true }
      : null,
    item.allowedReactions ? item.allowedReactions.map((ar) => ({ id: ar.id, description: ar.name })) : []
  );
};

const createAnnouncementItemViewModel = (
  item: Item,
  publisher: PublisherViewModel,
  students: Dictionary<Student>,
  attachments: Dictionary<Attachment>,
  school: School,
  doTransformMessage: boolean,
  hasTranslationFeature: boolean,
  translation: ItemTranslation,
  reaction: ItemReaction
): AnnouncementItemViewModel => {
  const documents: Attachment[] = [];
  const images: Attachment[] = [];
  item.attachments.forEach((element) => {
    const attachment = attachments[element];
    if (attachment) {
      if (attachment.attachmentMimeType.indexOf('image') >= 0) {
        images.push(attachment);
      } else {
        documents.push(attachment);
      }
    }
  });
  const hasTranslation = translation && !translation?.error;
  const showTranslate = (hasTranslation || hasTranslationFeature) && !translation?.showingTranslation;
  const showRevert = hasTranslation && translation?.showingTranslation;
  let title = translation?.showingTranslation ? translation.title : item.title;
  let message = translation?.showingTranslation ? translation.message : item.message;
  // For search we construct an item with plaing text content. We also want to be able to search
  // against both original and translated text. Since we never display this type of result we can
  // concatenate original and trranslated text for search purposes
  if (!doTransformMessage) {
    title = title + ' ' + (translation?.showingTranslation ? item.title : translation?.title);
    message = message + ' ' + (translation?.showingTranslation ? item.message : translation?.message);
  }
  return new AnnouncementItemViewModel(
    item.pageSource,
    item.showInFeed,
    item.itemType,
    item.sequenceNumber,
    item.id,
    item.schoolId,
    title,
    doTransformMessage ? transformMessage(message, title, item.createdOn) : message,
    {
      showTranslate: showTranslate,
      showRevert: showRevert,
      inProgress: translation?.inProgress,
      direction: translation?.direction ?? 'ltr'
    },
    item.createdOn,
    !!item.readOn || !!item.localReadOn,
    item.readOn,
    item.localReadOn,
    item.done,
    item.createdOn < school.confirmedOn,
    publisher,
    new AttachmentsViewModel(
      images.sort((a, b) => a.order - b.order),
      documents.sort((a, b) => a.order - b.order)
    ),
    `school/${school.id}/student/${item.studentId}/dms/${item.sequenceNumber}`,
    students[item.studentId],
    item.reaction
      ? { id: item.reaction.id, description: item.reaction.name, inProgress: reaction?.inProgress }
      : reaction?.inProgress
      ? { id: null, description: null, inProgress: true }
      : null,
    item.allowedReactions ? item.allowedReactions.map((ar) => ({ id: ar.id, description: ar.name })) : []
  );
};
const createAttendanceItemViewModel = (
  item: Item,
  publisher: PublisherViewModel,
  students: Dictionary<Student>,
  attachments: Dictionary<Attachment>,
  school: School,
  doTransformMessage: boolean,
  hasTranslationFeature: boolean,
  translation: ItemTranslation,
  reaction: ItemReaction
): AttendanceItemViewModel => {
  const documents = [];
  const images = [];

  item.attachments.forEach((element) => {
    const attachment = attachments[element];

    if (attachment) {
      if (attachment.attachmentMimeType.indexOf('image') >= 0) {
        images.push(attachment);
      } else {
        documents.push(attachment);
      }
    }
  });
  const hasTranslation = translation && !translation?.error;
  const showTranslate = (hasTranslation || hasTranslationFeature) && !translation?.showingTranslation;
  const showRevert = hasTranslation && translation?.showingTranslation;
  let title = translation?.showingTranslation ? translation.title : item.title;
  let message = translation?.showingTranslation ? translation.message : item.message;
  // For search we construct an item with plaing text content. We also want to be able to search
  // against both original and translated text. Since we never display this type of result we can
  // concatenate original and trranslated text for search purposes
  if (!doTransformMessage) {
    title = title + ' ' + (translation?.showingTranslation ? item.title : translation?.title);
    message = message + ' ' + (translation?.showingTranslation ? item.message : translation?.message);
  }
  return new AttendanceItemViewModel(
    item.pageSource,
    item.showInFeed,
    item.itemType,
    item.sequenceNumber,
    item.id,
    item.schoolId,
    school.telephone,
    title,
    doTransformMessage ? transformMessage(message, title, item.createdOn) : message,
    {
      showTranslate: showTranslate,
      showRevert: showRevert,
      inProgress: translation?.inProgress,
      direction: translation?.direction ?? 'ltr'
    },
    item.createdOn,
    !!item.readOn || !!item.localReadOn,
    item.readOn,
    item.localReadOn,
    item.done,
    item.createdOn < school.confirmedOn,
    publisher,
    new AttachmentsViewModel(images, documents),
    `attendance-alert/${item.sequenceNumber}`,
    students[item.studentId],
    item.reaction
      ? { id: item.reaction.id, description: item.reaction.name, inProgress: reaction?.inProgress }
      : reaction?.inProgress
      ? { id: null, description: null, inProgress: true }
      : null,
    item.allowedReactions ? item.allowedReactions.map((ar) => ({ id: ar.id, description: ar.name })) : []
  );
};

const createPaymentItemViewModel = (
  item: Item,
  publisher: PublisherViewModel,
  students: Dictionary<Student>,
  attachments: Dictionary<Attachment>,
  school: School,
  doTransformMessage: boolean,
  hasTranslationFeature: boolean,
  translation: ItemTranslation,
  reaction: ItemReaction
): PaymentItemViewModel => {
  const documents: Attachment[] = [];
  const images: Attachment[] = [];
  item.attachments.forEach((element) => {
    const attachment = attachments[element];
    if (attachment) {
      if (attachment.attachmentMimeType.indexOf('image') >= 0) {
        images.push(attachment);
      } else {
        documents.push(attachment);
      }
    }
  });
  const hasTranslation = translation && !translation?.error;
  const showTranslate = (hasTranslation || hasTranslationFeature) && !translation?.showingTranslation;
  const showRevert = hasTranslation && translation?.showingTranslation;
  let title = translation?.showingTranslation ? translation.title : item.title;
  let message = translation?.showingTranslation ? translation.message : item.message;
  // For search we construct an item with plaing text content. We also want to be able to search
  // against both original and translated text. Since we never display this type of result we can
  // concatenate original and trranslated text for search purposes
  if (!doTransformMessage) {
    title = title + ' ' + (translation?.showingTranslation ? item.title : translation?.title);
    message = message + ' ' + (translation?.showingTranslation ? item.message : translation?.message);
  }
  return new PaymentItemViewModel(
    item.categoryId,
    item.pageSource,
    item.showInFeed,
    item.itemType,
    item.sequenceNumber,
    item.id,
    item.schoolId,
    title,
    doTransformMessage ? transformMessage(message, title, item.createdOn) : message,
    {
      showTranslate: showTranslate,
      showRevert: showRevert,
      inProgress: translation?.inProgress,
      direction: translation?.direction ?? 'ltr'
    },
    item.createdOn,
    !!item.readOn || !!item.localReadOn,
    item.readOn,
    item.localReadOn,
    item.done,
    item.createdOn < school.confirmedOn,
    publisher,
    new AttachmentsViewModel(
      images.sort((a, b) => a.order - b.order),
      documents.sort((a, b) => a.order - b.order)
    ),
    `school/${item.schoolId}/student/${item.studentId}/payment-messages/${item.sequenceNumber}`,
    students[item.studentId],
    item.reaction
      ? { id: item.reaction.id, description: item.reaction.name, inProgress: reaction?.inProgress }
      : reaction?.inProgress
      ? { id: null, description: null, inProgress: true }
      : null,
    item.allowedReactions ? item.allowedReactions.map((ar) => ({ id: ar.id, description: ar.name })) : []
  );
};

const createSlipItemViewModel = (
  item: Item,
  publisher: PublisherViewModel,
  students: Dictionary<Student>,
  attachments: Dictionary<Attachment>,
  school: School,
  doTransformMessage: boolean,
  hasTranslationFeature: boolean,
  translation: ItemTranslation,
  reaction: ItemReaction
): SlipItemViewModel => {
  const documents: Attachment[] = [];
  const images: Attachment[] = [];
  item.attachments.forEach((element) => {
    const attachment = attachments[element];
    if (attachment) {
      if (attachment.attachmentMimeType.indexOf('image') >= 0) {
        images.push(attachment);
      } else {
        documents.push(attachment);
      }
    }
  });
  const hasTranslation = translation && !translation?.error;
  const showTranslate = (hasTranslation || hasTranslationFeature) && !translation?.showingTranslation;
  const showRevert = hasTranslation && translation?.showingTranslation;
  let title = translation?.showingTranslation ? translation.title : item.title;
  let message = translation?.showingTranslation ? translation.message : item.message;
  // For search we construct an item with plaing text content. We also want to be able to search
  // against both original and translated text. Since we never display this type of result we can
  // concatenate original and trranslated text for search purposes
  if (!doTransformMessage) {
    title = title + ' ' + (translation?.showingTranslation ? item.title : translation?.title);
    message = message + ' ' + (translation?.showingTranslation ? item.message : translation?.message);
  }
  return new SlipItemViewModel(
    item.categoryId,
    item.categoryInfo,
    item.pageSource,
    item.showInFeed,
    item.itemType,
    item.sequenceNumber,
    item.id,
    item.schoolId,
    title,
    doTransformMessage ? transformMessage(message, title, item.createdOn) : message,
    {
      showTranslate: showTranslate,
      showRevert: showRevert,
      inProgress: translation?.inProgress,
      direction: translation?.direction ?? 'ltr'
    },
    item.createdOn,
    !!item.readOn || !!item.localReadOn,
    item.readOn,
    item.localReadOn,
    item.done,
    item.createdOn < school.confirmedOn,
    publisher,
    new AttachmentsViewModel(
      images.sort((a, b) => a.order - b.order),
      documents.sort((a, b) => a.order - b.order)
    ),
    `school/${item.schoolId}/student/${item.studentId}/slip-messages/${item.sequenceNumber}`,
    students[item.studentId],
    item.reaction
      ? { id: item.reaction.id, description: item.reaction.name, inProgress: reaction?.inProgress }
      : reaction?.inProgress
      ? { id: null, description: null, inProgress: true }
      : null,
    item.allowedReactions ? item.allowedReactions.map((ar) => ({ id: ar.id, description: ar.name })) : []
  );
};

const createUnknownItemViewModel = (
  item: Item,
  publisher: PublisherViewModel,
  students: Dictionary<Student>,
  attachments: Dictionary<Attachment>,
  school: School,
  channel: Channel,
  doTransformMessage: boolean,
  hasTranslationFeature: boolean,
  translation: ItemTranslation
): UnknownItemViewModel => {
  const documents = [];
  const images = [];

  item.attachments.forEach((element) => {
    const attachment = attachments[element];

    if (attachment) {
      if (attachment.attachmentMimeType.indexOf('image') >= 0) {
        images.push(attachment);
      } else {
        documents.push(attachment);
      }
    }
  });
  const hasTranslation = translation && !translation?.error;
  const showTranslate = (hasTranslation || hasTranslationFeature) && !translation?.showingTranslation;
  const showRevert = hasTranslation && translation?.showingTranslation;
  let title = translation?.showingTranslation ? translation.title : item.title;
  let message = translation?.showingTranslation ? translation.message : item.message;
  // For search we construct an item with plaing text content. We also want to be able to search
  // against both original and translated text. Since we never display this type of result we can
  // concatenate original and trranslated text for search purposes
  if (!doTransformMessage) {
    title = title + ' ' + (translation?.showingTranslation ? item.title : translation?.title);
    message = message + ' ' + (translation?.showingTranslation ? item.message : translation?.message);
  }
  return new UnknownItemViewModel(
    item.pageSource,
    item.showInFeed,
    item.itemType,
    item.sequenceNumber,
    item.id,
    item.schoolId || null,
    title,
    doTransformMessage ? transformMessage(message, title, item.createdOn) : message,
    {
      showTranslate: showTranslate,
      showRevert: showRevert,
      inProgress: translation?.inProgress,
      direction: translation?.direction ?? 'ltr'
    },
    item.createdOn,
    !!item.readOn || !!item.localReadOn,
    item.readOn,
    item.localReadOn,
    item.done,
    item.createdOn < school.confirmedOn,
    publisher,
    new AttachmentsViewModel(images, documents),
    `unknown-item/${item.sequenceNumber}`,
    students[item.studentId],
    channel || null,
    null,
    null
  );
};

const createPublisherViewModel = (
  publisherId: string,
  publishers: Dictionary<Publisher>,
  avatars: Dictionary<PublisherAvatar>
): PublisherViewModel => {
  const publisher: Publisher = publishers[publisherId];
  if (publisher) {
    const avatar = avatars[publisher.avatarUrl];
    return new PublisherViewModel(
      publisher.id,
      formatPublisherName(publisher),
      publisher.forename,
      publisher.surname,
      avatar ? avatar.avatarBase64 : null,
      publisher.avatarUrl
    );
  }
  return null;
};

export const getItemReadReceiptEntitiesState = createSelector(getCoreState, (state) => state.itemReadReceipts);

export const getShowDownloadingNewItemsSpinner = createSelector(
  getCoreState,
  (state) => state.showDownloadingNewItemsSpinner
);
export const getFirstContentLoading = createSelector(getCoreState, (state) => state.showFirstContentLoad);
export const {
  selectIds: getItemReadReceiptIds,
  selectEntities: getItemReadReceiptEntities,
  selectAll: getAllItemReadReceipts,
  selectTotal: getTotalItemReadReceipts
} = fromCore.itemReadReceiptAdapter.getSelectors(getItemReadReceiptEntitiesState);

export const getPendingReadReceipts = createSelector(getAllItemReadReceipts, (items) =>
  items.filter((item) => item.failureCode !== 404 && item.failureCode !== 403)
);

export const getUnreadItemsState = createSelector(getCoreState, (state) => state.unreadItemsState);

export const getUnreadItemCount = createSelector(getAllItemViewModels, getUnreadItemsState, (items, state) => {
  if (state) {
    let total: number;
    const itemsExcludingOldPosts = items.filter((item) => !item.presub);
    const itemsReceived = itemsExcludingOldPosts.filter(
      (item) => !moment(item.createdOn).isAfter(state.lastTotalUnreadFetch)
    );
    const itemsReceivedAfterSync = itemsExcludingOldPosts.filter((item) =>
      moment(item.createdOn).isAfter(state.lastTotalUnreadFetch)
    );

    // Assign total of unread items fetched from server
    total = state.remoteUnreadItems;

    // Add items received after sync to the total
    total += itemsReceivedAfterSync.length;

    // Subtract read items
    itemsReceived.forEach((item) => {
      if (item.readOn !== 'now' && moment(item.readOn).isAfter(state.lastTotalUnreadFetch)) {
        total--;
      }
    });

    // Subtract read items after sync
    itemsReceivedAfterSync.forEach((item) => {
      if (item.readOn !== 'now' && moment(item.readOn).isAfter(state.lastTotalUnreadFetch)) {
        total--;
      }
    });

    itemsExcludingOldPosts.forEach((item) => {
      if (item.readOn === 'now') {
        total--;
      }
    });

    return { totalUnread: total };
  } else {
    return { totalUnread: 0 };
  }
});

export const getPaymentMethodId = createSelector(getCoreState, (state) => state.paymentsState.paymentMethodId);

export const getSchoolSubscribedChannels = (schoolId: number): MemoizedSelector<AppState, MyChannelsViewModel[]> =>
  createSelector(getSchool(schoolId), getChannelEntities, (school, channelEntities) => {
    const schoolChannels: MyChannelsViewModel[] = [];
    const channelArray: Channel[] = [];

    if (school && channelEntities) {
      Object.keys(channelEntities).map((key) => {
        if (channelEntities[key] && channelEntities[key].schoolInfo === school.id) {
          channelArray.push({
            id: channelEntities[key].id,
            name: channelEntities[key].name,
            description: channelEntities[key].description,
            code: channelEntities[key].code,
            subscribedOn: channelEntities[key].subscribedOn,
            publishers: [''],
            schoolInfo: school.id,
            subscriptionSource: channelEntities[key].subscriptionSource
          });
        }
      });

      schoolChannels.push({
        id: school.id,
        name: school.name,
        school: true,
        channels: channelArray
      });
    }
    return schoolChannels;
  });

const decorateMessageBody = (message: string, title: string, referenceDate: Date): string => {
  try {
    const strictUKChrono = new chrono.Chrono(
      chrono.options.mergeOptions([chrono.options.en_GB(), chrono.options.strictOption()])
    );
    const results = strictUKChrono.parse(message, referenceDate, {
      forwardDate: true
    });

    const parts = [];
    let index = 0;
    results.forEach((result) => {
      let offset = 0;
      let toIndex = result.index + result.text.length;
      let part = message.slice(index, result.index + result.text.length);

      // Due to the patch for chrono to avoid parsing urls, emails and incomplete urls, a hack is
      // needed to offset the date position as chrono doesnt have the correct result index when
      // compared with the original message. This code finds links, their position and length and
      // uses that to calculate the offset to be passed to the function in charge of placing the
      // calendar links if the result is not found in the slice of the message
      if (part.indexOf(result.text) === -1) {
        const linkRegex = RegExp(/<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g);

        let link: RegExpExecArray;
        while ((link = linkRegex.exec(message)) !== null) {
          if (result.index + offset > link.index) {
            offset += link[0].length;
          }
        }

        part = message.slice(index, result.index + result.text.length + offset);

        toIndex += offset;
      }

      // chrono gets mixed up if date at end of message i.e. immediately before </p>
      const text = result.text.replace('<', '');
      part = part.replace(text, constructTag(text, result, title));
      parts.push(part);
      index = toIndex;
    });
    parts.push(message.slice(index));
    return parts.join('');
  } catch {
    return message;
  }
};

const constructTag = (text: string, result: any, title: string): string => {
  let startTag = '';
  let endTag = '';
  const start = moment(result.start.date()).format('YYYYMMDDTHHmm00');
  startTag = `starting="${start}"`;
  if (result.end) {
    const end = moment(result.end.date()).format('YYYYMMDDTHHmm00');
    endTag = `ending="${end}"`;
  } else {
    const end2 = moment(result.start.date()).add(1, 'h').format('YYYYMMDDTHHmm00');
    endTag = `ending="${end2}"`;
  }
  const titleTag = `title="${title}"`;
  // prettier-ignore
  return `<app-calendar-link ${titleTag} ${startTag} ${endTag}>${text}</app-calendar-link>`;
};

export function text2HTML(text): string {
  // 1: Escape special characters
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(text));
  text = div.innerHTML;
  const style =
    `style="font-family: 'Open Sans';font-size: 1.6rem;line-height: 2.2rem;overflow: hidden;` +
    `padding-bottom: 14px !important;margin-top: 0px;margin-bottom: 2px;text-overflow: ellipsis;color: #3E424B;padding: 0 16px;"`;

  // 2: Line Breaks
  text = text.replace(/\r\n?|\n/g, '<br>');

  // 3: Paragraphs
  text = text.replace(/<br>\s*<br>/g, `</p><p ${style}>`);

  // 4: Wrap in Paragraph Tags
  text = '<p ' + style + '>' + text + '</p>';

  return text;
}
