import { createAction, props } from '@ngrx/store';

export const reactToItem = createAction(
  '[Item Reactions] React To Item',
  props<{
    id: number;
    reactionId: string;
  }>()
);
export const reactToItemSuccess = createAction(
  '[Item Reactions] React To Item Success',
  props<{
    id: number;
    reactionId: string;
  }>()
);
export const reactToItemFail = createAction(
  '[Item Reactions] React To Item Fail',
  props<{
    id: number;
    error: string;
  }>()
);
