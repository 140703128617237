import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { ChatThreadReplyCacheActions } from '../actions/chat-thread-reply-cache.action';

@Injectable()
export class ChatThreadReplyCahceEffects {
  constructor(private actions$: Actions) {}

  replyChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadReplyCacheActions.replyChanged),
      debounceTime(1000),
      mergeMap((action) => {
        return !action.chatThreadReply.content
          ? of(ChatThreadReplyCacheActions.delete({ chatThreadId: action.chatThreadReply.chatThreadId }))
          : of(ChatThreadReplyCacheActions.upsert({ chatThreadReply: action.chatThreadReply }));
      })
    )
  );
}
