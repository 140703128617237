import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ParticipantChange, TimeLineEntry } from '../../chat-modal.store';

@Component({
  selector: 'app-chat-timeline-participant-change-entry',
  templateUrl: './chat-timeline-participant-change-entry.component.html',
  styleUrls: ['./chat-timeline-participant-change-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTimelineParticipantChangeEntryComponent {
  @Input() entry: TimeLineEntry;
  get participantChange(): ParticipantChange {
    return this.entry as ParticipantChange;
  }
}
