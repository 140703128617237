import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { empty, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, takeUntil, tap, timeoutWith, withLatestFrom } from 'rxjs/operators';
import { PushService } from '../../push.service';
import * as pushActions from '../actions';
import { RegisterInstallation, RegisterInstallationFail, RegisterInstallationSuccess } from '../actions';
import * as fromFeature from '../reducers';
import { getPushState } from '../selectors';

@Injectable()
export class PushEffects {
  constructor(
    private actions$: Actions,
    private pushService: PushService,
    private store: Store<fromFeature.AppState>
  ) {}

  initialise$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<pushActions.PushInit>(pushActions.PUSH_INIT),
        tap((action) => {
          this.pushService.registerPlatform();
        })
      ),
    { dispatch: false }
  );

  teardown$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<pushActions.PushTeardown>(pushActions.PUSH_TEARDOWN),
        withLatestFrom(this.store.select(getPushState)),
        mergeMap(([action, state]) => {
          if (state.installationId) {
            this.pushService.unregisterPlatform();
            // console.log('deleting installation id: ' + state.installationId);
            return this.pushService.deleteInstallation(state.installationId).pipe(catchError((error) => of(null)));
          }
          return empty();
        })
      ),
    { dispatch: false }
  );

  deviceRegistered$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<pushActions.DeviceRegistered>(pushActions.DEVICE_REGISTERED),
      map((action: pushActions.DeviceRegistered) => action),
      withLatestFrom(this.store.select(getPushState)),
      mergeMap(([action, state]) => {
        if (
          !state.installationId ||
          !state.registration ||
          state.registration.registrationId !== action.payload.registration.registrationId ||
          state.version !== action.payload.version
        ) {
          return of(
            new RegisterInstallation({
              registration: action.payload.registration,
              version: action.payload.version
            })
          );
        }
        return empty();
      })
    )
  );

  registerInstallation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<pushActions.RegisterInstallation>(pushActions.REGISTER_INSTALLATION),
      map((action: pushActions.RegisterInstallation) => action),
      withLatestFrom(this.store.select(getPushState)),
      mergeMap(([action, state]) => {
        const installationId$ = state.installationId
          ? of(state.installationId)
          : this.pushService.getInstallationId(action.payload.registration.registrationId, action.payload.version);
        return installationId$.pipe(
          mergeMap((installationId) =>
            this.pushService
              .registerInstallation(
                installationId,
                action.payload.registration.registrationId,
                action.payload.registration.registrationType,
                [],
                action.payload.version
              )
              .pipe(
                map(() => {
                  return new RegisterInstallationSuccess({
                    installationId,
                    registration: action.payload.registration,
                    version: action.payload.version
                  });
                }),
                catchError((error) => {
                  return of(
                    new RegisterInstallationFail({
                      error,
                      registration: action.payload.registration,
                      version: action.payload.version
                    })
                  );
                })
              )
          ),
          catchError((error) => {
            return of(
              new RegisterInstallationFail({
                error,
                registration: action.payload.registration,
                version: action.payload.version
              })
            );
          })
        );
      })
    )
  );

  // We want to retry the failed registration immediately if we get told to flush
  // or after a timeout, until we get teardown (i.e. logout or a new register installation)

  registerInstallationFail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<pushActions.RegisterInstallationFail>(pushActions.REGISTER_INSTALLATION_FAIL),
      map((action: pushActions.RegisterInstallationFail) => action),
      mergeMap((action) => {
        if (this.pushService.pushEnabled()) {
          return this.actions$.pipe(
            ofType(pushActions.FLUSH_REGISTER_INSTALLATION),
            takeUntil(this.actions$.pipe(ofType(pushActions.PUSH_TEARDOWN, pushActions.REGISTER_INSTALLATION))),
            take(1),
            timeoutWith(
              60000,
              of(
                new RegisterInstallation({
                  registration: action.payload.registration,
                  version: action.payload.version
                })
              )
            ),
            mergeMap(() => {
              return of(
                new RegisterInstallation({
                  registration: action.payload.registration,
                  version: action.payload.version
                })
              );
            })
          );
        }
      })
    )
  );
}
