import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppReleaseDto } from 'src/app/services/app-version.api.service';
import { visitAppStore } from 'src/app/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-update-modal',
  templateUrl: './app-update-modal.component.html',
  styleUrls: ['./app-update-modal.component.scss']
})
export class AppUpdateModalComponent implements OnInit {
  @Input() forced: boolean;
  @Input() osSupported: boolean;
  @Input() latestRelease: AppReleaseDto;
  constructor(
    private modalController: ModalController,
    private store: Store
  ) {}

  ngOnInit(): void {}

  onDismiss(): void {
    this.modalController.dismiss();
  }

  onUpdate(): void {
    this.store.dispatch(visitAppStore());
  }

  get title(): string {
    return 'Update Available';
  }

  get appCurrentVersion(): string {
    return environment.versionName;
  }
}
