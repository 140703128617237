import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ChatThreadReplyCacheActions } from '../actions/chat-thread-reply-cache.action';
import * as coreActions from '../actions/core.action';

export interface ChatThreadReply {
  chatThreadId: number;
  content: string;
}
export interface State extends EntityState<ChatThreadReply> {}

export const chatThreadReplyCacheAdapter: EntityAdapter<ChatThreadReply> = createEntityAdapter<ChatThreadReply>({
  selectId: (chatThreadReply: ChatThreadReply) => chatThreadReply.chatThreadId
});

export const initialState: State = chatThreadReplyCacheAdapter.getInitialState({});

export const theReducer = createReducer(
  initialState,
  on(ChatThreadReplyCacheActions.upsert, (state, action) => {
    return chatThreadReplyCacheAdapter.upsertOne(action.chatThreadReply, state);
  }),
  on(ChatThreadReplyCacheActions.delete, (state, action) => {
    return chatThreadReplyCacheAdapter.removeOne(action.chatThreadId, state);
  })
);
export function reducer(state: State | undefined, action: Action): any {
  switch (action.type) {
    case coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED:
      return initialState;
    default:
      return theReducer(state, action);
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = chatThreadReplyCacheAdapter.getSelectors();
