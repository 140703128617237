import { ActionReducerMap } from '@ngrx/store';
import * as fromAccount from './account.reducer';
import * as fromAppVersion from './app-version.reducer';
import * as fromChannel from './channel.reducer';
import * as fromChatMessageReceipts from './chat-message-receipts.reducer';
import * as fromChatPushLoadProgress from './chat-push-load-progress.reducer';
import * as fromChatThreadPendingReply from './chat-thread-pending-reply.reducer';
import * as fromChatThreadReplyCache from './chat-thread-reply-cache.reducer';
import * as fromChatThread from './chat-thread.reducer';
import * as fromChatThreadsFilterSettings from './chat-threads-filter-settings.reducer';
import * as fromChatThreads from './chat-threads.reducer';
import * as fromContactDetails from './contact-details.reducer';
import * as fromCore from './core.reducer';
import * as fromHydrated from './hydrated.reducer';
import * as fromItemReactions from './item-reactions.reducer';
import * as fromItemTranslations from './item-translations.reducer';
import * as fromLanguages from './languages.reducer';
import * as fromOnboarding from './onboarding.reducer';
import * as fromPaymentIntent from './payment-intent.reducer';
import * as fromSchool from './school.reducer';
import * as fromSettings from './settings.reducer';
import * as fromSlipPaymentIntent from './slip-payment-intent.reducer';
import * as fromSlipQuestionResponse from './slip-question-response.reducer';
import * as fromSlipSettings from './slip-settings.reducer';
import * as fromStudents from './students.reducer';

export interface AppState {
  hydrated: fromHydrated.HydratedState;
  onboardingState: fromOnboarding.OnboardingState;
  coreState: fromCore.CoreState;
  schoolState: fromSchool.SchoolState;
  channelState: fromChannel.ChannelState;
  accountState: fromAccount.AccountState;
  settingsState: fromSettings.SettingsState;
  studentsState: fromStudents.StudentsState;
  contactDetailsState: fromContactDetails.ContactDetailsState;
  paymentIntentState: fromPaymentIntent.State;
  slipPaymentIntentState: fromSlipPaymentIntent.State;
  slipSettingsState: fromSlipSettings.SlipSettingsState;
  appVersionState: fromAppVersion.AppVersionState;
  itemTranslationState: fromItemTranslations.State;
  languagesState: fromLanguages.State;
  slipQuestionResponseState: fromSlipQuestionResponse.State;
  itemReactionsState: fromItemReactions.State;
  chatThreadsState: fromChatThreads.State;
  chatThreadState: fromChatThread.State;
  chatMessageReceiptsState: fromChatMessageReceipts.State;
  chatThreadReplyCacheState: fromChatThreadReplyCache.State;
  chatThreadPendingReplyState: fromChatThreadPendingReply.State;
  chatThreadsFilterSettingsState: fromChatThreadsFilterSettings.State;
  chatPushLoadProgressState: fromChatPushLoadProgress.State;
}

export const reducers: ActionReducerMap<AppState> = {
  hydrated: fromHydrated.reducer,
  onboardingState: fromOnboarding.reducer,
  coreState: fromCore.reducer,
  schoolState: fromSchool.reducer,
  channelState: fromChannel.reducer,
  accountState: fromAccount.reducer,
  settingsState: fromSettings.reducer,
  studentsState: fromStudents.reducer,
  contactDetailsState: fromContactDetails.reducer,
  paymentIntentState: fromPaymentIntent.reducer,
  slipPaymentIntentState: fromSlipPaymentIntent.reducer,
  slipSettingsState: fromSlipSettings.reducer,
  appVersionState: fromAppVersion.reducer,
  itemTranslationState: fromItemTranslations.reducer,
  languagesState: fromLanguages.reducer,
  slipQuestionResponseState: fromSlipQuestionResponse.reducer,
  itemReactionsState: fromItemReactions.reducer,
  chatThreadsState: fromChatThreads.reducer,
  chatThreadState: fromChatThread.reducer,
  chatMessageReceiptsState: fromChatMessageReceipts.reducer,
  chatThreadReplyCacheState: fromChatThreadReplyCache.reducer,
  chatThreadPendingReplyState: fromChatThreadPendingReply.reducer,
  chatThreadsFilterSettingsState: fromChatThreadsFilterSettings.reducer,
  chatPushLoadProgressState: fromChatPushLoadProgress.reducer
};
