import { PublisherViewModel } from './publisher.model';

export interface Attachment {
  attachmentMimeType: string;
  attachmentId: string;
  name: string;
  size: number;
  order: number;
  url: string;
  fullUrl: string;
  thumbUrl: string;
  downloading: boolean;
  downloaded: boolean;
}

export interface Item {
  categoryId: number;
  categoryInfo: any;
  pageSource: string;
  showInFeed: boolean;
  itemType: number;
  sequenceNumber: number;
  id: number;
  title: string;
  message: string;
  createdOn: Date;
  readOn: string;
  localReadOn: string;
  done: boolean;
  // Note: A doneOn date does not imply the item is done!
  doneOn: Date;
  archivedOn: Date;
  createdBy: string;
  attachments: string[];
  schoolId: number;
  channelId: number;
  studentId: number;
  allowedReactions: {
    id: string;
    name: string;
  }[];
  reaction: {
    id: string;
    name: string;
    reactionOn: string;
  };
}

export class AttachmentsViewModel {
  constructor(
    public images: Attachment[],
    public documents: Attachment[]
  ) {}
}

export type ItemViewModel =
  | ChannelArticleItemViewModel
  | AnnouncementItemViewModel
  | AttendanceItemViewModel
  | PaymentItemViewModel
  | SlipItemViewModel
  | UnknownItemViewModel;

export class ChannelArticleItemViewModel {
  constructor(
    public pageSource: string,
    public showInFeed: boolean,
    public itemType: number,
    public sequenceNumber: number,
    public id: number,
    public title: string,
    public message: string,
    public translation: {
      showTranslate: boolean;
      showRevert: boolean;
      inProgress: boolean;
      direction: string;
    },
    public createdOn: Date,
    public read: boolean,
    public readOn: string,
    public localReadOn: string,
    public done: boolean,
    public presub: boolean,
    public createdBy: PublisherViewModel,
    public attachments: AttachmentsViewModel,
    public urlFragment: string,
    public channel: {
      id: number;
      name: string;
    },
    public reaction: {
      id: string;
      description: string;
      inProgress: boolean;
    },
    public allowedReactions: {
      id: string;
      description: string;
    }[]
  ) {}
}

export class AnnouncementItemViewModel {
  constructor(
    public pageSource: string,
    public showInFeed: boolean,
    public itemType: number,
    public sequenceNumber: number,
    public id: number,
    public schoolId: number,
    public title: string,
    public message: string,
    public translation: {
      showTranslate: boolean;
      showRevert: boolean;
      inProgress: boolean;
      direction: string;
    },
    public createdOn: Date,
    public read: boolean,
    public readOn: string,
    public localReadOn: string,
    public done: boolean,
    public presub: boolean,
    public createdBy: PublisherViewModel,
    public attachments: AttachmentsViewModel,
    public urlFragment: string,
    public student: {
      id: number;
      forename: string;
    },
    public reaction: {
      id: string;
      description: string;
      inProgress: boolean;
    },
    public allowedReactions: {
      id: string;
      description: string;
    }[]
  ) {}
}

export class AttendanceItemViewModel {
  constructor(
    public pageSource: string,
    public showInFeed: boolean,
    public itemType: number,
    public sequenceNumber: number,
    public id: number,
    public schoolId: number,
    public schoolTelephone: string,
    public title: string,
    public message: string,
    public translation: {
      showTranslate: boolean;
      showRevert: boolean;
      inProgress: boolean;
      direction: string;
    },
    public createdOn: Date,
    public read: boolean,
    public readOn: string,
    public localReadOn: string,
    public done: boolean,
    public presub: boolean,
    public createdBy: PublisherViewModel,
    public attachments: AttachmentsViewModel,
    public urlFragment: string,
    public student: {
      id: number;
      forename: string;
    },
    public reaction: {
      id: string;
      description: string;
      inProgress: boolean;
    },
    public allowedReactions: {
      id: string;
      description: string;
    }[]
  ) {}
}

export class PaymentItemViewModel {
  constructor(
    public categoryId: number,
    public pageSource: string,
    public showInFeed: boolean,
    public itemType: number,
    public sequenceNumber: number,
    public id: number,
    public schoolId: number,
    public title: string,
    public message: string,
    public translation: {
      showTranslate: boolean;
      showRevert: boolean;
      inProgress: boolean;
      direction: string;
    },
    public createdOn: Date,
    public read: boolean,
    public readOn: string,
    public localReadOn: string,
    public done: boolean,
    public presub: boolean,
    public createdBy: PublisherViewModel,
    public attachments: AttachmentsViewModel,
    public urlFragment: string,
    public student: {
      id: number;
      forename: string;
    },
    public reaction: {
      id: string;
      description: string;
      inProgress: boolean;
    },
    public allowedReactions: {
      id: string;
      description: string;
    }[]
  ) {}
}

export class SlipItemViewModel {
  constructor(
    public categoryId: number,
    public categoryInfo: any,
    public pageSource: string,
    public showInFeed: boolean,
    public itemType: number,
    public sequenceNumber: number,
    public id: number,
    public schoolId: number,
    public title: string,
    public message: string,
    public translation: {
      showTranslate: boolean;
      showRevert: boolean;
      inProgress: boolean;
      direction: string;
    },
    public createdOn: Date,
    public read: boolean,
    public readOn: string,
    public localReadOn: string,
    public done: boolean,
    public presub: boolean,
    public createdBy: PublisherViewModel,
    public attachments: AttachmentsViewModel,
    public urlFragment: string,
    public student: {
      id: number;
      forename: string;
    },
    public reaction: {
      id: string;
      description: string;
      inProgress: boolean;
    },
    public allowedReactions: {
      id: string;
      description: string;
    }[]
  ) {}
}

export class UnknownItemViewModel {
  constructor(
    public pageSource: string,
    public showInFeed: boolean,
    public itemType: number,
    public sequenceNumber: number,
    public id: number,
    public schoolId: number,
    public title: string,
    public message: string,
    public translation: {
      showTranslate: boolean;
      showRevert: boolean;
      inProgress: boolean;
      direction: string;
    },
    public createdOn: Date,
    public read: boolean,
    public readOn: string,
    public localReadOn: string,
    public done: boolean,
    public presub: boolean,
    public createdBy: PublisherViewModel,
    public attachments: AttachmentsViewModel,
    public urlFragment: string,
    public student: {
      id: number;
      forename: string;
    },
    public channel: {
      id: number;
      name: string;
    },
    public reaction: {
      id: string;
      description: string;
      inProgress: boolean;
    },
    public allowedReactions: {
      id: string;
      description: string;
    }[]
  ) {}
}
