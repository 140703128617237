import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactDetailsState } from '../reducers/contact-details.reducer';

export const getContactDetailsState = createFeatureSelector<ContactDetailsState>('contactDetailsState');

export const getContactDetailsViewModel = createSelector(getContactDetailsState, (state) => {
  return {
    loading: state.loading,
    loadingFailed: state.loadingFailed,
    details: state.details
      ? {
          name: `${state.details.title} ${state.details.forename} ${state.details.surname}`,
          email: state.details.email,
          mobile: state.details.mobile
        }
      : null
  };
});

export const getLoadingContactDetails = createSelector(
  getContactDetailsState,
  (state) => state.loading || state.loading
);

export const getLoadingContactDetailsFailed = createSelector(
  getContactDetailsState,
  (state) => state.loadingFailed || state.loadingFailed
);
