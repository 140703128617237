// Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DisplayNameFormat } from '../models/publisher.model';

export const ChatThreadCompatibilityLevel = 0;
export const ChatThreadMessageCompatibilityLevel = 0;
export interface ChatThreadMessageDto {
  messageId: number;
  compatibilityLevel: number;
  createdOn: string;
  createdByParticipantId: number;
  deletedOn: string;
  deletedByParticipantId: number;
  content: string;
  reactions: {
    participantId: number;
    reactionId: string;
    reactionOn: string;
    readOn: string;
  }[];
}
export interface ChatThreadParticipantDto {
  participantId: number;
  isMe: boolean;
  joinedOn: string;
  personId: number;
  userId: string;
  title: string;
  forename: string;
  surname: string;
  displayNameFormat: DisplayNameFormat;
  avatarUrl: string;
}
// We need to make sure local edits get bubble up to the top of the list
// so add a local sequence for ordering purposes
export interface ExtendedChatThreadDto extends ChatThreadDto {
  localSequenceNumber?: number;
  isSearchResult: boolean;
}
export interface ChatThreadDto {
  threadId: number;
  schoolId: number;
  compatibilityLevel: number;
  createdOn: string;
  closedOn: string;
  changeSequenceNumber: number;
  orderSequenceNumber: number;
  chat: {
    chatId: number;
    createdOn: string;
    closedOn: string;
    title: string;
  };
  about: {
    personId: number;
    personTypeId: number;
    forename: string;
    surname: string;
  }[];
  participants: ChatThreadParticipantDto[];
  messages: ChatThreadMessageDto[];
}

export interface ChatsResponse {
  chatThreads: ExtendedChatThreadDto[];
  hasMore: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private http: HttpClient) {}

  loadChats(
    minChangeSequenceNumber: number,
    maxOrderSequenceNumber: number,
    searchTerm: string,
    filterExpression: string
  ): Observable<ChatsResponse> {
    const take = 10;
    let url = environment.apiBaseUrl + `/api/chat/thread?take=${take}`;
    if (minChangeSequenceNumber) url += '&minChangeSequenceNumber=' + minChangeSequenceNumber;
    if (maxOrderSequenceNumber) url += '&maxOrderSequenceNumber=' + maxOrderSequenceNumber;
    if (searchTerm) url += '&searchTerm=' + searchTerm;
    if (filterExpression) url += '&filters=' + filterExpression;
    return this.http.get<ChatsResponse>(url).pipe(
      map((result) => ({
        ...result,
        chatThreads: result.chatThreads.map((thread) => ({
          ...thread,
          localSequenceNumber: 0,
          isSearchResult: !!searchTerm
        }))
      }))
    );
    //return this.chatTestData();
  }

  loadChat(threadId: number, schoolId: number): Observable<ExtendedChatThreadDto> {
    const url = environment.apiBaseUrl + `/api/school/${schoolId}/chat/thread/${threadId}`;
    return this.http.get<ExtendedChatThreadDto>(url).pipe(
      map((result) => ({
        ...result,
        localSequenceNumber: 0
      }))
    );
  }

  putChatThreadLatestMessageRead(schoolId: number, threadId: number, chatThreadMessageId: number): Observable<void> {
    const url =
      environment.apiBaseUrl + `/api/school/${schoolId}/chat/thread/${threadId}/message/${chatThreadMessageId}/read`;

    return this.http.put<void>(url, null);
  }

  putChatThreadMessage(schoolId: number, threadId: number, content: string): Observable<ChatThreadMessageDto> {
    const url = environment.apiBaseUrl + `/api/school/${schoolId}/chat/thread/${threadId}/message`;

    return this.http.put<ChatThreadMessageDto>(url, { content: content });
  }

  // private chatTestData(): Observable<ChatsResponse> {
  //   return new Observable<ChatsResponse>((subscriber) => {
  //     subscriber.next({
  //       chatThreads: [
  //         {
  //           threadId: 1,
  //           schoolId: 1068,
  //           createdOn: '2023-08-04T09:29:22.443Z',
  //           closedOn: null,
  //           changeSequenceNumber: 1,
  //           orderSequenceNumber: 1,
  //           chat: {
  //             chatId: 2,
  //             createdOn: '2023-08-04T09:29:22.443Z',
  //             closedOn: null,
  //             title: 'Chat 1'
  //           },
  //           about: [
  //             {
  //               personId: 124,
  //               personTypeId: 346,
  //               forename: 'Lisa',
  //               surname: 'Simpson'
  //             }
  //           ],
  //           participants: [
  //             {
  //               participantId: 11,
  //               isMe: false,
  //               personId: 345,
  //               userId: 'aaa',
  //               joinedOn: '2023-08-04T09:29:22.443Z',
  //               title: 'Mr',
  //               forename: 'James',
  //               surname: 'Whitaker',
  //               displayNameFormat: DisplayNameFormat.TitleFirstnameLastname,
  //               avatarUrl: null
  //             },
  //             {
  //               participantId: 21,
  //               isMe: true,
  //               personId: 1345,
  //               userId: 'zaaa',
  //               joinedOn: '2023-08-04T09:29:22.443Z',
  //               title: null,
  //               forename: 'Homer',
  //               surname: 'Simpson',
  //               displayNameFormat: DisplayNameFormat.TitleFirstnameLastname,
  //               avatarUrl: null
  //             }
  //           ],
  //           messages: [
  //             {
  //               messageId: 777,
  //               createdOn: '2023-08-04T09:29:22.443Z',
  //               content: 'Hello',
  //               createdByParticipantId: 11,
  //               reactions: null
  //             }
  //           ]
  //         },
  //         {
  //           threadId: 2,
  //           schoolId: 1068,
  //           createdOn: '2023-08-04T09:29:22.443Z',
  //           closedOn: null,
  //           changeSequenceNumber: 2,
  //           orderSequenceNumber: 2,
  //           chat: {
  //             chatId: 2,
  //             createdOn: '2023-08-04T09:29:22.443Z',
  //             closedOn: null,
  //             title: 'Chat 1'
  //           },
  //           about: [
  //             {
  //               personId: 123,
  //               personTypeId: 345,
  //               forename: 'Bart',
  //               surname: 'Simpson'
  //             }
  //           ],
  //           participants: [
  //             {
  //               participantId: 12,
  //               isMe: false,
  //               personId: 234,
  //               userId: 'bbb',
  //               joinedOn: '2023-08-04T09:29:22.443Z',
  //               title: 'Mr',
  //               forename: 'James',
  //               surname: 'Whitaker',
  //               displayNameFormat: DisplayNameFormat.TitleFirstnameLastname,
  //               avatarUrl: null
  //             },
  //             {
  //               participantId: 22,
  //               isMe: true,
  //               personId: 1345,
  //               userId: 'zaaa',
  //               joinedOn: '2023-08-04T09:29:22.443Z',
  //               title: null,
  //               forename: 'Homer',
  //               surname: 'Simpson',
  //               displayNameFormat: DisplayNameFormat.TitleFirstnameLastname,
  //               avatarUrl: null
  //             }
  //           ],
  //           messages: [
  //             {
  //               messageId: 777,
  //               createdOn: '2023-08-04T09:29:22.443Z',
  //               content: 'Hello',
  //               createdByParticipantId: 12,
  //               reactions: null
  //             },
  //             {
  //               messageId: 778,
  //               createdOn: '2023-08-04T09:31:22.443Z',
  //               content: 'There',
  //               createdByParticipantId: 12,
  //               reactions: null
  //             },
  //             {
  //               messageId: 779,
  //               createdOn: '2023-08-04T10:45:22.443Z',
  //               content: 'Testing 123',
  //               createdByParticipantId: 22,
  //               reactions: null
  //             },
  //             {
  //               messageId: 780,
  //               createdOn: '2023-08-04T12:13:22.443Z',
  //               content: 'Thanks for you response',
  //               createdByParticipantId: 12,
  //               reactions: null
  //             }
  //           ]
  //         }
  //       ],
  //       hasMore: false
  //     });
  //     subscriber.complete();
  //   }).pipe(delay(2000));
  // }
}
