import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { IonRadioGroup, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getLanguageId, SetLanguage } from 'src/app/store';
import * as fromLanguages from 'src/app/store/actions/languages.action';
import { SupportedLanguage } from 'src/app/store/reducers/languages.reducer';
import { getLoading, selectLanguages } from 'src/app/store/selectors/languages.selector';
@Component({
  selector: 'app-set-language-modal',
  templateUrl: './set-language-modal.component.html',
  styleUrls: ['./set-language-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetLanguageModalComponent implements OnInit {
  languageId$: Observable<string>;
  loading$: Observable<boolean>;
  supportedLanguages$: Observable<SupportedLanguage[]>;
  @ViewChild(IonRadioGroup)
  private languageRadioGroup: IonRadioGroup;
  constructor(
    private store: Store,
    private modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    this.languageId$ = this.store.select(getLanguageId).pipe(map((languageId) => (languageId ? languageId : '0')));
    this.loading$ = this.store.select(getLoading);
    this.supportedLanguages$ = this.store.select(selectLanguages).pipe(
      tap((languages) => {
        if (languages.length === 0) {
          this.store.dispatch(fromLanguages.load());
        }
      })
    );
  }

  onDismiss(): void {
    this.modalCtrl.dismiss();
  }

  get itemSelected(): boolean {
    return !!this.languageRadioGroup?.value;
  }

  onOk(): void {
    this.store.dispatch(new SetLanguage({ id: this.languageRadioGroup.value }));
    this.modalCtrl.dismiss(true);
  }
}
