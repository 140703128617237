<div class="chat-info">
  <div>
    <b>{{ chatInfo.creatorName }}</b> created a new chat with you about {{ chatInfo.aboutName }}.
  </div>
  <div>
    <b>{{ chatInfo.otherParticipantNames.length + 1 }} participants:</b>
    {{ chatInfo.otherParticipantNames.join(', ') }} and You.
  </div>
  <div class="title">{{ chatInfo.title }}</div>
</div>
