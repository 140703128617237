<div class="messages-container">
  <app-user-badge *ngIf="participantMessages.userId" [userId]="participantMessages.userId"></app-user-badge>
  <div class="messages" (appDomChange)="onDomChange($event)">
    <div
      *ngFor="let message of participantMessages.messages; index as i"
      [id]="'msg-' + message.id"
      class="message-bubble"
      [ngClass]="participantMessages.name || message.removed ? 'other' : 'me'"
      [class.requires-upgrade]="message.requiresUpgrade"
      snInViewport
      [inViewportOptions]="inViewportOptions"
      (inViewportChange)="
        onInViewPortChange(!!participantMessages.name, message.id, message.unread, message.removed, $event)
      ">
      <div class="message-content">
        <div *ngIf="i === 0" class="author">{{ participantMessages.name }}</div>
        <div class="box">
          <div class="inner-box">
            <app-dynamic-content
              class="chat-message"
              [ngClass]="message.removed ? 'removed' : ''"
              [content]="message.content"></app-dynamic-content>
            <div class="timestamp">
              <span>{{ message.timestamp }}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="message.requiresUpgrade" class="requires-upgrade">
        <div>
          This message contains content that your current app version can't display. Tap below to upgrade to the latest
          version.
        </div>
        <ion-button class="ph-btn-white" (click)="upgradeClicked.emit()">UPGRADE</ion-button>
      </div>
    </div>
  </div>
</div>
