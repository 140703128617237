/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, selectEntities, SlipPaymentIntentTracking, State } from '../reducers/slip-payment-intent.reducer';

export const getSlipPaymentIntentState = createFeatureSelector<State>('slipPaymentIntentState');

export const selectSlipPaymentIntentEntities = createSelector(getSlipPaymentIntentState, (state) =>
  selectEntities(state)
);
export const selectAllSlipPaymentIntents = createSelector(getSlipPaymentIntentState, (state) => selectAll(state));
export const selectSlipPaymentIntent = (props: { id: number }) =>
  createSelector(selectSlipPaymentIntentEntities, (intents) => intents[props.id] as SlipPaymentIntentTracking);

export const selectSlipPaymentIntentBySlipStudent = (slipId: number, studentId: number) =>
  createSelector(selectAllSlipPaymentIntents, (intents) => {
    return intents.find((i) => i.slipId == slipId && i.studentId == studentId) as SlipPaymentIntentTracking;
  });
