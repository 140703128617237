// State
import { Action } from '@ngrx/store';
import { Channel } from '../../models/channel.model';
// Models
import { School } from '../../models/school.model';

export const FIND_SCHOOL_BY_HANDLE = '[School] Find school by handle';
export const FIND_SCHOOL_BY_HANDLE_SUCCESS = '[School] Find school by handle Success';
export const FIND_SCHOOL_BY_HANDLE_FAIL = '[School] Find school by handle Fail';
export const GET_SCHOOL_LOGO = '[School] Get school logo';
export const GET_SCHOOL_LOGO_SUCCESS = '[School] Get school logo Success';
export const GET_SCHOOL_LOGO_FAIL = '[School] Get school logo Fail';
export const VERIFY_SCHOOL = '[School] Verify school';
export const VERIFY_SCHOOL_SUCCESS = '[School] Verify school Success';
export const VERIFY_SCHOOL_FAIL = '[School] Verify school Fail';
export const VERIFY_REMINDER_SCHOOL = '[School] Verify reminder school';
export const VERIFY_REMINDER_SCHOOL_SUCCESS = '[School] Verify reminder school Success';
export const VERIFY_REMINDER_SCHOOL_FAIL = '[School] Verify reminder school Fail';
export const CONFIRM_VERIFICATION_SCHOOL = '[School] Confirm verification school';
export const CONFIRM_VERIFICATION_SCHOOL_SUCCESS = '[School] Confirm verification school Success';
export const CONFIRM_VERIFICATION_SCHOOL_FAIL = '[School] Confirm verification school Fail';
export const CONFIRM_DOB_SCHOOL = '[School] Confirm DOB school';
export const CONFIRM_DOB_SCHOOL_SUCCESS = '[School] Confirm DOB school Success';
export const CONFIRM_DOB_SCHOOL_FAIL = '[School] Confirm DOB school Fail';
export const CHECK_ADD_SCHOOL_PROGRESS = '[School] Check add school progress';
export const CHECK_ADD_SCHOOL_PROGRESS_SUCCESS = '[School] Check add school progress Success';
export const CHECK_ADD_SCHOOL_PROGRESS_FAIL = '[School] Check add school progress Fail';
export const CONFIRM_SCHOOL = '[School] Confirm school';
export const CONFIRM_SCHOOL_SUCCESS = '[School] Confirm school Success';
export const CONFIRM_SCHOOL_FAIL = '[School] Confirm school Fail';
export const GET_SCHOOL_FEED = '[School] Get school feed';
export const GET_SCHOOL_FEED_SUCCESS = '[School] Get school feed Success';
export const GET_SCHOOL_FEED_FAIL = '[School] Get school feed Fail';
export const GET_SCHOOL_CHANNELS_AND_CHANGE_STEP = '[School] Get school channels and change step';
export const GET_SCHOOL_CHANNELS_AND_CHANGE_STEP_SUCCESS = '[School] Get school channels and change step Success';
export const GET_SCHOOL_CHANNELS_AND_CHANGE_STEP_FAIL = '[School] Get school channels and change step Fail';
export const SUBSCRIBE_TO_SCHOOL_CHANNELS = '[School] Subscribe to school channels';
export const SUBSCRIBE_TO_SCHOOL_CHANNELS_SUCCESS = '[School] Subscribe to school channels Success';
export const SUBSCRIBE_TO_SCHOOL_CHANNELS_FAIL = '[School] Subscribe to school channels Fail';
export const LEAVE_SCHOOL = '[School] Leave school';
export const LEAVE_SCHOOL_SUCCESS = '[School] Leave school Success';
export const LEAVE_SCHOOL_FAIL = '[School] Leave school Fail';
export const SCHOOL_CHANGE_STEP = '[School] Change step';
export const GET_SCHOOL_CHANNELS_AND_NAVIGATE = '[School] Get school channels and navigate';
export const GET_SCHOOL_CHANNELS_AND_NAVIGATE_SUCCESS = '[School] Get school channels and navigate success';
export const GET_SCHOOL_CHANNELS_AND_NAVIGATE_FAIL = '[School] Get school channels and navigate fail';
export const GET_SCHOOL_CHANNELS = '[School] Get school channels';
export const GET_SCHOOL_CHANNELS_SUCCESS = '[School] Get school channels Success';
export const GET_SCHOOL_CHANNELS_FAIL = '[School] Get school channels Fail';
export const UPDATE_STATE_SCHOOL = '[School] Update state school';
export const GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL = '[School] Get subscribed channels for school';
export const GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL_SUCCESS = '[School] Get subscribed channels for school Success';
export const GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL_FAIL = '[School] Get subscribed channels for school Fail';

export class FindSchoolByHandle implements Action {
  readonly type = FIND_SCHOOL_BY_HANDLE;

  constructor(public payload: string) {}
}

export class FindSchoolByHandleSuccess implements Action {
  readonly type = FIND_SCHOOL_BY_HANDLE_SUCCESS;

  constructor(
    public payload: {
      school: School;
    }
  ) {}
}

export class FindSchoolByHandleFail implements Action {
  readonly type = FIND_SCHOOL_BY_HANDLE_FAIL;

  constructor(public payload: { header: string; message: string }) {}
}

export class GetSchoolLogo implements Action {
  readonly type = GET_SCHOOL_LOGO;

  constructor(
    public payload: {
      schoolId: number;
      subscriptionState: number;
      resumeOnboarding: boolean;
    }
  ) {}
}

export class GetSchoolLogoSuccess implements Action {
  readonly type = GET_SCHOOL_LOGO_SUCCESS;

  constructor(
    public payload: {
      schoolId: number;
      logoData: string;
      page: string;
      alreadySubscribed: boolean;
    }
  ) {}
}

export class GetSchoolLogoFail implements Action {
  readonly type = GET_SCHOOL_LOGO_FAIL;

  constructor(
    public payload: {
      page: string;
      alreadySubscribed: boolean;
    }
  ) {}
}

export class VerifySchool implements Action {
  readonly type = VERIFY_SCHOOL;

  constructor(public payload: { schoolId: number; mobileNumber: string }) {}
}

export class VerifySchoolSuccess implements Action {
  readonly type = VERIFY_SCHOOL_SUCCESS;
}

export class VerifySchoolFail implements Action {
  readonly type = VERIFY_SCHOOL_FAIL;

  constructor(
    public payload: {
      errorCode: number;
    }
  ) {}
}

export class ConfirmVerificationSchool implements Action {
  readonly type = CONFIRM_VERIFICATION_SCHOOL;

  constructor(public payload: { schoolId: number; confirmationCode: string }) {}
}

export class ConfirmVerificationSchoolSuccess implements Action {
  readonly type = CONFIRM_VERIFICATION_SCHOOL_SUCCESS;
}

export class ConfirmVerificationSchoolFail implements Action {
  readonly type = CONFIRM_VERIFICATION_SCHOOL_FAIL;

  constructor(public payload: { errorMessage: string; canRetry: boolean }) {}
}

export class VerifyReminderSchool implements Action {
  readonly type = VERIFY_REMINDER_SCHOOL;

  constructor(public payload: number) {}
}

export class VerifyReminderSchoolSuccess implements Action {
  readonly type = VERIFY_REMINDER_SCHOOL_SUCCESS;
}

export class VerifyReminderSchoolFail implements Action {
  readonly type = VERIFY_REMINDER_SCHOOL_FAIL;

  constructor(public payload?: { errorTitle: string; errorMsg: string }) {}
}

export class ConfirmDOBSchool implements Action {
  readonly type = CONFIRM_DOB_SCHOOL;

  constructor(public payload: { schoolId: number; enteredDate: string }) {}
}

export class ConfirmDOBSchoolSuccess implements Action {
  readonly type = CONFIRM_DOB_SCHOOL_SUCCESS;
}

export class ConfirmDOBSchoolFail implements Action {
  readonly type = CONFIRM_DOB_SCHOOL_FAIL;

  constructor(public payload: { errorHeader: string; errorMessage: string; canRetry: boolean }) {}
}

export class CheckAddSchoolProgress implements Action {
  readonly type = CHECK_ADD_SCHOOL_PROGRESS;

  constructor(public payload: { schoolId: number; resumeOnboarding: boolean }) {}
}

export class CheckAddSchoolProgressSuccess implements Action {
  readonly type = CHECK_ADD_SCHOOL_PROGRESS_SUCCESS;
}

export class CheckAddSchoolProgressFail implements Action {
  readonly type = CHECK_ADD_SCHOOL_PROGRESS_FAIL;
}

export class ConfirmSchool implements Action {
  readonly type = CONFIRM_SCHOOL;

  constructor(public payload: { schoolId: number }) {}
}

export class ConfirmSchoolSuccess implements Action {
  readonly type = CONFIRM_SCHOOL_SUCCESS;
}

export class ConfirmSchoolFail implements Action {
  readonly type = CONFIRM_SCHOOL_FAIL;
}

export class GetSchoolFeed implements Action {
  readonly type = GET_SCHOOL_FEED;

  constructor(public payload: { schoolId: number }) {}
}

export class GetSchoolFeedSuccess implements Action {
  readonly type = GET_SCHOOL_FEED_SUCCESS;

  constructor(public payload: { channel: Channel; school: School }) {}
}

export class GetSchoolFeedFail implements Action {
  readonly type = GET_SCHOOL_FEED_FAIL;
}

export class GetSchoolChannelsAndChangeStep implements Action {
  readonly type = GET_SCHOOL_CHANNELS_AND_CHANGE_STEP;

  constructor(public payload: number) {}
}

export class GetSchoolChannelsAndChangeStepSuccess implements Action {
  readonly type = GET_SCHOOL_CHANNELS_AND_CHANGE_STEP_SUCCESS;

  constructor(public payload: Channel[]) {}
}

export class GetSchoolChannelsAndChangeStepFail implements Action {
  readonly type = GET_SCHOOL_CHANNELS_AND_CHANGE_STEP_FAIL;
}

export class SubscribeToSchoolChannels implements Action {
  readonly type = SUBSCRIBE_TO_SCHOOL_CHANNELS;

  constructor(public payload: number[]) {}
}

export class SubscribeToSchoolChannelsSuccess implements Action {
  readonly type = SUBSCRIBE_TO_SCHOOL_CHANNELS_SUCCESS;

  constructor(public payload?: string) {}
}

export class SubscribeToSchoolChannelsFail implements Action {
  readonly type = SUBSCRIBE_TO_SCHOOL_CHANNELS_FAIL;
}

export class LeaveSchool implements Action {
  readonly type = LEAVE_SCHOOL;

  constructor(public payload: { id: number }) {}
}

export class LeaveSchoolSuccess implements Action {
  readonly type = LEAVE_SCHOOL_SUCCESS;
}

export class LeaveSchoolFail implements Action {
  readonly type = LEAVE_SCHOOL_FAIL;

  constructor(public payload: { id: number }) {}
}

export class SchoolChangeStep implements Action {
  readonly type = SCHOOL_CHANGE_STEP;

  constructor(public payload: { step: number; redirectTo?: string }) {}
}

export class GetSchoolChannelsAndNavigate implements Action {
  readonly type = GET_SCHOOL_CHANNELS_AND_NAVIGATE;

  constructor(public payload: { page: string; schoolId: number }) {}
}

export class GetSchoolChannelsAndNavigateSuccess implements Action {
  readonly type = GET_SCHOOL_CHANNELS_AND_NAVIGATE_SUCCESS;

  constructor(public payload: Channel[]) {}
}

export class GetSchoolChannelsAndNavigateFail implements Action {
  readonly type = GET_SCHOOL_CHANNELS_AND_NAVIGATE_FAIL;
}

export class GetSchoolChannels implements Action {
  readonly type = GET_SCHOOL_CHANNELS;

  constructor(public payload: number) {}
}

export class GetSchoolChannelsSuccess implements Action {
  readonly type = GET_SCHOOL_CHANNELS_SUCCESS;

  constructor(public payload: Channel[]) {}
}

export class GetSchoolChannelsFail implements Action {
  readonly type = GET_SCHOOL_CHANNELS_FAIL;
}

export class UpdateStateSchool implements Action {
  readonly type = UPDATE_STATE_SCHOOL;

  constructor(public payload: { school: School }) {}
}

export class GetSubscribedChannelsForSchool implements Action {
  readonly type = GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL;

  constructor(public payload?: string) {}
}

export class GetSubscribedChannelsForSchoolSuccess implements Action {
  readonly type = GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL_SUCCESS;

  constructor(public payload: Channel[]) {}
}

export class GetSubscribedChannelsForSchoolFail implements Action {
  readonly type = GET_SUBSCRIBED_CHANNELS_FOR_SCHOOL_FAIL;
}

export type SchoolAction =
  | FindSchoolByHandle
  | FindSchoolByHandleSuccess
  | FindSchoolByHandleFail
  | GetSchoolLogo
  | GetSchoolLogoSuccess
  | GetSchoolLogoFail
  | VerifySchool
  | VerifySchoolSuccess
  | VerifySchoolFail
  | ConfirmVerificationSchool
  | ConfirmVerificationSchoolSuccess
  | ConfirmVerificationSchoolFail
  | VerifyReminderSchool
  | VerifyReminderSchoolSuccess
  | VerifyReminderSchoolFail
  | ConfirmDOBSchool
  | ConfirmDOBSchoolSuccess
  | ConfirmDOBSchoolFail
  | CheckAddSchoolProgress
  | CheckAddSchoolProgressSuccess
  | CheckAddSchoolProgressFail
  | ConfirmSchool
  | ConfirmSchoolSuccess
  | ConfirmSchoolFail
  | GetSchoolFeed
  | GetSchoolFeedSuccess
  | GetSchoolFeedFail
  | GetSchoolChannelsAndChangeStep
  | GetSchoolChannelsAndChangeStepSuccess
  | GetSchoolChannelsAndChangeStepFail
  | SubscribeToSchoolChannels
  | SubscribeToSchoolChannelsSuccess
  | SubscribeToSchoolChannelsFail
  | LeaveSchool
  | LeaveSchoolSuccess
  | LeaveSchoolFail
  | SchoolChangeStep
  | GetSchoolChannelsAndNavigate
  | GetSchoolChannelsAndNavigateSuccess
  | GetSchoolChannelsAndNavigateFail
  | GetSchoolChannels
  | GetSchoolChannelsSuccess
  | GetSchoolChannelsFail
  | UpdateStateSchool
  | GetSubscribedChannelsForSchool
  | GetSubscribedChannelsForSchoolSuccess
  | GetSubscribedChannelsForSchoolFail;
