import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';

export const getPushState = createSelector(fromFeature.getPushModuleState, (state) => state.pushState);

export const getNotification = createSelector(getPushState, (state) => state.notification);
export const getNotificationsAtResume = createSelector(getPushState, (state) => state.notificationsAtResume);

export const getPushAction = createSelector(getPushState, (state) => state.action);
export const getChatTapActionPending = createSelector(getPushAction, (state) => {
  const pushData = state?.notification.data.aps ?? state?.notification.data;
  return pushData?.type === '2' && state?.actionId === 'tap';
});
