import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  appInsights: ApplicationInsights;
  properties: { [prop: string]: string };
  constructor(private router: Router) {}
  init(insightsConnectionString: string, telemetryInitializer: (item: ITelemetryItem) => boolean | void): void {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: insightsConnectionString
      }
    });
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
    this.appInsights.loadAppInsights();

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.appInsights.startTrackPage(event.url);
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.appInsights.stopTrackPage(event.url);
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
