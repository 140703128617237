import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../actions/slip-payment-intent.action';

export interface SlipPaymentIntentTracking {
  id: number;
  slipId: number;
  studentId: number;
  tenant: number;
  method: string;
  attempt: number;
  nextCheckTimestamp: number;
}
export type State = EntityState<SlipPaymentIntentTracking>;
export const slipPaymentIntentAdapter: EntityAdapter<SlipPaymentIntentTracking> =
  createEntityAdapter<SlipPaymentIntentTracking>();

export const initialState: State = slipPaymentIntentAdapter.getInitialState();

export const theReducer = createReducer(
  initialState,
  on(actions.addIntent, (state, action) => slipPaymentIntentAdapter.addOne(action.intent, state)),
  on(actions.updateIntent, (state, action) => slipPaymentIntentAdapter.updateOne(action.update, state)),
  on(actions.deleteIntent, (state, action) => slipPaymentIntentAdapter.removeOne(action.id, state))
);
export function reducer(state: State | undefined, action: Action): any {
  return theReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = slipPaymentIntentAdapter.getSelectors();
