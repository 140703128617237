import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, TimeoutError } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ReactionApiService } from 'src/app/services/reaction.api.service';
import { applyTimer } from 'src/app/services/timer';
import { getItemEntities } from '..';
import * as coreActions from '../actions/core.action';
import * as actions from '../actions/item-reactions.action';

@Injectable()
export class ItemReactionsEffects {
  constructor(
    private reactionService: ReactionApiService,
    private store: Store,
    private actions$: Actions
  ) {}

  translateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reactToItem),
      withLatestFrom(this.store.select(getItemEntities)),
      mergeMap(([action, items]) => {
        const item = items[action.id];
        const api = !item.channelId
          ? this.reactionService.reactToAnnouncement(item.schoolId, item.id, item.studentId, action.reactionId)
          : this.reactionService.reactToArticle(item.id, action.reactionId);
        return applyTimer(api).pipe(
          map((result) => {
            return result instanceof TimeoutError
              ? actions.reactToItemFail({ id: action.id, error: 'unknown' })
              : actions.reactToItemSuccess({
                  id: action.id,
                  reactionId: action.reactionId
                });
          }),
          catchError((err) => {
            const error = err.status === 0 ? 'offline' : err.error?.ErrorCode === 2001 ? 'not-supported' : 'unknown';
            return of(actions.reactToItemFail({ id: action.id, error: error }));
          })
        );
      })
    )
  );

  reactToItemSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reactToItemSuccess),
      mergeMap((action) => {
        return of(
          new coreActions.UpdateItem({
            id: action.id,
            changes: {
              reaction: action.reactionId
                ? { id: action.reactionId, name: null, reactionOn: new Date().toUTCString() }
                : null
            }
          })
        );
      })
    )
  );
}
