// State
import { ActionPerformed, PushNotificationSchema } from '@capacitor/push-notifications';
import { Action } from '@ngrx/store';
import { Registration } from '../registration.model';

export const PUSH_INIT = '[Push] Push Init';
export const PUSH_RECEIVED = '[Push] Push Received';
export const RESUMED_WITH_NOTIFICATIONS = '[Push] Resumed With Notifications';
export const RESUMED_WITH_NOTIFICATIONS_CLEAR = '[Push] Resumed With Notifications Clear';
export const PUSH_CLEAR = '[Push] Push Clear';
export const PUSH_ACTION_PERFORMED = '[Push] Push Action Performed';
export const PUSH_ACTION_CLEAR = '[Push] Push Action Clear';
export const DEVICE_REGISTERED = '[Push] Device Registered';
export const REGISTER_INSTALLATION = '[Push] Register Installation';
export const REGISTER_INSTALLATION_SUCCESS = '[Push] Register Installation Success';
export const REGISTER_INSTALLATION_FAIL = '[Push] Register Installation Fail';
export const FLUSH_REGISTER_INSTALLATION = '[Push] Flush Register Installation';
export const PUSH_TEARDOWN = '[Push] TEARDOWN';

export class PushInit implements Action {
  readonly type = PUSH_INIT;
}

export class PushReceived implements Action {
  readonly type = PUSH_RECEIVED;
  constructor(public payload: { notification: PushNotificationSchema; isForeground: boolean }) {}
}
export class ResumedWithNotifications implements Action {
  readonly type = RESUMED_WITH_NOTIFICATIONS;
  constructor(public payload: { notifications: PushNotificationSchema[] }) {}
}
export class ResumedWithNotificationsClear implements Action {
  readonly type = RESUMED_WITH_NOTIFICATIONS_CLEAR;
}
export class PushClear implements Action {
  readonly type = PUSH_CLEAR;
}

export class PushActionPerformed implements Action {
  readonly type = PUSH_ACTION_PERFORMED;
  constructor(public payload: { action: ActionPerformed }) {}
}

export class PushActionClear implements Action {
  readonly type = PUSH_ACTION_CLEAR;
}
export class DeviceRegistered implements Action {
  readonly type = DEVICE_REGISTERED;
  constructor(public payload: { registration: Registration; version: string }) {}
}

export class RegisterInstallation implements Action {
  readonly type = REGISTER_INSTALLATION;
  constructor(public payload: { registration: Registration; version: string }) {}
}
export class RegisterInstallationSuccess implements Action {
  readonly type = REGISTER_INSTALLATION_SUCCESS;
  constructor(public payload: { registration: Registration; installationId: string; version: string }) {}
}
export class RegisterInstallationFail implements Action {
  readonly type = REGISTER_INSTALLATION_FAIL;
  constructor(public payload: { error: any; registration: Registration; version: string }) {}
}
export class FlushRegisterInstallation implements Action {
  readonly type = FLUSH_REGISTER_INSTALLATION;
}

export class PushTeardown implements Action {
  readonly type = PUSH_TEARDOWN;
}

export type PushAction =
  | PushInit
  | PushReceived
  | ResumedWithNotifications
  | ResumedWithNotificationsClear
  | PushClear
  | PushActionPerformed
  | PushActionClear
  | DeviceRegistered
  | RegisterInstallation
  | RegisterInstallationSuccess
  | RegisterInstallationFail
  | FlushRegisterInstallation
  | PushTeardown;
