import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { StudentPaymentsStore } from 'src/app/pages/student-payments/student-payments.store';
import { PaymentService } from 'src/app/services/payment.service';
import * as actions from '../actions/payment-intent.action';
import { selectAllPaymentIntents, selectPaymentIntent } from '../selectors/payment-intent.selector';

@Injectable()
export class PaymentIntentEffects {
  constructor(
    private paymentService: PaymentService,
    private studentPaymentsStore: StudentPaymentsStore,
    private toastController: ToastController,
    private store: Store,
    private actions$: Actions
  ) {}

  startIntentProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.startIntentProcessing),
      switchMap(() => {
        return timer(10000, 10000).pipe(
          withLatestFrom(this.store.select(selectAllPaymentIntents)),
          mergeMap(([, intents]) =>
            intents
              .filter((s) => (s.nextCheckTimestamp ?? 0) <= Date.now())
              .map((s) => actions.checkIntent({ intent: s }))
          )
        );
      })
    )
  );

  checkIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.checkIntent),
      mergeMap((action) => {
        return this.paymentService
          .loadPaymentIntent(action.intent.tenant, action.intent.studentId, action.intent.paymentId, action.intent.id)
          .pipe(
            switchMap((intent) => {
              if (intent.completedOn && intent.status === 'succeeded') {
                return this.paymentService
                  .loadPayment(action.intent.tenant, action.intent.studentId, action.intent.paymentId)
                  .pipe(
                    map((result) =>
                      actions.checkIntentSuccess({ id: action.intent.id, amount: intent.amount, payment: result })
                    ),
                    catchError((result) =>
                      of(actions.checkIntentFail({ id: action.intent.id, code: result.status, reason: result.error }))
                    )
                  );
              } else {
                return of(actions.checkIntentFail({ id: action.intent.id, code: 200, reason: 'Not completed yet' }));
              }
            }),
            catchError((result) =>
              of(actions.checkIntentFail({ id: action.intent.id, code: result.status, reason: result.error }))
            )
          );
      })
    )
  );

  checkIntentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.checkIntentSuccess),
      exhaustMap((action) => {
        const amount = (action.amount / 100).toFixed(2);
        const toast = this.toastController.create({
          message: `Payment of £${amount} confirmed`,
          duration: 2000,
          position: 'top'
        });

        toast.then((t) => t.present());
        this.studentPaymentsStore.updatePayment({ payment: action.payment, studentId: action.payment.studentId });
        return of(actions.deleteIntent({ id: action.id }));
      })
    )
  );

  checkIntentFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.checkIntentFail),
      mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectPaymentIntent, { id: action.id })))),
      exhaustMap(([action, intentTracker]) => {
        if (action.code === 404) {
          return of(actions.deleteIntent({ id: action.id }));
        }
        const nextAttempt = intentTracker.attempt + 1;
        const offset = nextAttempt < 5 ? nextAttempt * 10 : 60;
        const now = new Date();
        const nextCheck = now.setSeconds(now.getSeconds() + offset);
        return of(
          actions.updateIntent({
            update: {
              id: intentTracker.id,
              changes: { ...intentTracker, attempt: nextAttempt, nextCheckTimestamp: nextCheck }
            }
          })
        );
      })
    )
  );
}
