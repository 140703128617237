<ion-header class="ion-no-border">
  <ion-toolbar noUnderline>
    <ion-buttons slot="end" *ngIf="forced === false && osSupported === true">
      <ion-button fill="clear" class="secondaryTextLink" type="button" (click)="onDismiss()">SKIP </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="supported" *ngIf="osSupported === true">
    <ion-grid>
      <ion-row class="image-row">
        <img src="/assets/imgs/update-os-supported.svg" />
      </ion-row>
      <ion-row>
        <ion-col>
          <h2 [ngClass]="{ forced: forced }">{{ forced ? 'App update required' : 'App update available now!' }}</h2>
          <h3 *ngIf="forced">Please update Parent Hub before continuing.</h3>
          <hr />
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <span class="whats-new">What's new?</span>
          <div [innerHTML]="latestRelease.notes"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="not-supported" *ngIf="osSupported === false">
    <ion-grid>
      <ion-row class="image-row">
        <img src="/assets/imgs/update-os-not-supported.svg" />
      </ion-row>
      <ion-row>
        <ion-col>
          <h2 class="forced">New app requires device<br />system update</h2>
          <h3 *ngIf="forced === false">
            To enjoy the latest version of Parent Hub, please update your device's operating system.
          </h3>
          <h3 *ngIf="forced === true">To keep using Parent Hub, please update your device's operating system.</h3>
          <hr />
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <span class="whats-new">What's new?</span>
          <div [innerHTML]="latestRelease.notes"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="versions">
    <ion-grid>
      <ion-row>
        <ion-col>
          <span>Current version: {{ appCurrentVersion }}</span>
          <span>Latest version: {{ latestRelease.appVersion }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer mode="ios" *ngIf="osSupported === true">
  <ion-button mode="md" (click)="onUpdate()" class="submitButton" color="secondary">Update now</ion-button>
</ion-footer>
<ion-footer mode="ios" *ngIf="forced === false && osSupported === false">
  <ion-button mode="md" (click)="onDismiss()" class="submitButton" color="secondary">OK</ion-button>
</ion-footer>
