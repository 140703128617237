import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from 'src/app/components/components.module';
import { InitialLoadingPage } from './initial-loading.page';

const routes: Routes = [
  {
    path: 'loading',
    component: InitialLoadingPage
  }
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ComponentsModule, RouterModule.forChild(routes)],
  declarations: [InitialLoadingPage]
})
export class InitialLoadingPageModule {}
