// State
import { Action } from '@ngrx/store';
import { ContactDetails } from '../../models/contact-details.model';

export const GET_CONTACT_DETAILS = '[Parent] Get Contact Details';
export const GET_CONTACT_DETAILS_SUCCESS = '[Parent] Get Contact Details Success';
export const GET_CONTACT_DETAILS_FAIL = '[Parent] Get Contact Details Fail';

export class GetContactDetails implements Action {
  readonly type = GET_CONTACT_DETAILS;
  constructor(public payload: { schoolId: number }) {}
}

export class GetContactDetailsSuccess implements Action {
  readonly type = GET_CONTACT_DETAILS_SUCCESS;
  constructor(public payload: { contactDetails: ContactDetails }) {}
}

export class GetContactDetailsFail implements Action {
  readonly type = GET_CONTACT_DETAILS_FAIL;
}

export type ContactDetailsAction = GetContactDetails | GetContactDetailsSuccess | GetContactDetailsFail;
