// Modules
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Channel } from '../models/channel.model';
import { School } from '../models/school.model';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  constructor(private http: HttpClient) {}

  findSchoolByHandle(handle: string): Observable<School> {
    return this.http.get<School>(environment.apiBaseUrl + '/api/school/findByHandle?handle=' + handle);
  }

  findSchoolById(schoolId: number): Observable<School> {
    return this.http.get<School>(environment.apiBaseUrl + `/api/school/${schoolId}`);
  }

  sendVerificationSMS(schoolId: number, mobileNumber: string): Observable<string> {
    const body = {
      mobileNumber: mobileNumber
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.apiBaseUrl + `/api/school/${schoolId}/subscriber/verify`, JSON.stringify(body), {
      headers: headers,
      responseType: 'text'
    });
  }

  confirmVerification(schoolId: number, confirmationCode: string): Observable<string> {
    const body = {
      confirmationCode: confirmationCode
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.apiBaseUrl + `/api/school/${schoolId}/subscriber/confirm`, JSON.stringify(body), {
      headers: headers,
      responseType: 'text'
    });
  }

  resendVerificationSMS(schoolId: number): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(
      environment.apiBaseUrl + `/api/school/${schoolId}/subscriber/verify/remind`,
      {},
      { headers: headers, responseType: 'text' }
    );
  }

  confirmDOB(schoolId: number, enteredDate: string): Observable<string> {
    const body = {
      enteredDate: enteredDate
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(
      environment.apiBaseUrl + `/api/school/${schoolId}/subscriber/confirmdob`,
      JSON.stringify(body),
      { headers: headers, responseType: 'text' }
    );
  }

  checkAddSchoolProgress(schoolId: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + `/api/school/${schoolId}/subscriber`);
  }

  subscribeToSchoolFeed(channelId: number): Observable<string> {
    const body = {
      channelId: channelId
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.apiBaseUrl + '/api/channelsubscription', JSON.stringify(body), {
      headers: headers,
      responseType: 'text'
    });
  }

  getSchool(schoolId: number): Observable<School> {
    return this.http.get<School>(environment.apiBaseUrl + '/api/school/' + schoolId);
  }

  getSchoolChannels(schoolId: number): Observable<Channel[]> {
    return this.http.get<Channel[]>(environment.apiBaseUrl + `/api/school/${schoolId}/channels`);
  }
}
