import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { IonContent, IonTextarea, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { debounceTime, filter, take, tap, timer } from 'rxjs';
import { CalendarEvent } from 'src/app/services/calendar.service';
import { SaveCalendarEvent, visitAppStore } from 'src/app/store';
import { ChatThreadPendingReplyActions } from 'src/app/store/actions/chat-thread-pending-reply.action';
import { ChatThreadReplyCacheActions } from 'src/app/store/actions/chat-thread-reply-cache.action';
import { ChatThreadActions } from 'src/app/store/actions/chat-thread.action';
import { ChatThreadsActions } from 'src/app/store/actions/chat-threads.action';
import { getCachedChatThreadReply } from 'src/app/store/selectors/chat-thread-reply-cache.selector';
import { ChatModalStore } from './chat-modal.store';
@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChatModalStore]
})
export class ChatModalComponent implements OnInit, OnDestroy {
  @Input() reason: string;
  contentModel$ = this.componentStore.contentModel$;
  footerModel$ = this.componentStore.footerModel$;
  // Transition from loading to not loading can happen too quickly for
  // change detector to pass to the spinner component so need cdr
  headerModel$ = this.componentStore.headerModel$.pipe(tap(() => this.cdr.detectChanges()));
  infoSheetModel$ = this.componentStore.infoSheetModel$;
  newMessageModel$ = this.componentStore.newMessagesModel$;
  loadFailureToastState$ = this.componentStore.loadFailureToastState$;

  scrollInfo: { scrollTop: number; scrollHeight: number; clientHeight: number };

  restoreKeyboardMode = null;

  replyContent = '';
  replyContentMaxLength = 2000;

  @ViewChild(IonContent) content: IonContent;
  // We are using a dummy text area to temporarily set focus to on message send
  // This helps reset the keyboard layout on iOS
  @ViewChild('text') textArea: IonTextarea;
  @ViewChild('dummyText') dummyText: IonTextarea;

  public toastButtons = [
    {
      text: 'RETRY',
      role: 'info'
    },
    {
      text: 'CLEAR',
      role: 'cancel'
    }
  ];

  constructor(
    private readonly store: Store,
    private readonly componentStore: ChatModalStore,
    private platform: Platform,
    private cdr: ChangeDetectorRef
  ) {
    this.componentStore.vm$.subscribe(console.log);
  }

  async ngOnDestroy(): Promise<void> {
    try {
      await Keyboard.setResizeMode({
        mode: this.restoreKeyboardMode.mode
      });
    } catch (error) {
      // ignore
    }
  }

  async ngOnInit(): Promise<void> {
    this.platform.pause.subscribe(async () => {
      if (this.textArea) {
        const el = await this.textArea.getInputElement();
        el.blur();
      }
    });
    this.componentStore.init({ reason: this.reason });

    this.store
      .select(getCachedChatThreadReply)
      .pipe(take(1))
      .subscribe((state) => {
        if (state) {
          this.replyContent = state.content;
        }
      });

    // We need Ionic mode for iOS to allow our scroll position preservation behaviour
    try {
      this.restoreKeyboardMode = await Keyboard.getResizeMode();
      await Keyboard.setResizeMode({
        mode: KeyboardResize.Ionic
      });
    } catch (error) {
      // ignore
    }

    this.content.ionScrollEnd.subscribe(async (event) => {
      await this.updateScrollState();
    });
  }

  private async updateScrollState(): Promise<void> {
    const scrollElement = await this.content.getScrollElement();
    // console.log(
    //   `*** Scroll Event - top:${scrollElement.scrollTop}, scrollHeight: ${scrollElement.scrollHeight}, clientHeight: ${scrollElement.clientHeight}`
    // );

    this.scrollInfo = {
      scrollTop: scrollElement.scrollTop,
      scrollHeight: scrollElement.scrollHeight,
      clientHeight: scrollElement.clientHeight
    };
    const outsideLiveZone = scrollElement.scrollTop + scrollElement.clientHeight < scrollElement.scrollHeight - 10;
    this.componentStore.setInsideLiveZone(!outsideLiveZone);
  }

  async ionViewDidEnter(): Promise<void> {
    this.componentStore.scrollAction$
      .pipe(
        debounceTime(100),
        filter((action) => !!action)
      )
      .subscribe(async (action) => {
        console.log('scroll-action', action);

        if (action.type === 'message') {
          if (action.message?.rendered) {
            const element = document.getElementById(action.message.id);
            if (!element) {
              console.log(`Can't find message to scroll to, id: ` + action.message.id);
              return;
            }
            await this.content.scrollToPoint(0, element.offsetTop - 5, 0);
            console.log('scrolling to message ' + action.message.id);

            // Keyboard can change size (emoji) during sending so need to keep
            // our client height up to date so onContentResize can get scroll offset
            // correct
            await this.updateScrollState();
            this.componentStore.clearScrollAction();
          }
        } else if (action.type === 'tidemark') {
          const element = document.getElementById('unread-tidemark');
          if (element) {
            await this.content.scrollToPoint(0, element.offsetTop - 5, 0);
            console.log('scrolling to tidemark');
            this.componentStore.clearScrollAction();
          }
        } else {
          await this.content.scrollToBottom(0);
          this.componentStore.clearScrollAction();
        }

        this.componentStore.setInitialScrollComplete();
      });
  }
  onDismiss(): void {
    this.componentStore.dismissModal();
  }

  // This is a get around for a problem where every second toast button press
  // selects the text area causing the keyboard to appear
  async onToastWillDismiss(): Promise<void> {
    const el = await this.textArea.getInputElement();
    el.blur();
  }
  async onToastDidDismiss(event: Event): Promise<void> {
    this.componentStore.dismissToast({ buttonRole: (event as CustomEvent).detail.role });
  }

  onRetrySendMessage(messageId: number): void {
    this.componentStore.retryReply({ id: messageId });
  }

  onReply(input: IonTextarea, threadId: number): void {
    this.dummyText.setFocus();
    // #11758 ios sometimes has box resizing issues
    timer(100).subscribe(() => this.textArea.setFocus());
    this.componentStore.reply({ content: input.value });
    this.onReplyContentChanged('', threadId);

    // text area sometimes doesn't get resized
    this.cdr.detectChanges();
  }

  onMarkAsRead(messageId: number): void {
    this.componentStore.markAsRead({ messageId: messageId });
  }

  onNewMessagesClick(tidemark: number): void {
    const element = document.getElementById('msg-' + tidemark);
    if (element) {
      this.content.scrollToPoint(0, element.offsetTop - 5, 500);
    }
    this.componentStore.resetNewMessageIdTidemark();
  }

  // Content will resize on keyboard show/hide but also as the reply text area grows/shrinks
  // We want to preserve the bottom scroll position where possible as this happens
  async onContentResize(entry: ResizeObserverEntry): Promise<void> {
    if (!this.scrollInfo) {
      // When the view is being created we may or may not get an intial scroll event
      // for priming our scrollInfo, we will always get a content resize event though
      // so we use this as the primer
      if (entry.contentRect.height > 0) {
        this.updateScrollState();
      }
      return;
    }
    // console.log('Content resize', this.scrollInfo, entry);
    // console.log('Scroll (content resize) - Content height: ' + entry.contentRect.height);
    const bottomPosition = this.scrollInfo.scrollTop + this.scrollInfo.clientHeight;
    const newTopPosition = bottomPosition - entry.contentRect.height;
    //console.log('Scroll (content resize) - New top: ' + newTopPosition);
    this.content.scrollToPoint(0, newTopPosition, 0);
  }

  onReplyContentChanged(value: string, threadId: number): void {
    this.replyContent = value;
    this.store.dispatch(
      ChatThreadReplyCacheActions.replyChanged({ chatThreadReply: { chatThreadId: threadId, content: value } })
    );
  }

  onRefreshChat(threadId: number): void {
    this.store.dispatch(ChatThreadPendingReplyActions.clearFailed({ threadId: threadId }));
    this.store.dispatch(
      ChatThreadsActions.updateThread({
        update: { id: threadId, changes: { closedOn: new Date().toUTCString() } }
      })
    );
  }

  onPendingClosureResize(): void {
    this.content.scrollToBottom(0);
  }

  onParticipantMessageAdded(id: string): void {
    this.componentStore.messageRendered({ id: id });
  }

  onPendingMessageAdded(id: string): void {
    this.componentStore.pendingMessageRendered({ id: id });
  }

  onRetryLoad(): void {
    this.store.dispatch(ChatThreadActions.retryLoad());
  }

  onSaveCalendar(event: Event): void {
    const evt = event as CustomEvent<CalendarEvent>;
    this.store.dispatch(
      new SaveCalendarEvent({
        event: evt.detail
      })
    );
  }

  onUpdate(): void {
    this.store.dispatch(visitAppStore());
  }
}
