import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Payment } from 'src/app/services/payment.service';
import { PaymentIntentTracking } from '../reducers/payment-intent.reducer';

export const startIntentProcessing = createAction('[Payment Intent] Start Intent Processing');
export const addIntent = createAction(
  '[Payment Intent] Add Intent',
  props<{
    intent: PaymentIntentTracking;
  }>()
);
export const updateIntent = createAction(
  '[Payment Intent] Update Intent',
  props<{
    update: Update<PaymentIntentTracking>;
  }>()
);
export const deleteIntent = createAction(
  '[Payment Intent] Delete Intent',
  props<{
    id: number;
  }>()
);
export const checkIntent = createAction(
  '[Payment Intent] Check Intent',
  props<{
    intent: PaymentIntentTracking;
  }>()
);
export const checkIntentSuccess = createAction(
  '[Payment Intent] Check Intent Success',
  props<{
    id: number;
    amount: number;
    payment: Payment;
  }>()
);
export const checkIntentFail = createAction(
  '[Payment Intent] Check Intent Fail',
  props<{
    id: number;
    code: number;
    reason: string;
  }>()
);
