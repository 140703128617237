import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChatService } from 'src/app/services/chat.service';
import { applyTimer } from 'src/app/services/timer';
import { ChatThreadPendingReplyActions } from '../actions/chat-thread-pending-reply.action';
import { ChatThreadsActions } from '../actions/chat-threads.action';
import { getChatThreadPendingReplyById } from '../selectors/chat-thread-pending-reply.selector';

@Injectable()
export class ChatThreadPendingReplyEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private chatService: ChatService
  ) {}

  reply$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadPendingReplyActions.reply),
      mergeMap((action) => {
        return applyTimer(this.chatService.putChatThreadMessage(action.schoolId, action.threadId, action.content)).pipe(
          map((result) =>
            ChatThreadPendingReplyActions.replySuccess({ id: action.id, threadId: action.threadId, dto: result })
          ),
          catchError((err) => {
            const error = err.status === 0 ? 'offline' : err.error?.ErrorCode === 2000 ? 'closed' : 'unknown';
            return of(ChatThreadPendingReplyActions.replyFail({ id: action.id, error: error }));
          })
        );
      })
    )
  );

  // Update the chats store with the newly sent message details
  replySuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadPendingReplyActions.replySuccess),
      mergeMap((action) => {
        return of(ChatThreadsActions.messageCreated({ threadId: action.threadId, dto: action.dto }));
      })
    )
  );

  retryReply$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatThreadPendingReplyActions.retryReply),
      concatLatestFrom((action) => this.store.select(getChatThreadPendingReplyById({ id: action.id }))),
      mergeMap(([action, pendingReply]) => {
        return of(
          ChatThreadPendingReplyActions.reply({
            id: action.id,
            threadId: pendingReply.threadId,
            schoolId: pendingReply.schoolId,
            content: pendingReply.content
          })
        );
      })
    )
  );
}
