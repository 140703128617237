import { createAction, props } from '@ngrx/store';
import { AppReleaseDto } from 'src/app/services/app-version.api.service';

export const coldStartCheck = createAction('[App Version] Cold Start Check');
export const resumeCheck = createAction('[App Version] Resume Check');
export const checkVersionApi = createAction('[App Version] Check Version Api');
export const checkVersionApiSuccess = createAction(
  '[App Version] Check Version Api Success',
  props<{ result: AppReleaseDto }>()
);
export const checkVersionApiFailure = createAction(
  '[App Version] Check Version Api Failure',
  props<{ message: string }>()
);
export const clearAvailableUpdateVersion = createAction('[App Version] Clear Available Update Version');
export const showUpdateAvailable = createAction('[App Version] Show Update Available', props<{ version: string }>());
export const visitAppStore = createAction('[App Version] Visit App Store');
