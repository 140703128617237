import { Action, createReducer, on } from '@ngrx/store';
import { ChatThreadsFilterSettingsActions } from '../actions/chat-threads-filter-settings.action';
import * as coreActions from '../actions/core.action';

export interface AboutOption {
  studentId: number;
  forename: string;
  surname: string;
}
export interface State {
  aboutFilter: AboutOption[];
  aboutOptions: AboutOption[];
}

export const initialState: State = {
  // About options are dynamic and will change as students are added/removed
  aboutFilter: null,
  aboutOptions: null
};

export const theReducer = createReducer(
  initialState,
  on(ChatThreadsFilterSettingsActions.updateFilters, (state, action) => {
    return { ...state, aboutFilter: action.aboutFilter };
  }),
  on(ChatThreadsFilterSettingsActions.aboutOptionsChanged, (state, action) => {
    return { ...state, aboutFilter: action.aboutFilter, aboutOptions: action.options };
  })
);
export function reducer(state: State | undefined, action: Action): any {
  switch (action.type) {
    case coreActions.REFRESH_CORE_SUBSCRIPTION_CHANGED:
      return initialState;
    default:
      return theReducer(state, action);
  }
}
