import { AccountEffects } from './account.effect';
import { AppVersionEffects } from './app-version.effect';
import { BadgeEffects } from './badge.effect';
import { ChannelEffects } from './channel.effect';
import { ChatMessageReceiptsEffects } from './chat-message-receipts.effect';
import { ChatPushLoadProgressEffects } from './chat-push-load-progress.effect';
import { ChatThreadPendingReplyEffects } from './chat-thread-pending-reply.effect';
import { ChatThreadReplyCahceEffects } from './chat-thread-reply-cache.effect';
import { ChatThreadEffects } from './chat-thread.effect';
import { ChatThreadsFilterSettingsEffects } from './chat-threads-filter-settings.effect';
import { ChatThreadsEffects } from './chat-threads.effect';
import { ContactDetailsEffects } from './contact-details.effect';
import { CoreEffects } from './core.effect';
import { ItemReactionsEffects } from './item-reactions.effect';
import { ItemTranslationEffects } from './item-translation.effect';
import { LanguageEffects } from './languages.effect';
import { OnboardingEffects } from './onboarding.effect';
import { PaymentIntentEffects } from './payment-intent.effect';
import { SchoolEffects } from './school.effect';
import { SettingsEffects } from './settings.effect';
import { SlipPaymentIntentEffects } from './slip-payment-intent.effect';
import { SlipQuestionResponseEffects } from './slip-question-response.effect';
import { StudentsEffects } from './students.effect';

export const effects: any[] = [
  OnboardingEffects,
  CoreEffects,
  SchoolEffects,
  ChannelEffects,
  AccountEffects,
  SettingsEffects,
  StudentsEffects,
  ContactDetailsEffects,
  PaymentIntentEffects,
  SlipPaymentIntentEffects,
  AppVersionEffects,
  ItemTranslationEffects,
  LanguageEffects,
  SlipQuestionResponseEffects,
  ItemReactionsEffects,
  ChatThreadsEffects,
  ChatMessageReceiptsEffects,
  BadgeEffects,
  ChatThreadReplyCahceEffects,
  ChatThreadEffects,
  ChatThreadPendingReplyEffects,
  ChatThreadsFilterSettingsEffects,
  ChatPushLoadProgressEffects
];

export * from './account.effect';
export * from './app-version.effect';
export * from './channel.effect';
export * from './contact-details.effect';
export * from './core.effect';
export * from './item-translation.effect';
export * from './languages.effect';
export * from './onboarding.effect';
export * from './payment-intent.effect';
export * from './school.effect';
export * from './settings.effect';
export * from './slip-question-response.effect';
export * from './students.effect';

{
  // EMPTY
}
