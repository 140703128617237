import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../actions/slip-question-response.action';

export interface SlipQuestionResponse {
  // using form studentId:componentId
  id: string;
  payload: any;
  modifiedTimestamp: number;
}
export type State = EntityState<SlipQuestionResponse>;
export const slipQuestionResponseAdapter: EntityAdapter<SlipQuestionResponse> =
  createEntityAdapter<SlipQuestionResponse>();

export const initialState: State = slipQuestionResponseAdapter.getInitialState();

export const theReducer = createReducer(
  initialState,
  on(actions.upsertQuestionResponse, (state, action) => slipQuestionResponseAdapter.upsertOne(action.data, state)),
  on(actions.deleteQuestionResponse, (state, action) => slipQuestionResponseAdapter.removeOne(action.id, state))
);
export function reducer(state: State | undefined, action: Action): any {
  return theReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = slipQuestionResponseAdapter.getSelectors();
