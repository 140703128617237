/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/chat-thread.reducer';

export const getChatThreadState = createFeatureSelector<State>('chatThreadState');

export const getChatThreadId = createSelector(getChatThreadState, (state) => {
  return state.id;
});

export const getChatThread = createSelector(getChatThreadState, (state) => {
  return state.thread;
});

export const getLoadMethod = createSelector(getChatThreadState, (state) => {
  return state.loadMethod;
});

export const getChatThreadModel = createSelector(getChatThreadState, (state) => {
  return {
    thread: state.thread,
    loading: state.loading,
    loadReason: state.loadReason,
    loadFailReason: state.loadFailReason
  };
});
