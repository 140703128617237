import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupportedLanguage } from '../store/reducers/languages.reducer';

export class ItemTranslationDto {
  title: string;
  message: string;
  language: string;
}
@Injectable({
  providedIn: 'root'
})
export class TranslationApiService {
  constructor(private http: HttpClient) {}

  translateArticle(articleId: number, language: string): Observable<ItemTranslationDto> {
    return this.http.post<ItemTranslationDto>(
      environment.apiBaseUrl + `/api/article/${articleId}/translate?language=${language}`,
      null
    );
    // return new Observable<ItemTranslationDto>((subscriber) => {
    //   subscriber.next({
    //     title: 'Translated Article Title',
    //     message: '你好, 你叫什么名字？',
    //     language: language
    //   });
    //   subscriber.complete();
    //   //subscriber.error({ status: 400, error: { ErrorCode: 2000 } });
    // }).pipe(delay(2000));
  }

  translateAnnouncement(
    schoolId: number,
    announcementId: number,
    studentId: number,
    language: string
  ): Observable<ItemTranslationDto> {
    return this.http.post<ItemTranslationDto>(
      environment.apiBaseUrl +
        `/api/school/${schoolId}/announcements/${announcementId}/student/${studentId}/translate?language=${language}`,
      null
    );
    // return new Observable<ItemTranslationDto>((subscriber) => {
    //   subscriber.next({
    //     title: 'Translated Announcement Title',
    //     message: 'Translated Announcement Message',
    //     language: language
    //   });
    //   subscriber.complete();
    // }).pipe(delay(2000));
  }

  getLanguages(): Observable<SupportedLanguage[]> {
    return this.http.get<SupportedLanguage[]>(environment.apiBaseUrl + '/api/languages');
  }
}
